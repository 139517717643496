<template>
    <div>
      <b-card
        tag="article"
        id="card_links"
        border-variant="white"
        style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px; background-color:#EBF0F4"
        class="mt-0 mb-2"
      >
      <div class="shadow-none mx-0 mt-2">
        <h4 class="pt-2" style="font-weight: 500 !important; font-size: 18px !important;">
        <img style="width: 32px; margin-top: -8px;" src="../../assets/surveys.svg" alt="Boletin"/> 
        <span class="ml-2">Encuesta semanal</span>
      </h4>
      </div>
       <b-card-body class="p-0 mb-4" v-if="loading">
          <div class="d-flex justify-content-center">
            <span style="font-weight: 500 !important;">Cargando...</span>
            <b-spinner class="ml-auto"></b-spinner>
          </div>
        </b-card-body>
        <div class="text-center pt-3 pb-3" v-if="!loading && !survey" >No hay encuesta semanal disponible</div>
        <div class="text-center pt-3 pb-3" v-if="!loading && answered && survey" >Usted ya ha respondido la encuesta "{{ text }}"</div>

        <b-card-body class="p-0 mb-4" v-if="!loading && !answered" > 
          <div v-if="typeSurvey === 'afirmation' && survey">
          <b-form-group 
          :label="text" class="d-flex justify-content-start flex-column" 
          style="text-align:left;flex-direction: column;" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-1"
                v-model="selected"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
                class="d-flex justify-content-start flex-column" style="text-align:left;flex-direction: column;"
              ></b-form-radio-group>
            </b-form-group>
          </div>
          <div v-if="typeSurvey === 'multi' && survey">
            <b-form-group :label="text" class="d-flex justify-content-start flex-column" 
          style="text-align:left;flex-direction: column;" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="checkbox-group-1"
                class="d-flex justify-content-start flex-column" style="text-align:left;flex-direction: column;"
                v-model="selected"
                :options="options"
                :aria-describedby="ariaDescribedby"
                name="flavour-1"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div v-if="error && survey" class="danger--text text--danger danger-text text-danger mt-2 mb-4">
            Debe seleccionar al menos una opción
          </div>
          <div class="d-flex justify-content-end" v-if="survey">
            <b-btn 
              type="button" 
              class="btn-ver ml-2"
              :disabled="sending || sended"
              @click="sendAnswer"
              >
              <b-spinner v-if="sending"></b-spinner>
              <span v-if="!sending && sended">Respuesta enviada</span>
              <span v-if="!sending && !sended">Enviar respuesta</span>
            </b-btn>
          </div>
        </b-card-body>
        <div style="max-height: 400px; overflow-y: auto; width: calc(100% + 10px); padding-right: 10px;" class="scroll">
          <div style="width: 100%" v-if="showBarGraph && answered && survey">
            <div v-for="answer in answers" :key="answer.value" class="containerSurveyAnswers">
              <div class="checkCircleFather">
                <div :class="answer.answered === true ? 'checked' : ''">
                  <b-icon icon="check" style="color:white;" aria-hidden="true" :style="answer.answered === true ? '' : 'display:none'" ></b-icon>
                </div>
              </div>
              <div class="barContainer">
                <div class="upperText">
                  <div class="answerText">
                    {{ answer.text }}
                  </div>
                  <div class="qttyText">
                    {{ answer.total }}
                  </div>
                </div>
                <div class="bar">
                  <div class="innerBar" :style="`width:${calculateWidth(answer.total)}%;`  "></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'Survey',
  components: {

  },
  data() {
      return {
        survey: null,
        loading: true,
        text: '',
        selected: '',
        sending: false,
        sended: false,
        loaded: false,
        typeSurvey: 'afirmation',
        user_id: '',
        error: false,
        answered: false,
        options: [
         /* { text: 'Totalmente de acuerdo', value: 'first' },
          { text: 'De acuerdo', value: 'second' },
          { text: 'Me es indiferente', value: 'third', },
          { text: 'En desacuerdo', value:  'fourth' },
          { text: 'Totalmente en desacuerdo', value:  'fith' }*/
        ],
        answers: [

        ],
        showBarGraph: false,
        moreHigh: 0
      }
    },
  mounted() {
    let userData = JSON.parse(localStorage.userData)
    this.user_id = userData[0].id
    this.getSurvey();
  },
  watch: {
    selected(){
      this.error = this.checkSelected() 
    }
  },
  methods: {
    checkSelected() {
      if (!this.loaded) {
        return false
      }
      if (this.typeSurvey === 'afirmation'){
        if (!this.selected) {
          return true
        } else {
          return false
        }
      }
      if (this.typeSurvey === 'multi'){
        if (this.selected.length < 1) {
          return true
        } else {
          return false
        }
      }
      return false
    },

    async getSurvey() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/survey-last-active';

      await axios.get(url, config).then(data => {
        if (data?.data.question_type){
          if (data?.data?.question_type === 'afirmation'){
            this.selected = ''
          } else {
            this.selected = []
          }
          this.text = data?.data?.question ?? ''
          this.typeSurvey = data?.data?.question_type
          let options = []
          data?.data?.answers_options.forEach(element => {
            options.push({
              text: element.answer_option, value: element.id
            })
          });
          this.options = options
          let options2 = [
            ...options
          ]
          options2.forEach(element => {
            element.answered = false
            element.total = 0
          });
          this.answers = options2
          this.survey = data?.data
        }
      })
      if (this.survey){
        await this.getSurveyInfo(this.survey.id)
      }
      this.loaded = true
      this.loading = false
    },

    async getSurveyInfo(id) {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/get-all-answers/'+id;

      let optionsAnswered = []

      await axios.get(url, config).then(data => {
        data.data.data.answers.forEach(element => {
          if (element?.id == this.user_id) {
            this.answered = true
            element.answers.forEach(answer => {
              if (answer.survey_id == id){
                optionsAnswered.push(answer.answer_option)
              }
            });
          }
        });
      })

      let url2 = process.env.VUE_APP_URL+'/api/auth/get-statistics/'+id;

      let answers = []


      await axios.get(url2, config).then(data => {
        if (data?.data?.data?.answers && Array.isArray(data?.data?.data?.answers)) {
          answers = data.data.data.answers
        }
      })

      let moreHigh = 0

      this.answers.forEach(element => {
        element.answered = optionsAnswered.some(answer => element.value === answer.id)
        let total = 0
        answers.forEach(answersData => {
          if (answersData.answer_option.id === element.value){
            total = answersData.total
          }
        });
        if (total > moreHigh){
          moreHigh = total
        }
        element.total = total
      });

      this.moreHigh = moreHigh

      this.showBarGraph = true
    },


    async sendAnswer(){
      if (this.checkSelected()) {
        this.error = this.checkSelected()
        return
      }
      this.sending = true
      
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

      let selected = this.typeSurvey === 'afirmation' ? [this.selected] :  this.selected

      const data ={
        user_id : this.user_id,
        survey_id: this.survey?.id,
        answer_option: selected
      }
      await axios.post(process.env.VUE_APP_URL+"/api/auth/answer", data, config)
        .then(() => {
          this.sended = true
          this.getSurveyInfo(this.survey.id)
        })
        .catch(() => {
          this.sended = true
        })
      this.sending = false
    },

    calculateWidth(total){
      if (this.moreHigh == 0){
        return 0
      } else {
        return (Number(total) / this.moreHigh) * 100
      }
    }
  },
}
</script>
<style>

.btn-ver{
  border: 1.4px solid #f0900f !important;
  background-color: transparent !important;
  color: #f0900f !important;
  font-size: 14px !important;
  padding: 3px 12px !important;
  max-height: 28px;
  transition: 0.3s ease-in;
}
.btn-ver:hover  {
  color: #FFFFFF !important;
  background-color: #f0900f !important;
}

.btn-ver svg{
  width:24px;
  height:24px;
  fill: #f0900f
}

.btn-ver:hover svg {
  fill: #FFFFFF  !important;
}

.hover-svg-dowload:hover svg{
  fill: #FFFFFF  !important;
}

.text-ellipsis-2-line {  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.checkCircleFather{
  width: 22px;
  height: 22px;
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3px;
}

.checkCircleFather div{
  border-radius: 50%;
  width: 22px;
  height: 22px;
  min-width: 22px;
  max-width: 22px;
  min-height: 22px;
  max-height: 22px;
  border: 1px solid rgba(116, 116, 116, 0.596);
}

.checkCircleFather .checked{
  background-color: rgb(240, 144, 15);
}

.containerSurveyAnswers{
  display: flex;
  width: 100%;
}

.barContainer{
  padding-left: 10px;
  width: 100%;
}

.upperText{
  display: flex;
  width: 100%;
  align-content: flex-end;
}

.answerText{
  width: 100%;
  text-align: start;
}

.qttyText{
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  text-align: end;
  font-size: 12px;
  height: fit-content;
  align-self: flex-end;
}

.bar{
  width: 100%;
  border-radius: 12px;
  background-color: rgb(158, 158, 158);
  height: 12px;
  margin-top: 2px;
  margin-bottom: 8px;
}

.innerBar{
  height: 100%;
  background-color: rgb(240, 144, 15);
  border-radius: 12px;
}
</style>
