<template>
    <div>
      <b-card
        tag="article"
        id="card_links"
        border-variant="white"
        style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px; background-color:#EBF0F4"
        class="mt-0 mb-2"
      >
      <div class="shadow-none mx-0 mt-2">
        <h4 class="pt-2" style="font-weight: 500 !important; font-size: 18px !important;">
        <img style="width: 32px; margin-top: -8px;" src="../../assets/ballon.png" alt="ballon"/> 
        <span class="ml-2">Cumpleaños y Eventos</span>
      </h4>
      </div>
       <b-card-body class="p-0 mb-4" v-if="!loadedBirthdays || !loadedEvents">
          <div class="d-flex justify-content-center">
            <span style="font-weight: 500 !important;">Cargando...</span>
            <b-spinner class="ml-auto"></b-spinner>
          </div>
        </b-card-body>
        <div v-if="loadedBirthdays && loadedEvents" class="mt-3 px-0 mx-0 d-flex mb-1">
              <div
                  class="m-0 pr-0 pl-0 d-flex justify-content-start"
                  @click="showNotifications('birthdays')"
              >
                <b-btn
                  id="birthdays_btn"
                  style="font-size: 13px; font-weight: 400 !important; width:110px; !important;"
                  :class="{ 'outline-btn-disabled' : !showBirthdays, 'outline-btn' : showBirthdays }"
                  class="py-1"
                >
                  Cumpleaños
                </b-btn>
              </div>
              <div
                  class="m-0 pl-2 d-flex justify-content-start"
                  @click="showNotifications('events')"
              >
                <b-btn
                  id="events_btn"
                  style="font-size: 13px; font-weight: 400 !important; width:110px; !important;"
                  :class="{ 'outline-btn-disabled' : !showEvents, 'outline-btn' : showEvents }"
                  class="py-1"
                >
                  Eventos
                </b-btn>
              </div>
            </div>
        <b-card-body v-if="loadedBirthdays && showBirthdays && loadedEvents" class="scroll scroll-changed-position birthdays_scroll p-0">
            <div  style="font-size:11px;text-align:left;font-weight:500;padding: 0px 0px 0px 20px" class="grayblue-text mb-2">
                HOY CELEBRAMOS A NUESTROS COLABORADORES CYM
            </div>
            <div 
                v-for="birthDay in birthDays" :key="birthDay.id"
                class="d-flex  justify-content-between align-items-center mb-2 pr-2 "
                style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important;padding: 0px 20px 0px 20px"
                >
                <div class="d-flex">
                    <b-avatar variant="warning" v-if="birthDay.image === '' || !birthDay.image" :text="$showInitials(birthDay.name, birthDay.lastname)" size="3.5rem"></b-avatar>
                    <b-avatar variant="warning" v-else :src="setImage(birthDay.image)" size="3.5rem"></b-avatar>
                    <div class="ml-2 d-flex flex-column justify-content-center" >
                        <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                            {{ formatDateToWord(birthDay.birthday) }}
                        </div>
                        <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                            {{ `${birthDay.name} ${birthDay.lastname ? birthDay.lastname : ''}` }}
                        </div>
                        <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                            {{ birthDay.position }}
                        </div>
                    </div>
                </div>
                <b-btn 
                  v-if="dataUser[0].id !== birthDay.id"
                  type="button" 
                  class="btn-ver ml-2"
                  @click="setMessage(birthDay)">
                  Saludar
                </b-btn>
            </div>
            <div 
                v-if="birthDays.length < 1"
                class="d-flex  justify-content-center align-items-center mb-2 pr-2 "
                style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important;padding: 0px 20px 0px 20px"
                >
                <div class="text-center pt-3 pb-3">Hoy no hay cumpleañeros</div>
            </div>
            <div v-if="nextBirthDays.length > 0" style="font-size:11px;text-align:left;font-weight:400;background-color:#7E9FB5;color:#FFFFFF;padding: 0px 20px 0px 20px" class="">
                PRÓXIMOS CUMPLEAÑOS
            </div>
            <div style="background-color:#FFFFFF" class="pt-2">
                <div 
                    v-for="birthDay in nextBirthDays" :key="birthDay.id"
                    class="d-flex  justify-content-between align-items-center pb-2 pr-2 "
                    style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important; padding: 0px 20px 0px 20px"
                    >
                    <div class="d-flex">
                        <b-avatar variant="warning" v-if="birthDay.image === '' || !birthDay.image" :text="$showInitials(birthDay.name, birthDay.lastname)" size="3.5rem"></b-avatar>
                        <b-avatar variant="warning" v-else :src="setImage(birthDay.image)" size="3.5rem"></b-avatar>
                        <div class="ml-2 d-flex flex-column justify-content-center" >
                            <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                                {{ formatDateToWord(birthDay.birthday) }}
                            </div>
                            <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                              {{ `${birthDay.name} ${birthDay.lastname ? birthDay.lastname : ''}` }}
                            </div>
                            <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                                {{ birthDay.position }}
                            </div>
                        </div>
                    </div>
                    <b-btn 
                      v-if="dataUser[0].id !== birthDay.id"
                      type="button" 
                      class="btn-ver ml-2"
                      @click="setMessage(birthDay)">
                      Saludar
                    </b-btn>
                </div>
            </div>
          </b-card-body>

        <b-card-body v-if="loadedBirthdays && loadedEvents && showEvents" class="scroll scroll-changed-position birthdays_scroll p-0">
            <div style="font-size:11px;text-align:left;font-weight:500;padding: 0px 0px 0px 20px" class="grayblue-text mb-2">
                EVENTOS DE HOY EN CYM
            </div>
            <div 
                v-for="event in todayEvents" :key="event.id"
                class="d-flex  justify-content-between align-items-center mb-2 pr-2 "
                style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important;padding: 0px 20px 0px 20px"
                >
                <div class="d-flex">
                    <b-avatar variant="light" icon="calendar-event" size="2.9rem"></b-avatar>
                    <div class="ml-2 d-flex flex-column justify-content-center" >
                        <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                            {{ getDate(event.start) }}
                        </div>
                        <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                            {{ event.title }}
                        </div>
                        <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                            {{ event.description }}
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-if="todayEvents.length < 1"
                class="d-flex  justify-content-center align-items-center mb-2 pr-2 "
                style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important;padding: 0px 20px 0px 20px"
                >
              <div class="text-center pt-3 pb-3">Hoy no hay eventos</div>
            </div>
            <div v-if="nextEvents.length > 0" style="font-size:11px;text-align:left;font-weight:400;background-color:#7E9FB5;color:#FFFFFF;padding: 0px 20px 0px 20px" class="">
                PRÓXIMOS EVENTOS
            </div>
            <div style="background-color:#FFFFFF" class="pt-2">
                <div 
                    v-for="event in nextEvents" :key="event.id"
                    class="d-flex  justify-content-between align-items-center pb-2 pr-2 "
                    style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important; padding: 0px 20px 0px 20px"
                    >
                    <div class="d-flex">
                        <b-avatar variant="secondary" icon="calendar-event" size="2.9rem"></b-avatar>
                        <div class="ml-2 d-flex flex-column justify-content-center" >
                            <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                                {{ getDate(event.start) }}
                            </div>
                            <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                                {{ event.title }}
                            </div>
                            <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                                {{ event.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </b-card-body>
        </b-card>
        <div class="form-saludo" v-if="modalMessage">
          <b-card
            border-variant="white"
            style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 4px; background-color:#FFFFFF"
            class="mt-0 mb-2"
          >
            <b-icon 
              @click="modalMessage = false" 
              icon="x" 
              style="width: 25px; height: 25px; color:black;position:absolute; right:10px;cursor:pointer" 
              aria-hidden="true">
            </b-icon>
            <template v-if="!sendedMessage">
              <div style="text-align:left;font-size:13px;line-height: 16px;font-weight:500" class="mb-3 mt-4">Envia un mensaje de felicitaciones a {{  nameUser }}</div>
              <b-form-group
                id="input-group-1"
                label=""
                label-for="input-1"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="message"
                  rows="3"
                  max-rows="6"
                  maxlength="200"
                  ></b-form-textarea>
              </b-form-group>
              <b-btn 
                type="button" 
                block
                class="btn-ver mb-2 "
                @click="postMessage()"
                :disabled="message === ''">
                Felicitar
              </b-btn>
              <b-btn 
                type="button" 
                block
                class="btn-ver mb-2 d-none"
                @click="postMessage()">
                Enviar sin mensaje
              </b-btn>
            </template>
            <template v-if="sendedMessage">
              <div>
                <div style="text-align:center;font-size:15px;line-height: 16px;font-weight:500" class="mb-0 mt-2">¡Saludo enviado!</div>
                <lottie :options="defaultOptions" :height="260" :width="260" v-on:animCreated="handleAnimation"/>
              </div>
            </template>
            <template v-if="loadingMessage">
              <div class="d-flex justify-content-center align-content-center div-loading-modal">
                <b-spinner style="margin-top:40%"></b-spinner>
              </div>
            </template>
          </b-card>
        </div>
        <b-modal ref="modal-messages" hide-footer hide-header size="lg"  content-class="modal-messages">
          <b-icon 
              @click="hideModal()" 
              icon="x" 
              style="width: 40px; height: 40px; color:white;position:absolute; right:0px;cursor:pointer;z-index:100" 
              aria-hidden="true">
          </b-icon>
          <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="10000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="800"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            <!-- Slide with blank fluid image to maintain slide aspect ratio -->
            <b-carousel-slide v-for="message in messages" :key="message.id" class="carrousel-background-grey"  img-blank img-alt="Blank image">
              <div class="min-height:1000px">
              <lottie :options="defaultOptions" :height="260" :width="260" class="lottie-anim-ballons" v-on:animCreated="handleAnimation"/>
              <div class="d-flex">
                  <b-avatar variant="warning" v-if="message.user_from.image === '' || !message.user_from.image" :text="$showInitials(message.user_from.name, message.user_from.lastname)" size="3.5rem"></b-avatar>
                  <b-avatar variant="warning" v-else :src="setImage(message.user_from.image)" size="3.5rem"></b-avatar>
                  <div class="ml-2 d-flex flex-column justify-content-center" >
                      <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                          {{ `${message.user_from.name} ${message.user_from.lastname ? message.user_from.lastname : ''}` }}
                      </div>
                      <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                          {{ message.user_from.position }}
                      </div>
                  </div>
              </div>
              <p class="mt-2 p-message-birthday">
                {{message.message}}
              </p>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </b-modal>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { orderBy } from 'lodash';
import Lottie from './Lottie.vue';
import * as animationData from './../../assets/lottieBallons.json';

export default {
  name: 'Birthdays',
  components: {
    'lottie': Lottie
  },
  data() {
      return {
        slide: 0,
        sliding: null,
        defaultOptions: {animationData: animationData},
        modalMessage:false,
        message:'',
        idUser:'',
        nameUser:'',
        links: [],
        text: '',
        loading: true,
        rol: '',
        showBirthdays: true,
        showEvents: false,
        events: [],
        next_event: [],
        interval: null,
        today: null,
        birthDays:[],
        nextBirthDays: [],
        todayEvents:[],
        nextEvents: [],
        urlImgBack: process.env.VUE_APP_URL+'/storage/',
        todayDay: '',
        loadedBirthdays:false,
        loadedEvents:false,
        sendedMessage: false,
        loadingMessage: false,
        dataUser: [ { id: 0 } ],
        messages: []
      }
    },
  mounted() {
    this.rol = (localStorage.userRol);
    this.dataUser = JSON.parse(localStorage.userData)
    this.today = moment().format('YYYY-MM-DD') 
    moment().locale('es')
    Promise.all([ 
        this.getMessagesUser(),
        this.getNextBirthdays(),
        this.getNextEvent()
    ]).then(values=>{
      if (!values){
        console.log(values)
      }
    })
  },
  watch: {
    slide(){
      this.readMessage(this.messages[this.slide])
      this.messages[this.slide].usedRead = true
    }
  },
  methods: {
    onSlideStart(slide) {
      console.log(slide)
      this.sliding = true
    },
    onSlideEnd(slide) {
      console.log(slide)
      this.sliding = false
    },
    showModal() {
      this.$refs['modal-messages'].show()
    },
    hideModal() {
      this.$refs['modal-messages'].hide()
    },
    handleAnimation: function (anim) {
      this.anim = anim;
    },

    showNotifications(type) {
      if(type == 'birthdays') {

        document.getElementById("events_btn").classList.remove("btn_active");
        document.getElementById("events_btn").classList.add("btn_inactive");
        document.getElementById("birthdays_btn").classList.remove("btn_inactive");
        document.getElementById("birthdays_btn").classList.add("btn_active");

        this.showBirthdays = true;
        this.showEvents = false;

      } else if(type == 'events') {
        this.modalMessage = false

        document.getElementById("events_btn").classList.remove("btn_inactive");
        document.getElementById("events_btn").classList.add("btn_active");
        document.getElementById("birthdays_btn").classList.remove("btn_active");
        document.getElementById("birthdays_btn").classList.add("btn_inactive");

        this.showBirthdays = false;
        this.showEvents = true;
      }
    },

    setMessage(birthDay){
      if (this.idUser === birthDay.id && this.modalMessage === true){
        return
      }
      this.loadingMessage = false
      this.sendedMessage = false
      this.modalMessage = false
      this.message = ''
      this.idUser = birthDay.id
      this.nameUser = birthDay.name
      setTimeout(() => {
        this.modalMessage = true
      }, 1);
    },
    truncateText(text, length = 255) {
      if (!text) return '';

      let content = text.trim();
      if(content.length>length) {
        content = content.substring(0, length);
        content = content + ('...');
      }

      return content;
    },
    formatDateToWord(date) {
      return date ? moment(date).locale('es').format('DD [de] MMMM') : ''
    },
    setImage(url) {
      return url ? this.urlImgBack + url : ''
    },
    checkIfTodayBirthDay(date){
      if (!date) {
        return false
      }
      if (moment(date).locale('es').format('DD [de] MMMM') === this.todayDay) {
        return true
      }
      else {
        return false
      }
    },
    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },
    getNextBirthdays() {
      this.loadedBirthdays = false
      this.todayDay = moment().locale('es').format('DD [de] MMMM')

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/get-birthdays';

      axios.get(url, config).then(async (data) => {
        this.birthDays = []
        this.nextBirthDays = []
        if (data.data.data){
           data.data.data.forEach(element => {
            if (this.checkIfTodayBirthDay(element.birthday)){
              this.birthDays.push(element)
            }
            if (!this.checkIfTodayBirthDay(element.birthday)) {
              this.nextBirthDays.push(element)
            }
          });
        }
        this.changeYearsNextBirthDaysAndSort()
        this.loadedBirthdays = true
      }).catch(error => {
        this.loadedBirthdays = true
        console.log(error)
      })
    },
    changeYearsNextBirthDaysAndSort(){
      if (this.nextBirthDays.length < 2) {
        return
      }
      
      this.nextBirthDays .forEach(element => {
        if (moment().format('MM') == '12'){
          element.birthday = `${
            moment(element.birthday).format('MM') == '12' 
              ? moment().format('YYYY') 
              : Number(moment().format('YYYY')) + 1
          }${element.birthday.slice(4,10)}`
        }
        else {
          element.birthday = `${moment().format('YYYY')}${element.birthday.slice(4,10)}`
        }
      });

      this.nextBirthDays  = this.nextBirthDays.sort((a,b) => new moment(a.birthday).format('YYYYMMDD') - new moment(b.birthday).format('YYYYMMDD')) 
    },
    postMessage() {
      this.loadingMessage = true

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/store-messages';

      let formData = new FormData();
      formData.append('from', this.dataUser[0].id);
      formData.append('to', this.idUser);
      let messagePayload = this.message
      if (this.message.trim === '' || !this.message) {
        messagePayload = `¡${this.dataUser[0].name} te ha enviado saludos por tu cumpleaños!`
      }
      formData.append('message', messagePayload);

      axios.post(url, formData, config).then(async (data) => {
        console.log(data)
        this.sendedMessage = true
        this.loadingMessage = false
        setTimeout(() => {
          this.modalMessage = false
        }, 2700);
      }).catch(error => {
        this.loadingMessage = false
        console.log(error)
        this.makeToast('danger', 'Ha ocurrido un error al enviar el mensaje', 'Error fatal.')
      })
    },
    getMessagesUser() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/get-messages/${this.dataUser[0].id}/false`;

      axios.get(url, config).then(async (data) => {
        this.messages = []
        data.data.data
        data.data.data.forEach(element => {
          element.usedRead = false
        })
        if (data.data.data.length > 0) {
          this.messages = data.data.data
          this.showModal()
          this.readMessage(this.messages[0])
          this.messages[0].usedRead = true
        }
        //console.log(data)
      }).catch(error => {
        console.log(error)
      })
    },

    readMessage(message) {
      if (message.usedRead) {
        return
      }
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/read-message/${message.id}`;

      axios.get(url, config).then(async (data) => {
        console.log(data)
      }).catch(error => {
        console.log(error)
      })
    },

    async getNextEvent() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/events';

      await axios.get(url, config).then(async (data) => {
        let rruleFixedEvents = data.data
        for (let i = 0; i < rruleFixedEvents.length; i++) {
          if (rruleFixedEvents[i].rrule){
            rruleFixedEvents[i].rrule = `${rruleFixedEvents[i].rrule.replace("Z", "")}` 
          }
        }
       let events = await rruleFixedEvents.map((e) => {
            if ((e.rrule && (e.rrule.includes('FREQ=WEEKLY') ||  e.rrule.includes('FREQ=MONTHLY')))) {
              e.start = e.start.replace(moment(e.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM'), moment().format('YYYY-MM').concat(`-${moment(moment().weekday(8)).format('DD')}`))
            }
            return e
          })

          events = await events.filter((e) => {
            return moment(moment(e.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')).isSameOrAfter(this.today) || (e.rrule && (e.rrule.includes('FREQ=WEEKLY') ||  e.rrule.includes('FREQ=MONTHLY')))
          })
          let index = 0;

          events = await orderBy(
          events,
          [
            e => {
              return moment(e.start, 'YYYY-MM-DD HH:mm:ss').unix();
            },
          ],
          ['asc'])


          this.next_event = [events[index]]
          /*this.interval = setInterval(() => {
            this.next_event = [events[index]]
            index = (index+1) < events.length ? (index+1) : 0
          }, 10000)*/ 
          //console.log(events,'events')
          //console.log(this.next_event)
          
          events.forEach(element => {
            if (this.checkDate(element.start) === 'Hoy'){
              this.todayEvents.push(element)
            }
            if (this.checkDate(element.start) === 'Próximo evento'){
              this.nextEvents.push(element)
            }
          });
          
          //this.$emit('notification')
      })

      this.loadedEvents = true
    },

    checkDate(date) {
      return moment(this.today).isSame(date, 'day') ? 'Hoy' : 'Próximo evento'
    },

    getDate(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').locale('es').format('DD [de] MMMM');
    }
  },
  
}
</script>
<style>
.card > .position-relative > img {
    height: 180px !important;
}

#card_links{
  border: none !important;
}

#card_links.scroll {
  max-height: 550px;
  overflow-y: auto !important;
}

.birthdays_scroll {
  max-height: 400px !important;
  overflow-y: auto;
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  top: 8px;
  padding: 0px !important;
}


/* Tamaño del scroll */
.scroll::-webkit-scrollbar {
  width: 8px;
}

 /* Estilos barra (thumb) de scroll */
.scroll::-webkit-scrollbar-thumb {
  background: #B57459;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #B57459;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

 /* Estilos track de scroll */
.scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-track:hover, 
.scroll::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.btn_active {
    text-align: center;
    color: #47B29E;
    border: 1px solid #47B29E;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
    margin-left:15px!important;
    font-size:14px;
}

.btn_active.col-md-3.offset-md-1{
  max-width: 50%!important;
}

.btn_inactive.col-md-3.offset-md-1{
  max-width: 50%!important;
}

@media screen and (max-width: 1280px){
  .btn_inactive{
    font-size:15px!important;
    padding-left:5px;
    padding-right:5px;
  }
  .btn_active{
    font-size:15px!important;
    padding-left:5px;
    padding-right:5px;
  }
}

.btn_inactive {
    text-align: center;
    letter-spacing: 0px;
    color: #B0B0B2;
    opacity: 1;
    background: #F4F6F8 0% 0% no-repeat padding-box;
    border-radius: 0px;
    opacity: 1;
    cursor: pointer;
    font-size: 14px;
    height: 18px;
}

.outline-btn-disabled {
  border: 1.4px solid #f4f6f8 !important;
  background-color: #f4f6f8 !important;
  border-radius: 0px !important;
  color: #aaaaaa !important;
}

.btn-ver{
  border: 1.4px solid #f0900f !important;
  background-color: transparent !important;
  color: #f0900f !important;
  font-size: 14px !important;
  padding: 3px 12px !important;
  max-height: 28px;
  transition: 0.3s ease-in;
}
.btn-ver:hover  {
  color: #FFFFFF !important;
  background-color: #f0900f !important;
}

.btn-ver svg{
  width:24px;
  height:24px;
  fill: #f0900f
}

.btn-ver:hover svg {
  fill: #FFFFFF  !important;
}

.text-ellipsis-2-line {  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.grayblue-text{
    color: #5F7D91;
}

.form-saludo{
  position: absolute;
  width: 290px;
  top: 75px;
  left: calc(100% - 5px);
  z-index: 10;
  opacity: 0;
  animation: showingUp 200ms ease-in-out forwards;
}

@keyframes showingUp{
    from  { opacity: 0;  }
    to { opacity: 1; }
  }

@media screen and (max-width: 767px) {
  .form-saludo{
    top: 65px;
    left: calc((100% - 290px) / 2);
  }
}

.div-loading-modal{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color:#ebf0f47c
}

.modal-messages{
  background-color: transparent !important;
  border: none !important;
}

.modal-messages .modal-body{
  padding: 0px !important;
}

.carrousel-background-grey  {
  background-color:rgb(95, 125, 145) !important
}

@media screen and (max-width: 991px) {
  .lottie-anim-ballons  {
    width: 300px !important;
    height: 300px !important;
  }
  .carrousel-background-grey  {
    aspect-ratio: 0.9 !important;
  }
}

@media screen and (max-width: 460px) {
  .lottie-anim-ballons  {
    width: 230px !important;
    height: 230px !important;
  }
}

@media screen and (max-width: 430px) {
  .carrousel-background-grey  {
    aspect-ratio: 0.8 !important;
  }
}

@media screen and (max-width: 390px) {
  .carrousel-background-grey  {
    aspect-ratio: 0.75 !important;
  }
}

@media screen and (max-width: 365px) {
  .carrousel-background-grey  {
    aspect-ratio: 0.7 !important;
  }
}

@media screen and (max-width: 350px) {
  .carrousel-background-grey  {
    aspect-ratio: 0.6 !important;
  }
}

@media screen and (min-width: 992px) {
  .lottie-anim-ballons  {
    width: 400px !important;
    height: 400px !important;
  }
}
@media screen and (max-width: 575px) {
  .p-message-birthday{
    font-size: 13px;
  }
}

</style>
