<template>
   <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
         <b-row id="users">
          <b-col col sm="12">
              <b-row>
                  <b-col xs="12" sm="12">
                      <b-row align-v="center" class="mb-5 mt-4 px-0 mx-0">
                        <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
                          <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">
                            Links Útiles
                          </h3>
                        </b-col>

                        <b-col class="m-0 p-0" cols="12" md="6">
                          <b-input-group class="pl-0 pl-sm-5" style="margin-top: 20px;">
                                <b-input-group-prepend style="background-color: white; height: 38px;">
                                <img
                                  src="../assets/search-interface-symbol.svg" 
                                  style="height:20px; margin-top:0.5em;"
                                  class="px-2" 
                                />
                                </b-input-group-prepend>
                                <b-form-input v-model="text" @keyup="searchTable" style="border: 0;" placeholder="Buscar links"></b-form-input>
                            </b-input-group>
                          
                          </b-col>

                        <b-col
                          class="p-0 mt-3"
                          :style="`display: flex; ${isMobile ? 'justify-content: center; margin-top: 10px;' : 'justify-content: start;'}`" 
                          xs="12" sm="12" md="6">
                          <b-button 
                            type="button" 
                            class="btn ver crear"
                            v-if="rol == 'admin'"
                            @click="showModalCreate()"
                            :style="`color: #FFFFFF; ${!isMobile ? 'width: 160px;' : 'width: 100%;'}`">
                            Crear link útil
                          </b-button>
                        </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret style="margin-left: 85%;">
                  <template #button-content>
                    <div class="d-flex" style="height: 20px;">
                      <img style="height: 14px; margin-top: 3px; margin-right: 3px;" :src="mosaic ? require('../assets/mosaico2.png') : require('../assets/lista.png')"/>
                      <p style="margin-left: 5px; color: black;">Ver Vistas</p>
                    </div>
                  </template>
                  <b-dropdown-item href="#" @click="showList()"><img src="../assets/lista.png" style="color: black; margin-right: 5px;" >Lista</b-dropdown-item>
                  <b-dropdown-item href="#" @click="showMosaic()"><img src="../assets/mosaico2.png" style="color: black; margin-right: 5px;">Mosaico</b-dropdown-item>
              </b-dropdown>
              <div id="lista" class="ocultar mt-4">
                  <table class="table table-striped mt-5" id="table_links">
                    <thead class="table-dark">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">NOMBRE</th>
                        <th scope="col">DESCRIPCIÓN</th>
                        <th scope="col">LINK</th>
                        <th scope="col">FECHA</th>
                        <th scope="col">ACCIONES</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(link, index) in links" :key="index" :class="`${index%2 === 0 ? 'odd' : 'seen'}`">
                        <td ></td>
                        <td>{{ link.name }}</td>
                        <td>{{ truncateText(link.description) }}</td>
                        <td><a :href="'http://'+link.link" target="_blank">{{ link.link }}</a> </td>
                        <td>{{ getFormattedDate(link.created_at) }}</td>
                        <td style="display: flex;">
                            <b-button 
                            type="button" 
                            v-show="rol === 'admin'"
                            class="rounded-btn"
                            style="color: #FFFFFF; background: #f0900f no-repeat padding-box;"
                            @click="showModalEdit(link.id)">
                            <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                            </b-button>
                            <b-button
                              type="button"
                              class="rounded-btn ml-3"
                              style="background: #45bba9 no-repeat;"
                              @click="showModalView(link.id)">
                            <b-icon scale="0.8" icon="box-arrow-up-right"></b-icon>
                          </b-button> 
                            <b-button 
                            type="button" 
                            v-show="rol === 'admin'"
                            class="rounded-btn ml-3"
                            style="background: #dc3545 no-repeat;"
                            @click="showModalDelete(link.id)">
                            <b-icon scale="0.8" icon="trash-fill"></b-icon>
                            </b-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
              </div>

              <div id="mosaico" class="mostrar">
                <b-row style="margin: 0; padding-bottom: 10px;">
                  <b-col class="mb-2" v-for="(link, index) in links" :key="index" cols="12" sm="6" md="4">
                      <b-card no-body class="overflow-hidden" style="max-width: 540px; margin-top: 15px;">
                        <b-row no-gutters style="cursor: pointer;">
                          <b-col md="2" style="display: flex; align-items: center; justify-content: center;">
                            <div @click="showModalViewModal(link.id)" :class="(index == 0 || index % 2 == 0) ? 'brown' : 'green'" style="width: 30px; height: 30px; border-radius: 15px;">
                              <img src="../assets/Capa 1.png" />
                            </div>
                          </b-col>
                          <b-col md="10">
                            <b-card-body>
                              <b-card-text style="display:flex; justify-content:space-between; align-items:start;" class="mb-0">
                                <h4 @click="showModalViewModal(link.id)" class="pt-1">{{link.name}}</h4>
                               <div style="display:flex;">
                               <b-button 
                                type="button" 
                                class="rounded-btn edit-btn"
                                style="border:0;"
                                v-show="rol === 'admin'"
                                @click="showModalEdit(link.id)">
                                <b-icon scale="0.6" icon="pencil-fill"></b-icon>
                              </b-button>
                              <b-button 
                                type="button" 
                                v-show="rol === 'admin'"
                                class="rounded-btn delete-btn ml-1"
                                style="border:0;"
                                @click="showModalDelete(link.id)">
                                <b-icon scale="0.6" icon="trash-fill"></b-icon>
                              </b-button>
                              </div>
                              </b-card-text>
                              <b-card-text class="my-0">
                                {{truncateText(link.description) }}
                              </b-card-text>
                              <b-card-text v-if="link.type === 'normal'">
                                Tipo: Información
                              </b-card-text>
                              <b-card-text v-if="link.type === 'download'">
                                Tipo: Certificado
                              </b-card-text>
                            </b-card-body>
                          </b-col>
                        </b-row>
                      </b-card>
                  </b-col>
                </b-row>
              </div>

              <div v-if="loading">
              <b-row style="margin: 0; padding-bottom: 10px;">
                <b-col style="" v-for="item in 6" :key="item" cols="12" md="6" lg="4">
                  <b-card class="py-0 mb-2">
                    <b-row no-gutters style="cursor: pointer;">
                      <b-col md="2" style="display: flex; align-items: center; justify-content: center;">
                        <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
                      </b-col>
                      <b-col md="10">
                        <b-card-body>
                          <b-card-text style="display:flex; justify-content:space-around; align-items:center;">
                            <b-skeleton animation="wave" width="90%"></b-skeleton>
                            <b-skeleton size="30px" class="mx-2" type="avatar"></b-skeleton>
                          </b-card-text>
                        </b-card-body>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
       
           <!-- Modal crear link -->
          <b-modal 
            ref="modal-1" 
            size="md"
            centered
            hide-footer >

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Crear link
              </b>
            </template>

            <b-row class="mt-4 justify-content-center">
              <b-col cols="10">
              <form v-on:submit.prevent="createLink" id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Tipo"
                  label-for="input-1"
                >
                  <b-form-select v-model="type" class="form-select">
                    <b-form-select-option value="normal">Información</b-form-select-option>
                    <b-form-select-option value="download">Certificado</b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Descripción"
                  label-for="input-1"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="description"
                    rows="3"
                    max-rows="6"
                    ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  id="link"
                  label="Link"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type_r="text"
                    v-model="link"
                    required
                  ></b-form-input>
                </b-form-group>
                 
              </form>
              </b-col>
             </b-row>

            <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal">
                  Cancelar
                </b-btn>
              </b-col>
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1">
                <b-btn
                  block
                  class="outline-btn"
                  @click="createLink">
                  Crear
                </b-btn>
              </b-col>
            </b-row>
          </b-modal>

        <!-- Modal editar link -->
          <b-modal 
            ref="modal-2" 
            size="md"
            centered
            hide-footer >

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Editar link
              </b>
            </template>

            <b-row class="mt-4 justify-content-center">
              <b-col cols="10">
              <form v-on:submit.prevent="updateLink" id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Tipo"
                  label-for="input-1"
                >
                  <b-form-select v-model="type" class="form-select">
                    <b-form-select-option value="normal">Información</b-form-select-option>
                    <b-form-select-option value="download">Certificado</b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Descripción"
                  label-for="input-1"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="description"
                    rows="3"
                    max-rows="6"
                    ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  id="link"
                  label="Link"
                  label-for="input-1"
                  class="mostrar"
                >
                  <b-form-input
                    id="input-1"
                    type_r="text"
                    v-model="link"
                    required
                  ></b-form-input>
                </b-form-group>
              </form>
              </b-col>
             </b-row>

            <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal2">
                  Cancelar
                </b-btn>
              </b-col>
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1">
                <b-btn
                  block
                  class="outline-btn"
                  @click="updateLink">
                  Editar
                </b-btn>
              </b-col>
            </b-row>
          </b-modal>


          <!-- Modal ver link -->
          <b-modal 
            ref="modal-view" 
            size="md"
            centered
            hide-footer >

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Link Útil
              </b>
            </template>

            <b-row class="mt-4 justify-content-center">
              <b-col cols="10">
              <b-card-text class="font-weight-bold">
                {{name}}
              </b-card-text>
              <b-card-text>
                {{description}}
              </b-card-text>
              <b-card-text v-if="type === 'normal'">
                Tipo: Información
              </b-card-text>
              <b-card-text v-if="type === 'download'">
                Tipo: Certificado
              </b-card-text>
                <b-btn
                  block
                  variant="outline-success"
                  @click="showModalView(link_id)">
                  Ir al link
                </b-btn>
              </b-col>
             </b-row>

            <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center ">
              <b-col cols="12" md="6" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModalView">
                  Cerrar
                </b-btn>
              </b-col>
            </b-row>
          </b-modal>

        <!-- Modal elminar link -->
         <b-modal 
          ref="modal-3" 
          centered
          hide-footer>
          <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Confirmación
              </b>
            </template>

          <p class="mt-3" style="text-align: center;">
            ¿Está seguro de que desea eliminar este link?
          </p>

           <b-row class="ma-0 mt-4 px-5 pb-3 justify-content-center">
              <b-col cols="12"  class="px-0 mx-0 mx-md-1 pb-3 pb-md-0  justify-content-center d-flex">
                 <b-btn
                  class="mx-2"
                  variant="outline-danger"
                  @click="closeModal3">
                  Mejor no
                </b-btn>
                <b-overlay
                    :show="loadingDelete"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
                  <b-btn
                    class="outline-btn mx-2"
                    @click="deleteLink">
                    Sí
                  </b-btn>
                </b-overlay>
              </b-col>
          </b-row>
        </b-modal>
    
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
export default {
  name: 'UtilsLinks',
  components: {

  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        links: '',
        original_links: '',
        name: '',
        description: '',
        link: '',
        type: 'normal',
        link_id: '',
        text: '',
        mosaic: true,
        rol: '',
        loading: true,
        loadingDelete:false
      }
    },
  mounted() {
    this.rol = (localStorage.userRol);
    this.getLinks();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },

    showModalCreate() {
        this.name = '';
        this.description = '';
        this.type = 'normal'
        this.status = '';
        this.link = '';
        this.$refs['modal-1'].show();
      },
    showModalEdit(id) {
      for (let i = 0; i < this.links.length; i++) {
        if(this.links[i].id == id) {
            this.link_id = this.links[i].id;
            this.name = this.links[i].name;
            this.description = this.links[i].description;
            this.type = this.links[i].type;
            this.link = this.links[i].link;
        }
      }

      this.$refs['modal-2'].show();
    },
    showModalDelete(id) {
        this.$refs['modal-3'].show();
        this.link_id = id;
      },
    createLink() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

       let formData = new FormData();
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('type', this.type);
       formData.append('link', this.link);

      axios.post(process.env.VUE_APP_URL+"/api/auth/links", formData, config)
        .then(() => {
           this.closeModal()
           this.getLinks();
        });
    },
    getLinks() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/links';

      axios.get(url, config).then(data => {
        this.loading = false
          this.links = data.data;
          this.original_links = data.data;
      })

    },
    updateLink() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'PUT');
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('type', this.type);
       formData.append('image', this.photo);
       formData.append('link', this.link);


      axios.post(process.env.VUE_APP_URL+"/api/auth/links/"+this.link_id, formData, config)
        .then((data) => {
           console.log(data);
          this.closeModal2()
           this.getLinks();
        });
    },
    deleteLink() {
      this.loadingDelete = true;
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'DELETE');

      axios.post(process.env.VUE_APP_URL+"/api/auth/links/"+this.link_id, formData, config)
        .then(data => {
            console.log(data);
            this.closeModal3()
            this.getLinks();
            this.loadingDelete = false;
        });
    },
    closeModal3() {
      this.$refs['modal-3'].hide();
      this.link_id = '';
    },

    closeModalView() {
      this.$refs['modal-view'].hide();
      this.link_id = '';
    },

    truncateText(text) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();
      if(content.length>70) {
          content = content.substring(0, 70);
          content = content + ('(...)');
      }
            
      return content;
    },

    getFormattedDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a')
    },

    closeModal() {
      this.$refs['modal-1'].hide()
      this.name = '';
      this.description = '';
      this.type = 'normal'
      this.status = '';
      this.image = '';
      this.link = '';
    },

    closeModal2() {
      this.$refs['modal-2'].hide()
      this.name = '';
      this.description = '';
      this.type = 'normal'
      this.status = '';
      this.image = '';
      this.link = '';
    },
    showList() {
      this.mosaic = false;
      document.getElementById("lista").classList.remove("ocultar");
      document.getElementById("lista").classList.add("mostrar");
      document.getElementById("mosaico").classList.remove("mostrar");
      document.getElementById("mosaico").classList.add("ocultar");

    },
    showMosaic() {
      this.mosaic = true;
      document.getElementById("mosaico").classList.remove("ocultar");
      document.getElementById("mosaico").classList.add("mostrar");
      document.getElementById("lista").classList.remove("mostrar");
      document.getElementById("lista").classList.add("ocultar");
    },
    searchTable() {
      var key = String(this.text).toLowerCase();
      var response = [];
      this.links = this.original_links;

      if(key !== "") {
        response = [];
        for(let link of this.links) {
            for(let value of Object.values(link)) {
              if(String(value).toLowerCase().search(key) >= 0) {
                response.push(link);
                break;
              }
          }
        }
         this.links = response;
      } else {
        this.links = this.original_links;
      }
    },
    showModalView(id) {
      const link = this.links.find((e) => { return e.id === id })
      if (link) {
          window.open('http://'+link.link);
      }
    },

    showModalViewModal(id) {
      for (let i = 0; i < this.links.length; i++) {
        if(this.links[i].id == id) {
            this.link_id = this.links[i].id;
            this.name = this.links[i].name;
            this.description = this.links[i].description;
            this.type = this.links[i].type;
            this.link = this.links[i].link;
        }
      }
      this.$refs['modal-view'].show();
    },
    
  },
  
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.ver {
  background-color: #3379A1 !important;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
}

.brown {
  background-color: #f0900f;
}

.green {
  background-color: #47A992;
}

.gray {
  background-color: #5F7D91 ;
}

.mostrar {
  display: block;
}

.ocultar {
  display: none;
}

.edit-btn {
  color: #45bba9 !important;
  background: white !important;
  border: 1.5px solid #45bba9 !important;
  padding: 0px 2px !important;
  height: 1.6em !important;
}

.edit-btn:hover {
  color: #FFFFFF !important; background:#45bba9 !important;
  border: 0 m !important;
}

.delete-btn {
  color: #dc3545 !important;
  background: white !important;
  border: 1.5px solid #dc3545 !important;
  padding: 0px 2px !important;
  height: 1.6em !important;
}

.delete-btn:hover {
  color: #FFFFFF !important; background:#dc3545 !important;
  border: 0 m !important;
}
</style>
