<template>
    <div>
      <b-card
        tag="article"
        id="card_links"
        border-variant="white"
        style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px; background-color:#EBF0F4"
        class="mt-0 mb-2"
      >
      <div class="shadow-none mx-0 mt-2">
        <h4 class="pt-2" style="font-weight: 500 !important; font-size: 18px !important;">
        <img style="width: 32px; margin-top: -8px;" src="../../assets/links_utiles.svg" alt="Boletin"/> 
        <span class="ml-2">Links Útiles</span>
      </h4>
      </div>
       <b-card-body class="p-0 mb-4" v-if="loading">
          <div class="d-flex justify-content-center">
            <span style="font-weight: 500 !important;">Cargando...</span>
            <b-spinner class="ml-auto"></b-spinner>
          </div>
        </b-card-body>
         <div v-if="!loading" class="mt-3 px-0 mx-0 d-flex mb-3">
              <div
                  class="m-0 pr-0 pl-0 d-flex justify-content-start"
                  @click="showLinks('certificates')"
              >
                <b-btn
                  id="information_btn"
                  style="font-size: 13px; font-weight: 400 !important; width:110px; !important;"
                  :class="{ 'outline-btn-disabled' : !showCertificates, 'outline-btn' : showCertificates }"
                  class="py-1"
                >
                  Certificados
                </b-btn>
              </div>
              <div
                  class="m-0 pl-2 d-flex justify-content-start"
                  @click="showLinks('information')"
              >
                <b-btn
                  id="certificates_btn"
                  style="font-size: 13px; font-weight: 400 !important; width:110px; !important;"
                  :class="{ 'outline-btn-disabled' : !showInformation, 'outline-btn' : showInformation }"
                  class="py-1"
                >
                Información
                </b-btn>
              </div>
            </div>
        <b-card-body v-if="!loading && showInformation" class="scroll links_scroll p-0">
            <div 
                v-for="(link, index) in normalLinks" :key="index"
                @click="showModalViewModal(link.id)"
                class="d-flex  justify-content-between align-items-center mb-2 pr-2 "
                style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important; cursor: pointer;"
                >
                <span class="text-ellipsis-2-line">
                  <strong class="pr-1">></strong> 
                  {{ truncateText(link.name,80) }}
                </span>
                <b-btn 
                  type="button" 
                  class="btn-ver ml-2"
                  @click="showModalViewModal(link.id)">
                  Ver
                </b-btn>
            </div>
            
          </b-card-body>

        <b-card-body v-if="!loading && showCertificates" class="scroll links_scroll p-0">
            <div 
                v-for="(link, index) in dowloadLinks" :key="index"
                @click="showModalViewModal(link.id)"
                class="d-flex  justify-content-between align-items-center mb-2 pr-2 "
                style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important; cursor: pointer;"
                >
                <span class="text-ellipsis-2-line">
                  <strong class="pr-1">></strong> 
                  {{ truncateText(link.name,80) }}
                </span>
                <b-btn 
                  type="button" 
                  class="btn-ver ml-2"
                  @click="showModalViewModal(link.id)"
                  >
                  <svg viewBox="0 0 24 24">
                      <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                  </svg>
                </b-btn>
            </div>
          </b-card-body>
        </b-card>

        <!-- Modal ver link -->
          <b-modal 
            ref="modal-view" 
            size="md"
            centered
            hide-footer >

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Link Útil
              </b>
            </template>

            <b-row class="mt-4 justify-content-center">
              <b-col cols="10">
              <b-card-text class="font-weight-bold">
                {{name}}
              </b-card-text>
              <b-card-text>
                {{description}}
              </b-card-text>
              <b-card-text v-if="type === 'normal'">
                Tipo: Información
              </b-card-text>
              <b-card-text v-if="type === 'download'">
                Tipo: Certificado
              </b-card-text>
              <b-btn
                v-if="type === 'normal'"
                block
                variant="outline-success"
                @click="showModalView(link_id)">
                Ir al link
              </b-btn>
              <b-btn
                v-if="type === 'download'"
                block
                class="hover-svg-dowload"
                variant="outline-success"
                @click="showModalView(link_id)">
                <span class="mr-2">Descargar</span>
                <svg viewBox="0 0 24 24" style="width:24px;height:24px;fill:#28a745">
                    <path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
                </svg>
              </b-btn>
              </b-col>
             </b-row>

            <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center ">
              <b-col cols="12" md="6" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModalView">
                  Cerrar
                </b-btn>
              </b-col>
            </b-row>
          </b-modal>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { orderBy } from 'lodash';

export default {
  name: 'Links',
  components: {

  },
  data() {
      return {
        links: [],
        normalLinks:[],
        dowloadLinks: [],
        text: '',
        loading: true,
        rol: '',
        name: '',
        description: '',
        type: '',
        link: '',
        link_id: '',
        showInformation: false,
        showCertificates: true
      }
    },
  mounted() {
    this.rol = (localStorage.userRol);
    this.getLinks();
  },
  methods: {
    showLinks(type) {
      if(type == 'information') {

        document.getElementById("certificates_btn").classList.remove("btn_active");
        document.getElementById("certificates_btn").classList.add("btn_inactive");
        document.getElementById("information_btn").classList.remove("btn_inactive");
        document.getElementById("information_btn").classList.add("btn_active");

        this.showInformation = true;
        this.showCertificates = false;

      } else if(type == 'certificates') {

        document.getElementById("certificates_btn").classList.remove("btn_inactive");
        document.getElementById("certificates_btn").classList.add("btn_active");
        document.getElementById("information_btn").classList.remove("btn_active");
        document.getElementById("information_btn").classList.add("btn_inactive");

        this.showInformation = false;
        this.showCertificates = true;
      }
    },
    openLink(link) {
      window.open('http://'.concat(link))
    },
    showModalView(id) {
      const link = this.links.find((e) => { return e.id === id })
      if (link) {
          window.open('http://'+link.link);
      }
    },
    downloadCertificate(id) {
      const link = this.links.find((e) => { return e.id === id })
      if (link) {
        console.log('1')
        console.log(`${'https://'.concat(link.link)}`)
          // window.open('http://'+link.link);
          axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
          axios.get(`${'https://'.concat(link.link)}`, {
              responseType: 'blob'
            })
            .then(response => {
              console.log('2')
              const href = URL.createObjectURL(response.data);
              // create "a" HTML element with href to file & click
              const link = document.createElement('a');
              link.href = href;
              link.setAttribute('download', 'file.pdf'); //or any other extension
              document.body.appendChild(link);
              link.click();

              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            })
      }
      this.$refs['modal-view'].hide();
    },
    closeModalView() {
      this.$refs['modal-view'].hide();
      this.link_id = '';
    },
    showModalViewModal(id) {
      for (let i = 0; i < this.links.length; i++) {
        if(this.links[i].id == id) {
            this.link_id = this.links[i].id;
            this.name = this.links[i].name;
            this.description = this.links[i].description;
            this.type = this.links[i].type;
            this.link = this.links[i].link;
        }
      }
      this.$refs['modal-view'].show();
    },
    getLinks() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/links';

      axios.get(url, config).then(data => {
        this.loading = false
        this.links = orderBy(
              data.data,
              [
                e => {
                  return moment(e.created_at).unix();
                },
              ],
              ['desc']
            );
        this.original_links = orderBy(
              data.data,
              [
                e => {
                  return moment(e.created_at).unix();
                },
              ],
              ['desc']
            );
        this.normalLinks = []
        this.dowloadLinks = []
        this.links.forEach(element => {
          if (element.type === 'normal') {
            this.normalLinks.push(element)
          }
          if (element.type === 'download') {
            this.dowloadLinks.push(element)
          }
        });
      })

    },
    truncateText(text, length = 255) {
      if (!text) return '';

      let content = text.trim();
      if(content.length>length) {
        content = content.substring(0, length);
        content = content + ('...');
      }

      return content;
    },
  },
  
}
</script>
<style>
.card > .position-relative > img {
    height: 180px !important;
}

#card_links{
  border: none !important;
}

#card_links.scroll {
  max-height: 750px;
  overflow-y: auto !important;
}

.links_scroll {
  max-height: 400px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Tamaño del scroll */
.scroll::-webkit-scrollbar {
  width: 8px;
}

 /* Estilos barra (thumb) de scroll */
.scroll::-webkit-scrollbar-thumb {
  background: #B57459;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #B57459;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

 /* Estilos track de scroll */
.scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-track:hover, 
.scroll::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.btn_active {
    text-align: center;
    color: #47B29E;
    border: 1px solid #47B29E;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
    margin-left:15px!important;
    font-size:14px;
}

.btn_active.col-md-3.offset-md-1{
  max-width: 50%!important;
}

.btn_inactive.col-md-3.offset-md-1{
  max-width: 50%!important;
}

@media screen and (max-width: 1280px){
  .btn_inactive{
    font-size:15px!important;
    padding-left:5px;
    padding-right:5px;
  }
  .btn_active{
    font-size:15px!important;
    padding-left:5px;
    padding-right:5px;
  }
}

.btn_inactive {
    text-align: center;
    letter-spacing: 0px;
    color: #B0B0B2;
    opacity: 1;
    background: #F4F6F8 0% 0% no-repeat padding-box;
    border-radius: 0px;
    opacity: 1;
    cursor: pointer;
    font-size: 14px;
    height: 18px;
}

.outline-btn-disabled {
  border: 1.4px solid #f4f6f8 !important;
  background-color: #f4f6f8 !important;
  border-radius: 0px !important;
  color: #aaaaaa !important;
}

.btn-ver{
  border: 1.4px solid #f0900f !important;
  background-color: transparent !important;
  color: #f0900f !important;
  font-size: 14px !important;
  padding: 3px 12px !important;
  max-height: 28px;
  transition: 0.3s ease-in;
}
.btn-ver:hover  {
  color: #FFFFFF !important;
  background-color: #f0900f !important;
}

.btn-ver svg{
  width:24px;
  height:24px;
  fill: #f0900f
}

.btn-ver:hover svg {
  fill: #FFFFFF  !important;
}

.hover-svg-dowload:hover svg{
  fill: #FFFFFF  !important;
}

.text-ellipsis-2-line {  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
