<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
        <b-row id="users" style="margin-bottom: 50px;" class="justify-content-center">
          <b-col xs="12" sm="12">
              <b-row align-v="center" class="mb-0 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">Eventos</h3>
                </b-col>
              </b-row>
          </b-col>
          <b-col xs="12" sm="12" md="12" class="pt-4 fix-things-calendar">
              <Calendario :class="{ 'pt-4' : isMd }" class="p-0" />
          </b-col>
        </b-row>
    </div>
</template>

<script>
import Calendario from '@/components/Dashboard/CalendarioPage.vue';

export default {
  components:{
    Calendario
  },
  name: 'Events',
  data() {
      return {
        isMobile: false,
        isMd: false,
      }
    },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
      this.isMd = window.innerWidth > 770 && window.innerWidth < 1000
    },
  }
}
</script>

<style>
  .fix-things-calendar .fc-button-group{
    margin-left: 0px !important;
    width: calc(100% - 1.25rem - 1.25rem) !important;
  }

  .fix-things-calendar .fc-theme-standard td {
    cursor: pointer !important;
  }

  @media screen and (min-width: 1200px) {
    .fix-things-calendar .fc-direction-ltr .fc-daygrid-event.fc-event-end {
      margin-top: -35px !important;
    }
    .fix-things-calendar .fc-theme-standard td a {
      font-size: 18px !important;
    }
  }
</style>