<template>
    <div class="mx-0 mx-md-5 px-2">
        <div class="row">
            <b-col md="4" offset-md="4" id="dropbox"> </b-col>
        </div>
        <div class="row" id="files" style="height: 500px;">
            <!-- <b-col md="4" offset-md="4" id="dropbox"> </b-col> -->
        </div>
    </div>
</template>
<script type="text/javascript" src="https://www.dropbox.com/static/api/2/dropins.js" id="dropboxjs" data-app-key="dg33regd5z36yci"></script>
<script>
 
 export default {
  name: 'Dropbox',
  components: {
  
  },
  data() {
      return {
        
      }
    },
  mounted() {
    
var options = {

    // Required. Called when a user selects an item in the Chooser.
    success: function(files) {
       
        // alert("Here's the file link: " + files[0].link)
        var options = {
            // Shared link to Dropbox file
            link: files[0].link,
            file: {
                // Sets the zoom mode for embedded files. Defaults to 'best'.
                zoom: "fit" // or "fit"
            },
            folder: {
                // Sets the view mode for embedded folders. Defaults to 'list'.
                view: "list", // or "grid"
                headerSize: "normal" // or "small"
            }
            }
            let element = document.getElementById('files');
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
            // element.html('');
            Dropbox.embed(options, element);
            },

    // Optional. Called when the user closes the dialog without selecting a file
    // and does not include any parameters.
    cancel: function() {

    },

    // Optional. "preview" (default) is a preview link to the document for sharing,
    // "direct" is an expiring link to download the contents of the file. For more
    // information about link types, see Link types below.
    linkType: "preview", // or "direct"

    // Optional. A value of false (default) limits selection to a single file, while
    // true enables multiple file selection.
    multiselect: true, // or true

    // Optional. This is a list of file extensions. If specified, the user will
    // only be able to select files with these extensions. You may also specify
    // file types, such as "video" or "images" in the list. For more information,
    // see File types below. By default, all extensions are allowed.
    extensions: ['.pdf', '.doc', '.docx', '.jpg', '.png', '.jpeg'],

    // Optional. A value of false (default) limits selection to files,
    // while true allows the user to select both folders and files.
    // You cannot specify `linkType: "direct"` when using `folderselect: true`.
    folderselect: true, // or true

    // Optional. A limit on the size of each file that may be selected, in bytes.
    // If specified, the user will only be able to select files with size
    // less than or equal to this limit.
    // For the purposes of this option, folders have size zero.
    sizeLimit: 1024, // or any positive number
};

var button = Dropbox.createChooseButton(options);
document.getElementById("dropbox").appendChild(button);
// document.querySelector('.dropbox-dropin-btn').appendChild = "<span class='dropin-btn-status'></span> hullaballoo";
document.getElementsByClassName('dropbox-dropin-btn')[0].innerHTML = "<span class='dropin-btn-status'></span> Iniciar sesión en Dropbox";
// document.getElementsByClassName('dropbox-dropin-btn')[0].appendChild = "<span class='dropin-btn-status'></span> hullaballoo";

  },
 
}
</script>
<style>
.dropbox-dropin-btn, .dropbox-dropin-btn:link {
    font-size: 20px;
    margin-top: 20px;
}
</style>
