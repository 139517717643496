<template>
    <div>
        <b-navbar :style="`${isMobile ? 'padding: 0px 0px !important;' : 'padding-right: 37px !important; padding-left: 37px !important;'}`">
            <b-btn
                variant="white"
                class="ml-0 mr-1 px-1"
                :class="{ 'no-display' : !isMd, 'px-2 mx-2' : isMobile }"
                v-b-toggle.sidebar-1
            >
                <b-icon icon="list"></b-icon>
            </b-btn>

            <b-nav-item-dropdown :class="{'no-display' : isMobile}" text="Somos CYM">
                <b-dropdown-item target="_blank" href="http://www.cobreymetales.com/">Ir al Sitio Web</b-dropdown-item>
                <!--<b-dropdown-item href="#">Sustentabilidad</b-dropdown-item>-->
            </b-nav-item-dropdown>

            <b-collapse id="nav-collapse" is-nav >
                <b-navbar-nav  class="ml-auto">
                    <b-nav-item :class="{'no-display' : isMobile}" href="/organigrama" style="margin-top:0.4em;" right>
                        <router-link style="color: black !important; font-weight: 400 !important; font-size: 14px !important;" to="/organigrama" class="item">
                        <img src="../assets/organigrama.svg" class="mr-2" style="width: 35px !important;" id="img_resource" alt="..."> 
                            <span>Organigrama</span>
                        </router-link>
                    </b-nav-item>
                    <b-nav-item href="/recursos" style="margin-top:0.4em;" right>
                        <router-link style="color: black !important; font-weight: 400 !important; font-size: 14px !important;" to="/recursos" class="item">
                        <img src="../assets/recursos.svg" class="mr-2" style="width: 35px !important;" id="img_resource" alt="..."> 
                            <span >Recursos</span>
                            <b-badge v-if="notViewvedResources > 0" variant="danger" class="ml-2" style="top: -2px; position: relative;">{{ notViewvedResources }}</b-badge>
                        </router-link>
                    </b-nav-item>
                    <b-nav-item :class="{'no-display' : isMobile}" href="/perfil" right style="margin-left: 0;">
                        <div class="picture">
                            <b-avatar variant="warning" v-if="image == '' || !image" :text="$showInitials(firstName, lastName)" size="38px"></b-avatar>
                            <img style="width: 38px !important; height: 38px;" v-else :src="image">
                        </div>
                    </b-nav-item>

                    <div id="popover-target" :class="{'no-display' : !isMobile}" class="picture mx-1" style="margin: 0; padding: 0.5em;">
                        <b-avatar variant="warning" v-if="image == '' || !image" :text="$showInitials(firstName, lastName)" size="38px"></b-avatar>
                        <img style="width: 38px !important; height: 38px; object-fit: cover !important;" v-else :src="image">
                    </div>

                    <b-popover placement="bottomright" target="popover-target">
                        <template #title>Menú</template>
                        <div class="mr-4">
                            <router-link style="color: black !important;" to="/perfil">
                                <div style="font-size: 13px !important;">Mi Perfil</div>
                            </router-link>
                            <div class="mt-2">
                                <router-link style="color: black !important;" @click="logout()" to="/">
                                <div style="font-size: 13px !important;">Cerrar sesión</div>
                            </router-link>
                            </div>
                        </div>
                    </b-popover>
                    
                    <b-nav-item-dropdown :class="{'no-display' : isMobile}" class="perfil" style="margin: 0;" :text="getUserNameFirst" right>
                        <router-link to="/perfil">
                            <b-dropdown-item style="font-size: 14px !important;" href="/perfil">Mi Perfil</b-dropdown-item>
                        </router-link>
                        <router-link to="/" @click="logout()">
                            <b-dropdown-item style="font-size: 14px !important;" href="#" @click="logout()">Cerrar sesión</b-dropdown-item>
                        </router-link>
                    </b-nav-item-dropdown>
                    
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <b-sidebar id="sidebar-1" width="290px" bg-variant="dark-blue" shadow>
        <div class="px-0 py-0">
            <Sidebar style="background-color:#5F7D91; height: 100% !important; width: 100% !important;" />
        </div>
        </b-sidebar>

    </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";
import axios from 'axios';
export default {
    components: {
        Sidebar
    },
    data: function() {
    return {
      name: "",
      image: "",
      image_default: "../assets/picture.png",
      url_img: process.env.VUE_APP_URL+'/storage/',
      isMd: false,
      isMobile: false,
      projects: [],
      data_user: null,
      firstName: '',
      lastName: ''
    }
  },
  computed:{
    getUserNameFirst() {
        if (Array.isArray(this.data_user)){
            if (this.data_user.length > 0){
                const formated = this.data_user[0].name.split(' ')
                return formated[0]
            }
        }
        return ''
    },
    notViewvedResources() {
        if (this.projects.length && this.projects.length > 0) {
            let flag = 0
            this.projects.forEach(element => {
                if (!element.resource_by_users) {
                    flag++
                }
            })
            return flag
        } else {
            return 0
        }
    }
  },
  mounted() {
    this.data_user = JSON.parse(localStorage.userData);
    if (Array.isArray(this.data_user)){
        if (this.data_user.length > 0){
            if (this.data_user[0].image !== "" && this.data_user[0].image) {
                this.image = this.url_img + this.data_user[0].image;
            }
            if (this.data_user[0].name){
                this.firstName = this.data_user[0].name
            }
            if (this.data_user[0].lastname){
                this.lastName = this.data_user[0].lastname
            }
        }
    }
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    localStorage.removeItem('updateResources')
    this.getResources()
    setInterval(() => {
       this.checkUpdate()
    }, 1000);
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    checkUpdate() {
        const update =  localStorage.getItem('updateResources')
        if (update) {
            this.getResources()
        }
    },
    onResize () {
      this.isMobile = window.innerWidth < 770
      this.isMd = window. innerWidth <= 1200
    },
    logout() {
        this.$router.push('/');
    },
    getResources() {
        localStorage.removeItem('updateResources')
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

        let url = process.env.VUE_APP_URL+'/api/auth/resources';
        axios.get(url, config).then(data => {
            this.projects = data.data;
        })
    },
  }
}
</script>
<style>
.navbar{
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.no-display {
    display: none !important;
}

.nav-item {
    display: flex;
    align-items: center;
}

 .resource {
    margin-left: 170px;
}

.nav-item > a > span {
    color: black;
    font-size: 20px;
}

.b-sidebar {
    background-color:#5F7D91 !important;
}

#img_resource {
    width: 20px;
    margin-top: -5px;
}

.item {
    border: 0px;
    border-radius: 0px;
    opacity: 1;
    font-size: 13px;
    text-decoration: none;
    font-weight: bold;
    padding: 8px;
}

a:hover, a:visited, a:link, a:active {
    text-decoration: none !important;
}

.perfil > a > span {
    color: black !important;
    font-size: 14px;
}

.nav-item > a > .picture > img {
    width: 50px ;
    height: 50px;
    border-radius: 25px;
    /*margin-top: -30px;/*margin-top: -50px;*/
}

#nav-collapse {
    justify-content: space-around;
}

.nav-link {
    padding: 5px 10px !important;
}

.popover-header {
    padding: 10px !important;
    background-color: #7e9fb5 !important;
    color: white !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    font-family: 'Rubik', sans-serif !important;
    margin: 0 !important;
}
</style>