import Vue from 'vue'
import App from './App.vue'
import router from './router'
import jquery from 'jquery'
import { BootstrapVue, IconsPlugin, AlertPlugin, SpinnerPlugin, ToastPlugin, CarouselPlugin  } from 'bootstrap-vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vmodal from 'vue-js-modal'
import Hotjar from 'vue-hotjar'
import VueGtag from "vue-gtag";
import VueMask from 'v-mask'

Vue.config.productionTip = false
Vue.use(jquery)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(AlertPlugin)
Vue.use(SpinnerPlugin)
Vue.use(ToastPlugin)
Vue.use(CarouselPlugin)
Vue.use(VueAxios, axios)
Vue.use(vmodal)
Vue.use(require('vue-moment'));
Vue.use(VueMask)
Vue.use (Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID, // Hotjar Site ID
  isProduction: true
})

Vue.use(VueGtag, {
  config: {
    id:  process.env.VUE_APP_GOOGLE_ID,
    send_page_view: true 
  }
});
// Vue.use (VueGtag, {
//   config: { id: process.env.VUE_APP_GOOGLE_ID } // Google ID
// })

Vue.prototype.$showInitials = showInitials

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')



function showInitials (name, lastname) {
  if (name) {
    const formated = name.split(' ')
    let first = ''
    let second = ''
    if (name) {
      first = Array.from(name)[0]
    }
    if (lastname) {
      second = Array.from(lastname)[0]
    }
    if (!lastname || !second || second === '' ) {
      if (formated.length > 1){
        second = Array.from(formated[1])[0]
      }
    }
    return `${first}${second}`.toUpperCase()
  } else {
    return ''
  }
}