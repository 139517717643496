<template>
    <div style="margin-bottom: 30px;">
        <b-btn 
            type="button" 
            :class="dataEntity.liked ? 'btn-heart btn-heart-liked' : 'btn-heart' "
            :disabled="dataEntity.liked"
            @click="() => { likeEntity(); dataEntity.liked = true }"
            style="max-height: 35px !important;"
            >
            <div style="display: flex;align-items: center; padding: 3px !important">
                <div style="margin-right: 5px;">
                    Me gusta
                </div>
                <b-icon icon="heart" style=" color:rgb(0, 0, 0);" aria-hidden="true"></b-icon> 
            </div>
        </b-btn>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        idFather: {
            type: String,
            default: '-1'
        },
        type: {
            type: String,
            default: 'noticias'
        }
    },
    data() {
      return {
        dataEntity: {
            liked:false,
            id: -1
        },
        user_id: '-1'
      }
    },
  mounted () {
    let userData = JSON.parse(localStorage.userData)
    this.user_id = userData[0].id
    this.dataEntity = {
        liked:false,
        id: this.idFather
    }
    this.getLikes()
  },
  methods:{
    likeNotice(data){
        return data
    },
    async getLikes(){
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

      await axios.get(process.env.VUE_APP_URL+"/api/auth/get-all-likes",  config)
        .then((data) => {
        
          if (data?.data?.data?.likes && Array.isArray(data?.data?.data?.likes)){
            data.data.data.likes.forEach(element => {
              if (element?.module === this.type && element?.like && this.dataEntity.id == element?.module_id){
                this.dataEntity.liked = true
              }
            });
          }
        })
        .catch(() => {
          
        })
        
    },
    likeEntity(){
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

        const data ={
          user_id : this.user_id,
          module: this.type,
          module_id: this.idFather
        }
      axios.post(process.env.VUE_APP_URL+"/api/auth/like", data, config)
        .then(() => {
       
        })
        .catch(() => {
          
        })
    },

  }
}
</script>

<style scoped>
    .btn-heart{
        border: none !important;
        background-color: transparent !important;
        color: #e31b23 !important;
        font-size: 14px !important;
        padding: 3px 3px !important;
        max-height: 28px !important;
        transition: 0.3s ease-in;
        }
        .btn-heart:hover  {
        color: #FFFFFF !important;
        background-color: #e31b23 !important;
        }

        .btn-heart svg{
        width:24px;
        height:24px;
        fill: #e31b23
        }

        .btn-heart:hover svg {
        fill: #FFFFFF  !important;
        }

        .btn-heart-liked {
        color: #FFFFFF !important;
        background-color: #e31b23 !important;
        }

        .btn-heart-liked svg {
        fill: #FFFFFF  !important;
        }

</style>