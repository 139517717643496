var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"m-0 p-0",staticStyle:{"margin-bottom":"100px"},attrs:{"id":"users"}},[_c('b-col',{staticClass:"m-0 p-0",attrs:{"xs":"12","sm":"12"}},[_c('b-row',{staticClass:"mb-5 mt-4 px-0 mx-0",attrs:{"align-v":"center"}},[_c('b-col',{staticStyle:{"margin":"0","padding":"0"},attrs:{"align-self":"center","cols":"12","sm":"12","md":"12"}},[_c('h3',{style:(((_vm.isMobile ? 'text-align: center;' : '') + " margin: 0 !important; padding:0;"))},[_vm._v("Usuarios que le dieron me gusta")])]),_c('b-col',{staticClass:"px-0",staticStyle:{"display":"none"},attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:" text-center",staticStyle:{"max-width":"320px"}},[_c('h4',{staticClass:"mb-2 mt-4",style:(((_vm.isMobile ? 'text-align: center;' : '') + " padding:0;"))},[_vm._v("Buscar por nombre:")]),_c('b-form-group',{staticClass:"mb-0",attrs:{"id":"input-group-1","label":"","label-for":"input-1"}},[_c('b-form-input',{attrs:{"id":"input-1","type":"text","required":""},model:{value:(_vm.searchName),callback:function ($$v) {_vm.searchName=$$v},expression:"searchName"}})],1)],1)])],1),_c('b-table',{staticClass:"mb-0",attrs:{"head-variant":"dark","per-page":_vm.perPage,"id":"my-table","current-page":_vm.currentPage,"fields":[
            {
              key: 'name',
              label: 'Nombre',
              thClass: 'table-dark'
            },
            {
              key: 'lastname',
              label: 'Apellido',
              thClass: 'table-dark'
            },
            {
              key: 'email',
              label: 'Email',
              thClass: 'table-dark'
            },
            {
              key: 'plant',
              label: 'Planta',
              thClass: 'table-dark'
            } ],"stacked":_vm.isMobile,"striped":"","th-class":"table-dark","items":_vm.loading ? [] : _vm.users},scopedSlots:_vm._u([{key:"cell(plant)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.getPlant(data.item.factory))+" ")]}},(_vm.users.length === 0)?{key:"top-row",fn:function(){return [_c('td',[_vm._v("No hay usuarios que hayan dado like")]),_c('td',[_vm._v(" ")]),_c('td',[_vm._v(" ")]),_c('td',[_vm._v(" ")])]},proxy:true}:null],null,true)}),(_vm.users.length > 0 && !_vm.loading)?_c('b-pagination',{staticClass:"mt-3",attrs:{"dark":"","align":"right","total-rows":_vm.users.length,"per-page":_vm.perPage,"aria-controls":"my-table"},scopedSlots:_vm._u([{key:"page",fn:function(ref){
          var page = ref.page;
          var active = ref.active;
return [(active)?_c('b',[_vm._v(_vm._s(page))]):_c('i',[_vm._v(_vm._s(page))])]}}],null,false,1479778492),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.loading)?_c('b-skeleton-table',{attrs:{"stacked":_vm.isMobile,"rows":7,"columns":7,"table-props":{ bordered: true, striped: true }}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }