<template>
    <div>
      <b-card
        tag="article"
        id="card_links"
        border-variant="white"
        style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px; background-color:#EBF0F4"
        class="mt-0 mb-2"
      >
       <b-card-body class="p-0 mb-4" v-if="!loadedEvents">
          <div class="d-flex justify-content-center">
            <span style="font-weight: 500 !important;">Cargando...</span>
            <b-spinner class="ml-auto"></b-spinner>
          </div>
        </b-card-body>
        <b-card-body v-if="loadedEvents && showEvents" class="scroll scroll-changed-position birthdays_scroll p-0">
            <div style="font-size:11px;text-align:left;font-weight:500;padding: 0px 0px 0px 20px" class="grayblue-text mb-2">
                EVENTOS DE HOY EN CYM
            </div>
            <div 
                v-for="event in todayEvents" :key="event.id"
                class="d-flex  justify-content-between align-items-center mb-2 pr-2 "
                style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important;padding: 0px 20px 0px 20px"
                >
                <div class="d-flex">
                    <b-avatar variant="light" icon="calendar-event" size="2.9rem"></b-avatar>
                    <div class="ml-2 d-flex flex-column justify-content-center" >
                        <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                            {{ getDate(event.start) }}
                        </div>
                        <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                            {{ event.title }}
                        </div>
                        <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                            {{ event.description }}
                        </div>
                    </div>
                </div>
            </div>
            <div 
                v-if="todayEvents.length < 1"
                class="d-flex  justify-content-center align-items-center mb-2 pr-2 "
                style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important;padding: 0px 20px 0px 20px"
                >
              <div class="text-center pt-3 pb-3">Hoy no hay eventos</div>
            </div>
            <div v-if="nextEvents.length > 0" style="font-size:11px;text-align:left;font-weight:400;background-color:#7E9FB5;color:#FFFFFF;padding: 0px 20px 0px 20px" class="">
                PRÓXIMOS EVENTOS
            </div>
            <div style="background-color:#FFFFFF" class="pt-2">
                <div 
                    v-for="event in nextEvents" :key="event.id"
                    class="d-flex  justify-content-between align-items-center pb-2 pr-2 "
                    style="color: black !important; font-weight: 400 !important; font-size: 14px !important; text-align: left !important; padding: 0px 20px 0px 20px"
                    >
                    <div class="d-flex">
                        <b-avatar variant="secondary" icon="calendar-event" size="2.9rem"></b-avatar>
                        <div class="ml-2 d-flex flex-column justify-content-center" >
                            <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                                {{ getDate(event.start) }}
                            </div>
                            <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                                {{ event.title }}
                            </div>
                            <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                                {{ event.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </b-card-body>
        </b-card>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import { orderBy } from 'lodash';

export default {
  name: 'JustEvents',
  data() {
      return {
        text: '',
        rol: '',
        showEvents: true,
        events: [],
        next_event: [],
        interval: null,
        today: null,
        todayEvents:[],
        nextEvents: [],
        todayDay: '',
        loadedBirthdays:false,
        loadedEvents:false,
        sendedMessage: false,
        loadingMessage: false,
        dataUser: [ { id: 0 } ],
        messages: []
      }
    },
  mounted() {
    this.rol = (localStorage.userRol);
    this.dataUser = JSON.parse(localStorage.userData)
    this.today = moment().format('YYYY-MM-DD') 
    moment().locale('es')
    Promise.all([ 
        this.getNextEvent()
    ]).then(values=>{
      if (!values){
        console.log(values)
      }
    })
  },
  methods: {
    truncateText(text, length = 255) {
      if (!text) return '';

      let content = text.trim();
      if(content.length>length) {
        content = content.substring(0, length);
        content = content + ('...');
      }

      return content;
    },
    formatDateToWord(date) {
      return date ? moment(date).locale('es').format('DD [de] MMMM') : ''
    },
    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },
   

    async getNextEvent() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/events';

      await axios.get(url, config).then(async (data) => {
        let rruleFixedEvents = data.data
        for (let i = 0; i < rruleFixedEvents.length; i++) {
          if (rruleFixedEvents[i].rrule){
            rruleFixedEvents[i].rrule = `${rruleFixedEvents[i].rrule.replace("Z", "")}` 
          }
        }
       let events = await rruleFixedEvents.map((e) => {
            if ((e.rrule && (e.rrule.includes('FREQ=WEEKLY') ||  e.rrule.includes('FREQ=MONTHLY')))) {
              e.start = e.start.replace(moment(e.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM'), moment().format('YYYY-MM').concat(`-${moment(moment().weekday(8)).format('DD')}`))
            }
            return e
          })

          events = await events.filter((e) => {
            return moment(moment(e.start, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')).isSameOrAfter(this.today) || (e.rrule && (e.rrule.includes('FREQ=WEEKLY') ||  e.rrule.includes('FREQ=MONTHLY')))
          })
          let index = 0;

          events = await orderBy(
          events,
          [
            e => {
              return moment(e.start, 'YYYY-MM-DD HH:mm:ss').unix();
            },
          ],
          ['asc'])


          this.next_event = [events[index]]
          /*this.interval = setInterval(() => {
            this.next_event = [events[index]]
            index = (index+1) < events.length ? (index+1) : 0
          }, 10000)*/ 
          //console.log(events,'events')
          //console.log(this.next_event)
          
          events.forEach(element => {
            if (this.checkDate(element.start) === 'Hoy'){
              this.todayEvents.push(element)
            }
            if (this.checkDate(element.start) === 'Próximo evento'){
              this.nextEvents.push(element)
            }
          });
          
          //this.$emit('notification')
      })

      this.loadedEvents = true
    },

    checkDate(date) {
      return moment(this.today).isSame(date, 'day') ? 'Hoy' : 'Próximo evento'
    },

    getDate(date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').locale('es').format('DD [de] MMMM');
    }
  },
  
}
</script>
<style>
.card > .position-relative > img {
    height: 180px !important;
}

#card_links{
  border: none !important;
}

#card_links.scroll {
  max-height: 550px;
  overflow-y: auto !important;
}

.birthdays_scroll {
  max-height: 400px !important;
  overflow-y: auto;
  position: relative;
  left: -20px;
  width: calc(100% + 40px);
  top: 8px;
  padding: 0px !important;
}


/* Tamaño del scroll */
.scroll::-webkit-scrollbar {
  width: 8px;
}

 /* Estilos barra (thumb) de scroll */
.scroll::-webkit-scrollbar-thumb {
  background: #B57459;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #B57459;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

 /* Estilos track de scroll */
.scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-track:hover, 
.scroll::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.btn_active {
    text-align: center;
    color: #47B29E;
    border: 1px solid #47B29E;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
    margin-left:15px!important;
    font-size:14px;
}

.btn_active.col-md-3.offset-md-1{
  max-width: 50%!important;
}

.btn_inactive.col-md-3.offset-md-1{
  max-width: 50%!important;
}

@media screen and (max-width: 1280px){
  .btn_inactive{
    font-size:15px!important;
    padding-left:5px;
    padding-right:5px;
  }
  .btn_active{
    font-size:15px!important;
    padding-left:5px;
    padding-right:5px;
  }
}

.btn_inactive {
    text-align: center;
    letter-spacing: 0px;
    color: #B0B0B2;
    opacity: 1;
    background: #F4F6F8 0% 0% no-repeat padding-box;
    border-radius: 0px;
    opacity: 1;
    cursor: pointer;
    font-size: 14px;
    height: 18px;
}

.outline-btn-disabled {
  border: 1.4px solid #f4f6f8 !important;
  background-color: #f4f6f8 !important;
  border-radius: 0px !important;
  color: #aaaaaa !important;
}

.btn-ver{
  border: 1.4px solid #f0900f !important;
  background-color: transparent !important;
  color: #f0900f !important;
  font-size: 14px !important;
  padding: 3px 12px !important;
  max-height: 28px;
  transition: 0.3s ease-in;
}
.btn-ver:hover  {
  color: #FFFFFF !important;
  background-color: #f0900f !important;
}

.btn-ver svg{
  width:24px;
  height:24px;
  fill: #f0900f
}

.btn-ver:hover svg {
  fill: #FFFFFF  !important;
}

.text-ellipsis-2-line {  
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.grayblue-text{
    color: #5F7D91;
}

.form-saludo{
  position: absolute;
  width: 290px;
  top: 75px;
  left: calc(100% - 5px);
  z-index: 10;
  opacity: 0;
  animation: showingUp 200ms ease-in-out forwards;
}

@keyframes showingUp{
    from  { opacity: 0;  }
    to { opacity: 1; }
  }

@media screen and (max-width: 767px) {
  .form-saludo{
    top: 65px;
    left: calc((100% - 290px) / 2);
  }
}

.div-loading-modal{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color:#ebf0f47c
}

.modal-messages{
  background-color: transparent !important;
  border: none !important;
}

.modal-messages .modal-body{
  padding: 0px !important;
}

.carrousel-background-grey  {
  background-color:rgb(95, 125, 145) !important
}

@media screen and (max-width: 991px) {
  .lottie-anim-ballons  {
    width: 300px !important;
    height: 300px !important;
  }
  .carrousel-background-grey  {
    aspect-ratio: 0.9 !important;
  }
}

@media screen and (max-width: 460px) {
  .lottie-anim-ballons  {
    width: 230px !important;
    height: 230px !important;
  }
}

@media screen and (max-width: 430px) {
  .carrousel-background-grey  {
    aspect-ratio: 0.8 !important;
  }
}

@media screen and (max-width: 390px) {
  .carrousel-background-grey  {
    aspect-ratio: 0.75 !important;
  }
}

@media screen and (max-width: 365px) {
  .carrousel-background-grey  {
    aspect-ratio: 0.7 !important;
  }
}

@media screen and (max-width: 350px) {
  .carrousel-background-grey  {
    aspect-ratio: 0.6 !important;
  }
}

@media screen and (min-width: 992px) {
  .lottie-anim-ballons  {
    width: 400px !important;
    height: 400px !important;
  }
}
@media screen and (max-width: 575px) {
  .p-message-birthday{
    font-size: 13px;
  }
}

</style>
