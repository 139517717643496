<template>
    <div style="height: 90% !important;" :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
        <b-row style="height: 100% !important;">
            <b-col cols="12" lg="8" style="margin-top: 20px; background-color: white;">
                <h3 style="color: black;">
                  <img src="../assets/reconocimientos.svg" alt="">
                    Conoce a 
                    <img id="back-arrow" src="../assets/left-arrow.svg" alt="" @click="$router.back()">
                  </h3>
                <!-- <b-col col sm="9" style="margin-top: 30px; margin-left: 20px; background-color: white;"> -->

                  <!-- SKELETON LOADER -->
                  <b-card border-variant="white" v-if="loading">
                    <b-skeleton-img></b-skeleton-img>
                    <b-skeleton animation="wave" height="40px" class="mt-4 mb-4" width="45%"></b-skeleton>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                  </b-card>
                  <b-card
                        v-else
                        img-alt="Image"
                        img-top
                        border-variant="white"
                        tag="article"
                        style=""
                        class="mb-4"
                        id="detail_project"
                    >
                      <b-card-text class="pt-2"  v-if="image1">
                          <b-card-img
                            :src="image1" 
                            alt="user_pic"
                            class="img reconocimientos_img">
                          </b-card-img>
                        </b-card-text>
                        <b-card-text class="mt-4">
                          <h2>Empleado: {{name}}</h2>

                          <h6 id="date_info">{{formatDate(date)}}</h6>

                          <div v-html="description" style="font-size: 17px;"></div>
                        </b-card-text>
                    </b-card>
                <!-- </b-col> -->
                <Comments module="reconocimientos" :idModule="$route.params.id" />
            </b-col>
             <b-col col sm="12" lg="4" style="margin-top: 20px;">
               <Reconocimientos  />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import Reconocimientos from '@/components/Dashboard/Reconocimientos.vue';
import moment from 'moment'
import Comments from '@/components/Comments.vue';

export default {
  name: 'Users',
  components: {
      Reconocimientos,
      Comments
  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        projects: '',
        name: '',
        description: '',
        status: '',
        rol: '',
        photo: null,
        image1: '',
        image2: '',
        image3: '',
        position: '',
        project_id: '',
        date: '',
        slide: 0,
        sliding: null,
        loading: false,
        isMobile: false
      }
    },
  mounted() {
    this.getProject(this.$route.params.id);
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    onResize () {
        this.isMobile = window.innerWidth < 1200
      },
    onSlideStart(slide) {
        console.log(slide);
        this.sliding = true
      },
      onSlideEnd(slide) {
        console.log(slide);
        this.sliding = false
      },
    getProject(id) {
      this.loading = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/reconocimientos/'+id;

      axios.get(url, config).then(data => {
        this.loading = false

        this.project_id = data.data.id;
        this.name = data.data.name;
        this.description = data.data.description;
        this.status = data.data.status;
        this.date = data.data.created_at;
        this.image1 = data.data.picture !== null && data.data.picture !== "" && data.data.picture ? process.env.VUE_APP_URL+'/storage/'+data.data.picture : null;
      })
    },
    formatDate(date) {
      //const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      //const formatDate = (date)=>{
        //let formatted_date = date.getDate() + " " + meses[date.getMonth()] + " " + date.getFullYear();
        //return formatted_date;
      //}
      return moment(date).format('DD/MM/YYYY');
    },
  },
}
</script>
<style>
h3 {
  color: #263A39;
  text-align: left;
  /* margin-top: 20px !important; */
}

h2 {
    font-weight: bold !important;
    color: black;
    margin-left: 0;

}

p {
    text-align: left;
}

.ver {
  background-color: #3379A1 !important;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
}


#detail_project > img {
    /* height: 270px; */
    max-height: 500px;
}

#detail_project{
  margin-top:2.5rem;
}

#back-arrow{
  /*style="margin-left: 500px; cursor: pointer;"*/
  cursor:pointer;
  width:25px;
  float:right;
  margin-top:10px;
}

#date_info{
  /*color:#47dedb;*/
  color:#55c3c0;
  margin-bottom:10px;
  font-size: 1.05rem;
}

@media screen and (max-width:1280px){
  #date_info{
    font-size: 1.1rem!important;
  }
}


.reconocimientos_img img{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.4 !important;
  /*object-fit: cover;
  object-position: center;*/
}
</style>
