<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }" ref="myPage" >
      
        <b-row id="users" style="margin-bottom: 50px;">
          <b-col xs="12" sm="12">
              <b-row align-v="center" class="mb-0 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">Estadísticas</h3>
                </b-col>
              </b-row>
          </b-col>
          <b-col xs="12" sm="12" class="d-flex justify-content-between align-items-center">
            <div style="max-width:250px">
              <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Intervalo de fechas:</h4>
              <b-form-group
                id="input-group-1"
                label=""
                label-for="input-1"
              >
                <b-form-select v-model="startDateOption" class="form-select" :disabled="disableSelect">
                  <b-form-select-option 
                    :value="item.value" 
                    v-for="item in startDateOptions" 
                    :key="item.value">
                      {{item.name}}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div>
              <b-button 
                type="button" 
                class="mt-2 mr-3 showPrintBtn"
                @click="generatePDF()" 
                :disabled="disablePDF"
                style="color: #FFFFFF; background: #f0900f no-repeat padding-box; border: none; width: 150px;">
                <span v-if="!loadingPDF && !disablePDF">Imprimir Reporte</span>
                <b-spinner small v-else></b-spinner>
              </b-button>
            </div>
          </b-col>
          <b-col xs="12" sm="6" md="3" class="px-2 py-2">
            <loading-overlay v-if="loadingPageViews"/>
            <b-card class="px-2 py-2" >
              <div style="width:100%" class="d-flex justify-content-center align-items-center">
                <b-icon icon="eye" style="width: 25px; height: 25px; color:#B57459" class="mr-2" aria-hidden="true"></b-icon> 
                <h4 style="text-align:center;padding:0;font-size:16px !important" class="mb-2 mt-2">Número de vistas</h4>
              </div>
              <h1 style="text-align:center;padding:0;" class="mb-2 mt-2">{{pageViews}}</h1>
            </b-card>
          </b-col>
          <b-col xs="12" sm="6" md="3" class="px-2 py-2">
            <loading-overlay v-if="loadingSourceUsers"/>
            <b-card class="px-2 py-2" >
              <div style="width:100%" class="d-flex justify-content-center align-items-center">
                <b-icon icon="person" style="width: 25px; height: 25px; color:#B57459" class="mr-2" aria-hidden="true"></b-icon> 
                <h4 style="text-align:center;padding:0;font-size:16px !important" class="mb-2 mt-2">Usuarios únicos</h4>
              </div>
              <h1 style="text-align:center;padding:0;" class="mb-2 mt-2">{{uniqueUsers}}</h1>
            </b-card>
          </b-col>
          <b-col xs="12" sm="6" md="3" class="px-2 py-2">
            <loading-overlay v-if="loadingBounceRate"/>
            <b-card class="px-2 py-2" >
              <div style="width:100%" class="d-flex justify-content-center align-items-center">
                <b-icon icon="box-arrow-in-left" style="width: 25px; height: 25px; color:#B57459" class="mr-2" aria-hidden="true"></b-icon> 
                <h4 style="text-align:center;padding:0;font-size:16px !important" class="mb-2 mt-2">Tasa de rebote</h4>
              </div>
              <h1 style="text-align:center;padding:0;" class="mb-2 mt-2">{{avgBounceRate}}%</h1>
            </b-card>
          </b-col>
          <b-col xs="12" sm="6" md="3" class="px-2 py-2">
            <loading-overlay v-if="loadingSessionDuration"/>
            <b-card class="px-2 py-2" >
              <div style="width:100%" class="d-flex justify-content-center align-items-center">
                <b-icon icon="clock" style="width: 25px; height: 25px; color:#B57459" class="mr-2" aria-hidden="true"></b-icon> 
                <h4 style="text-align:center;padding:0;font-size:16px !important" class="mb-2 mt-2">Promedio de Sesión</h4>
              </div>
              <h1 style="text-align:center;padding:0;" class="mb-2 mt-2">{{avgSesionDuration}} min.</h1>
            </b-card>
          </b-col>
          <b-col xs="12" md="12">
            <loading-overlay v-if="loadingPageViews"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Número de vistas por página</h4>
            <div style="min-width:100%;width:100%;margin-bottom:20px">
              <bar-chart :chartdata="barDataPageViews" :options="chartOptions" :key="keyBarDataPageViews" />
            </div>
            <doughnut-chart :chartdata="doughnutDataPageViews" :key="keyDoughnutDataPageViews" :options="chartOptions"  />
          </b-col>
          <b-col xs="12" md="12">
            <loading-overlay v-if="loadingUsersPageViews"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Usuarios únicos por página</h4>
            <div style="min-width:100%;width:100%;margin-bottom:20px">
              <bar-chart :chartdata="barDataUsersPageViews" :options="chartOptions" :key="keyBarDataUsersPageViews" />
            </div>
            <doughnut-chart :chartdata="doughnutDataUsersPageViews" :key="keyDoughnutDataUsersPageViews" :options="chartOptions"/>
          </b-col>
          <b-col xs="12" sm="12">
            <loading-overlay v-if="loadingBounceRate"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Tasa de rebote de cada página visitada (%)</h4>
            <bar-chart :chartdata="barDataBounceRate" :options="chartOptions" :key="keyBarDataBounceRate" />
          </b-col>
          <b-col xs="12" sm="12">
            <loading-overlay v-if="loadingSessionDuration"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Promedio de duración de la sesion por página (en minutos)</h4>
            <bar-chart :chartdata="barDataSessionDuration" :options="chartOptions" :key="keyBarDataSessionDuration" />
          </b-col>
          <b-col xs="12" md="6">
            <loading-overlay v-if="loadingSourceUsers"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Origen de los usuarios</h4>
            <doughnut-chart :chartdata="doughnutDataSourceUsers" :key="keyDoughnutDataSourceUsers" :options="chartOptions"/>
          </b-col>
          <b-col xs="12" md="6">
            <loading-overlay v-if="loadingUsedBrowsers"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Navegadores usados</h4>
            <doughnut-chart :chartdata="doughnutDataUsedBrowsers" :key="keyDoughnutDataUsedBrowsers" :options="chartOptions"/>
          </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import BarChart from './../components/BarChart.vue'
import DoughnutChart from './../components/DoughnutChart.vue'
import LoadingOverlay from './../components/LoadingOverlay.vue'
import jsPDF from 'jspdf';
import moment from 'moment'

export default {
  components:{
    BarChart,
    DoughnutChart,
    LoadingOverlay
  },
  name: 'Statistics',
  data() {
      return {
        disablePDF: true,
        loadingPDF: false,
        isMobile: false,
        rol: '',
        pageViews: '',
        uniqueUsers: '',
        avgBounceRate: '',
        avgSesionDuration: '',
        startDateOption: '30daysAgo',
        startDateOptions:[
          {
            name:'Ayer',
            value:'yesterday'
          },
          {
            name:'Los últimos 7 días',
            value:'7daysAgo'
          },
          {
            name:'Los últimos 14 días',
            value:'14daysAgo'
          },
          {
            name:'Los últimos 28 días',
            value:'28daysAgo'
          },
          {
            name:'Los últimos 30 días',
            value:'30daysAgo'
          },
          {
            name:'Los últimos 90 días',
            value:'90daysAgo'
          },
          {
            name:'Los últimos 180 días',
            value:'180daysAgo'
          },
        ],
        chartOptions:{
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index',
            intersect: false
          },
        },
        coloursChart:["#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411","#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411","#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411"],
        loadingBounceRate:true,
        keyBarDataBounceRate:1,
        barDataBounceRate: {
          labels: [],
          datasets: [
              {
                label: 'Tasa de rebote de cada página visitada',
                backgroundColor: '#3366cc',
                data: [],
              },
          ],
        },
        loadingSessionDuration:true,
        keyBarDataSessionDuration:1,
        barDataSessionDuration: {
          labels: [],
          datasets: [
              {
                label: 'Promedio de duración de la sesion por página (en minutos)',
                backgroundColor: '#dc3912',
                data: [],
              },
          ],
        },
        loadingPageViews:true,
        keyDoughnutDataPageViews:1,
        doughnutDataPageViews: {
          labels: [],
          datasets: [
              {
                label: 'Páginas visitadas en el sistema',
                data: [],
                backgroundColor: [],
              },
          ],
        },
        keyBarDataPageViews:1,
        barDataPageViews: {
          labels: [],
          datasets: [
              {
                label: 'Número de vistas por página',
                backgroundColor: '#a0522b',
                data: [],
              },
          ],
        },
        loadingUsedBrowsers:true,
        keyDoughnutDataUsedBrowsers:1,
        doughnutDataUsedBrowsers: {
          labels: [],
          datasets: [
              {
                label: 'Navegadores usados por usuarios',
                data: [],
                backgroundColor: [],
              },
          ],
        },
        loadingUsersPageViews:true,
        keyDoughnutDataUsersPageViews:1,
        doughnutDataUsersPageViews: {
          labels: [],
          datasets: [
              {
                label: 'Usuarios únicos por página',
                data: [],
                backgroundColor: [],
              },
          ],
        },
        keyBarDataUsersPageViews:1,
        barDataUsersPageViews: {
          labels: [],
          datasets: [
              {
                label: 'Usuarios únicos por página',
                backgroundColor: '#f0900f',
                data: [],
              },
          ],
        },
        loadingSourceUsers:true,
        keyDoughnutDataSourceUsers:1,
        doughnutDataSourceUsers: {
          labels: [],
          datasets: [
              {
                label: 'Origen de los usuarios',
                data: [],
                backgroundColor: [],
              },
          ],
        },
      }
    },
  watch:{
    startDateOption(){
      this.getAllData()
    },
    disableSelect(){
      if (!this.disableSelect){
        setTimeout(() => {
          this.disablePDF = false
        }, 1000);
      } else{
        this.disablePDF = true
      }
    }
  },
  computed:{
    disableSelect(){
      if (
        this.loadingBounceRate === true ||
        this.loadingSessionDuration === true ||
        this.loadingPageViews === true ||
        this.loadingUsedBrowsers === true ||
        this.loadingUsersPageViews === true ||
        this.loadingSourceUsers === true
        ){
          return true
        }
      else {
        return false
      }
    },
    getNameInterval() {
      let name = ''
      this.startDateOptions.forEach(element => {
        if (element.value === this.startDateOption){
          name = element.name
        }
      });
      return name
    }
  },
  mounted() {
    this.rol = (localStorage.userRol)
    if ( this.rol !== 'admin'){
        this.$router.back()
    }
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    if ( this.rol === 'admin'){
      this.getAllData()
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    getAllData(){
      this.loadingBounceRate = true
      this.loadingSessionDuration = true
      this.loadingPageViews = true
      this.loadingUsedBrowsers = true
      this.loadingUsersPageViews = true
      this.loadingSourceUsers = true

      this.barDataBounceRate.labels = []
      this.barDataBounceRate.datasets[0].data = []
      this.barDataSessionDuration.labels = []
      this.barDataSessionDuration.datasets[0].data = []
      this.barDataPageViews.labels = []
      this.barDataPageViews.datasets[0].data = []
      this.barDataUsersPageViews.labels = []
      this.barDataUsersPageViews.datasets[0].data = []

      this.doughnutDataUsedBrowsers.labels = []
      this.doughnutDataUsedBrowsers.datasets[0].data = []
      this.doughnutDataUsedBrowsers.datasets[0].backgroundColor = []
      this.doughnutDataPageViews.labels = []
      this.doughnutDataPageViews.datasets[0].data = []
      this.doughnutDataPageViews.datasets[0].backgroundColor = []
      this.doughnutDataUsersPageViews.labels = []
      this.doughnutDataUsersPageViews.datasets[0].data = []
      this.doughnutDataUsersPageViews.datasets[0].backgroundColor = []
      this.doughnutDataSourceUsers.labels = []
      this.doughnutDataSourceUsers.datasets[0].data = []
      this.doughnutDataSourceUsers.datasets[0].backgroundColor = []

      this.pageViews = ''
      this.uniqueUsers = ''
      this.avgBounceRate = ''
      this.avgSesionDuration = ''

      Promise.all([
          this.getPageViews(),
          this.getUsedBrowsers(),
          this.getBounceRate(),
          this.getSessionDuration(),
          this.getUsersPageViews(),
          this.getSourceUsers()
        ]).then(values=>{
          console.log(values)
      }) 
    },
    addColours(colours,chart){
        let sizeChart = chart.length
        let arrayColours = []
        colours.forEach((element,index) =>{
            if (index<sizeChart){
                arrayColours.push(element)
            }
        })
        return arrayColours
    },
    checkRoute(route){
      if (
        route === '/' ||
        /*route === '/reset-password' ||
        route === '/new-password' ||
        route === '/dashboard' ||*/
        /*route === '/asana' ||*/
        route === '/dropbox' ||
        route === '/inicio' ||
       /* route === '/usuarios' ||*/
        /*route === '/boletines' ||*/
        route === '/recursos' ||
        /*route === '/organigrama' ||*/
        route === '/links-utiles' ||
        route === '/listado-boletines' ||
        route === '/listado-noticias' ||
        /*route === '/noticias' ||*/
        route === '/reconocimientos' ||
        route === '/perfil' ||
        /*route === '/hvr' ||*/
        route === '/eventos' ||
        route === '/mensajes' ||
        /*route === '/estadisticas' ||*/
        route.includes('/noticia/') ||
        route.includes('/boletin/')  ||
        route.includes('/reconocimiento/')
      ){
        return true
      }
      else {
        return false
      }
    },
    async getPageViews(){
        await axios.get(
          `${process.env.VUE_APP_URL_API_ANALYTICS}/getAnalytics/${process.env.VUE_APP_GOOGLE_VIEW_ID}?startDate=${this.startDateOption}&endDate=today&metrics=ga:pageviews&dimensions=ga:pagePath`)
        .then(res => {
            // console.log(res.data.rows)
            res.data.rows.forEach(element => {
              if (this.checkRoute(element[0])){
                if (element[0] === '/'){
                  this.doughnutDataPageViews.labels.push('/login')
                  this.barDataPageViews.labels.push('/login')
                }
                else {
                  this.doughnutDataPageViews.labels.push(element[0])
                  this.barDataPageViews.labels.push(element[0])
                }
                this.doughnutDataPageViews.datasets[0].data.push(Number(element[1]))
                this.barDataPageViews.datasets[0].data.push(Number(element[1]))
              }
            })
            this.doughnutDataPageViews.datasets[0].backgroundColor = this.addColours(this.coloursChart,this.doughnutDataPageViews.labels)
            ++this.keyDoughnutDataPageViews
            ++this.keyBarDataPageViews
            
            let totalPageViews = 0
            this.doughnutDataPageViews.datasets[0].data.forEach(element => {
              totalPageViews += element
            });
            this.pageViews = totalPageViews
            
            this.loadingPageViews = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    async getUsedBrowsers(){
        await axios.get(
          `${process.env.VUE_APP_URL_API_ANALYTICS}/getAnalytics/${process.env.VUE_APP_GOOGLE_VIEW_ID}?startDate=${this.startDateOption}&endDate=today&metrics=ga:users&dimensions=ga:browser`)
        .then(res => {
            //console.log(res.data.rows)
            res.data.rows.forEach(element => {
              this.doughnutDataUsedBrowsers.labels.push(element[0])
              this.doughnutDataUsedBrowsers.datasets[0].data.push(Number(element[1]))
            })
            this.doughnutDataUsedBrowsers.datasets[0].backgroundColor = this.addColours(this.coloursChart,this.doughnutDataUsedBrowsers.labels)
            ++this.keyDoughnutDataUsedBrowsers
            this.loadingUsedBrowsers = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    async getBounceRate(){
        await axios.get(
          `${process.env.VUE_APP_URL_API_ANALYTICS}/getAnalytics/${process.env.VUE_APP_GOOGLE_VIEW_ID}?startDate=${this.startDateOption}&endDate=today&metrics=ga:bounceRate&dimensions=ga:pagePath`)
        .then(res => {
            //console.log(res.data.rows)
            res.data.rows.forEach(element => {
              if (this.checkRoute(element[0])){
                if (element[0] === '/'){
                  this.barDataBounceRate.labels.push('/login')
                }else {
                  this.barDataBounceRate.labels.push(element[0])
                }
                this.barDataBounceRate.datasets[0].data.push(Number(Number(element[1]).toFixed(2)))
              }
            })
            ++this.keyBarDataBounceRate

            let totalBounceRate = 0
            this.avgBounceRate = 0
            this.barDataBounceRate.datasets[0].data.forEach(element => {
              totalBounceRate += element
            });

            totalBounceRate = (totalBounceRate / this.barDataBounceRate.labels.length).toFixed(0)
            
            this.avgBounceRate = totalBounceRate

            this.loadingBounceRate = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    async getSessionDuration(){
        await axios.get(
          `${process.env.VUE_APP_URL_API_ANALYTICS}/getAnalytics/${process.env.VUE_APP_GOOGLE_VIEW_ID}?startDate=${this.startDateOption}&endDate=today&metrics=ga:avgSessionDuration&dimensions=ga:pagePath`)
        .then(res => {
            //console.log(res.data.rows)
            res.data.rows.forEach(element => {
              if (this.checkRoute(element[0])){
                if (element[0] === '/'){
                  this.barDataSessionDuration.labels.push('/login')
                }else {
                  this.barDataSessionDuration.labels.push(element[0])
                }
                this.barDataSessionDuration.datasets[0].data.push(Number(Number(element[1]/60).toFixed(2)))
              }
            })
            ++this.keyBarDataSessionDuration

            let totalSeconds = 0
            this.avgSesionDuration = 0
            this.barDataSessionDuration.datasets[0].data.forEach(element => {
              totalSeconds += element
            });

            totalSeconds = totalSeconds / this.barDataSessionDuration.labels.length
            
            if (totalSeconds){
              this.avgSesionDuration = (totalSeconds).toFixed(0)
            }
            
            this.loadingSessionDuration = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    async getUsersPageViews(){
        await axios.get(
          `${process.env.VUE_APP_URL_API_ANALYTICS}/getAnalytics/${process.env.VUE_APP_GOOGLE_VIEW_ID}?startDate=${this.startDateOption}&endDate=today&metrics=ga:users&dimensions=ga:pagePath`)
        .then(res => {
            // console.log(res.data.rows)
            res.data.rows.forEach(element => {
              if (this.checkRoute(element[0])){
                if (element[0] === '/'){
                  this.doughnutDataUsersPageViews.labels.push('/login')
                  this.barDataUsersPageViews.labels.push('/login')
                }else {
                  this.doughnutDataUsersPageViews.labels.push(element[0])
                  this.barDataUsersPageViews.labels.push(element[0])
                }
                this.doughnutDataUsersPageViews.datasets[0].data.push(Number(element[1]))
                this.barDataUsersPageViews.datasets[0].data.push(Number(element[1]))
              }
            })
            this.doughnutDataUsersPageViews.datasets[0].backgroundColor = this.addColours(this.coloursChart,this.doughnutDataUsersPageViews.labels)
            ++this.keyDoughnutDataUsersPageViews
            ++this.keyBarDataUsersPageViews

            //this.uniqueUsers = 0
            
            //let indexRoot = false
            /*this.doughnutDataUsersPageViews.labels.forEach((element,index) => {
              if (element == '/login') {
                indexRoot = index
              }
            });*/

            /*if (indexRoot !== false){
              this.uniqueUsers = this.doughnutDataUsersPageViews.datasets[0].data[indexRoot]
            }*/

            this.loadingUsersPageViews = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    async getSourceUsers(){
        await axios.get(
          `${process.env.VUE_APP_URL_API_ANALYTICS}/getAnalytics/${process.env.VUE_APP_GOOGLE_VIEW_ID}?startDate=${this.startDateOption}&endDate=today&metrics=ga:users&dimensions=ga:source`)
        .then(res => {
            //console.log(res.data.rows)
            res.data.rows.forEach(element => {
              this.doughnutDataSourceUsers.labels.push(element[0])
              this.doughnutDataSourceUsers.datasets[0].data.push(Number(element[1]))
            })
            this.doughnutDataSourceUsers.datasets[0].backgroundColor = this.addColours(this.coloursChart,this.doughnutDataSourceUsers.labels)
            
            this.uniqueUsers = res.data.totalsForAllResults['ga:users'] || 0 
            
            ++this.keyDoughnutDataSourceUsers
            this.loadingSourceUsers = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    generatePDF() {
      this.loadingPDF = true
      const canvas =  document.getElementsByTagName('CANVAS');

      let imgs = []
      
      for (let index = 0; index < canvas.length; index++) {
        imgs.push(canvas[index].toDataURL('image/png'))
      }
      
      const doc = new jsPDF();
      doc.setFontSize(14);
      doc.setTextColor(44, 62, 80);
      doc.text("Estadisticas de Cobre y Metales", 10, 10);
      doc.setFontSize(12);
      doc.text(`Fecha: ${moment().locale('es').format('DD [de] MMMM [del] YYYY')}`, 10, 17);
      doc.text(`Intervalo de fechas: ${this.getNameInterval}`, 10, 23);
      doc.text("Número de vistas", 20, 35);
      doc.text("Usuarios únicos", 65, 35);
      doc.text("Tasa de rebote", 110, 35);
      doc.text("Promedio de Sesión", 155, 35);
      doc.setFontSize(22);
      doc.text(`${this.pageViews}`, 30, 45);
      doc.text(`${this.uniqueUsers}`, 78, 45);
      doc.text(`${this.avgBounceRate}%`, 120, 45);
      doc.text(`${this.avgSesionDuration} .min`, 165, 45);

      doc.setFontSize(10);

      let texts = [
        'Número de vistas por página',
        'Número de vistas por página',
        'Usuarios únicos por página',
        'Usuarios únicos por página',
        'Tasa de rebote de cada página visitada (%)',
        'Promedio de duración de la sesion por página (en minutos)',
        'Origen de los usuarios',
        'Navegadores usados'
      ]

      for (let index = 0; index < imgs.length; index++) {
        let topNumber = 10
        let indexNumber = index
        let widthImage = 185
        let heightImage = 65
        let leftImage = 10
        if (index === 3) {
          doc.addPage("a4", "p");
        }
        if (index < 3){
          topNumber = 60 + (indexNumber * 80)
        }
        if (index > 2 && index < 6){
          topNumber = 30 + ((indexNumber - 3) * 80)
        }
        if (index === 6) {
          doc.addPage("a4", "p");
        }
        if (index > 5){
          widthImage = 120
          leftImage = 42
          heightImage = 75
          topNumber = 30 + ((indexNumber - 6) * 80)
        }
        
        doc.addImage(imgs[index], 'PNG', leftImage, topNumber, widthImage, heightImage);

        if (index === 0) {
          doc.text(`${texts[index]}`, 10, 58);
        }
        if (index === 1) {
          doc.text(`${texts[index]}`, 10, 135);
        }
        if (index === 2) {
          doc.text(`${texts[index]}`, 10, 218);
        }
        if (index === 3) {
          doc.text(`${texts[index]}`, 10, 25);
        }
        if (index === 4) {
          doc.text(`${texts[index]}`, 10, 105);
        }
        if (index === 5) {
          doc.text(`${texts[index]}`, 10, 185);
        }
        if (index === 6) {
          doc.text(`${texts[index]}`, 10, 25);
        }
        if (index === 7) {
          doc.text(`${texts[index]}`, 10, 105);
        }
      }
      this.loadingPDF = false
      doc.save('Cobre-Y-Metales-Estadisticas.pdf');
    },
  }
}
</script>

<style>
  .showPrintBtn{
    display: none;
  }
  @media (min-width: 900px) {
    .showPrintBtn{
      display: block;
    }
  }
</style>
