<template>
  <div class="px-3 px-sm-5">
    <b-row>
      <b-col cols="4 my-5" align-self="center" style="margin-top: 10px;">
        <h2 style="font-weight: 500 !important;">Nuestro Equipo</h2>
      </b-col>
    </b-row>
    <div class="loading-div-full-size d-flex justify-content-center align-items-center; width: 100%" v-if="loading">
      <span style="font-weight: 500 !important;">Cargando...</span>
      <b-spinner class="ml-2"></b-spinner>
    </div>
    <organization-chart :datasource="this.organigrama" :zoom="true" :pan="true" :zoomout-limit="0" >
        <div slot-scope="{ nodeData }" >
          <div :class="`box-user ${nodeData.nivel !== '' ? 'nivel-' + nodeData.nivel : ''}`" :style="{ borderColor: nodeData.color && nodeData.color.value !== '' ? nodeData.color.value : '#3AB6E3'}">
            <div class="container-buttons" v-if="rol == 'admin'">
              <button class="button-box"  @click="showModalCreate('old', nodeData.id)" >
                <b-icon scale="0.8" icon="person-plus-fill"></b-icon>
              </button>
              <button class="button-box"  @click="showModalCreate('edit', nodeData)" >
                <b-icon scale="0.8" icon="pencil-fill"></b-icon>
              </button>
              <button v-if="nodeData.parent_id !== 0" class="button-box" @click="deleteItem(nodeData)"  >
                <b-icon scale="0.8" icon="trash-fill"></b-icon>
              </button>
            </div>
            <div class="top">
              <h4 v-if="nodeData.name">{{nodeData.name}} {{nodeData.lastName}}</h4>
              <h6 v-if="nodeData.title && nodeData.title !== ''" :style="{ textTransform: 'capitalize' }">{{nodeData.title.replaceAll('_', ' ')}}</h6>
            </div>
           <div class="contact">
              <p v-if="nodeData.email && nodeData.email !== ''"><b>Correo:</b> {{nodeData.email}}</p>
              <p v-if="nodeData.phone && nodeData.phone !== ''"><b>Phone:</b> {{nodeData.phone}}</p>
              <h5 v-if="nodeData.factory && nodeData.factory.length > 0 " class="capitalize-text" :style="{ textTransform: 'capitalize', display: 'block' }"><b>Planta:</b> <ul><li v-for="(item, index) in nodeData.factory" :key="index" :style="{ textTransform: 'capitalize' }">{{ item.value.replaceAll('_', ' ') }} </li></ul></h5>
           </div>
          </div>
        </div>
      </organization-chart>
    <b-modal   centered    ref="modal-1"   size="md"   hide-footer>
        <template #modal-title>
            <b class="ml-1 ml-md-4" style="color:#fff"> Crear tarjeta Nueva </b>
        </template>
        <b-row class="mt-4 justify-content-center">
          <b-col cols="10">
            <form id="form_">
              <div>
                <b-form-group label="Nombre">
                  <b-form-input id="input-1" type="text" v-model="name" required> </b-form-input>
                  <b-form-invalid-feedback id="input-1-feedback">
                  El nombre debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="Apellido">
                    <b-form-input id="input-1" type="text"  v-model="lastName" required> </b-form-input>
                    <b-form-invalid-feedback id="input-1-feedback">
                    El apellido debe contener al menos 3 letras.
                    </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group  label="Email" >
                  <b-form-input id="email"  type="text"  v-model="email" autocomplete="off" required  ></b-form-input>
                   <b-form-invalid-feedback id="input-1-feedback">
                    Debe ingresar un email válido.
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <b-form-group label="Planta">
                <div class="multiple-select" @click="(e) => openList(e)" v-if="options.length > 0"  >
                  <p>Selecciona una opcion </p> 
                  <ul class="select-list" v-if="showFactories"  id="select-list" ref="targetElement">
                    <li v-for="(item, index) in factoryList" :key="index" @click="(e) => pushFactories(e, item, index)" >{{ item.text }} </li>
                  </ul>
                </div>
                <ul class="factory-list" v-if="factory.length > 0" >
                  <li v-for="(item, index) in factory" :key="index"> {{ item.text }} <button type="button"  @click="(e) => deleteFactories(index, item)"><b-icon scale="0.8" icon="trash-fill" ></b-icon></button></li>
                </ul>
              </b-form-group>
              <!-- <b-form-group  id="input-group-2"  label="Planta"  label-for="input-2" class="mb-3"  required >
                <b-form-select v-model="factory" :options="options" @change="(e) => checkState('factory')" ></b-form-select>
                <ul>
                  <li :v-for="((item, key) in factory)" :key="key">{{ item }}</li>
                </ul>
              </b-form-group>  -->
              <b-form-group label="Teléfono" >
                <b-form-input id="phone"  type="text" v-model="phone" autocomplete="off" required  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-feedback">
                  Debe ingresar un teléfono valido de 9 dígitos.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group label="Cargo" >
                <b-form-input id="position"  type="text"  v-model="position" autocomplete="off" required  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-feedback">
                  Debe ingresar el cargo.
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group  id="input-group-2" label="Nivel"  label-for="input-2" class="mb-3"  required >
                <b-form-select v-model="nivel" :options="niveles" ></b-form-select>
              </b-form-group> 
              <b-form-group label="Color de la tarjeta" class="container-colors">
                <div v-for="(item, index) in colors" :key="index" class="check-colors" >
                  <input type="radio" :id="item.name" :value="item" v-model="selectedColor">
                  <label :for="item.name" ></label>
                  <div class="bordered-card"  :style="{ background: item.value }"></div>
                  <div class="simulated-text"></div>
                </div>
              </b-form-group>
              <h4 class="error" v-if="error !== ''"><b-icon scale="0.8" icon="emoji-frown"></b-icon> {{ error }} </h4>
              <!-- <b-form-group label="Elige un Superior">
                  <b-form-input id="input-1" type="text" v-model="superior" @input="(e) => getUsers(e, 'superior')" required> </b-form-input>
                  <div class="loading-div-full-size d-flex justify-content-center align-items-center; width: 100%" v-if="loaderModal">
                    <span style="font-weight: 500 !important;">Cargando...</span>
                    <b-spinner class="ml-2"></b-spinner>
                  </div>
                  <ul class="users-list" v-if="users.length > 0" >
                    <li v-for="(item, index) in users" :key="index"><span>{{ item.name }} {{ item.lastname }}</span> <b>{{ item.factory && item.factory.replaceAll('_', ' ') }}</b> <button type="button" @click="(e) => selectParent(item)">Agregar</button></li>
                  </ul>
              </b-form-group> -->
            </form>
          </b-col>
        </b-row>

        <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
          <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
            <b-btn   block  variant="outline-danger"  @click="closeModal">
              Cancelar
          </b-btn>
        </b-col>
        <b-col v-if="type_modal === 'edit'" style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
            <b-btn  block  :disabled="disableBtn"  class="outline-btn"  @click="editUser">
              <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
              {{ loadingBtn ? "" : "Editar" }}
            </b-btn>
        </b-col>
        <b-col v-if="type_modal === 'old'" style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
            <b-btn  block  :disabled="disableBtn"  class="outline-btn"  @click="createUser">
              <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
              {{ loadingBtn ? "" : "Crear" }}
            </b-btn>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal   centered    ref="modal-delete"   size="md"   hide-footer>
      <template #modal-title>
            <b class="ml-1 ml-md-4" style="color:#fff"> Eliminar tarjeta</b>
        </template>
        <h4>¿Deseas borrar esta tarjeta?</h4>
          <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-center">
            <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
              <b-btn   block  variant="outline-danger"  @click="closeModalDelete">  Cancelar</b-btn>
            </b-col>
            <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
              <b-btn  block   class="outline-btn"  @click="deleteCard">
              <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                Borrar
            </b-btn>
          </b-col>
      </b-row>
    </b-modal>
  </div>
  
</template>

<script>
import axios from 'axios';
import OrganizationChart from 'vue-organization-chart';
import 'vue-organization-chart/dist/orgchart.css';
export default {
  name: 'Organigrama',
  components: {
      OrganizationChart,
  },
  data() {
    return {
      url_img: process.env.VUE_APP_URL+'/storage/',
      loading: false,
      loaderModal: false,
      users:[],
      card_users: [],
      organigrama: {},
      selected_user_id: '',
      name: '',
      email: '',
      showFactories: false,
      parent_id: '',
      superior: '',
      image: '',
      phone: '',
      id: '',
      lastName: '',
      rut: '',
      position: '',
      role: '',
      params: '',
      photo: null,
      type_modal: 'new',
      old_worker: {},
      isMobile: '',
      loadingBtn: false,
      select_user: '',
      rol: '',
      error: '',
      userDeleted: '',
      factory: [],
      factoryList : [],
      selectedColor: '',
      nivel: {},
      niveles: [
        {text: 'Primer Nivel', value: 1}, 
        {text: 'Segundo Nivel', value: 2}, 
        {text: 'Tercer Nivel', value: 3},
        {text: 'Cuarto Nivel', value: 4}, 
        {text: 'Quinto Nivel', value: 5},
        {text: 'Sexto Nivel', value: 6},
        {text: 'Séptimo Nivel', value: 7},
        {text: 'Octavo Nivel', value: 8},
      ],
      options: [
        { value: 'las_gaviotas', text: 'Planta las Gaviotas' },
        { value: 'san_ignacio', text: 'Planta San Ignacio' },
        { value: 'casa_matriz_santiago', text: 'Casa Matriz Santiago' },
        { value: 'auricop', text: 'Auricop' },
        { value: 'minera_cobre_verde', text: 'Minera Cobre Verde' },
        { value: 'enami', text: 'Enami' },
      ],
      colors: [
        {name: 'yellow', value: '#f8ed62'},
        {name: 'red', value: '#ff5252'},
        {name: 'blue', value: '#3AB6E3'},
        {name: 'green', value: '#93c47d'},
        {name: 'purple', value: '#674ea7'},
        {name: 'orange', value: '#e69138'},
        {name: 'deepblue', value: '#3a5874'},
        {name: 'deepgreen', value: '#347864'},
        {name: 'gray', value: '#8f8f8f'},
        {name: 'brown', value: '#8e582c'},
      ],
    }
  },
  mounted() {
    this.rol = (localStorage.userRol);
    this.onResize(),
    this.getData(),
    // Attach a click event listener to the document
    document.addEventListener("click", this.handleClickOutside);
  },
  computed:{
    disableBtn() {
      // if(( this.position.length < 3 )|| ( this.name.length < 3) || ( this.lastName.length < 3) || ( this.email.length < 3)){
      //   return true
      // }
      // else{
      //   return false
      // }
      return false
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
     handleClickOutside(event) {
      event.stopPropagation()
      const targetElement = this.$refs.targetElement;
      if (targetElement && !targetElement.contains(event.target)) {
        this.showFactories = false
      }},
      onResize () {
        this.isMobile = window.innerWidth < 1200
      },
      deleteFactories(index, item){
        this.factoryList.push(item)
        this.factory.splice(index, 1)
      },
      pushFactories(e, item, index){
        e.stopPropagation();
        if(item.value !== ''){
          this.factory.push(item)
          this.factoryList.splice(index, 1)
          this.showFactories = false
        }
      },
      openList(e){
        e.stopPropagation()
        this.showFactories =  true
      },
      async getData() {
        this.loading = true
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };
        let url = `${process.env.VUE_APP_URL}/api/auth/organization_chart`;
        await axios.get(url, config).then(data => {
          const arreglo = []
          let obj 
          if(data && data.data.data){
            data.data.data.map((key)=> {
              if(key.data_offline_user && key.data_offline_user.length > 0 && key.parent_id !== null){
                const element = JSON.parse(key.data_offline_user)
                const position =  key.position_card &&  key.data_offline_user !== null && key.position_card.length > 0 ? JSON.parse(key.position_card) : ''
                obj ={
                  'id': key.id,
                  'parent_id': key.parent_id,
                  'name':  element.name,
                  'lastName': element.lastName,
                  'email': element.email,
                  'factory': element.factory && Array.isArray(element.factory) ? element.factory : [],
                  'phone': element.phone && element.phone !== '' ? element.phone : '',
                  'title': position !== null && position.position && position.position !== '' ? position.position : '',
                  'color': element.color && element.color !== '' ? element.color : '',
                  'nivel': element.nivel && element.nivel !== '' ? element.nivel : '',
                  'children': []
                }
              }
              else{
                return
              }
              arreglo.push(obj)
            })
            arreglo.sort(function (a, b){
                return a.id - b.id
            });
            const parent = arreglo.filter((item) => {return item.parent_id === 0})
            arreglo.forEach(data=>{
              this.colors.forEach(item =>{
                if(data.color === item.name){
                  data.color = item
                }
              })
            })

            arreglo.forEach(data=>{
              arreglo.forEach(item =>{
                if(data.id === item.parent_id){
                  data.children.push(item)
                }
              })
            })
            this.loading = false
            this.organigrama = parent[0]
          }
        })
      },

      async getUsers(value, nameInput) {
        this.loaderModal = true
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };
        let url = `${process.env.VUE_APP_URL}/api/auth/all-users${value !== '' ? '?name='+ value : ''}`;
        await axios.get(url, config).then(data => {
          if(nameInput === 'select_user'){
            this.card_users = data.data
          }
          else{
            this.users = data.data
          }
          this.loaderModal = false
        })
      },

      selectParent(e){
          this.parent_id = e.id
          this.superior = `${e.name} ${e.lastname}`
          this.users = []
      },
      deleteItem(e){
        this.userDeleted = ''
        this.userDeleted = e.id
        this.$refs['modal-delete'].show();
      },
      closeModalDelete(){
        this.userDeleted = ''
        this.$refs['modal-delete'].hide();
      },
      async deleteCard(){
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };
        let url = `${process.env.VUE_APP_URL}/api/auth/organization_chart/${this.userDeleted}`;
        await axios.delete(url, config)
          .then(() => {
            this.loadingBtn = false;
            this.getData()
            this.closeModalDelete()
          })
          .catch((err) => {
            this.loadingBtn = false;
            console.log('>> Error while trying to create an user.', err);
          })
      },
      selectOldWorker(e){
          this.select_user = e.name
          this.selected_user_id = e.id
          this.card_users = []
      },
      filterByReference(arr1, arr2) {
        const uniqueArr1 = arr1.filter(el => {
          return !arr2.some(element => {
            return element.text === el.text && element.value === el.value;
          });
        });

        const uniqueArr2 = arr2.filter(el => {
          return !arr1.some(element => {
            return element.text === el.text && element.value === el.value;
          });
        });
        return uniqueArr1.concat(uniqueArr2);
      },
      showModalCreate(e, parent) {
        this.error = '';
        this.factory = [];
        const arr =  [
          { value: 'las_gaviotas', text: 'Planta las Gaviotas' },
          { value: 'san_ignacio', text: 'Planta San Ignacio' },
          { value: 'casa_matriz_santiago', text: 'Casa Matriz Santiago' },
          { value: 'auricop', text: 'Auricop' },
          { value: 'minera_cobre_verde', text: 'Minera Cobre Verde' },
          { value: 'enami', text: 'Enami' },
        ]
        if(e === 'edit'){
          if(parent.factory && parent.factory.length>0){
            this.factoryList = this.filterByReference(parent.factory, arr)
          }
          else{
            this.factoryList = arr
          }
          this.parent_id = parent.parent_id
          this.name = parent.name;
          this.id = parent.id;
          this.type_modal = e
          this.factory = parent.factory;
          this.lastName = parent.lastName;
          // this.position = parent.factory;
          this.selectedColor = parent.color;
          this.email = parent.email; 
          this.position =  parent.title;
          this.phone =  parent.phone;
          this.nivel = parent.nivel
          this.$refs['modal-1'].show();
        }
        else{
          this.factoryList = arr;
          this.factory = [];
          this.parent_id = parent;
          this.type_modal = e;
          this.selectedColor = '';
          this.name = '';
          this.lastName = ''; 
          this.phone = '';
          this.email = '';
          this.select_user = '';
          this.selected_user_id = '';
          this.superior = '';
          this.position = '';
          this.id = '';
          this.nivel = '';
          this.$refs['modal-1'].show();
        }
      },

      async createUser () {
        let formData = new FormData();
        let obj = {
          'name': this.name,
          'lastName': this.lastName,
          'email': this.email,
          'factory': this.factory,
          'phone': this.phone,
          'color': this.selectedColor,
          'nivel': this.nivel
        }
        let pos = {
          'position': this.position
        }
        formData.append('parent_id', this.parent_id);
        formData.append('user_id', this.selected_user_id && this.selected_user_id !== '' ? this.selected_user_id : '' );
        formData.append('data_offline_user', JSON.stringify(obj));
        formData.append('position_card', JSON.stringify(pos));
        this.loadingBtn = true;
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };
        let url = `${process.env.VUE_APP_URL}/api/auth/organization_chart`;
        await axios.post(url, formData, config)
        .then(() => {
          this.loadingBtn = false;
          this.getData()
          this.closeModal()
        })
        .catch((err) => {
          this.error = 'Ha ocurrido un error al editar'
          this.loadingBtn = false;
          console.log('>> Error while trying to create an user.', err);
        })
      },
      async editUser () {
        let formData = new FormData();
        let obj = {
          'name': this.name,
          'lastName': this.lastName,
          'email': this.email,
          'factory': this.factory,
          'phone': this.phone,
          'color': this.selectedColor,
          'nivel': this.nivel
        }
        let pos = {
          'position': this.position
        }
        formData.append('parent_id', this.parent_id);
        formData.append('user_id', this.selected_user_id && this.selected_user_id !== '' ? this.selected_user_id : '' );
        formData.append('data_offline_user', JSON.stringify(obj));
        formData.append('position_card', JSON.stringify(pos));
        this.loadingBtn = true;
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };
        let url = `${process.env.VUE_APP_URL}/api/auth/organization_chart/${this.id}`;
        await axios.post(url, formData, config)
        .then(() => {
          this.loadingBtn = false;
          this.getData()
          this.closeModal()
        })
        .catch((err) => {
          this.error = 'Ha ocurrido un error al editar'
          this.loadingBtn = false;
          console.log('>> Error while trying to create an user.', err);
        })
      },
      obtenerImagen(e){
          let file = e.target.files[0];
          this.photo = file;
          this.cargarImagen(file);
      },
      cargarImagen(file){
          let reader = new FileReader();
          reader.onload = (e) => {
              this.image = e.target.result;
          }
          reader.readAsDataURL(file);
      },
      closeModal() {
        this.$refs['modal-1'].hide();
        this.error = ''
        this.name = '';
        this.image = '';
        this.selected_user_id = '';
        this.phone = '';
        this.position = '';
        this.factory = [];
        this.factoryList = [];
        this.id = '';
        this.email = '';
        this.superior = '';
        this.parent_id = '';
        this.nivel = '';
        this.select_user = '';
      },
    },
}
</script>
<style>
  .chart-container{
    padding: 20px;
    text-align: center;
  }
  .box-user{
    min-width: 240px;
    width: 300px;
    height: 140px;
    min-height: 140px;
    max-height: 140px;
    border-radius: 4px;
    display: table-cell!important;
    position: relative;
    display: flex;
    align-items: center;
    box-shadow: 2px 2px 6px #d3d3d3;
    border: 1px solid #d3d3d3;
    flex-direction: column;
    border-top: 10px solid #3AB6E3;
    margin-top: 50px;
  }
  .box-user:first-child{
    margin-top: 0px;
  }
  .box-user h4{
    color: #111672;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 5px;
    min-height:15px;
    text-align:center;
  }
  .box-user ul{
    padding: 0px;
    margin-bottom: 0px;
    list-style-type: none;
  }
  .box-user h5{
    text-align: left;
    font-size: 12px;
    margin-bottom: 0px;
    display: flex;
  }
  .box-user h6{
    color: #404040;
    font-size: 13px;
    margin-top: 4px;
    min-height:15px
  }
  .box-user .top{
    padding: 5px;
    text-align: center;
    border-bottom:1px solid #d3d3d3;
    height: 50px;
    position: relative;
    display: block;
    width: 100%;
  }
  .box-user .contact{
    height: 80px;
    max-width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  .box-user .contact p {
    margin-bottom: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
  .box-user .contact li {
    font-size: 10px;
    display: inline-block;
    margin-left: 10px;
    position: relative;
  }
  .box-user .contact li:first-child{
    margin-left: 0px;
  }
  .box-user .contact li::after{
    content: '';
    position: absolute;
    right: -8px;
    top: 6px;
    width: 5px;
    height: 2px;
    background-color: #404040;
  }
  .box-user .contact li:last-child::after{
    display: none;
  }
  .box-user  p{
    font-size: 10px;
    min-height:15px
  }
  .box-user .photo-user{
    border-radius: 50%;
    margin-top: -35px;
    height: 50px;
    width: 50px;
    min-height:20px;
    min-height:50px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    border: 1px solid #d3d3d3;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  .box-user .photo-user img{
    min-width: 50px;
    height: auto;
    min-height: 50px;
  }
  .orgchart-container{
    min-height: 80vh;
    border: 1px solid #d3d3d3
  }
  .orgchart .node{
    width: 310px;
    height: 168px;
    min-height: 140px;
    max-height: 140px;
  }
  .orgchart .lines .rightLine{
    border-right: 1px solid #d3d3d3
  }
  .orgchart .lines .topLine{
    border-top: 2px solid #d3d3d3;
  }
  .orgchart .lines .leftLine{
    border-left: 1px solid #d3d3d3 
  }
  .orgchart .lines .downLine{
    background-color: #d3d3d3;
  }
  .orgchart .node.focused, .orgchart .node:hover {
    background-color: transparent
}
.capitalize-text{
  text-transform: capitalize;
}
.orgchart {
  background-image: unset !important;
  cursor: grab !important;
  
}
.users-list{
  list-style-type: none;
  padding: 0px;
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid #d3d3d3;
  margin-bottom: 0px;
}
.users-list li{
  text-transform: capitalize;
  padding: 5px 10px;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  justify-content: space-between;
  
}
.users-list li span,.users-list li b{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 110px;
  width:110px; /* some width */
}
.container-buttons{
  display: flex;
  margin-top: -25px;
  float: right;
  position: relative;
  margin-left: auto;
}
.button-box{
  height: 30px;
  width: 30px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  background: #fff;
  margin-right: 10px;
}
.button-box i{
  font-size: 15px;
}
.error{
  font-weight: 600;
  font-size: 16px;
  color: rgba(217,83,79,.8);
}
.container-colors div:not(.check-colors){
  display: block;
}
.check-colors .bordered-card{
  height: 6px;
  width: 100%;
}
.check-colors .simulated-text{    
    width: 80%;
    border-top: 1px groove #d3dddd;
    height: 10px;
    border-bottom: 1px solid #d3d3d3;
    margin: 10px auto;
}
.check-colors{
  width: 60px;
  height: 40px;
  display: inline-block;
  border-radius: 5px;
  position: relative;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
}
/* .check-colors input:checked + label{
  box-shadow: 0px 0px 7px blue;
} */
.check-colors input{
  position: absolute;
  top: -5px;
  left: 0px;
}
.check-colors label{
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.users-list li button{
    height: 20px;
    width: 90px;
    background-color: #3379A1 ;
    border: 1px solid #3379A1 ;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.multiple-select{
  display: block;
  list-style-type: none;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
}
.select-list{
  text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 110px;
    width: 380px;
    position: absolute;
    margin-bottom: 0px;
    background-color: #fff;
    padding-left: 0px;
    list-style-type: none;
    border: 1px solid #d3d3d3;
    box-shadow: 4px 4px 4px rgba(0,0,0,.1);
    left: 0px;
    top: 38px;
}
.select-list li{
  border-bottom: 1px solid #d3d3d3;
  padding: 10px 10px;
}
.select-list li:hover{
  background-color: #f1f1f1;
}

.factory-list {
  padding: 0px;
  margin-bottom: 0px;
}
.factory-list li{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding: 5px 10px;
}
.factory-list li button{
  background-color: rgba(217,83,79,.8);
  border-color: rgba(217,83,79,.8);
  width: 50px;
}
.factory-list li button svg{
  color: #fff;
}
.nivel-1{
  margin-top: 0px!important;
}
.nivel-1::after{
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  top: -100px;
  height: 70px;
  margin: auto;
  background-color: #d3d3d3;
  width: 2px;
}
.nivel-2::after{
  content: '';
  position: absolute;
  left: 1px;
  right: 0px;
  top: -234px;
  height: 216px;
  margin: auto;
  background-color: #d3d3d3;
  width: 2px;
}
.nivel-3::after{
  content: '';
  position: absolute;
  left: 1px;
  right: 0px;
  top: -366px;
  height: 350px;
  margin: auto;
  background-color: #d3d3d3;
  width: 2px;
}
.nivel-4::after{
  content: '';
  position: absolute;
  left: 1px;
  right: 0px;
  top: -487px;
  height: 460px;
  margin: auto;
  background-color: #d3d3d3;
  width: 2px;
}
.nivel-5::after{
  content: '';
  position: absolute;
  left: 1px;
  right: 0px;
  top: -614px;
  height: 588px;
  margin: auto;
  background-color: #d3d3d3;
  width: 2px;
}
.nivel-6::after{
  content: '';
  position: absolute;
  left: 1px;
  right: 0px;
  top: -738px;
  height: 720px;
  margin: auto;
  background-color: #d3d3d3;
  width: 2px;
}
.nivel-7::after{
  content: '';
  position: absolute;
  left: 1px;
  right: 0px;
  top: -958px;
  height: 652px;
  margin: auto;
  background-color: #d3d3d3;
  width: 2px;
}
.nivel-8::after{
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  top: -998px;
  height: 978px;
  margin: auto;
  background-color: #d3d3d3;
  width: 2px;
}
.node:has(.nivel-1) {
  margin-top: 70px;
}
.node:has(.nivel-2) {
  margin-top: 200px;
}
.node:has(.nivel-3) {
  margin-top: 330px;
}
.node:has(.nivel-4) {
  margin-top: 460px;
}
.node:has(.nivel-5) {
  margin-top: 590px;
}
.node:has(.nivel-6) {
  margin-top: 720px;
}
.node:has(.nivel-7) {
  margin-top: 850px;
}
.node:has(.nivel-8) {
  margin-top: 980px;
}
</style>
