<template>
    <div class="sidebar">
        <div class="logo">
            <img :height="`${isMobile ? '70px' : '100px'}}`" :src="`${!mini ? require('../assets/logo.svg') : require('../assets/logo_mini.svg')}`" />
        </div>
        <b-button
            pill
            v-show="!isMobile"
            @click="resizeMenu()"
            :style="`margin-left: ${mini ? 3 : 7.3}%; margin-top: -12px; position: fixed; z-index: 9 !important;`"
            class="arrow-button">
            <b-icon
                :style="`font-size:18px !important; margin-left: -${mini ? 6 : 8}px !important; margin-bottom: 18px !important;`"
                class="p-0"
                :icon="mini ? 'chevron-right' : 'chevron-left'"></b-icon>
        </b-button>
        
        <div class="title" :class="{'px-3' : mini, 'px-4' : !mini}" :style="`font-size: ${mini ? 14 : 18}px; text-align: ${mini ? 'center' : 'left'}`">
            Dashboard
        </div>

        <div class="menu-items">
            <div class="link-container">
             <router-link to="/inicio">
                <b-row :class="`${!mini ? 'left-padding' : ''} m-0`">
                    <b-col :cols="mini ? 12 : 2" class="icons-program" >
                        <b-icon icon="house" style="width: 25px; height: 25px; color:white;" aria-hidden="true"></b-icon> 
                    </b-col>
                    <b-col v-show="!mini" cols="3" class="title-program" style="margin-left: 3px;">
                        <span class="link-title">Inicio</span>
                    </b-col>
                </b-row>
            </router-link>

            </div>
            <div v-if="rol == 'admin'" class="link-container">
                <router-link to="/usuarios">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`">
                    <b-col :cols="mini ? 12 : 2" class="icons-program">
                        <b-icon icon="person-plus" style="width: 25px; height: 25px; color:white;" aria-hidden="true"></b-icon> 
                    </b-col>
                    <b-col v-show="!mini" cols="3" class="title-program" style="margin-left: 3px;">
                        <span class="link-title">Usuarios</span>
                    </b-col>
                </b-row>
                </router-link>
            </div>

            <div class="link-container">
                <router-link :to="`${rol === 'admin' ? '/boletines' : '/listado-boletines'}`">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`">
                        <b-col :cols="mini ? 12 : 2" clasiconss="-program">
                                <img 
                                    class="img-program"
                                    style="margin-left: -0.16em; margin-top:0.20em;"
                                    width="25px"
                                    src="../assets/pin.svg" />
                        </b-col>
                        <b-col v-show="!mini" cols="3" class="title-program" style="margin-left: 3px;">
                            <span class="link-title">Boletín</span>
                        </b-col>
                    </b-row>
                </router-link>
            </div>

            <div class="link-container">
                <router-link :to="`${rol === 'admin' ? '/noticias' : '/listado-noticias'}`">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`"> 
                        <b-col :cols="mini ? 12 : 2" class="icons-program">
                            <img 
                                    class="img-program"
                                    style="margin-left: -0.16em; margin-top:0.20em;"
                                    width="25px"
                                    src="../assets/noticias.svg" />
                        </b-col>
                        <b-col v-show="!mini" cols="3" class="title-program" style="margin-left: 3px;">
                            <span class="link-title">Noticias</span>
                        </b-col>
                    </b-row>
                </router-link>
            </div>

            <div v-if="rol == 'admin'" class="link-container">
                <router-link to="/encuestas">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`">
                    <b-col :cols="mini ? 12 : 2" class="icons-program">
                        <b-icon icon="clipboard-check" style="width: 25px; height: 25px; color:white;" aria-hidden="true"></b-icon>
                    </b-col>
                    <b-col v-show="!mini" cols="3" class="title-program" style="margin-left: 3px;">
                        <span class="link-title">Encuestas</span>
                    </b-col>
                </b-row>
                </router-link>
            </div>
            
            <div class="link-container">
                <router-link to="/links-utiles">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`"> 
                        <b-col :cols="mini ? 12 : 2" class="icons-program">
                            <b-icon rotate="-45" icon="link" style="width: 25px; height: 25px; color:white;" aria-hidden="true"></b-icon>
                        </b-col>
                        <b-col v-show="!mini" cols="9" class="title-program pr-0" style="margin-left: 3px; text-align: left;">
                            <span class="link-title">Links Útiles</span>
                        </b-col>
                    </b-row>
                </router-link>
            </div>

            <div class="link-container">
                <router-link to="/eventos">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`">
                    <b-col :cols="mini ? 12 : 2" class="icons-program">
                        <b-icon icon="calendar-event" style="width: 25px; height: 25px; color:white;" aria-hidden="true"></b-icon> 
                    </b-col>
                    <b-col v-show="!mini" cols="3" class="title-program" style="margin-left: 3px;">
                        <span class="link-title">Eventos</span>
                    </b-col>
                </b-row>
                </router-link>
            </div>

            <div class="link-container">
                <router-link to="/mensajes">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`">
                    <b-col :cols="mini ? 12 : 2" class="icons-program">
                        <b-icon icon="chat-left-text" style="width: 25px; height: 25px; color:white;" aria-hidden="true"></b-icon> 
                    </b-col>
                    <b-col v-show="!mini" cols="3" class="title-program d-flex" style="margin-left: 3px;">
                        <span class="link-title">Mensajes</span>
                        <b-badge v-if="newMessages !== 0" variant="danger" class="ml-2" style="position: relative; height:22px">{{ newMessages }}</b-badge>
                    </b-col>
                </b-row>
                </router-link>
            </div>

            <div class="link-container">
                <router-link to="/reconocimientos">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`"> 
                        <b-col :cols="mini ? 12 : 2" class="icons-program">
                            <img 
                                    class="img-program"
                                    style="margin-left: -0.16em; margin-top:0.20em;"
                                    width="34px"
                                    src="../assets/reconoce.png" />
                        </b-col>
                        <b-col v-show="!mini" cols="7" class="title-program" style="margin-left: 3px; text-align: left;">
                            <span class="link-title">Conoce a</span>
                        </b-col>
                    </b-row>
                </router-link>
            </div>

            <div v-if="rol == 'admin'" class="link-container">
                <router-link to="/estadisticas">
                    <b-row :class="`${!mini ? 'left-padding' : ''} m-0`">
                    <b-col :cols="mini ? 12 : 2" class="icons-program">
                        <b-icon icon="bar-chart-line" style="width: 25px; height: 25px; color:white;" aria-hidden="true"></b-icon> 
                    </b-col>
                    <b-col v-show="!mini" cols="3" class="title-program" style="margin-left: 3px;">
                        <span class="link-title">Estadísticas</span>
                    </b-col>
                </b-row>
                </router-link>
            </div>

            <div class="title mb-3" :class="{'px-3' : mini, 'px-4' : !mini}" :style="`font-size: ${mini ? 14 : 18}px; text-align: ${mini ? 'center' : 'left'}`">
                Mis programas
            </div>
            
            <!--
            <div class="link-container">
                <router-link to="/asana">
                    <b-row :class="`${!mini ? 'left-padding2' : 'left-padding3'} m-0`">
                        <b-col :cols="mini ? 12 : 2" class="icons-program">  
                            <div style="
                                background: #FFFFFF 0% 0% no-repeat padding-box;
                                border-radius: 6px;
                                width:2.1em;
                                height:2.1em;
                                margin-left: -8px;
                                opacity: 1;">
                                <img 
                                class="img-program"
                                style="margin-left: -0.35em; margin-top:0.24em;"
                                width="45px"
                                src="../assets/Asana-Emblema.png" />
                            </div>
                        </b-col>
                        <b-col v-show="!mini" class="title-program">
                        <span class="mt-1 link-title" style="display:flex; justify-content:start; margin-left: 2px;">Asana</span>  
                        </b-col>
                    </b-row>
                </router-link>
            </div>
            -->
            <div class="link-container">
                <a href="https://hvrautoconsulta.cl/login" target="_blank">
                    <b-row :class="`${!mini ? 'left-padding2' : 'left-padding3'} m-0`">
                        <b-col :cols="mini ? 12 : 2" class="icons-program">  
                            <div style="
                                background: #FFFFFF 0% 0% no-repeat padding-box;
                                border-radius: 6px;
                                width:2.1em;
                                height:2.1em;
                                margin-left: -8px;
                                opacity: 1;">
                                <img 
                                class="img-program"
                                style="margin-left: 0.1em; margin-top:0.3em;"
                                width="26px"
                                src="../assets/hvr.png" />
                            </div>
                        </b-col>
                        <b-col v-show="!mini" cols="3" class="title-program" style="margin-left: 2px;">
                            <span href="https://hvrautoconsulta.cl/login">HVR</span>
                        </b-col>
                    </b-row>
                </a>
            </div>
            
            <div class="link-container">
                <router-link to="/dropbox">
                    <b-row :class="`${!mini ? 'left-padding2' : 'left-padding3'} m-0`">
                        <b-col :cols="mini ? 12 : 2" class="icons-program">
                            <div style="
                                background: #FFFFFF 0% 0% no-repeat padding-box;
                                border-radius: 6px;
                                width:2.1em;
                                height:2.1em;
                                margin-left: -8px;
                                opacity: 1;">
                                <img 
                                class="img-program"
                                style="margin-left: 0.1em; margin-top:0.3em;"
                                width="26px"
                                src="../assets/dropbox.svg" />
                            </div>
                        </b-col>
                        <b-col v-show="!mini" cols="3" class="title-program">
                            <span class="mt-1 link-title" style="display:flex; justify-content:start; margin-left: 2px;">Dropbox</span>
                        </b-col>  
                    </b-row>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Sidebar',
    props: {
        mini: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            rol: "",
            name: "",
            image: "",
            image_default: "",
            url_img: process.env.VUE_APP_URL+'/storage/',
            isMobile: false,
            newMessages: 0,
            dataUser: [ { id: 0 } ],
        }
    }, 
    watch: {
    '$route.path' () {
      if (this.$route.path === '/mensajes'){
        this.newMessages = 0
      }
    }
  },
    mounted() {
        this.rol = (localStorage.userRol);
        let data_user = JSON.parse(localStorage.userData);
        this.name = data_user[0].name;
        this.image_default = require('@/assets/picture.png');
        if (data_user[0].image !== '') {
            this.image = this.url_img + data_user[0].image;
        } 
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
        this.dataUser = JSON.parse(localStorage.userData)
        this.getMessagesUser()
    },
    beforeDestroy () {
        if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize, { passive: true })
        }
    },
    methods: {
        onResize () {
        this.isMobile = window.innerWidth <= 1200
        },
        resizeMenu() {
            this.$emit('changeMenu', true)
        },
        logout() {
            this.$router.push('/');
        },
        async getMessagesUser() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.token}` }
            };

            let url = `${process.env.VUE_APP_URL}/api/auth/get-messages/${this.dataUser[0].id}/false`;

            await axios.get(url, config).then(async (data) => {
                this.newMessages = 0
                data.data.data.forEach(element => {
                    if (element) {
                        this.newMessages = this.newMessages+ 1
                    }
                });
            }).catch(error => {
                console.log(error)
            })
        },
  }
}
</script>
<style scoped>
.sidebar {

    /* position: fixed;
    width: 150px; */
    overflow-y: hidden;
   

}

/* Tamaño del scroll */
.sidebar::-webkit-scrollbar {
  width: 8px;
}

 /* Estilos barra (thumb) de scroll */
.sidebar::-webkit-scrollbar-thumb {
  background: #B57459;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:active {
  background-color: #B57459;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

 /* Estilos track de scroll */
.sidebar::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.sidebar::-webkit-scrollbar-track:hover, 
.sidebar::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.img-program{
    margin-left: -12px;
    margin-top: 3px
}

 .link-title, a {
    text-decoration: none !important;
    font-size:16px;
    font-weight: 400;
    color: white;
    background-color: transparent;
}

a:hover, a:visited, a:link, a:active {
    text-decoration: none !important;
    color: white;
}

.icons{
    background: #45BBA9 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
    width: 27px;
    height: 27px;
}

.icons-program{
    border-radius: 6px;
    opacity: 1;
    width: 45px;
}

.title-program {
    color: white;
    font-size: 19px;
}

.title {
    color: white;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom:8px;
    font-weight: 500;
    text-align: left;
}

.logo {
    margin-top: 10px;
}

.picture {
    margin-top: 30px;
}

.picture > img {
     width: 100px;
     height: 100px;
     border-radius:60px;
}

.menu-items {
    display: flex;
    margin-top:20px;
    padding-left: 0%;
    flex-direction: column;
    align-items: baseline;
}

.link-container {
    padding: 14px 5px;
    width: 100% !important;
}

.link-container:hover{
    background-color:#7e9fb5;
}

.side-btn {
    border: none;
    padding: 16px 0px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: white;
    background-color: transparent;
}
.side-btn:focus {
    outline: none;
}
.side-btn.active {
    position: relative;
    /* background-color: white; */
    color: teal;
    font-weight: 600;
    margin-left: 10px;
    border-radius: 30px 0 0 30px;
}
.side-btn.active::before {
    top: -30px;
}
.side-btn.active::after {
    bottom: -30px;
}
.side-btn.active::before, .side-btn.active::after {
    position: absolute;
    content: "";
    right: 0;
    height: 30px;
    width: 30px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-padding {
    padding-left:2%;
}

.left-padding2 {
    padding-left:5%;
}

.left-padding3 { 
    padding-left: 31%;
}
.arrow-button {
    background-color: #f0900f !important;
    border: none !important;
    height: 30px !important;
    width: 31px !important;
}
</style>