<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
        <b-row class="mx-0">
            <b-col class="ma-0" cols="12">
                <h3 
                  style="color:black;">
                  <img src="../assets/Boletin.svg" alt="" >
                    Boletín 
                  <img id="back-arrow" src="../assets/left-arrow.svg" alt="" @click="$router.push('/inicio')">
                </h3>
        <!---       <div class="row" style="margin-top: 40px; margin-bottom: 40px; justify-content: center;">
                    <b-col md="2" style="margin-left: 10px;" class="n_active" id="internal_btn">Actuales </b-col>
                    <b-col md="2" style="margin-left: 10px;" class="n_inactive" id="external_btn">Proximos </b-col>
                    <b-col md="2" style="margin-left: 10px;" class="n_inactive" id="external_btn">Pasados </b-col>
                </div> --->
               <b-row v-if="!loading" style="max-width:100%" class="mt-5 mx-0 pa-0">
                    <b-col 
                      cols="12" 
                      md="4"
                      lg="3" 
                      class="ma-0 px-2 mb-5" 
                      v-for="project in projects" :key="project.id">
                         <b-card
                            :title=project.name
                            :img-src="url_img + project.image"
                            img-alt="Image"
                            img-top
                            tag="article"
                            class="mb-2 boletin_row mx-0"
                            style="height:100%"
                        >
                            <b-card-text class="mt-2">
                             {{truncateText(project.description)}}
                            </b-card-text>

                            <template #footer>
                              <b-button 
                                @click="$router.push(`/boletin/${project.id}`)"
                                style="background: #3379A1;">
                                Leer más
                              </b-button>
                            </template>
                        </b-card>
                     </b-col>
                </b-row>

                <!-- SKELETON LOADERS -->
              <div v-if="loading">
                <b-row style="max-width:100%;" class="mt-5 mx-0 pa-0">
                  <b-col style="" v-for="item in 5" :key="item" cols="12" md="6" lg="4" xl="3">
                    <b-card class="project_row mt-2 mb-2 mt-4 mb-2" style="min-height: 24.8em; border-radius: 12px;" no-body img-top>
                    <b-skeleton-img card-img="top" aspect="2:1"></b-skeleton-img>
                    <b-card-body class="mx-2 px-0">
                      <b-skeleton width="80%"></b-skeleton>

                      <b-skeleton class="mt-4" animation="wave" width="85%"></b-skeleton>
                      <b-skeleton animation="wave" width="55%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>

                      <b-skeleton animation="wave" class="mt-4" width="70%"></b-skeleton>
                    </b-card-body>

                    <template #footer>
                        <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center">
                          <b-skeleton size="30px" class="mx-1" type="btn"></b-skeleton>
                        </b-card-body>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'ListProjects',
  components: {

  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        projects: '',
        name: '',
        description: '',
        status: '',
        rol: '',
        photo: null,
        image: '',
        position: '',
        project_id: '',
        isMobile: false,
        loading: true,
        user:{}
      }
    },
  mounted() {
    let userData = JSON.parse(localStorage.userData)
    this.rol = (localStorage.userRol)
    this.user = userData[0]
    this.getProjects();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    onResize () {
        this.isMobile = window.innerWidth < 1200
      },

    getProjects() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let queryParam = ''

      if ( this.rol !== 'admin'){
        queryParam = this.user.factory ? `?factory=${this.user.factory }` : ''
      }

      let url = process.env.VUE_APP_URL+'/api/auth/projects'+queryParam;

      axios.get(url, config).then(data => {
          this.projects = data.data;
          this.loading = false
      })

    },
  
    deleteProject() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'DELETE');

      axios.post(process.env.VUE_APP_URL+"/api/auth/projects/"+this.project_id, formData, config)
        .then(data => {
          console.log(data);
           this.getProjects();
        });
    },
    obtenerImagen(e){
        let file = e.target.files[0];
        // console.log(file);
        this.photo = file;
        this.cargarImagen(file);
    },
    cargarImagen(file){
        let reader = new FileReader();
        reader.onload = (e) => {
            this.image = e.target.result;
        }
        reader.readAsDataURL(file);
    },
    truncateText(text, length = 230) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();
      if(content.length>length) {
        content = content.substring(0, length);
        content = content + ('(...)');
      }

      return content;
    },
  },
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.ver {
  background-color: #3379A1 !important;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
}

.white img {
    height: 220px;
    margin-bottom: 10px;
    padding:10px;
}


#back_arrow{
  /*style="margin-left: 600px; cursor: pointer;"*/
  cursor: pointer;
  width:25px;
  float:left;
  margin-top: 10px;
}

.card.mb-2.white .card-body .card-text{
  height:150px;
}

@media screen and (min-width:1300px){
  .card.mb-2.white .card-body .card-title{
    font-size: 1.2rem!important;
  }
}

@media screen and (max-width:1280px){
  .card.mb-2.white .card-body .card-text{
    height:120px;
  }
}


.boletin_row img{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 0.80 !important;
  /*object-fit: cover;
  object-position: center;*/
}

</style>
