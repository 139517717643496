<template>
    <div style="height: 90% !important;" :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
        <b-row style="height: 100% !important;">
            <b-col cols="12" lg="8" style="margin-top: 20px; background-color: white;">
                <h3 style="color: black;">
                  <img src="../assets/Boletin.svg" alt="">
                    Boletín 
                    <img id="back-arrow" src="../assets/left-arrow.svg" alt="" @click="$router.back()">
                  </h3>
                <!-- <b-col col sm="9" style="margin-top: 30px; margin-left: 20px; background-color: white;"> -->

                  <!-- SKELETON LOADER -->
                  <b-card border-variant="white" v-if="loading">
                    <b-skeleton-img></b-skeleton-img>
                    <b-skeleton animation="wave" height="40px" class="mt-4 mb-4" width="45%"></b-skeleton>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                  </b-card>
                  <b-card
                        v-else
                        img-alt="Image"
                        img-top
                        border-variant="white"
                        tag="article"
                        style=""
                        class="mb-4 project_img-fix-aspect"
                        id="detail_project"
                    >
                      <b-carousel
                          id="carousel-1"
                          v-model="slide"
                          class="project_img-fix-aspect"
                          :interval="4000"
                          controls
                          indicators
                          
                          
                          @sliding-start="onSlideStart"
                          @sliding-end="onSlideEnd"
                        >

                          <!-- Slides with image only -->
                           <b-carousel-slide v-if="image1" class="project_img-fix-aspect">
                            <template #img>
                              <img
                                :src="image1"
                                class="project_img-fix-aspect"
                                alt="image slot"
                              >
                            </template>
                          </b-carousel-slide>

                            <b-carousel-slide v-if="image2">
                            <template #img>
                              <img
                                :src="image2"
                                class="project_img-fix-aspect"
                                alt="image slot"
                              >
                            </template>
                          </b-carousel-slide>

                            <b-carousel-slide v-if="image3">
                            <template #img>
                              <img
                                :src="image3"
                                class="project_img-fix-aspect"
                                alt="image slot"
                              >
                            </template>
                          </b-carousel-slide>

                        </b-carousel>
                        <b-card-text class="mt-4">
                          <h2>{{name}}</h2>

                          <h6 id="date_info">{{formatDate(date)}}</h6>

                          <LikeBtn type="boletines" :idFather="`${this.$route.params.id}`" />

                          <div v-if="link_video_computed" style="width: 100%; display: flex; justify-content: center;" class="mb-5">
                            <div v-if="link_video_computed" v-html="link_video_computed"></div>
                          </div>
                          
                          <div v-html="description" style="font-size: 17px;"></div>
                        </b-card-text>
                    </b-card>
                <!-- </b-col> -->
                <Comments module="boletines" :idModule="$route.params.id" />
                
                <div v-if="rol === 'admin' && !loading" class="mb-5 pb-5">
                  <UsersConfirm v-if="add_confirmation === 'recibi_conforme' || add_confirmation === 'confirmo_asistencia'" 
                      :idModule="$route.params.id" :typeConfirmation="add_confirmation" />
                  <UsersLiked typeModule="boletines" :idModule="$route.params.id"/>
                </div>
                
            </b-col>
             <b-col col sm="12" lg="4" style="margin-top: 20px;">
                <b-card border-variant="white" style="min-height:800px; contain: content;  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px; " :style="`${isMobile ? 'margin-bottom: 80px !important;' : ''} height: ${isMobile ? 30 : 90}% `">
                  <div class="shadow-none mx-0 mt-2 mb-3 d-flex flex-row justify-content-between">
                    <h4 class="pt-2" style="font-weight: 500 !important; font-size: 18px !important;">
                      <img style="width: 37px; margin-top: -10px;" src="../assets/Boletin.svg" alt="Boletin"/> 
                      <span class="ml-2">Boletín</span>
                    </h4>

                    <div @click="$router.push('/listado-boletines')" style="width:30px; height: 27px; margin-top: 7px; cursor: pointer;">
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </div>
                  </div>

                  <b-card-body class="px-4">
                      <Projects :style="`height: 100% !important; min-height:600px;
                        width: 90%;
                        contain: content;
                        left: 4%;
                        overflow: hidden;
                        position: ${!isMobile ? 'absolute' : ''};`" />
                  </b-card-body>
              </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import Projects from '@/components/Dashboard/ProyectosScroll.vue';
import moment from 'moment'
import Comments from '@/components/Comments.vue';
import UsersConfirm from '../components/UsersConfirm.vue';
import UsersLiked from '../components/UsersLiked.vue';
import LikeBtn from '../components/LikeBtn.vue';

export default {
  name: 'Users',
  components: {
    Projects,
    Comments,
    UsersConfirm,
    UsersLiked,
    LikeBtn
},
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        projects: '',
        name: '',
        description: '',
        status: '',
        rol: '',
        photo: null,
        image1: '',
        image2: '',
        image3: '',
        position: '',
        project_id: '',
        date: '',
        slide: 0,
        sliding: null,
        loading: true,
        isMobile: false,
        link_video: null,
        add_confirmation: ''
      }
    },
  mounted() {
    this.rol = (localStorage.userRol)
    this.getProject(this.$route.params.id);
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  computed: {
    link_video_computed(){
      if (this.link_video) {
        let linkVideo = this.link_video.replace('watch?v=', 'embed/')
        /// https://www.youtube.com/watch?v=l37SsRthCZI
        /// https://www.youtube.com/embed/l37SsRthCZI
        return `<iframe width="560" height="315" src="${linkVideo}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
      } else {
        return null
      }
    }
  },
  methods: {
    onResize () {
        this.isMobile = window.innerWidth < 1200
      },
    onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      },
    async getProject(id) {
      this.loading = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/projects/'+id;

      await axios.get(url, config).then(data => {
        
        this.add_confirmation = data.data.add_confirmation ?? 'none';
        this.project_id = data.data.id;
        this.name = data.data.name;
        this.link_video = !data.data.link_video || data.data.link_video === '' ? null :  data.data.link_video;
        this.description = data.data.description;
        this.status = data.data.status;
        this.date = data.data.created_at;
        this.image1 = data.data.image !== null && data.data.image !== "" ? process.env.VUE_APP_URL+'/storage/'+data.data.image : null;
        this.image2 = data.data.image2 !== null && data.data.image2 !== "" ? process.env.VUE_APP_URL+'/storage/'+data.data.image2 : null;
        this.image3 = data.data.image3 !== null && data.data.image3 !== "" ? process.env.VUE_APP_URL+'/storage/'+data.data.image3 : null;

      })

      this.loading = false
    },
    formatDate(date) {
      //const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      //const formatDate = (date)=>{
        //let formatted_date = date.getDate() + " " + meses[date.getMonth()] + " " + date.getFullYear();
        //return formatted_date;
      //}
      return moment(date).format('DD/MM/YYYY');
    },
  },
}
</script>
<style>
h3 {
  color: #263A39;
  text-align: left;
  /* margin-top: 20px !important; */
}

h2 {
    font-weight: bold !important;
    color: black;
    margin-left: 0;

}

p {
    text-align: left;
}

.ver {
  background-color: #3379A1 !important;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
}


#detail_project > img {
    /* height: 270px; */
    max-height: 500px;
}

#detail_project{
  margin-top:2.5rem;
}

#back-arrow{
  /*style="margin-left: 500px; cursor: pointer;"*/
  cursor:pointer;
  width:25px;
  float:right;
  margin-top:10px;
}

#date_info{
  /*color:#47dedb;*/
  color:#55c3c0;
  margin-bottom:10px;
  font-size: 1.05rem;
}

@media screen and (max-width:1280px){
  #date_info{
    font-size: 1.1rem!important;
  }
}

.project_img-fix-aspect{
  width: 100%;
  aspect-ratio: 0.8 !important;
  /*object-fit: cover;
  object-position: center;*/
}

</style>
