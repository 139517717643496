<template>
    <div :class="{
      'px-4' : !isMobile,
      'px-2' : isMobile,
      'mt-4' : isMobile || !isMobile
    }">

    <b-row class="no-gutters">
      <!--- LEFT SIDE --->
      <b-col
        class="text-left d-flex justify-content-center"
        :class="{
          'px-4 py-3' : !isMobile,
          'px-1 py-1' : isMobile
        }"
        cols="12"
        lg="4">
        <!--- LEFT CARD ---->
        <div v-if="!loadingUser" class="card" style="width: 100%;">
          <div class="card-body d-flex flex-column justify-content-start align-items-center">
            <div class="mb-3 mt-2">
              <b-avatar variant="warning" v-if="image == '' || !image" :text="$showInitials(getUserName, getUserLastName)" size="160px"></b-avatar>
              <img v-else width="160" height="160" style="border-radius: 80px; object-fit: cover; background-color:#5f7d91;" :src="image" alt="Foto del usuario">
            </div>

            <h4 style="color:#45bba9;">
              Cambiar contraseña
            </h4>

            <form v-on:submit.prevent="updateUser('pass')" style="width: 100%;" class="mt-2">
               <b-form-group
                  id="input-group-1"
                  label="Contraseña"
                  label-for="input-1"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :state="checkStatePassword('password').value"
                    autocomplete="off"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    {{ checkStatePassword('password').message }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Repetir contraseña"
                  label-for="input-1"
                >
                  <b-form-input
                    id="password"
                    v-model="repeatPassword"
                    :state="checkStatePassword('repeatPassword').value"
                    autocomplete="off"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    {{ checkStatePassword('repeatPassword').message }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <div
                  :class="{'d-flex justify-content-end' : !isMobile}">
                  <b-button
                    type="submit"
                    :disabled="blockPassBtn"
                    :class="{
                      'block' : isMobile
                    }"
                    class="outline-btn">
                    <span v-if="loadingPass" class="spinner-border text-info spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                    {{ loadingPass ? "" : "Actualizar" }}
                  </b-button>
                </div>

            </form>
          </div>
        </div>

        <!--- SKELETON LOADER ---->
        <div v-else class="card" style="width: 100%;">
          <div class="card-body d-flex flex-column justify-content-start align-items-center">
            <div class="mb-3 mt-2">
             <b-skeleton type="avatar" size="160px"></b-skeleton>
            </div>

            <b-skeleton width="100%"></b-skeleton>

            <div style="width: 100%;" class="mt-3">
               <b-skeleton width="70%"></b-skeleton>
               <b-skeleton type="input"></b-skeleton>

               <b-skeleton width="70%" class="mt-3"></b-skeleton>
               <b-skeleton type="input"></b-skeleton>

                <div
                  class="my-3"
                  :class="{'d-flex justify-content-end' : !isMobile}">
                  <b-skeleton :width="`${isMobile ? '100%' : '40%'}`" type="button"></b-skeleton>
                </div>
            </div>  
          </div>  
        </div>
      </b-col>

      <!--- RIGHT SIDE -->

      <b-col
        class="text-left"
        :class="{
          'px-4 py-3' : !isMobile,
          'px-1 py-1' : isMobile
        }"
        cols="12"
        lg="8">

        <!-- RIGHT CARD -->
        <div v-if="!loadingUser" class="card" style="width: 100%;">
          <div class="card-body p-4">
            <form v-on:submit.prevent="updateUser('all')" id="form_">
              <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    :state="checkState('name')"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Apellido"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="lastname"
                    :state="checkState('lastname')"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El apellido debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>


                <b-form-group
                  id="input-group-1"
                  label="RUT"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="rut"
                    :state="checkState('rut')"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El RUT debe contener al menos 7 caracteres.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Fecha de nacimiento"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-mask="'##/##/####'"
                    :state="checkState('birthday')"
                    placeholder="--/--/----"
                    v-model="birthday"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    Debe ingresar una fecha de nacimiento valida
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Email"
                  label-for="input-1"
                >
                  <b-form-input
                    id="email"
                    type="text"
                    :state="checkState('email')"
                    v-model="email"
                    autocomplete="off"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    Debe ingresar un email válido.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Cargo"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="position"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Planta"
                  label-for="input-1"
                >
                  <b-form-select v-model="factory" class="form-select" 
                  :disabled="rol !== 'admin'" :state="checkState('factory')">
                    <b-form-select-option value="las_gaviotas">Planta Las Gaviotas</b-form-select-option>
                    <b-form-select-option value="san_ignacio">Planta San Ignacio</b-form-select-option>
                    <b-form-select-option value="casa_matriz_santiago">Casa Matriz Santiago</b-form-select-option>
                    <b-form-select-option value="auricop">Auricop</b-form-select-option>
                    <b-form-select-option value="minera_cobre_verde">Minera Cobre Verde</b-form-select-option>
                    <b-form-select-option value="enami">Enami</b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback id="input-1-feedback">
                    Debe seleccionar una planta
                  </b-form-invalid-feedback>
                </b-form-group>
               
                 <b-form-group
                  id="input-group-1"
                  label="Foto"
                  label-for="input-1"
                >
                 <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo"
                    @change="obtenerImagen"
                    placeholder=" "
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  </b-form-group>
                  <div :class="{ 'd-flex justify-content-end' : !isMobile, 'pt-2' : isMobile || !isMobile }">
                  <b-button
                    type="submit"
                    :disabled="blockBtn"
                    :class="{
                      'block' : isMobile
                    }"
                    class="outline-btn">
                     <span v-if="loading" class="spinner-border text-info spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                    {{ loading ? "" : "Actualizar" }}
                  </b-button>
                </div>
         </form>
          </div>
        </div>

        <!--- SKELETON LOADER ---->
        <div v-else class="card" style="width: 100%;">
          <div class="card-body d-flex flex-column justify-content-start align-items-center">
            <div style="width: 100%;" class="mt-3">
               <b-skeleton width="40%"></b-skeleton>
               <b-skeleton type="input"></b-skeleton>

               <b-skeleton width="40%" class="mt-3"></b-skeleton>
               <b-skeleton type="input"></b-skeleton>

               <b-skeleton width="40%" class="mt-3"></b-skeleton>
               <b-skeleton type="input"></b-skeleton>

               <b-skeleton width="40%" class="mt-3"></b-skeleton>
               <b-skeleton type="input"></b-skeleton>

               <b-skeleton width="40%" class="mt-3"></b-skeleton>
               <b-skeleton type="input"></b-skeleton>

               <b-skeleton width="40%" class="mt-3"></b-skeleton>
               <b-skeleton type="input"></b-skeleton>

                <div
                  class="my-3"
                  :class="{'d-flex justify-content-end' : !isMobile}">
                  <b-skeleton :width="`${isMobile ? '100%' : '30%'}`" type="button"></b-skeleton>
                </div>
            </div>  
          </div>  
        </div>
      </b-col>
    </b-row>
    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'Users',
  components: {
  
  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        users: '',
        name: '',
        lastname: '',
        birthday:'',
        email: '',
        password: '',
        repeatPassword: '',
        factory: '',
        passAux: '',
        rol: '',
        photo: null,
        image: '',
        position: '',
        user_id: '',
        rut: '',
        isMobile: false,
        loadingPass: false,
        loading: false,
        loadingUser: true,
        actualDate: ''
      }
    },
  mounted() {
    this.rol = (localStorage.userRol);
    this.actualDate = moment().format('YYYY-MM-DD')
    moment.locale('es')
    this.getUser();
    this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
    },
    beforeDestroy () {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize, { passive: true })
      }
    },
  computed: {
    blockPassBtn() {
      if (!this.password || !this.repeatPassword) {
        return true
      }
      return false
    },
    blockBtn() {
      if (
        !this.name || !this.email || !this.rut || !this.birthday || !this.lastname || !this.checkDate(this.birthday) || !this.checkIfNotUnder18(this.birthday)
      ) {
        return true
      }
      return false
    },
    getUserName() {
      let data_user = JSON.parse(localStorage.userData);
      if (Array.isArray(data_user)){
        if (data_user.length > 0){
          return data_user[0].name ?? ''
        }
      }
      return ''
    },
    getUserLastName() {
        let data_user = JSON.parse(localStorage.userData);
        if (Array.isArray(data_user)){
          if (data_user.length > 0){
            return data_user[0].lastname ?? ''
          }
        }
        return ''
    },
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 800
    },
    getUser() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/user';

      axios.get(url, config).then(data => {
        this.loadingUser = false
        localStorage.userData = JSON.stringify(data.data);
        this.user_id = data.data[0].id;
        this.name = data.data[0].name;
        this.lastname = data.data[0].lastname ? data.data[0].lastname : '';
        this.birthday = moment(data.data[0].birthday).format('DD/MM/YYYY');
        this.factory =  data.data[0].factory ?? '';
        this.email = data.data[0].email;
        this.rut = data.data[0].rut && data.data[0].rut !== 'null' ? data.data[0].rut : '';
        this.passAux = data.data[0].password;
        this.position = data.data[0].position;
        if (data.data[0].image !== ""  && data.data[0].image) {
            this.image = this.url_img + data.data[0].image;
        } 
 
      })

    },
    updateUser(type) {
      if (this.rol == 'admin' && type === 'pass'){
        if (this.factory === '' || !this.factory){
          this.factory = 'las_gaviotas'
        }
      } 
      if (this.rol == 'admin' && type !== 'pass'){
        if (this.factory === '' || !this.factory){
          return
        }
      } else {
        if (this.factory === '' || !this.factory){
          this.factory = 'las_gaviotas'
        }
      }
      if (type === 'pass') {
        this.loadingPass = true
      } else {
        this.loading = true
      }

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       const aux  = this.password.length > 0 ? this.password : this.passAux;
       formData.append('user_id', this.user_id);
       formData.append('name', this.name);
       formData.append('lastname', this.lastname);
       formData.append('birthday', moment(this.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'));
       formData.append('rut', this.rut);
       formData.append('email', this.email);
       formData.append('factory', this.factory);
       formData.append('password', aux);
       formData.append('role', this.rol);

       if (this.photo !== null) {
            formData.append('image', this.photo);
       }
      
       formData.append('position', this.position);

      axios.post(process.env.VUE_APP_URL+"/api/auth/update-user", formData, config)
        .then(() => {
          this.makeToast('success', '¡Se ha actualizado el usuario!', '¡Actualización exitosa!')
          this.loadingPass = false
          this.loading = false
          this.password = '';
          this.repeatPassword = ''
          this.getUser();
        })
        .catch((err) => {
          console.log('>> Error while trying to update the user.', err);
          this.loadingPass = false
          this.loading = false
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar actualizar el usuario.', 'Error fatal.')
        })
    },
    obtenerImagen(e){
        let file = e.target.files[0];
        // console.log(file);
        this.photo = file;
        this.cargarImagen(file);
    },
    cargarImagen(file){
        let reader = new FileReader();
        reader.onload = (e) => {
            this.image = e.target.result;
        }
        reader.readAsDataURL(file);
    },

    checkState(field) {
      let aux = true;
      switch (field) {
        case 'name':
          aux = 0 === this.name.length ? null : false
          if (this.name.length >= 3) aux  = true
        break;
        case 'lastname':
          aux = 0 === this.lastname.length ? null : false
          if (this.lastname.length >= 3) aux  = true
        break;
        case 'email': 
         aux = 0 === this.email.length ? null : false
          if (
            this.email.length > 1 &&
            this.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[a-zA-Z0-9-.]+$")
          ) aux  = true
          break;
        case 'password':
          aux = 0 === this.password.length ? null : false
          if (this.password.length >= 4) aux  = true
          break;
        case 'repeatPassword':
          aux = this.repeatPassword.length < 1 ? null : false;
          if (this.password.length > 0 && this.repeatPassword.length > 0 && this.repeatPassword === this.password) aux = true
          break;
        case 'factory':
          if (this.rol == 'admin'){
            if (this.factory === '' || !this.factory){
              aux = false
            }
          } else {
            aux = true
          }
          
          break;  
        case 'birthday':
          aux = 0 === this.birthday.length ? null : false
          if (this.birthday.length >= 10) aux  = true
          if (this.birthday.length) {
            aux = this.checkDate(this.birthday)
            aux = this.checkIfNotUnder18(this.birthday)
          }
          break;
        default:
          aux = 0 === this.rut.length ? null : false
          if (this.rut.length >= 7) aux = true
          break;
      }

      return aux;
    },

    checkStatePassword(field) {
      let aux = {
        value: true,
        message: ''
      };
      switch (field) {
        case 'password':
          aux.value = this.password === this.repeatPassword
          if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/.test(this.password)) {
            aux.value = false
            aux.message= "La contraseña debe incluir una combinación de letras mayúsculas y minúsculas, números y alguno de los siguientes caracteres ! @ # $ % ^ & *"
          }
          if (12 > this.password.length){
            aux.value = false
            aux.message = 'La contraseña debe tener al menos 12 caracteres'
          }
          if (this.password !== this.repeatPassword && (aux.message === '' || this.password.length === 0)){
            aux.value = false
            aux.message = 'Las contraseñas deben ser iguales'
          }
          if (this.password.length === 0 && this.repeatPassword.length === 0){
            aux.value = null
            aux.message = ''
          }
          break;
        case 'repeatPassword':
          aux.value = this.password === this.repeatPassword
          if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/.test(this.repeatPassword)) {
            aux.value = false
            aux.message = "La contraseña debe incluir una combinación de letras mayúsculas y minúsculas, números y alguno de los siguientes caracteres ! @ # $ % ^ & *"
          }
          if (12 > this.repeatPassword.length){
            aux.value = false
            aux.message = 'La contraseña debe tener al menos 12 caracteres'
          }
          if (this.password !== this.repeatPassword && (aux.message === '' || this.repeatPassword.length === 0)){
            aux.value = false
            aux.message = 'Las contraseñas deben ser iguales'
          }
          if (this.password.length === 0 && this.repeatPassword.length === 0){
            aux.value = null
            aux.message = ''
          }
          break;
        default:
          aux.value = null
          aux.message = ''
          break;
      }
      return aux;
    },

    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },

    checkDate (value) {
      if (value.length < 10) {
        return false
      }
      const dateFormated = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      if (dateFormated === 'Invalid date' || dateFormated === 'Fecha inválida') {
        return false
      } else {
        return true
      }
    },

    checkIfNotUnder18 (value) {
      if (value.length < 10) {
        return false
      }
      const dateFormated = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      const diffDays = moment(dateFormated).diff(this.actualDate, 'days')
      if (dateFormated === 'Invalid date' || diffDays > 0 || dateFormated === 'Fecha inválida') {
        return false
      } else {
        return true
      }
    },
  },
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.block {
  border-radius: 5px;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px !important;
  border-radius: 5px;
}


</style>
