<template>
  
<div>
    <div style="margin-top: 30px;" id="loading" v-if="loading">
      <div class="d-flex justify-content-center">
        <span style="font-weight: 500 !important;">Cargando...</span>
        <b-spinner class="ml-auto"></b-spinner>
      </div>
    </div>

    <div :class="`${!isMobile ? 'projects-scroll scroll' : 'projects-scroll-mobile scroll'}`" class="px-0 mb-5" v-else>
      <div v-for="project in projects" :key="project.id" class="mb-4">
        <div  :class="{ 'px-2' : isMobile }" class="mb-3">
        
        <p style="font-weight: bold;" @click="goToThePage(project.id, false)">{{ project.name }}</p>
        <div class="box d-flex justify-content-center">
          <div v-if="checkVideo(project.link_video)" style="width: 100%; display: flex; justify-content: center;" class="mb-5">
            <div v-html="computedVideo(project.link_video)"></div>
          </div>
          <img style="
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
            display: block;"
            class="" :src="url_img+project.image"
            @click="goToThePage(project.id, false)"
            v-else
            >
        </div>
        <div class="w-100 d-flex mt-2 mb-2">
          <b-btn 
            type="button" 
            :class="project.liked ? 'btn-heart btn-heart-liked' : 'btn-heart' "
            :disabled="project.liked"
            @click="() => { likeProject(project); project.liked = true }"
            >
            <b-icon icon="heart" style=" color:rgb(0, 0, 0);" aria-hidden="true"></b-icon> 
          </b-btn>
          <b-btn 
            type="button" 
            class="btn-comment ml-2"
            @click="goToThePage(project.id, true)"
            >
            <b-icon icon="chat-text" style=" color:rgb(0, 0, 0);" aria-hidden="true"></b-icon> 
          </b-btn>
        </div>
        <b-btn
          block
          :disabled="project.received"
          variant="success"
          v-if="project.add_confirmation"
          @click="() => { confirmProject(project); project.received = true }">
          <span v-if="project.add_confirmation === 'recibi_conforme'">
            {{ project.received ? "Recibido" : "Recibí conforme" }}
          </span>
          <span v-if="project.add_confirmation === 'confirmo_asistencia'">
            {{ project.received ? "Asistencia Confirmada" : "Confirmo asistencia" }}
          </span>
        </b-btn>

        </div>
      </div>
    </div>

    <b-modal 
    v-model="dialog"
    id="modal-center" 
    centered
    size="lg"
    hide-footer>

    <template #modal-title>
      <b style="color:#45bba9">
        Detalle de boletín
      </b>
    </template>

    <b-card 
      v-if="currentProject"
      class="ma-0 pa-0"
      
      :header="currentProject.name">
                
      <b-card-text class="pt-2" style="width:100%;" v-if="currentProject.image">
        <b-card-img
          :src="url_img + currentProject.image" 
          alt="user_pic"
          style="object-fit: contain; max-height: 60em;"
          class="img">
        </b-card-img>
      </b-card-text>

      <b-card-text style="max-height: 400px; overflow-y: scroll; font-size: 16px !important;" class="pt-1 px-2 pb-3 scroll">
        <div v-html="currentProject.description"></div>
      </b-card-text>

      </b-card>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Users',
  components: {

  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        projects: '',
        name: '',
        description: '',
        status: '',
        rol: '',
        photo: null,
        image: '',
        position: '',
        project_id: '',
        loading: true,
        isMobile: false,
        dialog: false,
        currentProject: null,
        slide: 0,
        sliding: null,
        user_id: '',
        user: {}
      }
    },
  mounted() {
    let userData = JSON.parse(localStorage.userData)
    this.rol = (localStorage.userRol)
    this.user_id = userData[0].id
    this.user = userData[0]
    this.getProjects();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  watch: {
    '$route.path' () {
      this.$router.go()
    }
  },
  methods: {
     onResize () {
      this.isMobile = window.innerWidth < 1200
    },
   
    async getProjects() {
      
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let queryParam = ''

      if ( this.rol !== 'admin'){
        queryParam = this.user.factory ? `?factory=${this.user.factory }` : ''
      }

      let url = process.env.VUE_APP_URL+'/api/auth/projects'+queryParam;

      await axios.get(url, config).then(data => {
          data.data.forEach(element => {
            element.liked = false
            element.comment = false
            element.received = false
            
          });
          this.projects = data.data
          this.loading = false;
      })

     this.getLikes(this.projects)
    },
  
    obtenerImagen(e){
        let file = e.target.files[0];
        // console.log(file);
        this.photo = file;
        this.cargarImagen(file);
    },
    cargarImagen(file){
        let reader = new FileReader();
        reader.onload = (e) => {
            this.image = e.target.result;
        }
        reader.readAsDataURL(file);
    },
    truncateText(text, length = 255) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();
      if(content.length>length) {
        content = content.substring(0, length);
        content = content + ('(...)');
      }

      return content;
    },
    showDialog(project) {
      this.currentProject = project;
      this.dialog = true
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
    goToThePage(id, comment){
      this.$router.push( `/boletin/${id}${comment ? '?comentopen=true' : ''}`)
      //location.reload()
    },
    checkVideo(link){
      if (!link || link  === ''){
        return false
      }
      if ( link.includes('/www.youtube.com/watch')) {
        return true
      } else {
        return false
      }
    },
    computedVideo(link){
      
      if (link) {
        let width = 300
        if ( window.innerWidth > 1385 ) {
          width = 220
        }
        if ( window.innerWidth > 1600 ) {
          width = 320
        }
        if ( window.innerWidth > 1838 ) {
          width = 360
        }
        if ( window.innerWidth > 2000 ) {
          width = 400
        }
        if ( window.innerWidth > 2359 ) {
          width = 500
        }
        
        let linkVideo = link.replace('watch?v=', 'embed/')
        /// https://www.youtube.com/watch?v=l37SsRthCZI
        /// https://www.youtube.com/embed/l37SsRthCZI
        return `<iframe width="${width}" height="${(width / 2).toFixed(0)}" src="${linkVideo}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
      } else {
        return null
      }
    },

    likeProject(project){
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

        const data ={
          user_id : this.user_id,
          module: 'boletines',
          module_id: project.id
        }
      axios.post(process.env.VUE_APP_URL+"/api/auth/like", data, config)
        .then(() => {
       
        })
        .catch(() => {
          
        })
    },

    async getLikes(data){
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

      let projectData = data
      let arrayLikes = []

      await axios.get(process.env.VUE_APP_URL+"/api/auth/get-all-likes",  config)
        .then((data) => {
        
          if (data?.data?.data?.likes && Array.isArray(data?.data?.data?.likes)){
            data.data.data.likes.forEach(element => {
              if (element?.module === 'boletines' && element?.like ){
                arrayLikes.push(element.module_id)
              }
            });
          }
        })
        .catch(() => {
          
        })

        projectData.forEach(element => {
          element.liked = arrayLikes.some(like => like === element.id)
        });

        this.projects = projectData

        this.getConfirmations(projectData) 
    },


    async confirmProject(project){
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

        const data ={
          user_id : this.user_id,
          confirmation: 'recibi_conforme',
          project_id: project.id
        }
      axios.post(process.env.VUE_APP_URL+"/api/auth/confirmation", data, config)
        .then(() => {
       
        })
        .catch(() => {
          
        })
    },

    async getConfirmations(data){
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

      let projectData = data
      let arrayConfirmations = []

      await axios.get(process.env.VUE_APP_URL+"/api/auth/get-all-confirmations",  config)
        .then((data) => {
          
          if (data?.data?.data?.confirmations && Array.isArray(data?.data?.data?.confirmations)){
            data.data.data.confirmations.forEach(element => {
              if (element?.confirmation === 'recibi_conforme' && element?.project_id ){
                arrayConfirmations.push(element.project_id)
              }
            });
          }
        })
        .catch(() => {
          
        })


        projectData.forEach(element => {
          element.received = arrayConfirmations.some(confirmation => confirmation === element.id)
        });

        this.projects = projectData
    },

  },
}
</script>

<style scoped>
.card > .position-relative > img {
    height: 180px !important;
}

.projects-scroll {
  max-height: 90% !important;
  overflow-y: auto;
  scrollbar-color: #B57459 transparent !important;
}
.projects-scroll-mobile {
  max-height: 600px !important;
  overflow-y: auto;
  scrollbar-color: #B57459 transparent !important;
}

.project_row img{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.4 !important;
  border-radius: 5px;
  /*object-fit: cover;
  object-position: center;*/
}

.card_title{
  white-space: pre-line !important;
  color: white;
  font-size: 19px !important;
  font-weight: 600 !important;
}

.card-body .card-text{
  margin-top:12px;
  font-size:12px!important;
}

@media screen and (max-width: 1280px){
  .card-body .card-text{
    font-size:10px!important;
  }
}
.box{
    /* width: 250px;  */   
    width: 100%;    
} 


.btn-heart{
  border: none !important;
  background-color: transparent !important;
  color: #e31b23 !important;
  font-size: 14px !important;
  padding: 3px 3px !important;
  max-height: 28px;
  transition: 0.3s ease-in;
}
.btn-heart:hover  {
  color: #FFFFFF !important;
  background-color: #e31b23 !important;
}

.btn-heart svg{
  width:24px;
  height:24px;
  fill: #e31b23
}

.btn-heart:hover svg {
  fill: #FFFFFF  !important;
}

.btn-heart-liked {
  color: #FFFFFF !important;
  background-color: #e31b23 !important;
}

.btn-heart-liked svg {
  fill: #FFFFFF  !important;
}

.btn-comment{
  border: none !important;
  background-color: transparent !important;
  color: #f0900f !important;
  font-size: 14px !important;
  padding: 3px 3px !important;
  max-height: 28px;
  transition: 0.3s ease-in;
}
.btn-comment:hover  {
  color: #FFFFFF !important;
  background-color: #f0900f !important;
}

.btn-comment svg{
  width:24px;
  height:24px;
  fill: #f0900f;
  margin-top: -2px;
}

.btn-comment:hover svg {
  fill: #FFFFFF  !important;
}

.btn-comment-comented {
  color: #FFFFFF !important;
  background-color: #e31b23 !important;
}

.btn-comment-comented svg {
  fill: #FFFFFF  !important;
}


</style>
