<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
         <b-row id="users" class="m-0 p-0" style="margin-bottom: 100px;">
          <b-col xs="12" sm="12" class="m-0 p-0">
              <b-row align-v="center" class="mb-5 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="12">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">
                    <img src="../assets/surveys.svg" alt="">
                    Respuestas de la encuesta: <span  v-if="!loading" >{{ surveyData.question }}</span>  
                    <b-spinner v-if="loading" class="ml-auto"></b-spinner>
                  </h3>
                </b-col>

               
              </b-row>

              <!--- Tabla de usuarios --->

              <b-table
              head-variant="dark"
              class="mb-0"
              :per-page="perPage"
              id="my-table"
              :current-page="currentPage"
              :fields="[
                {
                  key: 'name',
                  label: 'Nombre',
                  thClass: 'table-dark'
                },
                {
                  key: 'lastname',
                  label: 'Apellido',
                  thClass: 'table-dark'
                },
                {
                  key: 'email',
                  label: 'Email',
                  thClass: 'table-dark'
                },
                {
                  key: 'plant',
                  label: 'Planta',
                  thClass: 'table-dark'
                },
                {
                  key: 'responses',
                  label: 'Respuestas',
                  thClass: 'table-dark'
                },
              ]"
              :stacked="isMobile"
              striped
              th-class="table-dark"
              :items="loading ? [] : users"
              v-if="!loading"
              >

           
              <template #cell(plant)="data">
                {{ getPlant(data.item.factory) }}
              </template>

              <template #cell(responses)="data">
                {{ showResponses(data.item) }}
              </template>

              <template #top-row v-if="users.length === 0">
                <td>
                  {{ `No hay respuestas de esta encuesta aun` }}
                </td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </template>
 
              </b-table>

              <b-pagination
                v-model="currentPage"
                v-if="users.length > 0 && !loading"
                dark
                align="right"
                :total-rows="users.length"
                :per-page="perPage"
                class="mt-3"
                aria-controls="my-table"
              >
                <template #page="{ page, active }">
                  <b v-if="active">{{ page }}</b>
                  <i v-else>{{ page }}</i>
                </template>
              </b-pagination>

              <!-- Skeleton loader --->

              <b-skeleton-table
              v-if="loading"
              :stacked="isMobile"
              :rows="7"
              :columns="7"
              :table-props="{ bordered: true, striped: true }"
            >
            </b-skeleton-table>
          </b-col>
          
          <b-col xs="12" md="12" v-if="!loadingResponses">
            <loading-overlay v-if="loadingResponses"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Usuarios que respondieron la encuesta y los que no</h4>
            <div style="min-width:100%;width:100%;margin-bottom:20px">
              <bar-chart :chartdata="barDataUsersResponses" :options="chartOptions" :key="keyBarDataUsersResponses" />
            </div>
            <doughnut-chart :chartdata="doughnutDataUsersResponses" :key="keyDoughnutDataUsersResponses" :options="chartOptions2"/>
          </b-col>

          <b-col xs="12" md="12" v-if="!loadingResponses">
            <loading-overlay v-if="loadingResponsesData"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Respuestas de la encuesta</h4>
            <div style="min-width:100%;width:100%;margin-bottom:20px">
              <bar-chart :chartdata="barDataResponses" :options="chartOptions" :key="keyBarDataResponses" />
            </div>
            <doughnut-chart :chartdata="doughnutDataResponses" :key="keyDoughnutDataResponses" :options="chartOptions2"/>
          </b-col>

          <b-col xs="12" md="12" v-if="loadingResponses">
            <loading-overlay v-if="loadingResponses"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Usuarios que respondieron la encuesta y los que no</h4>
            <div style="height: 400px; width: 100%;">

            </div>
          </b-col>

          <b-col xs="12" md="12" v-if="loadingResponses">
            <loading-overlay v-if="loadingResponsesData"/>
            <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Respuestas de la encuesta</h4>
            <div style="height: 400px; width: 100%;">
            </div>
          </b-col>
        </b-row>
    </div>
</template>

<script>
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import BarChart from './../components/BarChart.vue'
import DoughnutChart from './../components/DoughnutChart.vue'
import LoadingOverlay from './../components/LoadingOverlay.vue'

export default {
  name: 'SurveyInfo',
  components:{
    BarChart,
    DoughnutChart,
    LoadingOverlay
  },
  data() {
      return {
        typeConfirmation: '',
        idModule: '',
        url_img: process.env.VUE_APP_URL+'/storage/',
        users: [],
        allUsers: [],
        loading: true,
        isMobile: false,
        surveyData: {
          question:'',
          answers_options: []
        },
        perPage: 8,
        currentPage: 1,
        chartOptions:{
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index',
            intersect: false
          },
          scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
          }
        },
        chartOptions2:{
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index',
            intersect: false
          }
        },
        coloursChart:["#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411","#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411","#3366cc","#dc3912","#ff9900","#109618","#990099","#0099c6","#dd4477","#66aa00","#b82e2e","#316395","#3366cc","#994499","#22aa99","#aaaa11","#6633cc","#e67300","#8b0707","#651067","#329262","#5574a6","#3b3eac","#b77322","#16d620","#b91383","#f4359e","#9c5935","#a9c413","#2a778d","#668d1c","#bea413","#0c5922","#743411"],
        loadingResponses: true,
        loadingResponsesData: true,
        keyBarDataUsersResponses: 1,
        barDataUsersResponses: {
          labels: [ 'Respuestas de la encuesta' ],
          datasets: [
              {
                label: 'Usuarios que respondieron la encuesta',
                backgroundColor: "#3366cc",
                data: [ 0 ],
              },
              {
                label: 'Usuarios que no respondieron la encuesta',
                backgroundColor: "#dc3912",
                data: [ 0 ],
              },
          ],
        },
        keyDoughnutDataUsersResponses: 1,
        doughnutDataUsersResponses: {
          labels: [ 'Respondieron la encuesta', 'No respondieron la encuesta' ],
          datasets: [
              {
                label: 'Usuarios que respondieron la encuesta y los que no',
                data: [ 0, 0 ],
                backgroundColor: [ "#3366cc", "#dc3912" ],
              },
          ],
        },
        keyBarDataResponses: 1,
        barDataResponses: {
          labels: [ 'Respuestas de la encuesta' ],
          datasets: [
              /*{
                label: 'Respuestas de la encuesta',
                backgroundColor: '#f0900f',
                data: [],
              },*/
          ],
        },
        keyDoughnutDataResponses: 1,
        doughnutDataResponses: {
          labels: [],
          datasets: [
              {
                label: 'Respuestas de la encuesta',
                data: [],
                backgroundColor: [],
              },
          ],
        },
      }
    },
  mounted() {
    this.getUsersAnswers();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  watch: {
    searchName() {
      this.searchTimeOut()
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    async getUsersAnswers() {
      this.loading = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/get-all-answers/'+this.$route.params.id;

      let url2 = process.env.VUE_APP_URL+'/api/auth/survey/'+this.$route.params.id;

      let routeid = Number(this.$route.params.id)

      await axios.get(url, config).then(data => {
        
        data.data.data.answers.forEach(element => {
          element.answers = element.answers.filter(function(el) {
            return el.survey_id ==  routeid;
          });
        });
        this.users = data.data.data.answers
      })

      await axios.get(url2, config).then(data => {
        this.surveyData = data?.data
      })

      this.loading = false
      await this.getUsers()
      this.getDataForStadistics()
      this.getDataForStadisticsResponses()
    },

    async getDataForStadistics () {
      let responses = 0
      let noResponses = 0
      this.allUsers.forEach(element => {
        let did = this.users.some(user => user.id === element.id)
        if (did){
          ++responses
        } else {
          ++noResponses
        }
      });
      this.barDataUsersResponses.datasets[0].data[0] = responses
      this.barDataUsersResponses.datasets[1].data[0] = noResponses
      this.doughnutDataUsersResponses.datasets[0].data[0] = responses
      this.doughnutDataUsersResponses.datasets[0].data[1] = noResponses
      this.keyBarDataUsersResponses = ++this.keyBarDataUsersResponses
      this.keyDoughnutDataUsersResponses = ++this.keyDoughnutDataUsersResponses
      this.loadingResponses = false
    },

    async getUsers() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/all-users`;

      await axios.get(url, config).then(data => {
        this.allUsers = data.data
      })
    },

    async getDataForStadisticsResponses() {
      this.surveyData.answers_options.forEach((element,index) => {
        this.barDataResponses.datasets.push(
          {
            label: element.answer_option,
            backgroundColor: this.coloursChart[index],
            data: [0],
          }
        )
        this.doughnutDataResponses.labels.push(element.answer_option)
        this.doughnutDataResponses.datasets[0].backgroundColor.push(this.coloursChart[index])
        this.doughnutDataResponses.datasets[0].data.push(0)
      });

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/get-statistics/`+this.$route.params.id;

      let stadistics = []

      await axios.get(url, config).then(data => {
        stadistics = data.data.data.answers
      })
      stadistics.forEach(element => {
        let indexBar = this.barDataResponses.datasets.map(function(e) { return e.label; }).indexOf(element.answer_option.answer_option);
        let indexDoughnut = this.doughnutDataResponses.labels.map(function(e) { return e; }).indexOf(element.answer_option.answer_option);
        this.barDataResponses.datasets[indexBar].data = [ element.total ]
        this.doughnutDataResponses.datasets[0].data[indexDoughnut] = element.total
        console.log(indexBar,indexDoughnut)
      });
      

      this.keyBarDataResponses = ++this.keyBarDataResponses
      this.keyDoughnutDataResponses = ++this.keyDoughnutDataResponses
      this.loadingResponsesData = false

      console.log(stadistics)
    },
    

    getPlant (value) {
      if (value === 'las_gaviotas'){
        return 'Planta Las Gaviotas'
      }
      if (value === 'san_ignacio'){
        return 'Planta San Ignacio'
      }
      if (value === 'casa_matriz_santiago'){
        return 'Casa Matriz Santiago'
      }
      if (value === 'auricop'){
        return 'Auricop'
      }
      if (value === 'minera_cobre_verde'){
        return 'Minera Cobre Verde'
      }
      if (value === 'enami'){
        return 'Enami'
      }
      return 'No tiene planta asignada'
    },

    showResponses (user) {
      let responses = []
        user.answers.forEach(element => {
          responses.push(element.answer_option.answer_option)
      });
      return responses.join(', ')
    }
  },
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.ver {
  background-color: #3379A1 !important;
  margin: 0px !important;
}

.rounded-btn {
    border-radius: 20px !important;
    padding: 3px 5px !important;
    height: 1.9em !important;
    border: 0 !important;
}

.table-dark {
  background: #263A39!important;
}

#__BVID__39 > thead:nth-child(1), #__BVID__154 > thead:nth-child(1) {
  background: #263A39!important;
}
th {
  border: 0 !important;
}

tr.odd {
  background: #F4F6F8#f8aa8949 !important;
}

.page-item.active .page-link {
  background-color: #263A39!important;
  border-color: #263A39!important;
  color: #fff !important;
}

.page-link {
  color: #263A39!important;
}
</style>
