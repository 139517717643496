<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
         <b-row id="users">
          <b-col xs="12" sm="12">
              <b-row align-v="center" class="mb-5 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">
                    Noticias
                  </h3>
                </b-col>

                <b-col 
                  :style="`display: flex; ${isMobile ? 'justify-content: center; margin-top: 10px;' : 'justify-content: end;'}`" 
                  xs="12" sm="12" md="6">
                  <b-button 
                    type="button" 
                    class="btn ver crear"
                    @click="showModalCreate()" 
                    :style="`color: #FFFFFF; ${!isMobile ? 'width: 160px;' : 'width: 100%;'}`">
                    Crear Noticia
                  </b-button>
                </b-col>
              </b-row>
              
              
              <!--- Tabla de noticias --->

              <!-- <b-table
                head-variant="dark"
                class="mb-0"
                :per-page="perPage"
                id="my-table"
                :current-page="currentPage"
                :fields="[
                  {
                    key: 'name',
                    label: 'Nombre',
                    thClass: 'table-dark'
                  },
                  {
                    key: 'description',
                    label: 'Descripción',
                    thClass: 'table-dark'
                  },
                  {
                    key: 'created_at',
                    label: 'Fecha',
                    thClass: 'table-dark'
                  },
                  {
                    key: 'link',
                    label: 'Link',
                    thClass: 'table-dark'
                  },
                  {
                    key: 'actions',
                    label: 'Acciones',
                    thClass: 'table-dark'
                  }
                ]"
                :stacked="isMobile"
                striped
                th-class="table-dark"
                :items="notices"
              >

                <template #cell(description)="data">
                  {{ truncateText(data.item.description) }}
                </template>

                <template #cell(created_at)="data">
                  {{ getFormattedDate(data.item.created_at) }}
                </template>

                <template #cell(link)="data">
                  {{ data.item.link && data.item.link !== 'null' ? truncateText(data.item.link, 30) : 'No disponible.' }}
                </template>

                <template style="display:flex !important;" #cell(actions)="data">
                  <div style="display:flex !important;">
                    <b-button 
                      type="button" 
                      class="rounded-btn"
                      style="color: #FFFFFF; background: #f0900f no-repeat padding-box;"
                      @click="showModalEdit(data.item.id)">
                      <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                    </b-button>

                    <b-button 
                      type="button" 
                      class="rounded-btn ml-3"
                      style="background: #dc3545 no-repeat;"
                      @click="showModalDelete(data.item.id)">
                      <b-icon scale="0.8" icon="trash-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
              </b-table> -->

              <!-- <b-pagination
                v-model="currentPage"
                v-if="notices.length > 0"
                dark
                align="right"
                :total-rows="notices.length"
                :per-page="perPage"
                class="mt-3"
                aria-controls="my-table"
              >
                <template #page="{ page, active }">
                  <b v-if="active">{{ page }}</b>
                  <i v-else>{{ page }}</i>
                </template>
              </b-pagination> -->

              <!-- Skeleton loader --->

             <!--  <b-skeleton-table
              v-if="loading"
              :stacked="isMobile"
              :rows="5"
              :columns="5"
              :table-props="{ bordered: true, striped: true }"
            >
            </b-skeleton-table> -->
          </b-col>
        </b-row>

            <!-- CARDS -->
    <div v-if="!loading" class="mt-0" >
      <b-row style="margin: 0; padding-bottom: 10px;">
        <b-col v-for="(notice, index) in notices" :key="index" cols="12" md="6" lg="4" xl="3" class="mb-5">
          <b-card class=" mt-2 mb-2 mt-4 mb-2" style="border-radius: 12px;height:100%" no-body img-top>
            <a :href="'/noticia/internal/'+notice.id">
              <b-img style="border-radius: 12px 12px 0px 0px !important; cursor:pointer"
               :src="url_img + notice.image" class="notice_row">
              </b-img>
            </a>
          <b-card-body class="px-3 px-0 text-left">
            <div style="font-size: 15px !important; font-weight: 600;" class="mb-3 text-ellipsis-3-line">
              {{ notice.name }} {{ notice.name }}
            </div>

              <p class="mb-0 text-ellipsis-3-line" style="font-size: 14px;">
                <strong>
                  Descripcion:
                </strong> 
                {{
                  truncateText(notice.description)
                }}
              </p>
              </b-card-body>
              <b-card-body style="font-size: 14px;" class="px-3 text-left mt-0" >
                <strong>Creado el:</strong> {{ getFormattedDate(notice.created_at) }}
                <br>
                <strong>Estado:</strong> {{ notice.status === 'active' ? 'Activo' : 'Inactivo' }}
                <br>
                <span class="text-ellipsis-2-line">
                  <strong>Link:</strong> {{ notice.link && notice.link !== 'null' && notice.link.length > 0 ? notice.link : 'No disponible.' }}
                </span>
          </b-card-body>

          <template #footer>
              <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center align-items-center">
                    <b-button class="" size="sm" :href="'/noticia/internal/'+notice.id" style="background: #3379A1">Leer más</b-button>
                    <b-button 
                      type="button" 
                      class="rounded-btn ml-2"
                      style="color: #FFFFFF; background: #f0900f no-repeat padding-box;"
                      @click="showModalEdit(notice.id)">
                      <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                    </b-button>

                    <b-button 
                      type="button" 
                      class="rounded-btn ml-2"
                      style="background: #dc3545 no-repeat;"
                      @click="showModalDelete(notice.id)">
                      <b-icon scale="0.8" icon="trash-fill"></b-icon>
                  </b-button>
              </b-card-body>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- SKELETON LOADERS -->
    <div v-else>
      <b-row style="margin: 0; padding-bottom: 10px;">
        <b-col style="" v-for="item in 5" :key="item" cols="12" md="6" lg="4" xl="3">
          <b-card class="project_row mt-2 mb-2 mt-4 mb-2" style="min-height: 34em; border-radius: 12px;" no-body img-top>
          <b-skeleton-img card-img="top" aspect="2:1"></b-skeleton-img>
          <b-card-body class="mx-2 px-0">
            <b-skeleton width="80%"></b-skeleton>

            <b-skeleton class="mt-4" animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>

            <b-skeleton animation="wave" class="mt-4" width="70%"></b-skeleton>
          </b-card-body>

          <template #footer>
              <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center">
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
              </b-card-body>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div v-if="!loading && notices.length < 1">
      <h5 style="font-weight: 400 !important; opacity: .35;">
        No se encontraron noticias para mostrar.
      </h5>
    </div>

         <!-- Modal crear noticia -->
          <b-modal 
            ref="modal-1" 
            size="lg" 
            centered
            hide-footer
          >

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Crear Noticia
              </b>
            </template>

            <b-row class="mt-4 justify-content-center">
               <b-col cols="10">
              <form v-on:submit.prevent="createNotice" id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    :state="checkState('name')"
                    :max="255"
                    required
                  ></b-form-input>


                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Descripción"
                  label-for="input-1"
                >
                  <vue-editor v-model="description"></vue-editor>
                  <span class="text-danger" style="font-size:12.8px" v-if="showDescriptionAlert">
                    La descripción debe contener al menos 10 caracteres.
                  </span>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Link"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="link"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Estado"
                  label-for="input-1"
                >
                <b-form-select v-model="status" class="form-select">
                  <b-form-select-option value="active">Activo</b-form-select-option>
                  <b-form-select-option value="inactive">Inactivo</b-form-select-option>
                </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 1"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo1"
                    name="photo1"
                    :browse-text="'Seleccionar'"
                    @change="obtenerImagen"
                    placeholder=" "
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure  v-if="image1" class="mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="notice_row" :src="image1" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 2"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo2"
                    name="photo2"
                    :browse-text="'Seleccionar'"
                    @change="obtenerImagen"
                    placeholder=" "
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure  v-if="image2" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="notice_row"  :src="image2" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 3"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo3"
                    name="photo3"
                    :browse-text="'Seleccionar'"
                    @change="obtenerImagen"
                    placeholder=" "
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure  v-if="image3" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="notice_row" :src="image3" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
              </form>
              </b-col>
             </b-row>

              <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
                <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                  <b-btn
                    block
                    variant="outline-danger"
                    @click="closeModal">
                    Cancelar
                  </b-btn>
                </b-col>
                <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                  <b-btn
                    block
                    :disabled="disableBtn || showDescriptionAlert"
                    class="outline-btn"
                    @click="createNotice">
                    <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                    {{ loadingBtn ? "" : "Crear" }}
                  </b-btn>
                </b-col>
              </b-row>
          </b-modal>
        <!-- Modal editar noticia -->
          <b-modal 
            ref="modal-2" 
            size="lg"
            hide-footer
            centered>

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Editar Noticia
              </b>
            </template>

            <b-row class="mt-4 justify-content-center">
               <b-col cols="10">
              <form v-on:submit.prevent="createNotice" id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    :state="checkState('name')"
                    :max="255"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre debe contener al menos 3 letras y menos de 255
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Descripción"
                  label-for="input-1"
                >
                  <vue-editor v-model="description"></vue-editor>
                  <span class="text-danger" style="font-size:12.8px" v-if="showDescriptionAlert">
                    La descripción debe contener al menos 10 caracteres.
                  </span>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Link"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="link"
                    required
                ></b-form-input>
                </b-form-group>
                 <b-form-group
                  id="input-group-1"
                  label="Estado"
                  label-for="input-1"
                >
                <b-form-select v-model="status" class="form-select">
                  <b-form-select-option value="active">Activo</b-form-select-option>
                  <b-form-select-option value="inactive">Inactivo</b-form-select-option>
                </b-form-select>
                </b-form-group>
                 <b-form-group
                  id="input-group-1"
                  label="Foto 1"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo1"
                    name="photo1"
                    :browse-text="'Seleccionar'"
                    @change="obtenerImagen"
                    placeholder=" "
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure  v-if="image1 !== null" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="notice_row"  :src="image1" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 2"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo2"
                    name="photo2"
                    :browse-text="'Seleccionar'"
                    @change="obtenerImagen"
                    placeholder=" "
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure  v-if="image2 !== null" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="notice_row"  :src="image2" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 3"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo3"
                    name="photo3"
                    :browse-text="'Seleccionar'"
                    @change="obtenerImagen"
                    placeholder=" "
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure  v-if="image3 !== null" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="notice_row"  :src="image3" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
              </form>
              </b-col>
             </b-row>

            <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal2">
                  Cancelar
                </b-btn>
              </b-col>
              <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                <b-btn
                  block
                  :disabled="disableBtn || showDescriptionAlert"
                  class="outline-btn"
                  @click="updateNotice">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Editar" }}
                </b-btn>
              </b-col>
            </b-row>
          </b-modal>

        <!-- Modal elminar noticia -->
         <b-modal 
          ref="modal-3" 
          centered
          hide-footer>
          <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Confirmación
              </b>
            </template>

          <p class="mt-3" style="text-align: center;">
            ¿Está seguro de que desea eliminar esta noticia?
          </p>

           <b-row class="ma-0 mt-4 px-5 pb-3 justify-content-center">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal3">
                  Mejor no
                </b-btn>
              </b-col>

              <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  class="outline-btn"
                  @click="deleteNotice">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Sí" }}
                </b-btn>
              </b-col>
          </b-row>
        </b-modal>

    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { VueEditor } from "vue2-editor";

export default {
  name: 'Notices',
  components: {
    VueEditor
  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        notices: [],
        name: '',
        description: '',
        showDescriptionAlert:false,
        status: '',
        link: '',
        photo1: null,
        photo2: null,
        photo3: null,
        image1: '',
        image2: '',
        image3: '',
        notice_id: '',
        loading: true,
        isMobile: false,
        perPage: 8,
        currentPage: 1,
        loadingBtn: false
      }
    },
  mounted() {
    this.getNotices();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  watch:{
    description(){
      let plainDesc = this.description.replace(/<[^>]*>/g, '');
      if (plainDesc.length < 10){
        this.showDescriptionAlert = true;
      }
      else {
        this.showDescriptionAlert = false;
      }
    }
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  computed: {
    disableBtn() {
        if(
          !this.name || !this.description ||
          !this.status || (!this.photo1 && !this.image1)
        ) {
          return true
        }

        return false
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    showModalCreate() {
        this.name = '';
        this.description = '';
        this.status = '';
        this.link = '';
        this.image1 = '';
        this.image2 = '';
        this.image3 = '';
        this.$refs['modal-1'].show();
        this.showDescriptionAlert = false;
      },
    showModalEdit(id) {
      const news = this.notices.find((e) => { return e.id === id })
        if(news) {
          this.notice_id = news.id;
          this.name = news.name;
          this.description = news.description;
          this.link = news.link && news.link !== 'null' ? news.link : '';
          this.status = news.status;
          this.image1 = news.image !== null && news.image !== "" ? process.env.VUE_APP_URL+'/storage/'+news.image : null;
          this.image2 = news.image2 !== null && news.image2 !== "" ? process.env.VUE_APP_URL+'/storage/'+news.image2 : null;
          this.image3 = news.image3 !== null && news.image3 !== "" ? process.env.VUE_APP_URL+'/storage/'+news.image3 : null;
          this.showDescriptionAlert = false;
      }

      this.$refs['modal-2'].show();
    },
    showModalDelete(id) {
        this.$refs['modal-3'].show();
        this.notice_id = id;
      },
    createNotice() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

       let formData = new FormData();
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('image1', this.photo1);
       formData.append('image2', this.photo2);
       formData.append('image3', this.photo3);
       formData.append('status', this.status);
       formData.append('link', this.link);
       this.loadingBtn = true

      axios.post(process.env.VUE_APP_URL+"/api/auth/notices", formData, config)
        .then(() => {
           this.loadingBtn = false;
           this.closeModal()
           this.getNotices();
           this.makeToast('success', '¡La noticia ha sido creada satisfactoriamente!', '¡Noticia creada!')
        })
        .catch((err) => {
          this.loadingBtn = false;
          this.closeModal()
          console.log('>> Error while trying to create a new.', err);
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar crear la noticia.', 'Error fatal.')
        })
    },
    getNotices() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/notices';

      axios.get(url, config).then(data => {
        this.notices = data.data;
        this.loading = false
      })

    },
    updateNotice() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'PUT');
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('image1', this.photo1);
       formData.append('image2', this.photo2);
       formData.append('image3', this.photo3);
       formData.append('status', this.status);
       formData.append('link', this.link);
       this.loadingBtn = true


      axios.post(process.env.VUE_APP_URL+"/api/auth/notices/"+this.notice_id, formData, config)
        .then(() => {
          this.loadingBtn = false;
          this.closeModal2()
          this.getNotices();
          this.makeToast('success', '¡La noticia ha sido actualizada satisfactoriamente!', '¡Noticia actualizada!')
        })
        .catch((err) => {
          console.log('>> Error while trying to update a notice.', err);
          this.loadingBtn = false;
          this.closeModal2()
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar actualizar la noticia.', 'Error fatal.')
        })
    },
    deleteNotice() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'DELETE');
       this.loadingBtn = true;

      axios.post(process.env.VUE_APP_URL+"/api/auth/notices/"+this.notice_id, formData, config)
        .then(() => {
            this.loadingBtn = false
            this.closeModal3()
            this.getNotices();
            this.makeToast('success', '¡La noticia ha sido eliminada satisfactoriamente!', '¡Noticia eliminada!')
        })
        .catch((err) => {
          this.loadingBtn = false;
          console.log('>> Error while trying to delete a new.', err);
          this.closeModal3()
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar eliminar la noticia.', 'Error fatal.')
        })
    },
    closeModal3() {
      this.$refs['modal-3'].hide();
      this.notice_id = '';
    },
    obtenerImagen(e){
        let file = e.target.files[0];
        let name = e.target.name;

        this.cargarImagen(file, name.slice(-1));
    },
    cargarImagen(file, position){
        let reader = new FileReader();

        reader.onload = (e) => {
            this['image'+position] = e.target.result;
        }
        reader.readAsDataURL(file);
    },
    truncateText(text, length) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();
      const l = length || 70
      if(content.length>l) {
          content = content.substring(0, l);
          content = content + ('(...)');
      }
            
      return content;
    },

    getFormattedDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a')
    },

    closeModal() {
      this.$refs['modal-1'].hide()
      this.name = '';
      this.description = '';
      this.status = '';
      this.image = '';
      this.link = '';
    },

    closeModal2() {
      this.$refs['modal-2'].hide()
      this.name = '';
      this.description = '';
      this.status = '';
      this.image = '';
      this.link = '';
    },

    checkState(field) {
      let aux = true;
      switch (field) {
        case 'name':
          aux = 0 === this.name.length ? null : false
          if (this.name.length >= 3 && this.name.length < 255) aux  = true
          break;
        default:
          aux = 0 === this.description.length ? null : false
          if (this.description.length >= 10) aux = true
          break;
      }

      return aux;
    },

    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    }
  },
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.ver {
  background-color: #3379A1 !important;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
}


.v-dialog {
    overflow-y: auto;
    scrollbar-color: #B57459 transparent !important;
}

/* Tamaño del scroll */
.v-dialog::-webkit-scrollbar {
  width: 8px;
}

 /* Estilos barra (thumb) de scroll */
.v-dialog::-webkit-scrollbar-thumb {
  background: #B57459 !important;
  border-radius: 4px;
}

.v-dialog::-webkit-scrollbar-thumb:active {
  background-color: #e69b7b;
}

.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px #e8b098;
}

 /* Estilos track de scroll */
.v-dialog::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.v-dialog::-webkit-scrollbar-track:hover,
.v-dialog::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.text-ellipsis-3-line {  
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-ellipsis-2-line {  
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notice_row {
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.9 !important;
  /*object-fit: cover;
  object-position: center;*/
}



</style>
