<template>
<div>
  <b-card
      tag="article"
      border-variant="white"
      style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px;"
      class="mb-2"
      id="reconocimientos"
  >
    <div class="shadow-none mx-0 mt-2 mb-3">
      <h4 class="pt-2" style="font-weight: 500 !important; font-size: 18px !important;">
        <img style="width: 37px; margin-top: -8px;" src="../../assets/conoce.png" alt="Boletin"/> 
        <span class="ml-2">Conoce a</span>
      </h4>
    </div>

    <b-card-body style="margin-top: 30px;" id="loading" v-if="loading">
      <div class="d-flex justify-content-center">
        <strong>Cargando...</strong>
        <b-spinner class="ml-auto"></b-spinner>
      </div>
    </b-card-body>

    <b-card-body class="scroll reconocimientos-scroll p-0" v-else>
      <div
      style="cursor: pointer;"
      @click="goToThePage(reconocimiento.id)"
      v-for="(reconocimiento, index) in reconocimientos"
      :class="{'mb-3' : (index + 1) < reconocimientos.length}"
      :key="reconocimiento.id">
      <b-row class="m-0 p-0">
        <b-col class="pl-0" cols="2" md="3" lg="3" xl="2">
          <img height="40px" width="40px" style="object-fit: cover; border-radius: 20px;" :src="url_img + reconocimiento.picture" />
        </b-col>

        <b-col class="d-flex flex-column pl-3 m-0" cols="10" md="9" lg="8" xl="10">
            <h6 class="m-0 p-0" style="font-size: 13px;">{{ reconocimiento.name  }}</h6>
            <p style="font-size: 13px;" class="m-0 p-0">
              {{ getPlainAndTruncateText(reconocimiento.description,40) }}
            </p>
        </b-col>
      </b-row>
      </div>

      <div v-if="!loading && reconocimientos.length < 1">
      <h4 style="font-weight: 400 !important; opacity: .35;">
        No se encontraron reconocimientos para mostrar.
      </h4>
    </div>
    </b-card-body>
  </b-card>

  <b-modal 
    v-model="dialog"
    id="modal-center" 
    centered
    size="lg"
    hide-footer>

    <template #modal-title>
      <b style="color:#45bba9">
        Detalle de reconocimiento
      </b>
    </template>

    <b-card 
      v-if="user"
      class="ma-0 pa-0"
      :header="user.name">
                
      <b-card-text class="pt-2" style="height:28em;" v-if="user.picture">
        <b-card-img
          :src="url_img + user.picture" 
          alt="user_pic"
          style="height:28em;object-fit: cover;"
          class="img">
        </b-card-img>
      </b-card-text>

      <div v-else style="background-color:#f0efeb; width:28.7em; margin:0; padding:0;">
        <img  src="../../assets/picture.png" class="img mx-auto" style="display:flex; justify-content:center;" alt="picture">
      </div>
            
        <article style="max-height: 400px; overflow-y: scroll; font-size: 15px;" class="pt-1 px-2 pb-3 scroll">
          <div v-html="user.description"></div>
        </article>
      </b-card>
    </b-modal>
</div>
</template>

<script>

import axios from 'axios';
import moment from 'moment'
import { orderBy } from 'lodash'

export default {
  name: 'Reconocimientos',
  components: {

  },
  data() {
    return {
      url_img: process.env.VUE_APP_URL+'/storage/',
      projects: [],
      reconocimientos: [],
      users: [],
      image_user: '',
      original_projects: [],
      search: null,
      rol: null,
      mosaic: true,
      name: '',
      description: '',
      status: '',
      photo: null,
      dialog: false,
      image: '',
      position: '',
      reconocimiento_id: '',
      text: '',
      link: '',
      user: null,
      loading: {
        crearReconocimiento: false,
        deleteReconocimiento: false,
        editarRecurso: false,
      }
    }
  },
  mounted() {
    this.rol = (localStorage.userRol);
    this.getReconocimientos();
    console.log()
  },
  watch: {
    '$route.path' () {
      this.$router.go()
    }
  },
  methods: {
    showList() {
      this.mosaic = false;
    },
    showMosaic() {
      this.mosaic = true;
    },
   
    obtenerImagen(e){
      let file = e.target.files[0];
      // console.log(file);
      this.photo = file;
      this.cargarImagen(file);
    },
    getFormattedDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a')
    },
    closeModal(modal) {
      this.$refs[modal].hide()
      this.reset()
    },
    getReconocimientos() {

      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/reconocimientos';

      axios.get(url, config).then(data => {
        this.reconocimientos = orderBy(
              data.data,
              [
                e => {
                  return moment(e.created_at).unix();
                },
              ],
              ['desc']
            );
        // this.original_projects = data.data;
        this.loading = false;
      })

    },
    getPlainAndTruncateText(text, length = 255) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '');
      let content = plainText.trim();

      if(content.length>length) {
        content = content.substring(0, length);
        content = content + ('(...)');
      }

      return content;
    },

    showDetailDialog(user) {
      this.user = user
      this.dialog = true
    },

    goToThePage(id){
      this.$router.push('/reconocimiento/'+id)
    }
    
  },
}
</script>


<style scoped>
.card > .position-relative > img {
    height: 150px !important;
}

.reconocimientos-scroll {
    max-height: 220px;/*450px;*/
    overflow-y: auto;
}

.recognitions_scroll {
  max-height: 100% !important;
  min-height: 220px !important;
}

/* Tamaño del scroll */
.scroll::-webkit-scrollbar {
  width: 8px;
}

 /* Estilos barra (thumb) de scroll */
.scroll::-webkit-scrollbar-thumb {
  background: #B57459;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #B57459;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

 /* Estilos track de scroll */
.scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-track:hover,
.scroll::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}


</style>
