<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
        <b-row style="height: 100% !important;">
            <b-col cols="12" lg="8"  style="margin-top: 20px;">
                <h3 style="color:black;" class="mb-0 pb-0"><img src="../assets/noticia.svg" alt="" class="mr-2">Noticias <img @click="$router.back()" id="back-arrow" src="../assets/left-arrow.svg" alt=""></h3>
                <!-- <b-col col sm="9" style="margin-top: 30px; margin-left: 20px; background-color: white;"> -->
                  <!-- SKELETON LOADER -->
                  <b-card border-variant="white" v-if="loading">
                    <b-skeleton-img></b-skeleton-img>
                    <b-skeleton animation="wave" height="40px" class="mt-4 mb-4" width="45%"></b-skeleton>
                    <b-skeleton animation="wave" width="85%"></b-skeleton>
                    <b-skeleton animation="wave" width="55%"></b-skeleton>
                    <b-skeleton animation="wave" width="70%"></b-skeleton>
                  </b-card>

                     <b-card
                        v-else
                        img-alt="Image"
                        img-top
                        tag="article"
                        border-variant="white"
                        style=""
                        class="mb-4 notice_row px-0 pt-0 mt-1"
                        id="detail_notice"
                    >
                        <b-carousel
                          id="carousel-1"
                          v-model="slide"
                          :interval="4000"
                          controls
                          indicators
                          
                          img-width="100%"
                          img-height="300"
                          
                          @sliding-start="onSlideStart"
                          @sliding-end="onSlideEnd"
                        >

                          <!-- Slides with image only -->
                          <b-carousel-slide v-if="image1 !== null" :img-src="image1"></b-carousel-slide>

                          <b-carousel-slide v-if="image2 !== null" :img-src="image2"></b-carousel-slide>

                          <b-carousel-slide v-if="image3 !== null" :img-src="image3"></b-carousel-slide>

                        </b-carousel>
                        <b-card-text class="px-0 mx-0">

                          <h2 class="mb-4 mt-0">{{name}}</h2>
                          <p><a :href="link" v-if="link !== ''" target="_blank" style="font-size: 17px;"> <b-button class='outline-btn'>{{ $route.params.type === 'external' ? 'Ir al sitio de la noticia' : 'Ver link adjunto' }}</b-button></a></p>
                          <LikeBtn v-if=" $route.params.type !== 'external'" type="noticias" :idFather="`${this.$route.params.id}`" />
                          <p id="content_notice" class="links" v-html="description"></p>

                        </b-card-text>
                    </b-card>
                <!-- </b-col> -->
                <div v-if="!loading && $route.params.type == 'internal'">
                  <Comments module="noticias" :idModule="$route.params.id" />
                </div>
              <div v-if="rol === 'admin' && !loading && $route.params.type == 'internal'" class="mb-5 pb-5">
                <UsersLiked typeModule="noticias" :idModule="$route.params.id"/>
              </div>
            </b-col>
            <b-col col sm="12" lg="4" style="margin-top: 20px;">
                 <Noticias :dashVr="false"  />
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from 'axios';
import Noticias from '@/components/Dashboard/Noticias.vue';
import Comments from '@/components/Comments.vue';
import UsersLiked from '../components/UsersLiked.vue';
import LikeBtn from '../components/LikeBtn.vue';

export default {
  name: 'DetailsNotice',
  components: {
    Noticias,
    Comments,
    UsersLiked,
    LikeBtn
  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        notices: '',
        notices_external: '',
        name: '',
        description: '',
        status: '',
        link: '',
        photo: null,
        image1: '',
        image2: '',
        image3: '',
        notice_id: '',
        categories_external: [],
        images_external: [],
        slide: 0,
        sliding: null,
        isMobile: false,
        loading: true,
        rol: ''
      }
    },
  mounted() {
      this.rol = (localStorage.userRol)
      if (this.$route.params.type == 'internal') {
        this.getNoticeInternal(this.$route.params.id);
      } else if (this.$route.params.type == 'external') {
        this.getNoticeExternal(this.$route.params.id);
      }
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
      onResize () {
        this.isMobile = window.innerWidth < 1200
      },
      onSlideStart(slide) {
        console.log(slide);
        this.sliding = true
      },
      onSlideEnd(slide) {
        console.log(slide);
        this.sliding = false
      },
      async getNoticeInternal(id) {
        this.loading = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/notices/'+id;

      await axios.get(url, config).then(data => {
        this.project_id = data.data.id;
        
        this.name = data.data.name;
        this.description = data.data.description;
        this.status = data.data.status;
        this.link = data.data.link && data.data.link !== 'null' ? data.data.link : ''
        this.date = data.data.created_at;
        this.image1 = data.data.image !== null && data.data.image !== "" ? process.env.VUE_APP_URL+'/storage/'+data.data.image : null;
        this.image2 = data.data.image2 !== null && data.data.image2 !== "" ? process.env.VUE_APP_URL+'/storage/'+data.data.image2 : null;
        this.image3 = data.data.image3 !== null && data.data.image3 !== "" ? process.env.VUE_APP_URL+'/storage/'+data.data.image3 : null;
        
      })

      this.loading = false
    },
    async getNoticeExternal(id) {
      this.loading = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = 'https://www.redimin.cl/wp-json/wp/v2/posts/'+id;

      await axios.get(url, config).then(data => {
        
        this.name =  data.data.title.rendered.replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
        // this.description = this.htmlDecode(data.data.content.rendered);
        this.description = data.data.content.rendered;
        this.link = data.data.link;
        this.image = this.getImageNoticeExternalFromId(data.data.featured_media)
      })

      this.loading = false
    },
    htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
},
    showNotice(type_notice) {
      if(type_notice == 'internal') {

        document.getElementById("external_btn").classList.remove("n_active");
        document.getElementById("external_btn").classList.add("n_inactive");
        document.getElementById("internal_btn").classList.remove("n_inactive");
        document.getElementById("internal_btn").classList.add("n_active");
        document.getElementById("notices_externals").classList.remove("show");
        document.getElementById("notices_externals").classList.add("hide");
        document.getElementById("notices_internals").classList.remove("hide");
        document.getElementById("notices_internals").classList.add("show");


      } else if(type_notice == 'external') {

        document.getElementById("external_btn").classList.remove("n_inactive");
        document.getElementById("external_btn").classList.add("n_active");
        document.getElementById("internal_btn").classList.remove("n_active");
        document.getElementById("internal_btn").classList.add("n_inactive");
        document.getElementById("notices_externals").classList.remove("hide");
        document.getElementById("notices_externals").classList.add("show");
        document.getElementById("notices_internals").classList.remove("show");
        document.getElementById("notices_internals").classList.add("hide");


      }
    },
    showModalCreate() {
        this.name = '';
        this.description = '';
        this.status = '';
        this.link = '';
        this.image = '';
        this.$refs['modal-1'].show();
      },
    showModalEdit(id) {
      for (let i = 0; i < this.notices.length; i++) {
        if(this.notices[i].id == id) {
            this.notice_id = this.notices[i].id;
            this.name = this.notices[i].name;
            this.description = this.notices[i].description;
            this.link = this.notices[i].link;
            this.status = this.notices[i].status;
            this.image = process.env.VUE_APP_URL+'/storage/'+this.notices[i].image;
        }
      }

      this.$refs['modal-2'].show();
    },
    showModalDelete(id) {
        this.$refs['modal-3'].show();
        this.notice_id = id;
      },
    createNotice() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

       let formData = new FormData();
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('image', this.photo);
       formData.append('status', this.status);
       formData.append('link', this.link);

      axios.post(process.env.VUE_APP_URL+"/api/auth/notices", formData, config)
        .then(data => {
           console.log(data);
            this.name = '';
            this.description = '';
            this.status = '';
            this.image = '';
            this.link = '';

           this.getNoticesInternal();
        });
    },
    getNoticesInternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/notices';

      axios.get(url, config).then(data => {
        console.log(data.data);
          this.notices = data.data;
        console.log('this.notices',this.notices);
      })

    },
    getCategoriesExternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = 'https://redimin.cl/wp-json/wp/v2/categories';

      axios.get(url, config).then(data => {
        for (let i = 0; i < data.data.length; i++) {
          this.categories_external[i] =  data.data[i].id;
        }

        this.getNoticesExternal();
      })

    },
    getImageNoticeExternal(id) {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = ' https://www.redimin.cl/wp-json/wp/v2/media/'+id;


      axios.get(url, config).then(data => {
        this.images_external.push(data.data);

      })

    },
    getImageNoticeExternalFromId(id) {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = ' https://www.redimin.cl/wp-json/wp/v2/media/'+id;


      axios.get(url, config).then(data => {
          this.image = data.data.guid.rendered;

      })

    },
    setImageNoticeExternal(id) {
      for (let i = 0; i < this.images_external.length; i++) {
        if (this.images_external[i].id == id) {
          return this.images_external[i].guid.rendered;
        }

      }
    },
    getNoticesExternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = 'https://redimin.cl/wp-json/wp/v2/posts?categories='+this.categories_external.toString();
      // let images = [];

      axios.get(url, config).then(data => {
        this.notices_external = data.data;

        console.log('notices_external', this.notices_external);
        for (let i = 0; i < this.notices_external.length; i++) {
           this.getImageNoticeExternal(this.notices_external[i].featured_media)
        }

      })



    },
    getTitleNoticeExternal(id) {
        let title = '';

        for (let i = 0; i < this.notices_external.length; i++) {
          if(this.notices_external[i].id == id) {
             title = this.notices_external[i].title.rendered;
          }
        }

        return title;

    },
     getDescriptionNoticeExternal() {
        // let notice = '';

        // for (let i = 0; i < this.notices_external.length; i++) {
        //   if(this.notices_external[i].id == id) {
        //      notice = this.notices_external[i].content.rendered;
        //   }
        // }

        // // console.log('notice image', notice);
        // // let position_ini = notice.search("<img");
        // // let position_fin = notice.search("/>") ;
        // // let result = notice.substring(position_ini, parseInt(position_fin) + 2);

        // return result;

    },
    updateNotice() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'PUT');
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('image', this.photo);
       formData.append('status', this.status);
       formData.append('link', this.link);


      axios.post(process.env.VUE_APP_URL+"/api/auth/notices/"+this.notice_id, formData, config)
        .then(data => {
           console.log(data);
            this.name = '';
            this.description = '';
            this.status = '';
            this.image = '';
            this.link = '';

           this.getNoticesInternal();
        });
    },
    deleteNotice() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'DELETE');

      axios.post(process.env.VUE_APP_URL+"/api/auth/notices/"+this.notice_id, formData, config)
        .then(data => {
          console.log(data);
           this.getNoticesInternal();
        });
    },
    obtenerImagen(e){
        let file = e.target.files[0];
        // console.log(file);
        this.photo = file;
        this.cargarImagen(file);
    },
    cargarImagen(file){
        let reader = new FileReader();
        reader.onload = (e) => {
            this.image = e.target.result;
        }
        reader.readAsDataURL(file);
    },
  },
}
</script>

<style>
h4 {
    text-align: left;
}

.n_active {
    text-align: center;
    color: #47B29E;
    border: 1px solid #47B29E;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
    font-size: 16px;
    height: 29px;
}

.card-body h6{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.card-body .card-text{
    text-align: left;
    font-size: 10px;
}

.n_inactive {
    text-align: center;
    letter-spacing: 0px;
    color: #B0B0B2;
    opacity: 1;
    background: #F4F6F8 0% 0% no-repeat padding-box;
    border-radius: 0px;
    opacity: 1;
    cursor: pointer;
    font-size: 16px;
    height: 29px;
}

.card-img {
    padding-top: 15px;
}

.img-notice {
    max-height: 95px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

#detail_notice  > img {
    /* height: 300px; */
    width: 100%;
    max-height: 500px;
}


#content_notice {
  font-size: 17px;
}

.wp-block-image > img{
  max-width: 100%;
}

#detail_notice{
  /*style="margin-left: 500px; cursor: pointer;"*/
  margin-top:2.5rem;
}

.notice_row img{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.9 !important;
  /*object-fit: cover;
  object-position: center;*/
}
.links a {
  color: #45bba9 !important;
}
</style>
