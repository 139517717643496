<template>
  <div id="app" style="min-height: 100% !important;">
    <router-view/>
  </div>
</template>

<script>
export default {
  watch:{
    '$route.fullPath'(){
      this.sendDataHotjarAndGoogle()
    }
  },
  mounted() {
    this.sendDataHotjarAndGoogle()
  },
  methods: {
    sendDataHotjarAndGoogle(){
      if (this.$hj) {
      this.$hj('stateChange', this.$route.fullPath)
    }
    this.$gtag.pageview(this.$route.fullPath)
    }
  },
}
</script>

<style>
#app {
  font-family: 'Rubik', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

body {
  font-family: 'Rubik', sans-serif !important;
}

html,body { height: 100%; margin: 0px; padding: 0px; }


#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.ql-align-right{
  text-align: right !important;
}

.ql-align-left{
  text-align: left !important;
}

.ql-align-center{
  text-align: center !important;
}
</style>
