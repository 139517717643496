<template>
  <div>
    <b-card border-variant="white"
      style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px; padding: 0 !important;"  class="disableCalendar">
      <b-card-body style="padding: 0 0 30px 0px !important;">
        <div class='demo-app'>
          <div class='demo-app-main'>
            <FullCalendar ref="fullCalendar" class='demo-app-calendar' :options='calendarOptions'>

            </FullCalendar>
          </div>
        </div>
      </b-card-body>
    </b-card>

    <div class="ableEvents">
      <JustEvents/>
    </div>

    <!-- Modal editar evento -->
    <b-modal ref="modal-3" size="lg" :title="title" @ok="updateEvent" hide-footer>
      <b-container>
        <b-row style="margin-bottom: 10px;">
          <b-col col md="12">
            <form v-on:submit.prevent="updateEvent" id="form_">
              <b-form-group id="input-group-1" label="Nombre" label-for="input-1">
                <b-form-input id="input-1" type="text" v-model="name" required></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-1" label="Descripción" label-for="input-1">
                <b-form-textarea id="textarea" v-model="description" rows="3" max-rows="6" required
                  maxlength="250"></b-form-textarea>
              </b-form-group>
              <b-form-group label="Inicio">
                <date-picker v-model="date_start" format="HH:mm" :show-second="false" type="time"
                  value-type="format"></date-picker>
                  <div class="invalid-feedback" style="display: block;" v-if="!checkState('date_start').value">{{ checkState('date_start').message }}</div>
              </b-form-group>
              <b-form-group label="Fin">
                <date-picker v-model="date_end" format="HH:mm" :show-second="false" type="time"
                  value-type="format"></date-picker>
                  <div class="invalid-feedback" style="display: block;" v-if="!checkState('date_end').value">{{ checkState('date_end').message }}</div>
              </b-form-group>
              <b-form-group id="input-group-1" label="Repetir" label-for="input-1">
                <b-form-select v-model="repeat" :options="repeat_options" class="form-select" @change="repeatEvent()">
                </b-form-select>
              </b-form-group>
              <b-row v-if="options_repeat">
                <b-col col sm="4">
                  <b-form-group label="Repetir hasta">
                    <b-form-datepicker 
                      id="datepicker-valid" 
                      size="sm"
                      label-help="Utilice las teclas de cursor para navegar por las fechas del calendario"
                      :min="minDatePicker" 
                      label-no-date-selected="Seleccione una fecha"
                      label-current-month="Mes en curso"
                      label-next-decade="Próxima década"
                      label-next-month="Próximo mes"
                      label-next-year="Próximo año"
                      label-prev-decade="Década anterior"
                      label-prev-month="Mes anterior"
                      label-prev-year="Año anterior"
                      v-model="date_repeat_end">
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-row>

              <div style="display: flex; justify-content: center;">
                <b-button type="submit" class="btn ver crear" style="color: #FFFFFF; width: 240px;">Actualizar
                  Evento</b-button>
              </div>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Modal crear evento -->
    <b-modal ref="modal-1" size="lg" :title="title" @ok="createEvent" hide-footer>
      <b-container>
        <b-row style="margin-bottom: 10px;">
          <b-col col md="12">
            <form v-on:submit.prevent="createEvent" id="form_">
              <b-form-group id="input-group-1" label="Nombre" label-for="input-1">
                <b-form-input id="input-1" type="text" v-model="name" required></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-1" label="Descripción" label-for="input-1">
                <b-form-textarea id="textarea" v-model="description" rows="3" max-rows="6" required
                  maxlength="250"></b-form-textarea>
              </b-form-group>
              <b-form-group label="Inicio">
                <date-picker v-model="date_start" format="HH:mm" :show-second="false" type="time" value-type="format" required></date-picker>
                <div class="invalid-feedback" style="display: block;" v-if="!checkState('date_start').value">{{ checkState('date_start').message }}</div>
              </b-form-group>
              <b-form-group label="Fin">
                <date-picker v-model="date_end" format="HH:mm" :show-second="false" type="time" value-type="format" required></date-picker>
                <div class="invalid-feedback" style="display: block;" v-if="!checkState('date_end').value">{{ checkState('date_end').message }}</div>
              </b-form-group>
              <b-form-group id="input-group-1" label="Repetir" label-for="input-1">
                <b-form-select v-model="repeat" :options="repeat_options" class="form-select" @change="repeatEvent()">
                </b-form-select>
              </b-form-group>
              <b-row v-if="options_repeat">
                <b-col col sm="4">
                  <b-form-group label="Repetir hasta">
                    <b-form-datepicker 
                      id="datepicker-valid" 
                      size="sm"
                      label-help="Utilice las teclas de cursor para navegar por las fechas del calendario"
                      :min="minDatePicker" 
                      label-no-date-selected="Seleccione una fecha"
                      label-current-month="Mes en curso"
                      label-next-decade="Próxima década"
                      label-next-month="Próximo mes"
                      label-next-year="Próximo año"
                      label-prev-decade="Década anterior"
                      label-prev-month="Mes anterior"
                      label-prev-year="Año anterior"
                      v-model="date_repeat_end">
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-row>

              <div style="display: flex; justify-content: center;">
                <b-button type="submit" class="btn ver crear" style="color: #FFFFFF; width: 240px;">Crear
                  Evento</b-button>
              </div>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>


    <!-- Modal ver evento -->
    <b-modal ref="modal-2" size="md" :title="title" hide-footer>

      <b-container class="container-items-events">
        
        <b-row>
          <b-col col cols="12">
            <p class="my-4"><strong>{{ auxData.title }}</strong></p>
            <p v-if="auxData.extendedProps"><strong>Descripción: </strong>
              {{ auxData.extendedProps.description }}
            </p>
          </b-col>
          <b-col col>
            <b-button size="sm" v-if="rol == 'admin'" block variant="success" @click="showModalEdit(auxData.publicId)">
              Editar
            </b-button>
          </b-col>
          <b-col col>
            <b-button size="sm" v-if="rol == 'admin'" block style="height: 100%;" variant="danger"
              @click="deleteEvent(auxData.publicId)">
              Eliminar
            </b-button>
          </b-col>
        </b-row>
      </b-container>

    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import esLocale from "@fullcalendar/core/locales/es"
import rrulePlugin from '@fullcalendar/rrule'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import JustEvents from './JustEvents.vue'; 

export default {

  components: {
    FullCalendar, // make the <FullCalendar> tag available
    DatePicker,
    JustEvents
  },

  data: function () {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          interactionPlugin,
          timeGridPlugin,
          rrulePlugin
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth'
        },
        // eventDataTransform: function( json ) {
        //   // alert('aki');
        //   // console.log('akiii', json);
        //     // return JSON.parse(json);
        //     return JSON.parse(json);
        // },
        initialView: 'dayGridMonth',
        locale: esLocale,
        editable: false,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        events: [],
      },
      id_selected: '',
      title: '',
      name: '',
      date: '',
      date_start: '',
      date_end: '',
      date_repeat_end: '',
      repeat: 'no-repeat',
      options_repeat: false,
      description: '',
      currentEvents: [],
      eventBackgroundColor: '#5f7d91',
      eventGuid: 0,
      date_selected: '',
      rol: '',
      minDatePicker:'',
      auxData: [],
      days_selected: [],
      month_selected: [],
      repeat_options: [
        { text: 'No Repetir', value: 'no-repeat' },
        { text: 'Diario', value: 'daily' },
        { text: 'Semanal', value: 'weekly' },
        { text: 'Mensual', value: 'monthly' },
        { text: 'Anual', value: 'yearly' },
      ],
      day_options: [
        { text: 'Lunes', value: 'mo' },
        { text: 'Martes', value: 'tu' },
        { text: 'Miercoles', value: 'we' },
        { text: 'Jueves', value: 'th' },
        { text: 'Viernes', value: 'fr' },
        { text: 'Sábado', value: 'sa' },
        { text: 'Domingo', value: 'su' }
      ],
      month_options: [
        { text: 'Enero', value: '1' },
        { text: 'Febrero', value: '2' },
        { text: 'Marzo', value: '3' },
        { text: 'Abril', value: '4' },
        { text: 'Mayo', value: '5' },
        { text: 'Junio', value: '6' },
        { text: 'Julio', value: '7' },
        { text: 'Agosto', value: '8' },
        { text: 'Septiembre', value: '9' },
        { text: 'Octubre', value: '10' },
        { text: 'Noviembre', value: '11' },
        { text: 'Diciembre', value: '12' }
      ],
    }
  },
  mounted() {
    this.rol = (localStorage.userRol);
    this.getEvents();
  },
  watch: {

    date() {
      if (this.date){
        this.minDatePicker = moment(this.date).add(1,'day').format('YYYY-MM-DD')
      }
    }
  },
  methods: {

    optionButtons() {
      return (
        <div >
          <button>
            editar
          </button>
          <button>
            eliminar
          </button>
        </div>
      )
    },

    createEventId() {
      this.eventGuid++;
    },
    /*handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },*/
    handleDateSelect(selectInfo) {

      if (this.rol == 'admin') {
        let date_array = moment(selectInfo.startStr).format("DD-MM-YYYY");

        this.title = `Crear Nuevo Evento el ${date_array}`;
        this.description = '';
        this.date = moment(selectInfo.startStr).format("YYYY-MM-DD HH:mm:ss");
        this.name = ''
        this.repeat = 'no-repeat'
        this.options_repeat = false;
        this.date_start = ''
        this.date_end  = ''
        this.date_end_repeat = ''
        this.date_start_repeat = '';
        this.$refs['modal-1'].show('slow');
        // this.calendarOptions2.initialDate = moment(selectInfo.startStr).format("YYYY-MM-DD"); 
      }
    },
    createEvent() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };
      if (!this.checkState('date_start').value || !this.checkState('date_end').value){
        return
      }
      if (this.date_start == '' || this.date_end == '' || !this.date_end || !this.date_start) {
        this.makeToast('error', 'Debe seleccionar un horario para el evento.', 'Error fatal')
      } 
      else if (this.options_repeat && (this.date_repeat_end == '' || !this.date_repeat_end)) {
        this.makeToast('error', 'Debe seleccionar una fecha final a repetir para el evento.', 'Error fatal')
      } 
      else {

        let formData = new FormData();
        formData.append('name', this.name);
        formData.append('description', this.description);
        formData.append('date', this.date);
        formData.append('date_start', moment(this.date).format('YYYY-MM-DD') + ' ' + moment(this.date_start, 'HH:mm').format('HH:mm:ss'));
        formData.append('date_end', moment(this.date).format('YYYY-MM-DD') + ' ' + moment(this.date_end, 'HH:mm').format('HH:mm:ss'));

        if (this.options_repeat) {
          formData.append('date_start_repeat', moment(this.date).format('YYYY-MM-DD') + ' ' + moment(this.date_start, 'HH:mm').format('hh:mm:ss A'));
          formData.append('date_end_repeat', moment(this.date_repeat_end).format('YYYY-MM-DD'));
          formData.append('frecuency', this.repeat);
          formData.append('repeat', this.month_selected);
          formData.append('days', moment(this.date).format('dd'));
        }

        axios.post(process.env.VUE_APP_URL + "/api/auth/events", formData, config)
          .then(() => {
            this.name = '';
            this.description = '';
            this.date = '';
            this.date_start = '';
            this.date_end = '';
            this.repeat = '';
            this.days = '';
            this.frecuency = '';
            this.date_end_repeat = '';
            this.date_start_repeat = '';

            this.makeToast('success', '¡Evento creado correctamente!', '¡Evento creado!')
            this.$refs['modal-1'].hide();
            this.getEvents();

          });
      }


    },
    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },
    updateEvent() {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };
      if (!this.checkState('date_start').value || !this.checkState('date_end').value){
        return
      }
      if (this.date_start == '' || this.date_end == '' || !this.date_end || !this.date_start) {
        this.makeToast('error', 'Debe seleccionar un horario para el evento.', 'Error fatal')
      } 
      else if (this.options_repeat && (this.date_repeat_end == '' || !this.date_repeat_end)) {
        this.makeToast('error', 'Debe seleccionar una fecha final a repetir para el evento.', 'Error fatal')
      }  else {

        let formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', this.name);
        formData.append('description', this.description);
        formData.append('date', moment(this.date).format('YYYY-MM-DD'));
        formData.append('date_start', moment(this.date).format('YYYY-MM-DD') + ' ' + moment(this.date_start, 'HH:mm').format('HH:mm:ss'));
        formData.append('date_end', moment(this.date).format('YYYY-MM-DD') + ' ' + moment(this.date_end, 'HH:mm').format('HH:mm:ss'));

        if (this.options_repeat) {
          formData.append('date_start_repeat', moment(this.date).format('YYYY-MM-DD') + ' ' + moment(this.date_start, 'HH:mm').format('HH:mm:ss'));
          formData.append('date_end_repeat', moment(this.date_repeat_end).format('YYYY-MM-DD'));
          formData.append('frecuency', this.repeat);
          formData.append('repeat', this.month_selected);
          formData.append('days', moment(this.date).format('dd'));
        }

        axios.post(process.env.VUE_APP_URL + "/api/auth/events/" + this.id_selected, formData, config)
          .then(data => {
            console.log(data)
            this.name = '';
            this.description = '';
            this.date = '';
            this.date_start = '';
            this.date_end = '';
            this.repeat = '';
            this.days = '';
            this.frecuency = '';
            this.date_end_repeat = '';
            this.date_start_repeat = '';

            this.makeToast('success', '¡Evento actualizado correctamente!', '¡Evento actualizado!')
            this.$refs['modal-3'].hide();
            this.name = '';
            this.$refs['modal-2'].hide();


          }).finally(() => {
            this.getEvents();
          });
      }
    },
    getEvents() {

      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL + '/api/auth/events';

      axios.get(url, config).then(data => {
        /*const findByDate = data.data.filter(i => i.start.includes(this.date))
        if (findByDate.length == 0) {
          this.$refs['modal-2'].hide();
        }
        this.auxData = findByDate*/
        let events = data.data
        for (let i = 0; i < events.length; i++) {
          if (events[i].rrule){
            events[i].rrule =`${events[i].rrule.slice(0,17)}${moment(events[i].start).format('HHmmss')}${events[i].rrule.slice(24,events[i].rrule.length)}`
            let lengthRule = events[i].rrule.length
            let rrule = events[i].rrule
            let untilDate = `${rrule.slice(lengthRule - 8,lengthRule - 4)}-${rrule.slice(lengthRule - 4,lengthRule - 2)}-${rrule.slice(lengthRule - 2,lengthRule)}`
            let ultilDateFixed = moment(untilDate, 'YYYY-MM-DD').add(1, 'day').format('YYYYMMDD')
            events[i].rrule = `${events[i].rrule.slice(0,events[i].rrule.length - 8)}${ultilDateFixed}`
          }
          
        }
        this.calendarOptions.events = events;
        localStorage.events = JSON.stringify(this.calendarOptions.events);

        /*setTimeout(() => {
              let calendarApi = this.$refs.fullCalendar.getApi()
            console.log(calendarApi.getEvents())
        }, 2000);*/


      })

    },
    handleEventClick(clickInfo) {
      let date = moment(clickInfo.event.start).format('YYYY-MM-DD');
      this.date = date
   
      this.auxData = clickInfo.event._def
      this.title = `Evento ${date}`
      this.$refs['modal-2'].show();


      //buscar modal de fechas
      const modalFechas = document.getElementsByClassName('fc-popover')
      
      if(modalFechas.length == 1){
        modalFechas[0].remove()
      }

    },
    showModalEdit(id) {
      this.id_selected = id
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL + '/api/auth/events/' + id;

      axios.get(url, config).then(data => {
        let hour_start = moment(data.data.date_start).format('HH:mm');
        let hour_end = moment(data.data.date_end).format('HH:mm');
        this.date_start = hour_start;
        this.date_end = hour_end;
        this.date = data.data.date;
        this.name = data.data.name
        this.description = data.data.description

        if (data.data.date_start_repeat != null) {
          this.options_repeat = true;
          this.repeat = data.data.frecuency;
          this.date_repeat_end = data.data.date_end_repeat;
        } else {
          this.options_repeat = false;
          this.repeat = 'no-repeat'
        }


      }).finally(() => {
        this.$refs['modal-3'].show();
      })



    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    deleteEvent(id) {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
        };

        let formData = new FormData();
        formData.append('_method', 'DELETE');
        axios.post(process.env.VUE_APP_URL + "/api/auth/events/" + id, formData, config)
          .then(data => {
            console.log(data)
            this.getEvents();
            this.makeToast('success', '¡Evento eliminado correctamente!', '¡Evento eliminado!')
            this.$refs['modal-2'].hide();
          });
    
    },
    repeatEvent() {
      if (this.repeat == 'no-repeat') {
        this.options_repeat = false;
      } else {
        this.options_repeat = true;
      }

    },
    checkState(field) {
      let aux = {
        value: true,
        message: ''
      };
      switch (field) {
        case 'date_start':
          if (this.date_start !== '' && this.date_end !== '' && this.date_start && this.date_end &&
          moment(this.date_start, 'HH:mm').format('HH:mm:ss') !== 'Invalid date' && moment(this.date_end, 'HH:mm').format('HH:mm:ss') !== 'Invalid date'){
            if(
              Number(moment(this.date_start, 'HH:mm').format('HHmmss')) > Number(moment(this.date_end, 'HH:mm').format('HHmmss')) 
            ) {
              aux.value = false
              aux.message = 'La hora de inicio no puede ser mayor a la final'
            }
            if(
              Number(moment(this.date_start, 'HH:mm').format('HHmmss')) == Number(moment(this.date_end, 'HH:mm').format('HHmmss')) 
            ) {
              aux.value = false
              aux.message = 'La hora de inicio no puede ser igual a la final'
            }
          }
          break;
        case 'date_end':
          if (this.date_start !== '' && this.date_end !== '' && this.date_start && this.date_end &&
            moment(this.date_start, 'HH:mm').format('HH:mm:ss') !== 'Invalid date' && moment(this.date_end, 'HH:mm').format('HH:mm:ss') !== 'Invalid date'){
              if(
                Number(moment(this.date_start, 'HH:mm').format('HHmmss')) > Number(moment(this.date_end, 'HH:mm').format('HHmmss')) 
              ) {
                aux.value = false
                aux.message = 'La hora del fin no puede ser menor a la de inicio'
              }
              if(
                Number(moment(this.date_start, 'HH:mm').format('HHmmss')) == Number(moment(this.date_end, 'HH:mm').format('HHmmss')) 
              ) {
                aux.value = false
                aux.message = 'La hora del fin no puede ser igual a la de inicio'
              }
            }
          break;
        default:
          aux.value = null
          aux.message = ''
          break;
      }
      return aux;
    },

  }
}
</script>

<style>

 .fc .fc-toolbar.fc-header-toolbar, .fc .fc-toolbar-title {
  background-color: #5f7d91 !important;
  color: white !important;
  font-weight: 500 !important;
  font-size: 19px !important;
  padding: 1em 0 !important;
  border-radius: 0px !important;
  margin: 0 !important;
}


.fc-toolbar-chunk:nth-child(3){
visibility: hidden;
}
.fc-today-button{
  visibility: hidden;
}
.fc-prev-button {
  margin-right: 80%!important;
}

.fc-button-group {
  display: flex;
  margin-left: 2%;
  margin-top: 0px;
  position: absolute !important;
  flex-wrap: wrap;
  width: 96% !important;
  justify-content: space-between !important;
}
.container-items-events{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container-items-events > .row{
  display: flex;
  gap: 12em;
}
.container-items-events > .row .col{
  padding: 0;
}

.fc-more-link:hover {
  color: #f0900f !important;
}

.fc-daygrid-day-number {
  color: #000;
}

.fc-col-header-cell-cushion {
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.4);
}

.fc-daygrid-more-link {
  color: rgba(0, 0, 0, 0.6);
}

.fc-more-popover {
  width: 20em;
  border: 0 !important;
  border-radius: .3em !important;
  overflow: hidden;
  box-shadow: 0 0 1.2em #00000056 !important;
}
.fc-daygrid-day-frame{
  overflow: hidden;
}
.fc-daygrid-day-frame:hover{
  overflow: auto;
}

.fc-popover-header {
  color: #fff;
  background-color: #5f7d91 !important;
  padding: .6em .5em !important;
}
.modal-header{
  background-color: #5f7d91 !important;
  color: #fff !important;
}
.fc-daygrid-day-events{
  display: flex;
    flex-direction: column;
    gap: 22.5px;
}
@media (min-width:1200px) and (max-width:1500px) {
  .fc-daygrid-day-events{
    margin-top: 18px !important;
  }
  .fc-daygrid-day{
    height: 7em !important;
    width: 7em !important;
    
  }
  .fc-daygrid-event-harness{
    height: 1em;
  }
  .fc-daygrid-event-harness >a {
    margin: 0 !important;
  }
  .fc-event-time{
    font-size: .8em !important;
  }
  .fc-event-title{
    font-size: .8em !important;
  }
  .fc-daygrid-day-bottom{
    margin-top: -25px !important;
  }
}
@media (min-width:1200px) and (max-width:1500px) {
  .fc-daygrid-day-events{
    gap: 5px;
  }
}
@media (min-width:1px) and (max-width:1200px) {
  .fc-daygrid-day-events{
    gap: 0px;
  }
}
@media (min-width:1px) and (max-width:732px) {
  .fc-daygrid-day-bottom{
    margin-top: -15px !important;
  }
}
@media (max-width:605px) {
  .disableCalendar{
    display: none !important;
  }
}
.ableEvents{
  display: block;
}
@media (min-width:606px) {
  .ableEvents{
    display: none;
  }
}
.fc-popover-body {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

@media (min-width:1200px) {
  .fc-popover-body {
    padding-top: 40px !important;
  }
}
.close{
  color: #fff !important;
}

.fc-daygrid-event-harness>.fc-daygrid-event {
  color: #333;
  font-weight: 400 !important;
}

.fc-daygrid-event-dot {
  border-color: #db8756 !important;
}
.fix-things-calendar{
  width: 100% !important;
  max-width: 100% !important;

}

</style>