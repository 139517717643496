<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
         <b-row id="users" style="margin-bottom: 50px;">
          <b-col xs="12" sm="12">
              <b-row align-v="center" class="mb-0 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">Boletines</h3>
                </b-col>

                <b-col 
                  :style="`display: flex; ${isMobile ? 'justify-content: center; margin-top: 10px;' : 'justify-content: end;'}`" 
                  xs="12" sm="12" md="6">
                  <b-button 
                    type="button" 
                    class="btn ver crear"
                    @click="showModalCreate()" 
                    :style="`color: #FFFFFF; ${!isMobile ? 'width: 160px;' : 'width: 100%;'}`">
                    Crear Boletin
                  </b-button>
                </b-col>
              </b-row>
          </b-col>
        </b-row>

        <b-row v-if="changedOrder" id="users" style="margin-bottom: 50px;">
          <b-col xs="12" sm="12" class="d-flex flex-column align-items-start" style="padding: 0px 30px;">
              <h4 style="font-size: 18px !important;">
                ¿Desea guardar los cambios en el orden de los boletines?
              </h4>

              <div class="d-flex">
                <b-button 
                  type="button" 
                  class="mt-2 mr-3"
                  @click="cancelSaveOrder()" 
                  style="color: #FFFFFF; background: #5f7d91 no-repeat padding-box; border: none; width: 110px;">
                  <span>Cancelar</span>
                </b-button>
                <b-button 
                  type="button" 
                  class="mt-2"
                  @click="saveOrder()" 
                  style="color: #FFFFFF; background: #f0900f no-repeat padding-box; border: none; width: 110px;">
                  <span v-if="!loadingChangeOrder">Guardar</span>
                  <b-spinner small v-if="loadingChangeOrder"></b-spinner>
                </b-button>
              </div>

           
          </b-col>
        </b-row>

        <!-- CARDS -->
    <div v-if="!loading" class="mt-0" >
      <b-row style="margin: 0; padding-bottom: 10px;">
        <b-col v-for="(boletin, index) in projects" :key="boletin.id" cols="12" md="6" lg="4" xl="3" class="mb-5">
          <b-card 
            class="mt-2 mb-2 mt-4 mb-2" 
            style="height:100%; border-radius: 12px;" 
            :class="`${ !boletin.grab ? 'grab_cursor' : 'grabing_cursor' }`"
            no-body 
            @mousedown=" boletin.grab = true"
            @mouseup=" boletin.grab = false"
            @mouseover=" boletin.grab = false"
            img-top
            draggable="true" 
            @dragstart="dragStart(index, $event)" 
            @dragover.prevent @dragenter="dragEnter" 
            @dragleave="dragLeave" 
            @dragend="dragEnd"
            @drop="dragFinish(index, $event)"
            >
            <b-img style="border-radius: 12px 12px 0px 0px !important;" :src="url_img + boletin.image" class="boletin_row ">
          </b-img>
          <b-card-body class="px-3 px-0 text-left">
            <h4 style="font-size: 15px !important;" class="mb-3">
              {{ boletin.name }}
            </h4>

              <p class="mb-0" style="font-size: 14px;">
                <strong>
                  Descripcion:
                </strong> 
                {{
                  truncateText(boletin.description)
                }}
              </p>
              </b-card-body>
              <b-card-body style="font-size: 14px;" class="px-3 text-left mt-0" >
                <strong>Creado el:</strong> {{ formatDate(boletin.created_at) }}
                <br>
                <strong>Estado:</strong> {{ boletin.status === 'active' ? 'Activo' : 'Inactivo' }}
                <br>
                <strong>Link del video:</strong> {{ !boletin.link_video || boletin.link_video === '' ? 'No tiene' : boletin.link_video }}
          </b-card-body>

          <template #footer>
              <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center">
                    <b-button 
                      type="button" 
                      class="rounded-btn"
                      style="color: #FFFFFF; background: #f0900f no-repeat padding-box;"
                      @click="showModalEdit(boletin.id)">
                      <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                    </b-button>

                    <b-button 
                      type="button" 
                      class="rounded-btn ml-3"
                      style="background: #dc3545 no-repeat;"
                      @click="showModalDelete(boletin.id)">
                      <b-icon scale="0.8" icon="trash-fill"></b-icon>
                  </b-button>
                  <b-button
                    type="button"
                    class="rounded-btn ml-3"
                    style="background: #5f7d91 no-repeat;"
                    @click="$router.push(`/boletin/${boletin.id}`)">
                  <b-icon scale="0.8" icon="eye-fill"></b-icon>
                </b-button> 
              </b-card-body>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>

     <!-- SKELETON LOADERS -->
     <div v-else>
      <b-row style="margin: 0; padding-bottom: 10px;">
        <b-col style="" v-for="item in 5" :key="item" cols="12" md="6" lg="4" xl="3">
          <b-card class="project_row mt-2 mb-2 mt-4 mb-2" style="min-height: 30em; border-radius: 12px;" no-body img-top>
          <b-skeleton-img card-img="top" aspect="2:1"></b-skeleton-img>
          <b-card-body class="mx-2 px-0">
            <b-skeleton width="80%"></b-skeleton>

            <b-skeleton class="mt-4" animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>

            <b-skeleton animation="wave" class="mt-4" width="70%"></b-skeleton>
          </b-card-body>

          <template #footer>
              <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center">
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
              </b-card-body>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div v-if="!loading && projects.length < 1">
      <h5 style="font-weight: 400 !important; opacity: .35;">
        No se encontraron boletines para mostrar.
      </h5>
    </div>

         <!-- Modal crear proyecto -->
          <b-modal 
            ref="modal-1" 
            size="lg" 
            centered
            hide-footer>

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Crear Boletín
              </b>
            </template>

            <b-row class="mt-4 px-2 px-sm-5 justify-content-center">
               <b-col cols="12" >
              <form v-on:submit.prevent="createProject" id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    :state="checkState('name')"
                    :max="255"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Descripción"
                  label-for="input-1"
                >
                  <vue-editor v-model="description"></vue-editor>
                  <span class="text-danger" style="font-size:12.8px" v-if="showDescriptionAlert">
                    La descripción debe contener al menos 10 caracteres.
                  </span>
                </b-form-group>
                <b-form-group label="Seleccionar destinatarios" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="recipients"
                    :options="optionsFactories"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="Agregar monitores" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="monitors"
                    :options="optionsFactories"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="Agregar confirmación" v-slot="{ ariaDescribedby }">
                  <b-form-radio 
                    v-model="add_confirmation" 
                    :aria-describedby="ariaDescribedby" 
                    name="Sin confirmación" 
                    id="radio2"
                    :value="''">
                    Sin confirmación
                  </b-form-radio>
                  <b-form-radio 
                    v-model="add_confirmation" 
                    :aria-describedby="ariaDescribedby"
                    name="Recibí conforme"
                    id="radio1"
                    value="recibi_conforme">
                    Recibí conforme
                  </b-form-radio>
                  <b-form-radio 
                    v-model="add_confirmation" 
                    :aria-describedby="ariaDescribedby" 
                    name="Confirmo asistencia" 
                    id="radio2"
                    value="confirmo_asistencia">
                    Confirmo asistencia
                  </b-form-radio>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Link del video"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="link_video"
                    :max="255"
                    required
                  ></b-form-input>
                </b-form-group>
                 <b-form-group
                  id="input-group-1"
                  label="Estado"
                  label-for="input-1"
                >
                <b-form-select v-model="status" class="form-select">
                  <b-form-select-option value="active">Activo</b-form-select-option>
                  <b-form-select-option value="inactive">Inactivo</b-form-select-option>
                </b-form-select>
                </b-form-group>
                 <b-form-group
                  id="input-group-1"
                  label="Foto 1"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo1"
                    name="photo1"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure v-if="image1" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="boletin_row" :src="image1" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 2"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo2"
                    name="photo2"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure v-if="image2" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img wclass="boletin_row" :src="image2" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 3"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo3"
                    name="photo3"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure v-if="image3" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="boletin_row" :src="image3" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
              </form>
            </b-col>
          </b-row>

          <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
            <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
              <b-btn
                block
                variant="outline-danger"
                @click="closeModal">
                Cancelar
              </b-btn>
            </b-col>
            <b-col
              style="display: flex; justify-content: center;"
              cols="12"
              md="4"
              class="px-0 mx-0 mx-md-1">
              <b-btn
                block
                :disabled="disableBtn || showDescriptionAlert"
                class="outline-btn"
                @click="createProject">
                <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                {{ loadingBtn ? "" : "Crear" }}
              </b-btn>
            </b-col>
          </b-row>
        </b-modal>
        <!-- Modal editar proyecto -->
          <b-modal 
            ref="modal-2" 
            size="lg" 
            centered
            hide-footer>

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Editar Boletín
              </b>
            </template>

            <b-row class="mt-4 px-2 px-sm-5 justify-content-center">
               <b-col cols="12">
              <form v-on:submit.prevent="createProject" id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    :max="255"
                    :state="checkState('name')"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Descripción"
                  label-for="input-1"
                >
                  <vue-editor v-model="description"></vue-editor>
                  <span class="text-danger" style="font-size:12.8px" v-if="showDescriptionAlert">
                    La descripción debe contener al menos 10 caracteres.
                  </span>
                </b-form-group>
                <b-form-group label="Seleccionar destinatarios" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-1"
                    v-model="recipients"
                    :options="optionsFactories"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="Agregar monitores" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    v-model="monitors"
                    :options="optionsFactories"
                    :aria-describedby="ariaDescribedby"
                    name="flavour-1"
                  ></b-form-checkbox-group>
                </b-form-group>
                <b-form-group label="Agregar confirmación" v-slot="{ ariaDescribedby }">
                  <b-form-radio 
                    v-model="add_confirmation" 
                    :aria-describedby="ariaDescribedby" 
                    name="Sin confirmación" 
                    id="radio2"
                    :value="''">
                    Sin confirmación
                  </b-form-radio>
                  <b-form-radio v-model="add_confirmation" :aria-describedby="ariaDescribedby" name="Recibí conforme" value="recibi_conforme">Recibí conforme</b-form-radio>
                  <b-form-radio v-model="add_confirmation" :aria-describedby="ariaDescribedby" name="Confirmo asistencia" value="confirmo_asistencia">Confirmo asistencia</b-form-radio>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Link del video"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="link_video"
                    :max="255"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Estado"
                  label-for="input-1"
                >
                <b-form-select v-model="status" class="form-select">
                  <b-form-select-option value="active">Activo</b-form-select-option>
                  <b-form-select-option value="inactive">Inactivo</b-form-select-option>
                </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 1"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo1"
                    name="photo1"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure v-if="image1 !== null" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="boletin_row" :src="image1" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 2"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo2"
                    name="photo2"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure v-if="image2 !== null" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="boletin_row" :src="image2" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Foto 3"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo3"
                    name="photo3"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure v-if="image3 !== null" class=" mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img class="boletin_row" :src="image3" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
              </form>
            </b-col>
          </b-row>

          <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
            <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
              <b-btn
                block
                variant="outline-danger"
                @click="closeModal2">
                Cancelar
              </b-btn>
            </b-col>
            <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
              <b-btn
                block
                :disabled="disableBtn || showDescriptionAlert"
                class="outline-btn"
                @click="updateProject">
                <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                {{ loadingBtn ? "" : "Editar" }}
              </b-btn>
            </b-col>
          </b-row>
        </b-modal>

        <!-- Modal elminar proyecto -->
        <b-modal 
          ref="modal-3" 
          centered
          hide-footer>
          <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Confirmación
              </b>
            </template>

          <p class="mt-3" style="text-align: center;">
            ¿Está seguro de que desea eliminar este boletín?
          </p>

           <b-row class="ma-0 mt-4 px-5 pb-3 justify-content-center">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal3">
                  Mejor no
                </b-btn>
              </b-col>

              <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  class="outline-btn"
                  @click="deleteProject">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Sí" }}
                </b-btn>
              </b-col>
          </b-row>
        </b-modal>

         <b-modal 
          v-model="detailDialog"
          id="modal-center" 
          centered
          size="lg"
          hide-footer>

          <template #modal-title>
            <b style="color:#45bba9">
              Detalle de boletín
            </b>
          </template>

          <b-card 
            v-if="currentProject"
            class="ma-0 pa-0"
            
            :header="currentProject.name">
                      
            <b-card-text class="pt-2" style="width:100%;" v-if="currentProject.image">
              <b-card-img
                :src="url_img + currentProject.image" 
                alt="user_pic"
                style="object-fit: contain; max-height: 60em;"
                class="img">
              </b-card-img>
            </b-card-text>

            <b-card-text style="max-height: 400px; overflow-y: scroll; font-size: 16px !important;" class="pt-1 px-2 pb-3 scroll">
              <div v-html="currentProject.description"></div>
            </b-card-text>

            </b-card>
          </b-modal>

    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment'
import { VueEditor } from "vue2-editor";

export default {
  name: 'Users',
  components: {
    VueEditor,
    
  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        projects: [],
        name: '',
        description: '',
        showDescriptionAlert:false,
        status: '',
        rol: '',
        photo1: null,
        photo2: null,
        photo3: null,
        order: null,
        link_video: '',
        image1: '',
        image2: '',
        image3: '',
        position: '',
        project_id: '',
        loading: true,
        isMobile: false,
        perPage: 8,
        currentPage: 1,
        loadingBtn: false,
        currentProject: null,
        detailDialog: false,
        dragging: -1,
        projectsBackup: [],
        changedOrder: false,
        loadingChangeOrder: false,
        recipients: [],
        monitors: [],
        add_confirmation: '',
        optionsFactories: [
                      { value: 'all', text: 'Todos' },
                      { value: 'las_gaviotas', text: 'Planta Las Gaviotas' },
                      { value: 'san_ignacio', text: 'Planta San Ignacio' },
                      { value: 'casa_matriz_santiago', text: 'Casa Matriz Santiago' },
                      { value: 'auricop', text: 'Auricop' },
                      { value: 'minera_cobre_verde', text: 'Minera Cobre Verde' },
                      { value: 'enami', text: 'Enami' }
                    ]
      }
    },
  mounted() {
    this.rol = (localStorage.userRol)
    if ( this.rol !== 'admin'){
        this.$router.back()
    }
    this.getProjects();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  watch:{
    description(){
      let plainDesc = this.description.replace(/<[^>]*>/g, '');
      if (plainDesc.length < 10){
        this.showDescriptionAlert = true;
      }
      else {
        this.showDescriptionAlert = false;
      }
    }
  },
  computed: {
    disableBtn() {
        if(
          !this.name || !this.description ||
          !this.status || (!this.photo1 && !this.image1) 
        ) {
          return true
        }

        return false
    },
    isDragging() {
      return this.dragging > -1
    }
  },
  methods: {
    formatDate(date) {
        return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    showModalCreate() {
        this.name = '';
        this.description = '';
        this.link_video = '';
        this.image1 = '';
        this.image2 = '';
        this.image3 = '';
        this.status = '';
        this.recipients = [];
        this.monitors = [];
        this.add_confirmation = '';
        this.$refs['modal-1'].show();
        this.showDescriptionAlert = false;
      },
    showModalEdit(id) {
      const project = this.projects.find((e) => { return e.id === id });
      if (project) {


        let recipients = []
        let monitors = []

        if (project.factorybyproject && Array.isArray(project.factorybyproject)){
          project.factorybyproject.forEach(element => {
            if (element?.type === "recipient"){
              recipients.push(element?.value)
            }
            if (element?.type === "monitor"){
              monitors.push(element?.value)
            }
          });
        }

        if (recipients.length === 0 || recipients.length === 4){
          recipients.push('all')
        }

        if (monitors.length === 0 || monitors.length === 4){
          monitors.push('all')
        }

        this.recipients = recipients;
        this.monitors = monitors;
        this.project_id = project.id;
        this.name = project.name;
        this.link_video = !project.link_video ? '' : project.link_video;
        this.description = project.description;
        this.add_confirmation = project.add_confirmation ? project.add_confirmation : '';
        this.status = project.status;
        this.image1 = project.image !== null && project.image !== "" ? process.env.VUE_APP_URL+'/storage/'+project.image : null;
        this.image2 = project.image2 !== null && project.image2 !== "" ? process.env.VUE_APP_URL+'/storage/'+project.image2 : null;
        this.image3 = project.image3 !== null && project.image3 !== "" ? process.env.VUE_APP_URL+'/storage/'+project.image3 : null;
        this.showDescriptionAlert = false;
      }
      
      this.$refs['modal-2'].show();
    },
    showModalDelete(id) {
        this.$refs['modal-3'].show();
        this.project_id = id;
      },
    createProject() {
      this.loadingBtn = true

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}`,  "Content-type": "application/x-www-form-urlencoded"}
      };

      let factories = []

      if (this.recipients.length === 4 || this.recipients.some(recipient => recipient === 'all')) {
        this.optionsFactories.forEach(element => {
          if (element.value !== 'all'){
            factories.push(
              { "type": "recipient", "value": element.value },
            )
          }
          
        });
      } else {
        this.recipients.forEach(element => {
          if (element !== 'all'){
            factories.push(
              { "type": "recipient", "value": element },
            )
          }
        });
      }
      
      if (this.monitors.length === 4 || this.monitors.some(monitor => monitor === 'all')) {
        this.optionsFactories.forEach(element => {
          if (element.value !== 'all'){
            factories.push(
              { "type": "monitor", "value": element.value },
            )
          }
          
        });
      } else {
        this.monitors.forEach(element => {
          if (element !== 'all'){
            factories.push(
              { "type": "monitor", "value": element },
            )
          }
        });
      }

      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('link_video', this.link_video);
      formData.append('description', this.description);
      formData.append('add_confirmation', this.add_confirmation);
      formData.append('factories', JSON.stringify(factories));
      formData.append('image1', this.photo1);
      formData.append('image2', this.photo2);
      formData.append('image3', this.photo3);
      formData.append('status', this.status);

      
      axios.post(process.env.VUE_APP_URL+"/api/auth/projects", formData, config)
        .then(() => {
          this.loadingBtn = false;
          this.closeModal()
          this.getProjects();
          this.makeToast('success', '¡El boletín ha sido creado satisfactoriamente!', '¡Boletín creada!')
        })
        .catch((err) => {
          this.loadingBtn = false;
          console.log('>> Error while trying to create a bulletin.', err);
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar crear el boletín.', 'Error fatal.')
        })
    },
    getProjects() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/projects';

      axios.get(url, config).then(data => {
        this.loading = false
        
        data.data.forEach((element) => {
          element.grab = false
        });
        this.projects = JSON.parse(JSON.stringify(data.data));
        this.projectsBackup =  JSON.parse(JSON.stringify(data.data));
      })

    },
    updateProject() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let factories = []

      if (this.recipients.length === 4 || this.recipients.some(recipient => recipient === 'all')) {
        this.optionsFactories.forEach(element => {
          if (element.value !== 'all'){
            factories.push(
              { "type": "recipient", "value": element.value },
            )
          }
          
        });
      } else {
        this.recipients.forEach(element => {
          if (element !== 'all'){
            factories.push(
              { "type": "recipient", "value": element },
            )
          }
        });
      }
      
      if (this.monitors.length === 4 || this.monitors.some(monitor => monitor === 'all')) {
        this.optionsFactories.forEach(element => {
          if (element.value !== 'all'){
            factories.push(
              { "type": "monitor", "value": element.value },
            )
          }
          
        });
      } else {
        this.monitors.forEach(element => {
          if (element !== 'all'){
            factories.push(
              { "type": "monitor", "value": element },
            )
          }
        });
      }

       let formData = new FormData();
       formData.append('_method', 'PUT');
       formData.append('name', this.name);
       formData.append('link_video', this.link_video);
       formData.append('description', this.description);
       formData.append('add_confirmation', this.add_confirmation);
       formData.append('factories', JSON.stringify(factories));
       formData.append('image1', this.photo1);
       formData.append('image2', this.photo2);
       formData.append('image3', this.photo3);
       formData.append('status', this.status);
       this.loadingBtn = true

      axios.post(process.env.VUE_APP_URL+"/api/auth/projects/"+this.project_id, formData, config)
        .then(() => {
          this.loadingBtn = false;
          this.closeModal2()
          this.getProjects();
          this.makeToast('success', '¡El boletín ha sido actualizado satisfactoriamente!', '¡Boletín actualizado!')
        })
        .catch((err) => {
          console.log('>> Error while trying to update a bulletin.', err);
          this.loadingBtn = false;
          this.closeModal2()
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar actualizar el boletín.', 'Error fatal.')
        })
    },
    deleteProject() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'DELETE');
       this.loadingBtn = true;

      axios.post(process.env.VUE_APP_URL+"/api/auth/projects/"+this.project_id, formData, config)
        .then(()=> {
          this.loadingBtn = false
          this.closeModal3()
          this.getProjects();
          this.makeToast('success', '¡El boletín a ha sido eliminado satisfactoriamente!', '¡Boletín eliminado!')
        })
        .catch((err) => {
          this.loadingBtn = false;
          console.log('>> Error while trying to delete a bulletin.', err);
          this.closeModal3()
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar eliminar el boletín.', 'Error fatal.')
        })
    },
    closeModal3() {
      this.$refs['modal-3'].hide();
      this.project_id = '';
    },
    obtenerImagen(e){
        let file = e.target.files[0];
        if (file.size > 600 * 1024) {
          this.makeToast('danger', 'El archivo es demasiado grande. Por favor, seleccione un otro archivo.', 'Error fatal.')
          return false
        }
        else{
          let name = e.target.name;
          this.cargarImagen(file, name.slice(-1));
        }
       
    },
    cargarImagen(file, position){
        let reader = new FileReader()
        reader.onload = (e) => {
            this['image'+position] = e.target.result;
        }
        reader.readAsDataURL(file);
    },

    truncateText(text) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();
      if(content.length>70) {
          content = content.substring(0, 70);
          content = content + ('(...)');
      }
            
      return content;
    },
    closeModal() {
      this.$refs['modal-1'].hide()
      this.name = '';
      this.link_video = '';
      this.description = '';
      this.status = '';
      this.image = '';
      this.recipients = [];
      this.monitors = [];
      this.add_confirmation = '';
      this.showDescriptionAlert = false;
    },

    closeModal2() {
      this.$refs['modal-2'].hide()
      this.name = '';
      this.link_video = '';
      this.description = '';
      this.status = '';
      this.image = '';
      this.recipients = [];
      this.monitors = [];
      this.add_confirmation = '';
      this.showDescriptionAlert = false;
    },
    checkState(field) {
      let aux = true;
      switch (field) {
        case 'name':
          aux = 0 === this.name.length ? null : false
          if (this.name.length >= 3) aux  = true
          break;
        case 'recipients':
          aux = 0 === this.recipients.length ? null : false
          if (this.recipients.length >= 1) aux  = true
          break;
        case 'monitors':
          aux = 0 === this.monitors.length ? null : false
          if (this.monitors.length >= 1) aux  = true
          break;
        case 'add_confirmation':
          aux = 0 === this.add_confirmation.length ? null : false
          if (this.add_confirmation.length >= 1) aux  = true
          break; 
        default:
          aux = 0 === this.description.length ? null : false
          if (this.description.length >= 10) aux = true
          break;
      }

      return aux;
    },

    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },
    showDialog(project) {
      this.currentProject = project;
      this.detailDialog = true
    },

    dragStart(which, ev) {
      ev.dataTransfer.setData('Text', this.id);
      ev.dataTransfer.dropEffect = 'move'
      this.dragging = which;
    },
    dragEnter() {

    },
    dragLeave() {

    },
    dragEnd() {
      this.dragging = -1
    },
    dragFinish(to, ev) {
      this.moveItem(this.dragging, to);
      ev.target.style.marginTop = '2px'
      ev.target.style.marginBottom = '2px'
    },
    moveItem(from, to) {
      this.changedOrder = true
      this.projects.splice(to, 0, this.projects.splice(from, 1)[0]);
    },
    cancelSaveOrder() {
      this.changedOrder = false
      this.projects = JSON.parse(JSON.stringify(this.projectsBackup))
    },
    async saveOrder () {
      this.loadingChangeOrder = true

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let projectsReordered = []
      this.projects.forEach((element,index) => {
        projectsReordered.push({
          id: element.id,
          order: index+1
        })
      });

      let formData = new FormData();
       formData.append('_method', 'PUT');
       formData.append('projects', projectsReordered);

      formData = {
        projects: projectsReordered
      }

      await axios.post(process.env.VUE_APP_URL+"/api/auth/update/projects-order", formData, config)
        .then(() => {
          this.makeToast('success', '¡El orden de los boletines ha sido actualizado satisfactoriamente!', '¡Orden actualizado!')
        })
        .catch((err) => {
          console.log('>> Error while trying to update a bulletin.', err);
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar actualizar el orden de los boletines.', 'Error fatal.')
        })
      this.changedOrder = false
      this.loadingChangeOrder = false
    }
  },
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.ver {
  background-color: #3379A1 !important;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
}

.btn-danger{
  background: transparent linear-gradient(180deg, #e47c86 0%, #dc3545 100%) 0% 0% no-repeat padding-box;
  padding: 0 20px 0 20px!important;
}

.project_row img{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.4 !important;
  /*object-fit: cover;
  object-position: center;*/
}

.boletin_row{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 0.80 !important;
  /*object-fit: cover;
  object-position: center;*/
}

.grab_cursor {
  cursor: -webkit-grab; 
  cursor: grab;
}

.grabing_cursor {
  cursor: -webkit-grabbing; 
  cursor: grabbing;
}

</style>
