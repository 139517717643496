<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
        <b-row>
            <b-col col sm="12">
                <h3 style="color:black;"><img src="../assets/noticia.svg" alt="" id="img_project">Noticias <img @click="$router.push('/inicio')" id="back-arrow" src="../assets/left-arrow.svg" alt=""></h3> 
               <div class="d-flex" style="margin-top: 40px; margin-bottom: 40px;">
                    <div
                      class="m-0 pr-0 pl-0 d-flex justify-content-start"
                      @click="showNotice('internal')"
                  >
                    <b-btn
                      id="internal_btn"
                      style="font-size: 13px; font-weight: 400 !important; width:80px; !important;"
                      class="outline-btn py-1"
                    >
                      Internas
                    </b-btn>
                  </div>
                  <div
                      class="m-0 pl-2 d-flex justify-content-start"
                      @click="showNotice('external')"
                  >
                    <b-btn
                      id="external_btn"
                      style="font-size: 13px; font-weight: 400 !important; width:80px; !important;"
                      class="py-1 outline-btn-disabled"
                    >
                      Externas
                    </b-btn>
                   </div>
                </div>
               <b-row id="notices_internals">
                    <b-col class="mb-4" cols="12" sm="6" md="4" lg="4" xl="3" v-for="notice in notices" :key="notice.id">
                      <b-card
                        tag="article"
                        no-body
                        class="mb-2  p-0"
                      >
                          <img v-if="!notice.image" src="../assets/noticia.svg" alt="" class="notice_row">
                          <b-card-img
                            v-else
                            :src="url_img + notice.image"
                            alt="Image"
                            class="rounded-0 p-0 notice_row"></b-card-img>
                            <b-col
                              cols="12">
                              <b-card-body class="px-0">
                                <h4 style="font-weight:500 !important; font-size: 15px !important;">
                                  {{ notice.name }}
                                </h4>
                                <b-card-text>
                                  {{truncateText(notice.description, 250)}}
                                </b-card-text>
                                
                              </b-card-body>
                            </b-col>
                            <template #footer>
                              <b-button class="my-1" :href="'/noticia/internal/'+notice.id" style="background: #3379A1">Leer más</b-button>
                            </template>
                        </b-card>
                     </b-col>
                </b-row>
                <b-row id="notices_externals" class="hide">
                    <b-col cols="12" sm="6" md="4" lg="4" xl="3" class="mb-4" v-for="notice in notices_external" :key="notice.id">
                         <b-card
                            no-body
                            tag="article"
                            class="mb-2  p-0"
                        >

                          <img v-if="!notice.featured_media" src="../assets/noticia.svg" alt="" class="notice_row">
                          <b-card-img
                            v-else
                            :src="setImageNoticeExternal(notice.featured_media)"
                            alt="Image"
                            class="rounded-0 p-0 notice_row"></b-card-img>
                        
                        <b-col
                          cols="12">
                          <b-card-body class="p-0">
                            <h4 style="font-weight:500 !important; font-size: 15px !important;">
                              {{ truncateText(notice.title.rendered, 46) }}
                            </h4>
                            <b-card-text>
                                {{ truncateText(notice.content.rendered, 250) }}
                            </b-card-text>
                            
                          </b-card-body>
                          </b-col>
                        <template #footer>
                          <b-button class="my-1" :href="'/noticia/external/'+notice.id" style="background: #3379A1">Leer más</b-button>
                        </template>
                        </b-card>
                     </b-col>
                </b-row>

                <!-- SKELETON LOADERS -->
              <div v-if="loading">
                <b-row style="margin: 0; padding-bottom: 10px;">
                  <b-col style="" v-for="item in 5" :key="item" cols="12" md="6" lg="4" xl="3">
                    <b-card class="project_row mt-2 mb-2 mt-4 mb-2" style="min-height: 24.8em; border-radius: 12px;" no-body img-top>
                    <b-skeleton-img card-img="top" aspect="2:1"></b-skeleton-img>
                    <b-card-body class="mx-2 px-0">
                      <b-skeleton width="80%"></b-skeleton>

                      <b-skeleton class="mt-4" animation="wave" width="85%"></b-skeleton>
                      <b-skeleton animation="wave" width="55%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>

                      <b-skeleton animation="wave" class="mt-4" width="70%"></b-skeleton>
                    </b-card-body>

                    <template #footer>
                        <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center">
                          <b-skeleton size="30px" class="mx-1" type="btn"></b-skeleton>
                        </b-card-body>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'Noticias',
  components: {
  
  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        notices: '',
        notices_external: '',
        name: '',
        description: '',
        status: '',
        link: '',
        photo: null,
        image: '',
        notice_id: '',
        categories_external: [],
        images_external: [],
        isMobile: false,
        loading: true
      }
    },
  mounted() {
    this.getNoticesInternal();
    this.getCategoriesExternal();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    onResize () {
        this.isMobile = window.innerWidth < 1200
      },
    showNotice(type_notice) {
      if(type_notice == 'internal') {

        document.getElementById("external_btn").classList.remove("outline-btn");
        document.getElementById("external_btn").classList.add("outline-btn-disabled");
        document.getElementById("internal_btn").classList.remove("outline-btn-disabled");
        document.getElementById("internal_btn").classList.add("outline-btn");
        document.getElementById("notices_externals").classList.remove("show2");
        document.getElementById("notices_externals").classList.add("hide");
        document.getElementById("notices_internals").classList.remove("hide");
        document.getElementById("notices_internals").classList.add("show2");
        
        
      } else if(type_notice == 'external') {

        document.getElementById("external_btn").classList.remove("outline-btn-disabled");
        document.getElementById("external_btn").classList.add("outline-btn");
        document.getElementById("internal_btn").classList.remove("outline-btn");
        document.getElementById("internal_btn").classList.add("outline-btn-disabled");
        document.getElementById("notices_externals").classList.remove("hide");
        document.getElementById("notices_externals").classList.add("show2");
        document.getElementById("notices_internals").classList.remove("show2");
        document.getElementById("notices_internals").classList.add("hide");
       

      }
    },
    showModalCreate() {
        this.name = '';
        this.description = '';
        this.status = '';
        this.link = '';
        this.image = '';
        this.$refs['modal-1'].show();
      },
    showModalEdit(id) {
      for (let i = 0; i < this.notices.length; i++) {
        if(this.notices[i].id == id) {
            this.notice_id = this.notices[i].id;
            this.name = this.notices[i].name;
            this.description = this.notices[i].description;
            this.link = this.notices[i].link;
            this.status = this.notices[i].status;
            this.image = process.env.VUE_APP_URL+'/storage/'+this.notices[i].image;
        }
      }

      this.$refs['modal-2'].show();
    },
    showModalDelete(id) {
        this.$refs['modal-3'].show();
        this.notice_id = id;
      },
    createNotice() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

       let formData = new FormData();
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('image', this.photo);
       formData.append('status', this.status);
       formData.append('link', this.link);

      axios.post(process.env.VUE_APP_URL+"/api/auth/notices", formData, config)
        .then(() => {
            this.name = '';
            this.description = '';
            this.status = '';
            this.image = '';
            this.link = '';

           this.getNoticesInternal();
        });  
    },
    getNoticesInternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/notices';

      axios.get(url, config).then(data => {
        this.loading = false
        this.notices = data.data;
      })

    },
    getCategoriesExternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = 'https://redimin.cl/wp-json/wp/v2/categories';

      axios.get(url, config).then(data => {
        for (let i = 0; i < data.data.length; i++) {
          this.categories_external[i] =  data.data[i].id; 
        }

        this.getNoticesExternal();
      })

    },
    getImageNoticeExternal(id) {
      
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = ' https://www.redimin.cl/wp-json/wp/v2/media/'+id;


      axios.get(url, config).then(data => {
        this.images_external.push(data.data);

      })

    },
    setImageNoticeExternal(id) {
      for (let i = 0; i < this.images_external.length; i++) {
        if (this.images_external[i].id == id) {
          return this.images_external[i].guid.rendered;
        }
        
      }
    },
    getNoticesExternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = 'https://redimin.cl/wp-json/wp/v2/posts?categories='+this.categories_external.toString();
      // let images = [];

      axios.get(url, config).then(data => {
        this.notices_external = data.data;

        console.log('notices_external', this.notices_external);
        for (let i = 0; i < this.notices_external.length; i++) {
           this.getImageNoticeExternal(this.notices_external[i].featured_media)
        }
        
      })

     

    },
    getTitleNoticeExternal(id) {
        let title = '';

        for (let i = 0; i < this.notices_external.length; i++) {
          if(this.notices_external[i].id == id) {
             title = this.notices_external[i].title.rendered;
          }
        }

        return title;

    },
     getDescriptionNoticeExternal() {
        // let notice = '';

        // for (let i = 0; i < this.notices_external.length; i++) {
        //   if(this.notices_external[i].id == id) {
        //      notice = this.notices_external[i].content.rendered;
        //   }
        // }

        // // console.log('notice image', notice);
        // // let position_ini = notice.search("<img");
        // // let position_fin = notice.search("/>") ;
        // // let result = notice.substring(position_ini, parseInt(position_fin) + 2);

        // return result;

    },
    updateNotice() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'PUT');
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('image', this.photo);
       formData.append('status', this.status);
       formData.append('link', this.link);


      axios.post(process.env.VUE_APP_URL+"/api/auth/notices/"+this.notice_id, formData, config)
        .then(data => {
           console.log(data);
            this.name = '';
            this.description = '';
            this.status = '';
            this.image = '';
            this.link = '';

           this.getNoticesInternal();
        });  
    },
    deleteNotice() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'DELETE');

      axios.post(process.env.VUE_APP_URL+"/api/auth/notices/"+this.notice_id, formData, config)
        .then(data => {
          console.log(data);
           this.getNoticesInternal();
        });  
    },
    obtenerImagen(e){
        let file = e.target.files[0];
        // console.log(file);
        this.photo = file;
        this.cargarImagen(file);
    },
    cargarImagen(file){
        let reader = new FileReader();
        reader.onload = (e) => {
            this.image = e.target.result;
        }
        reader.readAsDataURL(file);
    },
     truncateText(text, length) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();
      if(content.length>length) {
          content = content.substring(0, length);
          content = content + ('(...)');
      }
            
      return content;
    },
  },
}
</script>

<style>
h4 {
    text-align: left;
    font-size: 15px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold !important;

}

.n_active {
    text-align: center;
    color: #47B29E;
    border: 1px solid #47B29E;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
    font-size: 16px;
    height: 29px;
}

.card-body h6{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.card-body .card-text{
    text-align: left;
    font-size: 10px;
}

.n_inactive {
    text-align: center;
    letter-spacing: 0px;
    color: #B0B0B2;
    opacity: 1;
    background: #F4F6F8 0% 0% no-repeat padding-box;
    border-radius: 0px;
    opacity: 1;
    cursor: pointer;
    font-size: 16px;
    height: 29px;
}

.card-img { 
    padding-top: 15px;
}

.img-notice {
    max-height: 95px;
}

.hide { 
  display: none !important;
}

.show2 {
  display: flex;
}

#notices_externals .col-sm-3.col .card.mb-2.white .card-body .card-text{
  height:100px;
}

#notices_externals .col-sm-3.col .card.mb-2.white{
  margin-bottom:30px!important;
}

.notice_row {
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.9 !important;
  /*object-fit: cover;
  object-position: center;*/
}
</style>