<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
         <b-row id="surveys" class="m-0 p-0" style="margin-bottom: 100px;">
          <b-col xs="12" sm="12">
              <b-row align-v="center" class="mb-5 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">Encuestas</h3>
                </b-col>

                <b-col 
                  :style="`display: flex; ${isMobile ? 'justify-content: center; margin-top: 10px;' : 'justify-content: end;'}`" 
                  class="p-0"
                  cols="12" sm="12" md="6">
                  <b-button 
                    type="button" 
                    class="btn ver crear"
                    @click="showModalCreate()" 
                    :style="`color: #FFFFFF; ${!isMobile ? 'width: 160px;' : 'width: 100%;'}`">
                    Crear Encuesta
                  </b-button>
                </b-col>

              </b-row>

              <!--- Tabla de encuestas --->

              <b-table
              head-variant="dark"
              class="mb-0"
              :per-page="perPage"
              id="my-table"
              :current-page="currentPage"
              :fields="[
                {
                  key: 'question',
                  label: 'Pregunta',
                  thClass: 'table-dark'
                },
                {
                  key: 'type',
                  label: 'Tipo',
                  thClass: 'table-dark'
                },
                {
                  key: 'options',
                  label: 'Número de opciones',
                  thClass: 'table-dark'
                },
                {
                  key: 'statusSurvey',
                  label: 'Estado',
                  thClass: 'table-dark'
                },
                {
                  key: 'created_at',
                  label: 'Fecha de creacion',
                  thClass: 'table-dark'
                },
                {
                  key: 'role',
                  label: 'Acciones',
                  thClass: 'table-dark'
                }
              ]"
              :stacked="isMobile"
              striped
              th-class="table-dark"
              :items="loading ? [] : surveys"
              v-if="!loading"
              >
                <template #top-row v-if="surveys.length === 0">
                  <td>
                    {{ `No hay encuestas registradas` }}
                  </td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </template>

                <template #cell(type)="data">
                  {{ data.item.question_type === 'afirmation' ? 'Simple' : 'Múltiple'}}
                </template>

                <template #cell(options)="data">
                  {{ data.item.answers_options.length }}
                </template>

                <template #cell(statusSurvey)="data">
                  {{ data.item.status === 'active' ? 'Activa' : 'Inactiva' }}
                </template>

                <template #cell(created_at)="data">
                  {{ getFormattedDate(data.item.created_at) }}
                </template>

                <template style="display:flex; justify-content: center;" #cell(role)="data">
                    <div style="display:flex !important; justify-content: center;">
                    <b-button 
                      type="button" 
                      class="rounded-btn"
                      style="color: #FFFFFF; background: #f0900f no-repeat padding-box;"
                      @click="showModalEdit(data.item.id)">
                      <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                    </b-button>

                    <b-button 
                      type="button" 
                      class="rounded-btn ml-3"
                      style="background: #dc3545 no-repeat;"
                      @click="showModalDelete(data.item.id)">
                      <b-icon scale="0.8" icon="trash-fill"></b-icon>
                    </b-button>
                    
                    <b-button
                      type="button"
                      class="rounded-btn ml-3"
                      style="background: #5f7d91 no-repeat;"
                      @click="$router.push(`/encuesta/${data.item.id}`)">
                      <b-icon scale="0.8" icon="eye-fill"></b-icon>
                    </b-button> 
                  </div>
                </template>
              </b-table>

              <b-pagination
                v-model="currentPage"
                v-if="surveys.length > 0 && !loading"
                dark
                align="right"
                :total-rows="surveys.length"
                :per-page="perPage"
                class="mt-3"
                aria-controls="my-table"
              >
                <template #page="{ page, active }">
                  <b v-if="active">{{ page }}</b>
                  <i v-else>{{ page }}</i>
                </template>
              </b-pagination>

              <!-- Skeleton loader --->

              <b-skeleton-table
              v-if="loading"
              :stacked="isMobile"
              :rows="7"
              :columns="7"
              :table-props="{ bordered: true, striped: true }"
            >
            </b-skeleton-table>
          </b-col>
        </b-row>

         <!-- Modal crear encuesta -->
          <b-modal 
            centered 
            ref="modal-1" 
            size="md"  
            hide-footer>

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Crear encuesta
              </b>
            </template>

            <b-row class="mt-4 justify-content-center">
               <b-col cols="10">
              <!-- Login Form -->
              <form id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Pregunta"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    :state="checkState('question')"
                    v-model="question"
                    required
                  >
                  </b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    La pregunta debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="¿Selección múltiple o simple?" v-slot="{ ariaDescribedby }">
                  <b-form-radio 
                    v-model="question_type" 
                    :aria-describedby="ariaDescribedby" 
                    name="Selección simple" 
                    id="radio2"
                    value="afirmation">
                    Selección simple
                  </b-form-radio>
                  <b-form-radio 
                    v-model="question_type" 
                    :aria-describedby="ariaDescribedby"
                    name="Selección múltiple"
                    id="radio1"
                    value="multi">
                    Selección múltiple
                  </b-form-radio>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Opciones"
                  label-for="input-1"
                >
                <div style="max-height: 400px; overflow-y: auto; width: calc(100% + 10px); padding-right: 10px;margin-bottom: 20px;" class="scroll">
                  <div v-for="(option,index) in answers_options" 
                    class="mb-2"
                        :v-bind="`${option.id}${index}`" 
                        :key="`${option.id}${index}`">
                      <div class="d-flex">
                        <b-form-input
                        id="input-1"
                        type="text"
                        v-model="option.value"
                        required
                        >
                        </b-form-input>
                        <b-button 
                          type="button" 
                          :disabled="answers_options.length < 3"
                          class="rounded-btn ml-3"
                          style="background: #dc3545 no-repeat;"
                          @click="deleteOption(option.id)">
                          <b-icon scale="0.8" icon="trash-fill"></b-icon>
                        </b-button>
                      </div>
                      
                      <div  v-if="option.value.length < 2" class="danger--text text--danger danger-text text-danger mt-2">
                        Debe ingresar al menos 2 caracteres por opción
                      </div>
                  </div>
                </div>
                <div 
                  class="mb-2 d-flex justify-content-center">
                  <b-btn
                    block
                    :disabled="answers_options.length >= 30"
                    class="outline-btn"
                    @click="addOption()">
                    Agregar opción
                  </b-btn>
                </div>
                 
                  
                </b-form-group>
              </form>
               </b-col>
             </b-row>

             <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
               <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal">
                  Cancelar
                </b-btn>
               </b-col>
               <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  :disabled="disableBtn"
                  class="outline-btn"
                  @click="createSurvey">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Crear" }}
                </b-btn>
               </b-col>
             </b-row>
          </b-modal>
        <!-- Modal editar encuesta -->
          <b-modal ref="modal-2" size="md" centered hide-footer>

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Editar encuesta
              </b>
            </template>
            
            <b-row class="mt-4 justify-content-center">
               <b-col cols="10" md="10" >
              <!-- Login Form -->
              <form v-on:submit.prevent="createSurvey" id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Pregunta"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    :state="checkState('question')"
                    v-model="question"
                    required
                  >
                  </b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    La pregunta debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group label="¿Selección múltiple o simple?" v-slot="{ ariaDescribedby }">
                  <b-form-radio 
                    v-model="question_type" 
                    :aria-describedby="ariaDescribedby" 
                    name="Selección simple" 
                    id="radio2"
                    value="afirmation">
                    Selección simple
                  </b-form-radio>
                  <b-form-radio 
                    v-model="question_type" 
                    :aria-describedby="ariaDescribedby"
                    name="Selección múltiple"
                    id="radio1"
                    value="multi">
                    Selección múltiple
                  </b-form-radio>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Opciones"
                  label-for="input-1"
                >
                <div style="max-height: 400px; overflow-y: auto; width: calc(100% + 10px); padding-right: 10px;margin-bottom: 20px;" class="scroll">
                  <div v-for="(option,index) in answers_options" 
                    class="mb-2"
                        :v-bind="`${option.id}${index}`" 
                        :key="`${option.id}${index}`">
                      <div class="d-flex">
                        <b-form-input
                        id="input-1"
                        type="text"
                        v-model="option.value"
                        required
                        >
                        </b-form-input>
                        <b-button 
                          type="button" 
                          :disabled="answers_options.length < 3"
                          class="rounded-btn ml-3"
                          style="background: #dc3545 no-repeat;"
                          @click="deleteOption(option.id)">
                          <b-icon scale="0.8" icon="trash-fill"></b-icon>
                        </b-button>
                      </div>
                      
                      <div  v-if="option.value.length < 2" class="danger--text text--danger danger-text text-danger mt-2">
                        Debe ingresar al menos 2 caracteres por opción
                      </div>
                  </div>
                </div>
                <div 
                  class="mb-2 d-flex justify-content-center">
                  <b-btn
                    block
                    :disabled="answers_options.length >= 30"
                    class="outline-btn"
                    @click="addOption()">
                    Agregar opción
                  </b-btn>
                </div>
                </b-form-group>

              </form>
               </b-col>
             </b-row>

             <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
               <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal2">
                  Cancelar
                </b-btn>
               </b-col>
               <b-col style="display:flex; justify-content:center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  :disabled="disableBtn"
                  class="outline-btn"
                  @click="updateSurvey">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Editar" }}
                </b-btn>
               </b-col>
             </b-row>
          </b-modal>

        <!-- Modal elminar encuesta -->

         <b-modal 
          ref="modal-3" 
          centered
          hide-footer>
          <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Confirmación
              </b>
            </template>

          <p class="mt-3" style="text-align: center;">
            ¿Está seguro de que desea eliminar esta encuesta?
          </p>

           <b-row class="ma-0 mt-4 px-5 pb-3 justify-content-center">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal3">
                  Mejor no
                </b-btn>
              </b-col>

              <b-col style="display:flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  class="outline-btn"
                  @click="deleteSurvey">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Sí" }}
                </b-btn>
              </b-col>
          </b-row>
        </b-modal>

    </div>
</template>

<script>
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import moment from 'moment'

export default {
  name: 'Surveys',
  components: {

  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        surveys: [],
        question: '',
        question_type: 'afirmation',
        answers_options: [
          {
            value: 'Opción 1',
            id: 1
          },
          {
            value: 'Opción 2',
            id: 2
          }
        ],
        survey_id: '',
        loading: true,
        isMobile: false,
        perPage: 8,
        currentPage: 1,
        loadingBtn: false,
      }
    },
  mounted() {
    this.getSurveys();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  watch: {
    searchName() {
      this.searchTimeOut()
    }
  },
  computed: {
    disableBtn() {
      if(
          !this.question
        ) {
          return true
        }
        return false
    },
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    showModalCreate() {
        this.question = '';
        this.question_type = 'afirmation';
        this.answers_options = [     {
            value: 'Opción 1',
            id: 1
          },
          {
            value: 'Opción 2',
            id: 2
          }];
        this.$refs['modal-1'].show();
      },
    showModalEdit(id) {
      const survey = this.surveys.find((e) => { return e.id === id })
      this.survey_id = id
      if (survey) {
            this.question = survey.question;
            this.question_type = survey.question_type;
            let options = []
            survey.answers_options.forEach(element => {
              options.push({
                value: element.answer_option,
                id: element.id
              })
            });
            this.answers_options = options;
      }
      this.$refs['modal-2'].show();
    },
    showModalDelete(id) {
        this.$refs['modal-3'].show();
        this.survey_id = id;
    },
    async createSurvey() {
      this.loadingBtn = true;
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let options = []

      this.answers_options.forEach(element => {
        options.push(
          {
            answer: element.value
          }  
        )
      });
      let data =  {
        question: this.question,
        answers_options: options,
        question_type: this.question_type,
        factories: [
          
        ]
      }

      await axios.post(process.env.VUE_APP_URL+"/api/auth/survey", data, config)
        .then(() => {
          this.closeModal()
          this.loadingBtn = false;
          this.makeToast('success', 'La encuesta ha sido creada satisfactoriamente!', '¡Encuesta creada!')
          this.getSurveys();
        })
        .catch((err) => {
          this.loadingBtn = false;
          
          console.log('>> Error while trying to create an survey.', err);
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar crear la encuesta.', 'Error fatal.')
        })
    },
    async getSurveys() {
      this.loading = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/survey`;

      await axios.get(url, config).then(data => {
        const filteredArray = data.data.filter(obj => obj.status === "active").concat(data.data.filter(obj => obj.status === "inactive"));
        this.surveys = filteredArray
        this.loading = false
      })
      
    },


    async updateSurvey() {
      this.loadingBtn = true;
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let options = []

      this.answers_options.forEach(element => {
        options.push(
          {
            answer: element.value
          }  
        )
      });
      let data =  {
        question: this.question,
        answers_options: options,
        question_type: this.question_type,
        factories: [
          
        ]
      }

      await axios.put(process.env.VUE_APP_URL+"/api/auth/survey/"+ this.survey_id, data, config)
        .then(res => {
            if (res.status === 201) {
              this.loadingBtn = false;
              this.closeModal2()
              this.getSurveys()
              this.makeToast('success', '¡La encuesta ha sido actualizada satisfactoriamente!', '¡Encuesta actualizada!')
          }
        })
        .catch((err) => {
          console.log('>> Error while trying to update an survey.', err);
          this.loadingBtn = false;
          
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar actualizar la encuesta.', 'Error fatal.')
        })
    },
    deleteSurvey() {
      this.loadingBtn = true;
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      axios.delete(process.env.VUE_APP_URL + '/api/auth/survey/' + this.survey_id , config)
        .then(() => {
          this.loadingBtn = false;
          this.closeModal3()
          this.getSurveys();
          this.makeToast('success', 'La encuesta ha sido eliminada satisfactoriamente!', '~!Encuesta eliminado!')
        })
        .catch((err) => {
          this.loadingBtn = false;
          console.log('>> Error while trying to delete an survey.', err);
          this.closeModal3()
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar eliminar la encuesta.', 'Error fatal.')
        })
    },
    closeModal3() {
      this.$refs['modal-3'].hide();
    },

    closeModal() {
      this.$refs['modal-1'].hide()
      this.question = '';
      this.question_type = 'afirmation';
      this.answers_options = [  {
            value: 'Opción 1',
            id: 1
          },
          {
            value: 'Opción 2',
            id: 2
          }];
    },

    closeModal2() {
      this.$refs['modal-2'].hide()
      this.question = '';
      this.question_type = 'afirmation';
      this.answers_options = [   {
            value: 'Opción 1',
            id: 1
          },
          {
            value: 'Opción 2',
            id: 2
          }];
    },

    checkState(field) {
      let aux = true;
      switch (field) {
        case 'question':
          aux = 0 === this.question.length ? null : false
          if (this.question.length >= 3) aux  = true
        break;
        default:
          aux = 0 === this.rut.length ? null : false
          if (this.rut.length >= 7) aux = true
          break;
      }

      return aux;
    },

    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },

    addOption () {
      this.answers_options = [
        ...this.answers_options, {
          value: `Opción ${this.answers_options.length + 1}`,
          id: `${this.answers_options.length + 1}${Date.now()}`
        }
      ]
    },

    deleteOption(id) {
      let index = this.answers_options.map(function(e) { return e.id; }).indexOf(id);
      this.answers_options.splice(index,1)
      
    },

    getFormattedDate(date) {
      if (date === '' || !date) {
        return 'No asignada'
      }
      else {
        return moment(date).format('DD/MM/YYYY')
      }
    },
  },
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.ver {
  background-color: #3379A1 !important;
  margin: 0px !important;
}

.rounded-btn {
    border-radius: 20px !important;
    padding: 3px 5px !important;
    height: 1.9em !important;
    border: 0 !important;
}

.table-dark {
  background: #263A39!important;
}

#__BVID__39 > thead:nth-child(1), #__BVID__154 > thead:nth-child(1) {
  background: #263A39!important;
}
th {
  border: 0 !important;
}

tr.odd {
  background: #F4F6F8#f8aa8949 !important;
}

.page-item.active .page-link {
  background-color: #263A39!important;
  border-color: #263A39!important;
  color: #fff !important;
}

.page-link {
  color: #263A39!important;
}
</style>
