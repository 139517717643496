import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'
import DashboardInfo from '../views/DashboardInfo.vue'
import ResetPassword from '../views/ResetPassword.vue'
import ChangePassword from '../views/ChangePassword.vue'
import NewPassword from '../views/NewPassword.vue'
// import Asana from '../views/Asana.vue'
import Dropbox from '../views/Dropbox.vue'
import Users from '../views/Users.vue'
import Surveys from '../views/Surveys.vue'
import SurveyInfo from '../views/SurveyInfo.vue'
import UserProfile from '../views/UserProfile.vue'
import Organigrama from '../views/Organigrama.vue'
import Projects from '../views/Projects.vue'
import Notices from '../views/Notices.vue'
import DetailsProject from '../views/DetailsProject.vue'
import DetailsNotice from '../views/DetailsNotice.vue'
import DetailsReconocimiento from '../views/DetailsReconocimiento.vue'
import ListProjects from '../views/ListProjects.vue'
import ListNotices from '../views/ListNotices.vue'
import Resources from '../views/Resources.vue'
import UtilsLinks from '../views/UtilsLinks.vue'
// import Hvr from '../views/Hvr.vue'
import Reconocimientos from '../views/Reconocimientos.vue'
import Statistics from '../views/Statistics.vue'
import Events from '../views/Events.vue'
import Messages from '../views/Messages.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    component: NewPassword
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
    /*{
      path: '/asana',
      props: true,
      name: 'asana',
      component: Asana
    },*/
    {
      path: '/dropbox',
      props: true,
      name: 'dropbox',
      component: Dropbox
    },
    {
      path: '/inicio',
      props: true,
      name: 'inicio',
      component: DashboardInfo
    },
    {
      path: '/usuarios',
      props: true,
      name: 'users',
      component: Users
    },
    {
      path: '/encuestas',
      props: true,
      name: 'surveys',
      component: Surveys
    },
    {
      path: '/encuesta/:id',
      props: true,
      name: 'survey-info',
      component: SurveyInfo
    },
    {
      path: '/organigrama',
      props: true,
      name: 'organigrama',
      component: Organigrama
    },
    {
      path: '/boletines',
      props: true,
      name: 'projects',
      component: Projects
    },
    {
      path: '/recursos',
      props: true,
      name: 'resources',
      component: Resources
    },
    {
      path: '/links-utiles',
      props: true,
      name: 'utils-links',
      component: UtilsLinks
    },
    {
      path: '/listado-boletines',
      props: true,
      name: 'list-projects',
      component: ListProjects
    },
    {
      path: '/listado-noticias',
      props: true,
      name: 'list-notices',
      component: ListNotices
    },
    {
      path: '/noticias',
      props: true,
      name: 'notices',
      component: Notices
    },
    {
      path: '/reconocimientos',
      props: true,
      name: 'Conoce a',
      component: Reconocimientos
    },
    {
      path: '/boletin/:id',
      props: true,
      name: 'details-project',
      component: DetailsProject
    },
    {
      path: '/noticia/:type/:id',
      props: true,
      name: 'details-notice',
      component: DetailsNotice
    },
    {
      path: '/reconocimiento/:id',
      props: true,
      name: 'details-reconocimiento',
      component: DetailsReconocimiento
    },
    {
      path: '/perfil',
      props: true,
      name: 'profile',
      component: UserProfile
    },
    /*{
      path: '/hvr',
      props: true,
      name: 'hvr',
      component: Hvr
    },*/
    {
      path: '/estadisticas',
      props: true,
      name: 'statistics',
      component: Statistics
    },
    {
      path: '/eventos',
      props: true,
      name: 'events',
      component: Events
    },
    {
      path: '/mensajes',
      props: true,
      name: 'messages',
      component: Messages
    }
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
