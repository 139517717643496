<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
         <b-row id="users" class="m-0 p-0" style="margin-bottom: 100px;">
          <b-col xs="12" sm="12">
              <b-row align-v="center" class="mb-5 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">Usuarios</h3>
                </b-col>

                <b-col 
                  :style="`display: flex; ${isMobile ? 'justify-content: center; margin-top: 10px;' : 'justify-content: end;'}`" 
                  class="p-0"
                  cols="12" sm="12" md="6">
                  <b-button 
                    type="button" 
                    class="btn ver crear"
                    @click="showModalCreate()" 
                    :style="`color: #FFFFFF; ${!isMobile ? 'width: 160px;' : 'width: 100%;'}`">
                    Crear Usuario
                  </b-button>
                </b-col>

                <b-col  cols="3" lg="3" sm="6" md="6" class="pr-1">
                  <div style="max-width: 320px; width: 100%;" class=" text-center">
                    <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Buscar por nombre:</h4>
                    <b-form-group
                      id="input-group-1"
                      label=""
                      label-for="input-1"
                      class="mb-0"
                    >
                      <b-form-input
                        id="input-1"
                        type="text"
                        @input="() => onSearchBy()"
                        v-model="searchName"
                        required
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col  cols="3" lg="3" sm="6" md="6" class="pr-1">
                  <div style="max-width: 320px;width: 100%" class=" text-center">
                    <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Buscar por planta:</h4>
                    <b-form-group
                      id="input-group-2"
                      label=""
                      label-for="input-2"
                      class="mb-0"
                    >
                      <b-form-select v-model="selected" :options="options" @change="(e) => onSearchBy()" ></b-form-select>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col  cols="3" lg="3" sm="6" md="6" class="pr-1">
                  <div style="max-width: 320px; width: 100%;" class=" text-center">
                    <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Buscar por Email:</h4>
                    <b-form-group
                      id="input-group-3"
                      label=""
                      label-for="input-3"
                      class="mb-0"
                    >
                      <b-form-input
                        id="input-3"
                        type="text"
                        @input="(e) => onSearchBy()"
                        v-model="byEmail"
                        required
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col  cols="3" lg="3" sm="6" md="6" class="pr-1">
                  <div style="max-width: 320px; width: 100%;" class=" text-center">
                    <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Buscar por apellido:</h4>
                    <b-form-group
                      id="input-group-4"
                      label=""
                      label-for="input-4"
                      class="mb-0"
                    >
                      <b-form-input
                        id="input-4"
                        type="text"
                        @input="(e) => onSearchBy()"
                        v-model="byLastName"
                        required
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>

              <!--- Tabla de usuarios --->

              <b-table
              head-variant="dark"
              class="mb-0"
              :per-page="perPage"
              id="my-table"
              :current-page="currentPage"
              :fields="[
                {
                  key: 'name',
                  label: 'Nombre',
                  thClass: 'table-dark'
                },
                {
                  key: 'lastname',
                  label: 'Apellido',
                  thClass: 'table-dark'
                },
                {
                  key: 'email',
                  label: 'Email',
                  thClass: 'table-dark'
                },
                {
                  key: 'rut',
                  label: 'RUT',
                  thClass: 'table-dark'
                },
                {
                  key: 'birthday',
                  label: 'Fecha de nacimiento',
                  thClass: 'table-dark'
                },
                {
                  key: 'created_at',
                  label: 'Fecha de creacion',
                  thClass: 'table-dark'
                },
                {
                  key: 'position',
                  label: 'Cargo',
                  thClass: 'table-dark'
                },
                {
                  key: 'plant',
                  label: 'Planta',
                  thClass: 'table-dark'
                },
                {
                  key: 'role',
                  label: 'Acciones',
                  thClass: 'table-dark'
                }
              ]"
              :stacked="isMobile"
              striped
              th-class="table-dark"
              :items="loading ? [] : users"
              >

              <template #cell(rut)="data">
                {{ data.item.rut ? data.item.rut : 'No disponible.' }}
              </template>

              <template #cell(created_at)="data">
                {{ getFormattedDate(data.item.created_at) }}
              </template>

              <template #cell(birthday)="data">
                {{ getFormattedDate(data.item.birthday) }}
              </template>

              <template #cell(plant)="data">
                {{ getPlant(data.item.factory) }}
              </template>
              
              
                <template #cell(status)>
                  Activo
                </template>

                 <template  #cell(role)="data">
                   <div style="display:flex !important;">
                    <b-button 
                      type="button" 
                      :disabled="getRole(data.item) !== 'user'" 
                      class="rounded-btn"
                      style="color: #FFFFFF; background: #f0900f no-repeat padding-box;"
                      @click="showModalEdit(data.item.id)">
                      <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                    </b-button>

                    <b-button 
                      type="button" 
                      :disabled="getRole(data.item) !== 'user'" 
                      class="rounded-btn ml-3"
                      style="background: #dc3545 no-repeat;"
                      @click="showModalDelete(data.item.id)">
                      <b-icon scale="0.8" icon="trash-fill"></b-icon>
                  </b-button>
                  </div>
                </template>
              </b-table>

              <b-pagination
                v-model="currentPage"
                v-if="users.length > 0 && !loading"
                dark
                align="right"
                :total-rows="users.length"
                :per-page="perPage"
                class="mt-3"
                aria-controls="my-table"
              >
                <template #page="{ page, active }">
                  <b v-if="active">{{ page }}</b>
                  <i v-else>{{ page }}</i>
                </template>
              </b-pagination>

              <!-- Skeleton loader --->

              <b-skeleton-table
              v-if="loading"
              :stacked="isMobile"
              :rows="7"
              :columns="7"
              :table-props="{ bordered: true, striped: true }"
            >
            </b-skeleton-table>
          </b-col>
        </b-row>

         <!-- Modal crear usuario -->
          <b-modal 
            centered 
            ref="modal-1" 
            size="md"  
            hide-footer>

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Crear usuario
              </b>
            </template>

            <b-row class="mt-4 justify-content-center">
               <b-col cols="10">
              <!-- Login Form -->
              <form id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    :state="checkState('name')"
                    v-model="name"
                    required
                  >
                  </b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Apellido"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    :state="checkState('lastname')"
                    v-model="lastname"
                    required
                  >
                  </b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El apellido debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="RUT"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    :state="checkState('rut')"
                    v-model="rut"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El RUT debe contener al menos 7 caracteres.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Fecha de nacimiento"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-mask="'##/##/####'"
                    :state="checkState('birthday')"
                    placeholder="--/--/----"
                    v-model="birthday"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    Debe ingresar una fecha de nacimiento valida
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Email"
                  label-for="input-1"
                >
                  <b-form-input
                    id="email"
                    type="text"
                    :state="checkState('email')"
                    v-model="email"
                    autocomplete="off"
                    required
                  ></b-form-input>
                   <b-form-invalid-feedback id="input-1-feedback">
                    Debe ingresar un email válido.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Contraseña"
                  label-for="input-1"
                >
                  <b-form-input
                    id="password"
                    type="text"
                    :state="checkState('password')"
                    v-model="password"
                    autocomplete="off"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                     {{ messagePassword() }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Rol"
                  label-for="input-1"
                >
                  <b-form-select v-model="rol" class="form-select">
                    <b-form-select-option value="admin">Administrador</b-form-select-option>
                    <b-form-select-option value="user">Usuario</b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Cargo"
                  class="mt-3"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="position"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Planta"
                  label-for="input-1"
                >
                <b-form-select v-model="factory" class="form-select">
                  <b-form-select-option value="las_gaviotas">Planta Las Gaviotas</b-form-select-option>
                  <b-form-select-option value="san_ignacio">Planta San Ignacio</b-form-select-option>
                  <b-form-select-option value="casa_matriz_santiago">Casa Matriz Santiago</b-form-select-option>
                  <b-form-select-option value="auricop">Auricop </b-form-select-option>
                  <b-form-select-option value="minera_cobre_verde">Minera Cobre Verde</b-form-select-option>
                  <b-form-select-option value="enami">Enami</b-form-select-option>
                </b-form-select>
                </b-form-group>
                <!--
                  <b-form-group
                    id="input-group-1"
                    label="Departamento"
                    label-for="input-1"
                  >
                    <b-form-select
                      v-model="departament"
                      class="form-select">
                      <b-form-select-option value="1">Gerencia General</b-form-select-option>
                      <b-form-select-option value="2">Unidades de Servicio</b-form-select-option> 
                      <b-form-select-option value="3">Unidades de Negocio</b-form-select-option>
                    </b-form-select>
                  </b-form-group> 
                -->
                 <b-form-group
                  id="input-group-1"
                  label="Foto"
                  label-for="input-1"
                >
                 <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo"
                    @change="obtenerImagen"
                    :browse-text="'Seleccionar'"
                    placeholder=" "
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <figure id="img_user" v-if="image" class="project_row mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                      <img width="300" height="300" :src="image" alt="Foto del producto">
                  </figure> <br>
                  </b-form-group>
              </form>
               </b-col>
             </b-row>

             <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
               <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal">
                  Cancelar
                </b-btn>
               </b-col>
               <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  :disabled="disableBtn"
                  class="outline-btn"
                  @click="createUser">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Crear" }}
                </b-btn>
               </b-col>
             </b-row>
          </b-modal>
        <!-- Modal editar usuario -->
          <b-modal ref="modal-2" size="md" centered hide-footer>

            <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Editar usuario
              </b>
            </template>
            
            <b-row class="mt-4 justify-content-center">
               <b-col cols="10" md="10" >
              <!-- Login Form -->
              <form v-on:submit.prevent="createUser" id="form_">
                <b-form-group
                  id="input-group-1"
                  label="Nombre"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    :state="checkState('name')"
                    v-model="name"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Apellido"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    :state="checkState('lastname')"
                    v-model="lastname"
                    required
                  >
                  </b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El apellido debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="RUT"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="rut"
                    required
                    :state="checkState('rut')"
                  ></b-form-input>
                   <b-form-invalid-feedback id="input-1-feedback">
                    El RUT debe contener al menos 7 caracteres.
                  </b-form-invalid-feedback>
                </b-form-group>
                 <b-form-group
                  id="input-group-1"
                  label="Fecha de nacimiento"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-mask="'##/##/####'"
                    :state="checkState('birthday')"
                    placeholder="--/--/----"
                    v-model="birthday"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    Debe ingresar una fecha de nacimiento valida
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Email"
                  label-for="input-1"
                >
                  <b-form-input
                    id="email"
                    type="text"
                    v-model="email"
                    :state="checkState('email')"
                    autocomplete="off"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-feedback">
                    Debe ingresar un email válido.
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Nueva Contraseña"
                  label-for="input-1"
                >
                  <b-form-input
                    id="password"
                    type="text"
                    v-model="password"
                    autocomplete="off"
                    :state="checkState('password')"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-feedback">
                    {{ messagePassword() }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Rol"
                  label-for="input-1"
                >
                <b-form-select v-model="rol" class="form-select">
                  <b-form-select-option value="admin">Administrador</b-form-select-option>
                  <b-form-select-option value="user">Usuario</b-form-select-option>
                </b-form-select>
                <b-form-group
                  id="input-group-1"
                  label="Cargo"
                  class="mt-3"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="position"
                    required
                  ></b-form-input>
                </b-form-group>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Planta"
                  label-for="input-1"
                >
                <b-form-select v-model="factory" class="form-select">
                  <b-form-select-option value="las_gaviotas">Planta Las Gaviotas</b-form-select-option>
                  <b-form-select-option value="san_ignacio">Planta San Ignacio</b-form-select-option>
                  <b-form-select-option value="casa_matriz_santiago">Casa Matriz Santiago</b-form-select-option>
                  <b-form-select-option value="auricop">Auricop</b-form-select-option>
                  <b-form-select-option value="minera_cobre_verde">Minera Cobre Verde</b-form-select-option>
                  <b-form-select-option value="enami">Enami</b-form-select-option>
                </b-form-select>
                </b-form-group>
                 <b-form-group
                  id="input-group-1"
                  label="Foto"
                  label-for="input-1"
                >
                 <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                  ></b-form-file>
                  <figure id="img_user" v-if="image" class="project_row mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                      <img width="300" height="300" :src="image" alt="Foto del producto">
                  </figure>
                  <div class="mt-2" style="font-size: 12px;" v-else>
                    Sin foto cargada.
                  </div>
                  </b-form-group>
              </form>
               </b-col>
             </b-row>

             <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
               <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal2">
                  Cancelar
                </b-btn>
               </b-col>
               <b-col style="display:flex; justify-content:center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  :disabled="disableBtn2"
                  class="outline-btn"
                  @click="updateUser">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Editar" }}
                </b-btn>
               </b-col>
             </b-row>
          </b-modal>

        <!-- Modal elminar usuario -->

         <b-modal 
          ref="modal-3" 
          centered
          hide-footer>
          <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Confirmación
              </b>
            </template>

          <p class="mt-3" style="text-align: center;">
            ¿Está seguro de que desea eliminar este usuario?
          </p>

           <b-row class="ma-0 mt-4 px-5 pb-3 justify-content-center">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal3">
                  Mejor no
                </b-btn>
              </b-col>

              <b-col style="display:flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  class="outline-btn"
                  @click="deleteUser">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Sí" }}
                </b-btn>
              </b-col>
          </b-row>
        </b-modal>

    </div>
</template>

<script>
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import moment from 'moment'

export default {
  name: 'Users',
  components: {

  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        users: [],
        name: '',
        lastname: '',
        email: '',
        password: '',
        rol: '',
        photo: null,
        image: '',
        position: '',
        user_id: '',
        rut: '',
        departament: 1,
        loading: true,
        isMobile: false,
        perPage: 8,
        currentPage: 1,
        loadingBtn: false,
        birthday: '',
        searchDepartament: '',
        timer:null,
        actualDate: '',
        factory: '',
        byEmail: '',
        byLastName: '',
        byFactory: '',
        searchName: '',
        selected: '',
        params: '',
        paramsFilter:[
          {name: 'name', value: '', type: 'searchName'},
          {name: 'factory', value: '', type: 'selected'},
          {name: 'email', value: '', type: 'byEmail'},
          {name: 'lastname', value: '', type: 'byLastName'}
        ],
        options: [
          { value: '', text: 'Selecciona una opción'},
          { value: 'las_gaviotas', text: 'Planta las Gaviotas' },
          { value: 'san_ignacio', text: 'Planta San Ignacio' },
          { value: 'casa_matriz_santiago', text: 'Casa Matriz Santiago' },
          { value: 'auricop', text: 'Auricop' },
          { value: 'minera_cobre_verde', text: 'Minera Cobre Verde' },
          { value: 'enami', text: 'Enami' }
        ]
      }
    },
    watch: {
    '$data': {
      handler: function() {
        this.paramsFilter[0].value = this.searchName
        this.paramsFilter[1].value = this.selected
        this.paramsFilter[2].value = this.byEmail
        this.paramsFilter[3].value = this.byLastName
      },
      deep: true
    },
    'params'(){
        this.onSearchBy()
    },
  },
  async mounted() {
    this.getUsers();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.actualDate = moment().format('YYYY-MM-DD')
    moment.locale('es')
    //this.actualDate = moment().subtract(18, 'years').format('YYYY-MM-DD')
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  computed: {
    disableBtn() {
        if(
          !this.name || !this.email ||
          !this.rut || !this.password ||
          !this.rol || !this.factory ||
          !this.position || !this.birthday || !this.lastname || 
          !this.checkDate(this.birthday) || !this.checkIfNotUnder18(this.birthday)
        ) {
          return true
        }

        return false
    },
    disableBtn2() {
        if(
          !this.name || !this.email ||
          !this.rut || !this.position || !this.factory ||
          !this.rol || !this.birthday || !this.lastname ||
          !this.checkDate(this.birthday) || !this.checkIfNotUnder18(this.birthday)
        ) {
          return true
        }

        return false
    },
    today(){
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    clearTimeout () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    onSearchBy() {
      const queryParams =  this.paramsFilter.map((key)=> {
        if(key.value !=='') {
            const parametros = '?' +  key.name +'='+ key.value.toString()+ '&' 
            return parametros
        }
      })
      const remplazo = queryParams.join().replaceAll(',', '')
      this.params = remplazo
      this.getUsers()
    },
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    showModalCreate() {
        this.name = '';
        this.lastname = '';
        this.email = '';
        this.rut = '';
        this.password = 'Cobreymetales@10';
        this.rol = '';
        this.factory = '';
        this.departament = 1;
        this.image = '';
        this.position = '';
        this.birthday = '';
        this.$refs['modal-1'].show();
      },
    showModalEdit(id) {
      const user = this.users.find((e) => { return e.id === id })
      if (user) {
            this.user_id = user.id;
            this.name = user.name;
            this.lastname = user.lastname ? user.lastname : '';
            this.rut = user.rut;
            this.email = user.email;
            
            this.factory = user.factory;
            this.rol = user.role.length > 0 ? user.role[0].name : 'Usuario'
            this.image = user.image ? process.env.VUE_APP_URL+'/storage/'+user.image : '';
            this.position = user.position;
            this.birthday = moment(user.birthday).format('DD/MM/YYYY')
            this.password = '';
      }
      this.$refs['modal-2'].show();
    },
    showModalDelete(id) {
        this.$refs['modal-3'].show();
        this.user_id = id;
    },
    createUser() {
       let formData = new FormData();
       formData.append('name', this.name);
       formData.append('lastname', this.lastname);
       formData.append('rut', this.rut);
       formData.append('email', this.email);
       formData.append('password', this.password);
       formData.append('role', this.rol);
       formData.append('factory', this.factory);
       formData.append('departament_id', 1);
       formData.append('image', this.photo);
       formData.append('position', this.position);
       formData.append('birthday', moment(this.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'));
       this.loadingBtn = true;

      axios.post(process.env.VUE_APP_URL+"/api/auth/signup", formData)
        .then(() => {
          this.closeModal()
          this.loadingBtn = false;
          this.params = ''
          this.makeToast('success', '¡El usuario ha sido creado satisfactoriamente!', '¡Usuario creado!')
          this.getUsers();
        })
        .catch((err) => {
          this.loadingBtn = false;
          
          console.log('>> Error while trying to create an user.', err);
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar crear el usuario.', 'Error fatal.')
        })
    },
    async getUsers() {
      this.loading = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };
      let url = `${process.env.VUE_APP_URL}/api/auth/all-users${this.params !== '' ? this.params : ''}`;

      await axios.get(url, config).then(data => {
        this.users = data.data
        this.loading = false
      })
    },

    getRole(user) {
      return user.role.length > 0 ? user.role[0].name : ''
    },

    updateUser() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('user_id', this.user_id);
       formData.append('name', this.name);
       formData.append('lastname', this.lastname);
       formData.append('rut', this.rut);
       formData.append('email', this.email);
       formData.append('role', this.rol);
       formData.append('factory', this.factory);
       formData.append('image', this.photo);
       formData.append('position', this.position);
       formData.append('birthday', moment(this.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD'));
       if (this.password.length > 3) formData.append('password', this.password);
       this.loadingBtn = true;

      axios.post(process.env.VUE_APP_URL+"/api/auth/update-user", formData, config)
        .then(res => {
            if (res.status === 201) {
              this.loadingBtn = false;
              this.closeModal2()
              let url = process.env.VUE_APP_URL+'/api/auth/all-users';
              axios.get(url, config).then(data => {
                this.users = data.data;
              })
              this.makeToast('success', '¡El usuario ha sido actualizado satisfactoriamente!', '¡Usuario actualizado!')
          }
        })
        .catch((err) => {
          console.log('>> Error while trying to update an user.', err);
          this.loadingBtn = false;
          
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar actualizar el usuario.', 'Error fatal.')
        })
    },
    deleteUser() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('user_id', this.user_id);
       this.loadingBtn = true;

      axios.post(process.env.VUE_APP_URL+"/api/auth/delete-user", formData, config)
        .then(() => {
          this.loadingBtn = false;
          this.params = ''
          this.closeModal3()
           let url = process.env.VUE_APP_URL+'/api/auth/all-users';
              axios.get(url, config).then(data => {
                this.users = data.data;
                 this.getUsers();
              })
          this.makeToast('success', '¡El usuario ha sido eliminado satisfactoriamente!', '¡Usuario eliminado!')
        })
        .catch((err) => {
          this.loadingBtn = false;
           console.log('>> Error while trying to delete an user.', err);
           this.closeModal3()
          this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar eliminar el usuario.', 'Error fatal.')
        })
    },
    closeModal3() {
      this.$refs['modal-3'].hide();
      this.user_id = '';
    },
    getFormattedDate(date) {
      if (date === '' || !date) {
        return 'No asignada'
      }
      else {
        return moment(date).format('DD/MM/YYYY')
      }
    },

    closeModal() {
      this.$refs['modal-1'].hide()
      this.name = '';
      this.lastname = '';
      this.rut = '';
      this.email = '';
      this.rol = '';
      this.factory = '';
      this.image = '';
      this.position = '';
      this.birthday = '';
    },

    closeModal2() {
      this.$refs['modal-2'].hide()
      this.name = '';
      this.lastname = '';
      this.rut = '';
      this.email = '';
      this.rol = '';
      this.factory = '';
      this.image = '';
      this.position = '';
      this.birthday = '';
    },

    messagePassword (){
      let message = ''
      if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/.test(this.password)) {
        message = "La contraseña debe incluir una combinación de letras mayúsculas y minúsculas, números y alguno de los siguientes caracteres ! @ # $ % ^ & *"
      }
      if (12 > this.password.length){
        message = 'La contraseña debe tener al menos 12 caracteres'
      }
      if (0 === this.password.length){
        message = ''
      }
      return message
    },

    checkState(field) {
      let aux = true;
      switch (field) {
        case 'name':
          aux = 0 === this.name.length ? null : false
          if (this.name.length >= 3) aux  = true
        break;
        case 'lastname':
          aux = 0 === this.lastname.length ? null : false
          if (this.lastname.length >= 3) aux  = true
        break;
        case 'email': 
         aux = 0 === this.email.length ? null : false
          if (
            this.email.length > 1 &&
            this.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[a-zA-Z0-9-.]+$")
          ) aux  = true
          break;
        case 'password':
          aux = true
          if (!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/.test(this.password)) {
            aux = false
          }
          if (12 > this.password.length){
            aux = false
          }
          if (0 === this.password.length){
            aux = null
          }
          break;
        case 'birthday':
          aux = 0 === this.birthday.length ? null : false
          if (this.birthday.length >= 10) aux  = true
          if (this.birthday.length) {
            aux = this.checkDate(this.birthday)
            aux = this.checkIfNotUnder18(this.birthday)
          }
          break;
        default:
          aux = 0 === this.rut.length ? null : false
          if (this.rut.length >= 7) aux = true
          break;
      }

      return aux;
    },

    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },

    checkDate (value) {
      if (value.length < 10) {
        return false
      }
      const dateFormated = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      if (dateFormated === 'Invalid date' || dateFormated === 'Fecha inválida') {
        return false
      } else {
        return true
      }
    },

    checkIfNotUnder18 (value) {
      if (value.length < 10) {
        return false
      }
      const dateFormated = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD')
      const diffDays = moment(dateFormated).diff(this.actualDate, 'days')
      if (dateFormated === 'Invalid date' || diffDays > 0 || dateFormated === 'Fecha inválida') {
        return false
      } else {
        return true
      }
    },

    getPlant (value) {
      if (value === 'las_gaviotas'){
        return 'Planta Las Gaviotas'
      }
      if (value === 'san_ignacio'){
        return 'Planta San Ignacio'
      }
      if (value === 'casa_matriz_santiago'){
        return 'Casa Matriz Santiago'
      }
      if (value === 'auricop'){
        return 'Auricop'
      }
      if (value === 'minera_cobre_verde'){
        return 'Minera Cobre Verde'
      }
      if (value === 'enami'){
        return 'Enami'
      }
      return 'No tiene planta asignada'
    },
  },
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.ver {
  background-color: #3379A1 !important;
  margin: 0px !important;
}

.rounded-btn {
    border-radius: 20px !important;
    padding: 3px 5px !important;
    height: 1.9em !important;
    border: 0 !important;
}

.table-dark {
  background: #263A39!important;
}

#__BVID__39 > thead:nth-child(1), #__BVID__154 > thead:nth-child(1) {
  background: #263A39!important;
}
th {
  border: 0 !important;
}

tr.odd {
  background: #F4F6F8#f8aa8949 !important;
}

.page-item.active .page-link {
  background-color: #263A39!important;
  border-color: #263A39!important;
  color: #fff !important;
}

.page-link {
  color: #263A39!important;
}
</style>
