<template>
    <div :class="{
      'mx-0 px-2' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
        <b-row id="users" style="margin-bottom: 50px;" class="justify-content-center">
          <b-col xs="12" sm="12" md="12" class="pt-4 fix-things-calendar">
              <h3 class="mb-3" :style="`padding:0; font-size: 22px;`">Comentarios:</h3>
              <h4 v-if="comments.length < 1 && loadedFirstTime" class="mb-5" style="text-align: center;">No hay comentarios para mostrar</h4>
              <div v-for="comment in comments" :key="comment.id" class="p-md-3 p-2 mb-4" style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 8px; background-color:#EBF0F4">
                <div class="d-flex">
                  <b-avatar variant="warning" v-if="comment.user.image === '' || !comment.user.image" :text="$showInitials(comment.user.name, comment.user.lastname)" size="3.5rem"></b-avatar>
                  <b-avatar variant="warning" v-else :src="setImage(comment.user.image)" size="3.5rem"></b-avatar>
                  <div class="ml-2 d-flex flex-column justify-content-center" >
                      <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                          Comentado el: {{ formatDate(comment.created_at) }}
                      </div>
                      <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                          {{ `${comment.user.name} ${comment.user.lastname ? comment.user.lastname : ''}` }}
                      </div>
                      <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                          {{ comment.user.position }}
                      </div>
                  </div>
              </div>
              <p v-if="!comment.isEditing" class="mt-2 p-message-birthday mb-1">
                {{comment.comment}}
              </p>
              <div v-if="comment.user.id === userId && !comment.isEditing" class="d-flex justify-content-end">
                <b-button 
                    type="button" 
                    class="rounded-btn ml-2"
                    style="color: #FFFFFF; background: #f0900f no-repeat padding-box;"
                    @click="comment.isEditing = !comment.isEditing">
                    <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                </b-button>
                <b-button 
                    type="button" 
                    class="rounded-btn ml-2"
                    style="background: #dc3545 no-repeat;"
                    @click="showModalDelete(comment.id)">
                    <b-icon scale="0.8" icon="trash-fill"></b-icon>
                </b-button>
              </div>
              <div v-if="comment.user.id !== userId && !comment.isEditing && rol === 'admin'" class="d-flex justify-content-end">
                <b-button 
                    type="button" 
                    class="rounded-btn ml-2"
                    style="background: #dc3545 no-repeat;"
                    @click="showModalDelete(comment.id)">
                    <b-icon scale="0.8" icon="trash-fill"></b-icon>
                </b-button>
              </div>
              <div v-if="comment.user.id === userId && comment.isEditing" class="d-flex flex-column align-items-end mt-2" style="position:relative">
                    <b-form-group
                        id="input-group-1"
                        label=""
                        label-for="input-1"
                        style="width:100%"
                    >
                        <b-form-textarea
                            id="textarea"
                            v-model="comment.comment"
                            rows="2"
                            max-rows="5"
                            maxlength="200"
                        ></b-form-textarea>
                    </b-form-group>
                    <div  class="d-flex justify-content-end">
                        <b-btn 
                            type="button" 
                            class="btn-ver mr-2"
                            size="lg"
                            style="min-height: 32px; width: 100px; font-size: 15px !important;"
                            :disabled="loadingEditComment"
                            @click="comment.isEditing = !comment.isEditing">
                            Cancelar
                        </b-btn>
                        <b-btn 
                            type="button" 
                            class="btn-ver"
                            size="lg"
                            style="min-height: 32px; width: 100px; font-size: 15px !important;"
                            @click="editComment(comment.comment, comment.id)"
                            :disabled="comment.comment === ''">
                            <span v-if="loadingEditComment" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                            {{ loadingEditComment ? "" : "Editar" }}
                        </b-btn>
                    </div>
                </div>
            </div>
            <div v-if="loadedFirstTime" class="p-md-3 p-2 mb-4" style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 8px; background-color:#FFFFFF;position:relative">
                <div class="d-flex flex-column align-items-end" style="position:relative">
                    <div style="text-align:left;font-size:16px;line-height: 16px;font-weight:500" class="mb-3" id="send-coment-id">Envia un comentario</div>
                    <b-form-group
                        id="input-group-1"
                        label=""
                        label-for="input-1"
                        style="width:100%"
                    >
                        <b-form-textarea
                            id="textarea"
                            v-model="comment"
                            rows="2"
                            max-rows="5"
                            maxlength="200"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-btn 
                        type="button" 
                        class="btn-ver"
                        size="lg"
                        style="min-height: 32px; width: 100px; font-size: 15px !important;"
                        @click="postComment()"
                        :loading="loadingComment"
                        :disabled="comment === ''">
                        Enviar
                    </b-btn>
                </div>
                <template v-if="loadingComment">
                    <div class="d-flex justify-content-center align-items-center div-loading-modal-comments">
                        <b-spinner ></b-spinner>
                    </div>
                </template>
            </div>
            <template v-if="loadingComments && !loadedFirstTime">
              <div class="d-flex justify-content-center align-content-center pt-4 pb-4" style="width:100%">
                <b-spinner ></b-spinner>
              </div>
            </template>
          </b-col>
        </b-row>

        <!-- Modal elminar comentario -->
        <b-modal 
          ref="modal-delete" 
          centered
          hide-footer>
          <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Confirmación
              </b>
            </template>

          <p class="mt-3" style="text-align: center;">
            ¿Está seguro de que desea eliminar este comentario?
          </p>

           <b-row class="ma-0 mt-4 px-5 pb-3 justify-content-center">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModalDelete">
                  Mejor no
                </b-btn>
              </b-col>

              <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  class="outline-btn"
                  @click="deleteCommentModal">
                  <span v-if="loadingBtn" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loadingBtn ? "" : "Sí" }}
                </b-btn>
              </b-col>
          </b-row>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'Comments',
  props:{
    module:{
        type: String,
        default: 'noticias' // 'noticias', 'boletines', 'reconocimientos'
    },
    idModule:{
        type: String,
        default: '0'
    }
  },
  data() {
      return {
        isMobile: false,
        isMd: false,
        dataUser: [ { id: 0 } ],
        comments: [],
        urlImgBack: process.env.VUE_APP_URL+'/storage/',
        loadingComments:true,
        loadedFirstTime:false,
        loadingComment:false,
        comment: '',
        userId: '',
        loadingEditComment: false,
        deleteId: '',
        loadingBtn: false,
        rol:'user'
      }
    },
  mounted() {
    this.rol = (localStorage.userRol)
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.dataUser = JSON.parse(localStorage.userData)
    this.userId = this.dataUser[0].id
    Promise.all([ 
        this.getAllComments()
    ]).then(values=>{
        console.log(values)
    })
    this.initDataQuery()
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    initDataQuery() {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      let comentopen = params.get('comentopen');
     
      if (!comentopen) {
        return;
      }

      this.scrollToComment() 
      
      const urlActual = new URL(window.location.href);
      // Obtener los query params
      const queryParams = new URLSearchParams(urlActual.search);

      queryParams.delete('comentopen');
    },
    scrollToComment() {
      var target = document.querySelector('#send-coment-id'); // obtiene el componente
      if (!target) {
        setTimeout(() => {
          this.scrollToComment()
        }, 50);
        return
      }
      console.log(target,'3')
      target.scrollIntoView({ behavior: 'smooth' }); // hace scroll suave hasta el componente
    },
    onResize () {
      this.isMobile = window.innerWidth < 1200
      this.isMd = window.innerWidth > 770 && window.innerWidth < 1000
    },
    formatDateToWord(date) {
      return date ? moment(date).locale('es').format('DD [de] MMMM') : ''
    },
    formatDate(date){
      if (date === '' || !date) {
        return 'No asignada'
      }
      else {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    setImage(url) {
      return url ? this.urlImgBack + url : ''
    },
    getAllComments() {
      this.loadingComments = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/get-comments/${this.module}/${this.idModule}`;

      axios.get(url, config).then(async (data) => {
        data.data.data.forEach(element => {
            element.isEditing = false
        })
        this.comments = data.data.data
        this.loadingComments = false
        this.loadedFirstTime = true
        console.log(data)
      }).catch(error => {
        this.loadingComments = false
        this.loadedFirstTime = true
        console.log(error)
      })
    },

    postComment() {
        this.loadingComment = true
        if (this.comment === '' || !this.comment) {
            return
        }

        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

        let url = `${process.env.VUE_APP_URL}/api/auth/store-comments`;
        
        let formData = new FormData();
        formData.append('user_id', this.dataUser[0].id);
        formData.append('module_id', this.idModule);
        formData.append('comment', this.comment);
        formData.append('module', this.module);

        axios.post(url, formData, config).then(async (data) => {
            console.log(data)
            this.loadingComment = false
            this.comment = ''
            this.makeToast('success', '¡El comentario se ha enviado satisfactoriamente!', '¡Comentario publicado!')
            this.getAllComments()
        }).catch(error => {
            console.log(error)
            this.loadingComment = false
            this.makeToast('danger', 'Ha ocurrido un error al enviar el comentario', 'Error fatal.')
        })
    },

    editComment(newContent, idComment) {
        this.loadingEditComment = true
        if (newContent === '' || !newContent) {
            return
        }

        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

        let url = `${process.env.VUE_APP_URL}/api/auth/update-comment/${idComment}`;
        
        /*let formData = new FormData();
        formData.append('comment', newContent);*/

        let formData = {
          comment: newContent
        }

        axios.put(url, formData, config).then(async (data) => {
            console.log(data)
            this.loadingEditComment = false
            this.makeToast('success', '¡El comentario se ha editado satisfactoriamente!', '¡Comentario editado!')
            this.getAllComments()
        }).catch(error => {
            console.log(error)
            this.loadingEditComment = false
            this.makeToast('danger', 'Ha ocurrido un error al editar el comentario', 'Error fatal.')
        })
    },

    showModalDelete(id) {
        this.$refs['modal-delete'].show();
        this.deleteId = id;
    },

    closeModalDelete() {
      this.$refs['modal-delete'].hide();
      this.deleteId = '';
    },

    deleteCommentModal(){
      if ( this.rol !== 'admin'){
        this.deleteComment()
      } else {
        this.deleteCommentAdmin()
      }
    },

    deleteComment() {
        this.loadingBtn = true

        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

        let url = `${process.env.VUE_APP_URL}/api/auth/delete-comment/${this.deleteId}/${this.userId}`;
    
        axios.delete(url, config).then(async (data) => {
            console.log(data)
            this.loadingBtn = false
            let index = this.comments.map(function(e) { return e.id; }).indexOf(this.deleteId);
            this.comments.splice(index,1)
            this.closeModalDelete()
            this.makeToast('success', '¡El comentario se ha eliminado satisfactoriamente!', '¡Comentario eliminado!')
            this.getAllComments()
        }).catch(error => {
            console.log(error)
            this.loadingBtn = false
            this.closeModalDelete()
            this.makeToast('danger', 'Ha ocurrido un error al eliminar el comentario', 'Error fatal.')
        })
    },

    deleteCommentAdmin() {
        this.loadingBtn = true

        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

        let url = `${process.env.VUE_APP_URL}/api/auth/delete-comment-admin/${this.deleteId}`;
    
        axios.delete(url, config).then(async (data) => {
            console.log(data)
            this.loadingBtn = false
            let index = this.comments.map(function(e) { return e.id; }).indexOf(this.deleteId);
            this.comments.splice(index,1)
            this.closeModalDelete()
            this.makeToast('success', '¡El comentario se ha eliminado satisfactoriamente!', '¡Comentario eliminado!')
            this.getAllComments()
        }).catch(error => {
            console.log(error)
            this.loadingBtn = false
            this.closeModalDelete()
            this.makeToast('danger', 'Ha ocurrido un error al eliminar el comentario', 'Error fatal.')
        })
    },

    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },
  }
}
</script>

<style>
    .div-loading-modal-comments{
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color:#ebf0f400
    }
</style>

