<template>
  <div>
    <div style="overflow-y: hidden !important;" :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }" class="show-desktop" v-if="pc">
    <b-row style="margin-top:30px;">
      <b-col :class="{ 'pr-3' : !isMobile }" cols="12" md="6" :lg="4" >
        <BirthDaysAndEvents/>
        <Survey class="mt-4" />
        <Links class="mt-4 mb-5" />
      </b-col>
      <b-col :class="{ 'pl-4' : !isMobile }" cols="12" md="6" :lg="4">
        <b-card border-variant="white" style="contain: content;  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px; " :style="`${isMobile ? 'margin-bottom: 80px !important;' : ''} height: ${isMobile ? 95 : 95}% `">
            <div class="shadow-none mx-0 mt-2 mb-3 d-flex flex-row justify-content-between">
              <h4 class="pt-2" style="font-weight: 500 !important; font-size: 18px !important;">
                <img style="width: 37px; margin-top: -10px;" src="../assets/Boletin.svg" alt="Boletin"/> 
                <span class="ml-2">Boletín</span>
              </h4>

              <div @click="$router.push('/listado-boletines')" style="width:30px; height: 27px; margin-top: 7px; cursor: pointer;">
                <b-icon icon="three-dots-vertical"></b-icon>
              </div>
            </div>

            <b-card-body style="height: 90%;" class="px-0">
                <Proyectos class="media-proyects" :style="`height: 100% !important; 
                  contain: content;
                  left: 4%;
                  overflow: hidden;
                  position: ${!isMobile ? 'absolute' : ''};`" />
            </b-card-body>
        </b-card>
      </b-col>
      <b-col :class="{ 'pl-4' : !isMobile && !isMd }" cols="12" md="12" :lg="4">
        <Noticias :dashVr="true" />
        <Reconocimientos  class="mt-4"/>
        <!-- <Encuestas /> -->
      </b-col>
    </b-row>
    </div>
    <div style="overflow-y: hidden !important;" :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }" class="show-mobile" v-if="!pc">
    <b-row style="margin-top:30px;">
      <b-col :class="{ 'pr-3' : !isMobile }" cols="12" md="6" :lg="4" >
        <Survey class="mb-5" />
      </b-col>
      <b-col :class="{ 'pl-4' : !isMobile }" cols="12" md="6" :lg="4">
        <b-card border-variant="white" style="contain: content;  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px; " :style="`${isMobile ? 'margin-bottom: 80px !important;' : ''} height: ${isMobile ? 95 : 100}% `">
            <div class="shadow-none mx-0 mt-2 mb-3 d-flex flex-row justify-content-between">
              <h4 class="pt-2" style="font-weight: 500 !important; font-size: 18px !important;">
                <img style="width: 37px; margin-top: -10px;" src="../assets/Boletin.svg" alt="Boletin"/> 
                <span class="ml-2">Boletín</span>
              </h4>

              <div @click="$router.push('/listado-boletines')" style="width:30px; height: 27px; margin-top: 7px; cursor: pointer;">
                <b-icon icon="three-dots-vertical"></b-icon>
              </div>
            </div>

            <b-card-body style="height: 90%;" class="px-0">
                <Proyectos class="media-proyects" :style="`height: 100% !important; 
                  contain: content;
                  left: 4%;
                  overflow: hidden;
                  position: ${!isMobile ? 'absolute' : ''};`" />
            </b-card-body>
        </b-card>
      </b-col>
      <b-col :class="{ 'pl-4' : !isMobile && !isMd }" class="mt-0" cols="12" md="12" :lg="4">
        <Noticias :dashVr="true" class="mb-2" />
        <Reconocimientos  class="mt-5"/>
        <!-- <Encuestas /> -->
      </b-col>
      <b-col :class="{ 'pr-3' : !isMobile }" cols="12" md="6" :lg="4" >
        <BirthDaysAndEvents class="mt-5 mb-5"/>
        <Links class="mt-4 mb-5" />
      </b-col>
    </b-row>
    </div>
  </div>
</template>

<script>
import Proyectos from '@/components/Dashboard/ProyectosScroll.vue';
import Noticias from '@/components/Dashboard/Noticias.vue';
import Links from '@/components/Dashboard/Links.vue';
import Survey from '@/components/Dashboard/Survey.vue';
import Reconocimientos from '@/components/Dashboard/Reconocimientos.vue';
import BirthDaysAndEvents from '@/components/Dashboard/BirthDaysAndEvents.vue';

export default {
  name: 'DashboardInfo',
  components: {
    Proyectos,
    Noticias,
    Links,
    Reconocimientos,
    BirthDaysAndEvents,
    Survey
  },
  data () {
    return {
      isMobile: false,
      isMd: false,
      isLg: false,
      hasAsanaProjects: false,
      hasNotification: false,
      pc: true
    }
  },
  mounted() {
    this.onResize()
    if(!localStorage.token) {
        this.$router.push('/');
    }
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    onNotification() {
      this.hasNotification = true
    },
    onResize () {
      this.pc = window.innerWidth > 767
      this.isMobile = window.innerWidth < 770
      this.isMd = window.innerWidth > 770 && window.innerWidth < 1000
      this.isLg = 1000 < window.innerWidth && window.innerWidth < 1290;
    },
  }
}
</script>

<style>
  .media-proyects{
    width: 100%;
  }
  .show-desktop{
      display: none;
    }
    .show-mobile{
      display: block;
    }
  @media (min-width: 768px) {
    .media-proyects{
      width: 90%;
    }
    .show-desktop{
      display: block;
    }
    .show-mobile{
      display: none;
    }
  }
</style>