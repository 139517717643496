<template>
  <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
    <b-row>
      <b-col xs="12">
        <b-row align-v="center" class="mb-5 mt-4 px-0 mx-0">
          <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
            <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">
              Conoce a
            </h3>
          </b-col>

          <b-col 
            :style="`display: flex; ${isMobile ? 'justify-content: center; margin-top: 10px;' : 'justify-content: end;'}`" 
            xs="12" sm="12" md="6">
            <b-button 
              type="button" 
              v-if="rol === 'admin'"
              class="btn ver crear"
              @click="showModalCreate()" 
              :style="`color: #FFFFFF; ${!isMobile ? 'width: 160px;' : 'width: 100%;'}`">
              Crear Conoce a
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- CARDS -->
    <div v-if="!loading.get" id="mosaico" class="mt-2" >
      <b-row style="margin: 0; padding-bottom: 10px;">
        <b-col v-for="(reconocimiento, index) in reconocimientos" :key="index" cols="12" md="6" lg="4" xl="3">
          <b-card class="reconocimientos_row mt-2 mb-2 mt-4 mb-2" style="min-height: 24.8em;border-radius: 12px;" no-body img-top>
            <b-img style="border-radius: 12px 12px 0px 0px !important;" :src="url_img + reconocimiento.picture" class="projec_row img-reconocimientos-card">
          </b-img>
          <b-card-body style="line-height: 0.9em !important;" class="px-3 px-0 text-left">
            <h4 class="mb-3">
              {{ `Empleado: ${reconocimiento.name}` }}
            </h4>

              <p class="mb-0" style="font-size: 12px;">
                <strong>
                  Descripcion:
                </strong> 
                {{
                  getPlainAndTruncateText(reconocimiento.description)
                }}
              </p>
              </b-card-body>
              <b-card-body class="px-3 text-left mt-0" >
              <span style="font-size: 12px;">
                <strong>Creado el:</strong> {{ getFormattedDate(reconocimiento.created_at) }}
              </span>
          </b-card-body>

          <template #footer>
              <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center">
                <b-button
                    type="button"
                    :disabled="rol === 'user'"
                    v-show="rol === 'admin'"
                    class="rounded-btn ml-3"
                    style="color: #FFFFFF; background: #f0900f no-repeat padding-box;"
                    @click="showModalEdit(reconocimiento.id)">
                  <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                </b-button>
                <b-button
                    type="button"
                    v-show="rol === 'admin'"
                    class="rounded-btn ml-3"
                    style="background: #dc3545 no-repeat;"
                    @click="showModalDelete(reconocimiento.id)">
                  <b-icon scale="0.8" icon="trash-fill"></b-icon>
                </b-button>
                <b-button
                    type="button"
                    class="rounded-btn ml-3"
                    style="background: #5f7d91 no-repeat;"
                    :href="'/reconocimiento/'+reconocimiento.id">
                  <b-icon scale="0.8" icon="eye-fill"></b-icon>
                </b-button>
              </b-card-body>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- SKELETON LOADERS -->
    <div v-else>
      <b-row style="margin: 0; padding-bottom: 10px;">
        <b-col style="" v-for="item in 5" :key="item" cols="12" md="6" lg="4" xl="3">
          <b-card class="reconocimientos_row mt-2 mb-2 mt-4 mb-2" style="min-height: 24.8em; border-radius: 12px;" no-body img-top>
          <b-skeleton-img card-img="top" aspect="2:1"></b-skeleton-img>
          <b-card-body class="mx-2 px-0">
            <b-skeleton width="80%"></b-skeleton>

            <b-skeleton class="mt-4" animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>

            <b-skeleton animation="wave" class="mt-4" width="70%"></b-skeleton>
          </b-card-body>

          <template #footer>
              <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center">
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
              </b-card-body>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div v-if="!loading.get && reconocimientos.length < 1">
      <h5 style="font-weight: 400 !important; opacity: .35;">
        No se encontraron reconocimientos para mostrar.
      </h5>
    </div>

    <!-- Modal crear reconocimiento -->
    <b-modal
        id="modal-crear-reconocimiento"
        ref="modal-crear-reconocimiento"
        size="lg"
        centered
        hide-footer >

      <template #modal-title>
        <b class="ml-1 ml-md-4" style="color:#45bba9">
          Crear Conoce a
        </b>
      </template>

      <b-row class="mt-4 mx-0 px-5 justify-content-center">
        <b-col class="m-0 p-0" cols="12">
          <form v-on:submit.prevent="createReco" id="form_crear_reconocimiento">
           <b-form-group
                  id="input-group-1"
                  label="Empleado"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    :state="checkState('name')"
                    :max="255"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre de empleado debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
            
            <b-form-group
                id="input-group-1"
                label="Descripción"
                label-for="input-1"
            >
            <vue-editor v-model="description"></vue-editor>
            <span class="text-danger" style="font-size:12.8px" v-if="showDescriptionAlert">
              La descripción debe contener al menos 10 caracteres.
            </span>
            </b-form-group>
             <b-form-group
                  id="input-group-1"
                  label="Foto"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo"
                    name="photo1"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure v-if="image" class="reconocimientos_row mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img width="300"  :src="image" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
            <figure class="reconocimientos_row mx-auto mt-4 mb-0" style="width: 40% !important; display:flex;">
              <img v-if="image_user !== ''" width="200" height="200"  style="border-radius: 80px;" :src="image_user">
              <img v-if="user && !image_user" width="80" height="100" style="border-radius: 80px;" src="@/assets/picture.png" alt="Foto del producto">
            </figure> 
          </form>
        </b-col>
      </b-row>

      <b-row class="mx-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
          <b-btn
              block
              variant="outline-danger"
              @click="closeModal('modal-crear-reconocimiento')">
            Cancelar
          </b-btn>
        </b-col>
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1">
            <b-btn
              block
              :disabled="blockBtn || showDescriptionAlert"
              class="outline-btn"
              @click="createReco">
              <span v-if="loading.create" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
              {{ loading.create ? "" : "Crear" }}
            </b-btn>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal editar reconocimiento -->
    <b-modal
        id="modal-editar-reconocimiento"
        ref="modal-editar-reconocimiento"
        size="lg"
        centered
        hide-footer >

      <template #modal-title>
        <b class="ml-1 ml-md-4" style="color:#45bba9">
          Editar Conoce a
        </b>
      </template>

      <b-row class="mt-4 mx-0 px-5 justify-content-center">
        <b-col class="m-0 p-0" cols="12">
          <form v-on:submit.prevent="updateReco" id="form_crear_reconocimiento">
           <b-form-group
                  id="input-group-1"
                  label="Empleado"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="name"
                    :state="checkState('name')"
                    :max="255"
                    required
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-1-feedback">
                    El nombre de empleado debe contener al menos 3 letras.
                  </b-form-invalid-feedback>
                </b-form-group>
            <b-form-group
                id="input-group-1"
                label="Descripción"
                label-for="input-1"
            >
              <vue-editor v-model="description"></vue-editor>
              <span class="text-danger" style="font-size:12.8px" v-if="showDescriptionAlert">
                La descripción debe contener al menos 10 caracteres.
              </span>
            </b-form-group>

            <figure class="reconocimientos_row mx-auto mt-4 mb-0" style="width: 40% !important; display:flex;">
              <img v-if="image_user && !image" width="200" style="border-radius: 80px;" :src="image_user">
              <img v-if="!image_user && !image" width="80" style="border-radius: 80px;" src="@/assets/picture.png" alt="Foto del producto">
            </figure>

             <b-form-group
                  id="input-group-1"
                  label="Foto"
                  label-for="input-1"
                >
                  <b-form-file class="form-control-file"
                    accept="image/jpeg, image/png"
                    v-model="photo"
                    name="photo1"
                    @change="obtenerImagen"
                    placeholder=" "
                    :browse-text="'Seleccionar'"
                    drop-placeholder="Drop file here..."
                    >
                  </b-form-file>
                  <figure v-if="image" class="reconocimientos_row mx-auto mt-4 mb-0" style="width:80% !important; display:flex;">
                    <img width="300"  :src="image" alt="Foto del producto">
                  </figure> 
                  <br>
                </b-form-group>
          </form>
        </b-col>
      </b-row>

      <b-row class="mx-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
          <b-btn
              block
              variant="outline-danger"
              @click="closeModal('modal-editar-reconocimiento')">
            Cancelar
          </b-btn>
        </b-col>
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1">
            <b-btn
              block
              :disabled="blockBtnEdit || showDescriptionAlert"
              class="outline-btn"
              @click="updateReco">
              <span v-if="loading.update" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
              {{ loading.update ? "" : "Actualizar" }}
            </b-btn>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal elminar reconocimiento -->
      <b-modal 
          ref="modal-eliminar-reconocimiento"
          centered
          hide-footer>
          <template #modal-title>
              <b class="ml-1 ml-md-4" style="color:#45bba9">
              Confirmación
              </b>
            </template>

          <p class="mt-3" style="text-align: center;">
            ¿Está seguro de que desea eliminar este reconocimiento?
          </p>

           <b-row class="ma-0 mt-4 px-5 pb-3 justify-content-center">
              <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
                 <b-btn
                  block
                  variant="outline-danger"
                  @click="closeModal3">
                  Mejor no
                </b-btn>
              </b-col>

              <b-col style="display: flex; justify-content: center;" cols="12" md="4" class="px-0 mx-0 mx-md-1">
                 <b-btn
                  block
                  class="outline-btn"
                  @click="deleteReconocimiento">
                  <span v-if="loading.delete" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                  {{ loading.delete ? "" : "Sí" }}
                </b-btn>
              </b-col>
          </b-row>
      </b-modal>

      <b-modal 
        v-model="dialog"
        id="modal-center" 
        centered
        size="lg"
        hide-footer>

        <template #modal-title>
          <b style="color:#45bba9">
            Detalle de reconocimiento
          </b>
        </template>

        <b-card 
          v-if="user"
          class="ma-0 pa-0"
          :header="user.name">
                    
          <b-card-text class="pt-2" style="height:28em;" v-if="user.picture">
            <b-card-img
              :src="url_img + user.picture" 
              alt="user_pic"
              style="height:28em;object-fit: cover;"
              class="img">
            </b-card-img>
          </b-card-text>

            <article style="max-height: 400px; overflow-y: scroll; font-size: 15px;" class="pt-1 px-2 pb-3 scroll">
              <div v-html="user.description"></div>
            </article>
          </b-card>
        </b-modal>
  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment'
import { VueEditor } from "vue2-editor";

export default {
  name: 'Resources2',
  components: {
    VueEditor
  },
  data() {
    return {
      url_img: process.env.VUE_APP_URL+'/storage/',
      projects: [],
      reconocimientos: [],
      users: [],
      image_user: '',
      original_projects: [],
      search: null,
      rol: null,
      mosaic: true,
      name: '',
      description: '',
      showDescriptionAlert:false,
      photo: null,
      image: '',
      position: '',
      dialog: false,
      reconocimiento_id: '',
      text: '',
      link: '',
      user: null,
      isMobile: false,
      loading: {
        get: true,
        create: false,
        update: false,
        delete: false
      }
    }
  },
  mounted() {
    this.rol = (localStorage.userRol);
    this.getReconocimientos();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  computed: {
    blockBtn() {
      return !this.description || !this.name || !this.photo ? true : false
    },
    blockBtnEdit() {
      return !this.description || !this.name ? true : false
    }
  },
  watch:{
    description(){
      let plainDesc = this.description.replace(/<[^>]*>/g, '');
      if (plainDesc.length < 10){
        this.showDescriptionAlert = true;
      }
      else {
        this.showDescriptionAlert = false;
      }
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    showList() {
      this.mosaic = false;
    },
    showMosaic() {
      this.mosaic = true;
    },
    setUserImage() {
        this.image_user = this.users.find(el => el.id === this.user);

        if(this.image_user.image !== "") {
            this.image_user = +this.image_user.image;
        } else {
            this.image_user = "";
        }
        
    },
    obtenerImagen(e){
      let file = e.target.files[0];
      let name = e.target.name;
      this.photo = file;
      this.cargarImagen(file, name.slice(-1));
    },
    cargarImagen(file){
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      }
      reader.readAsDataURL(file);
    },
    getFormattedDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a')
    },
    createReco() {
      this.loading.create = true
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('description', this.description);
      formData.append('picture', this.photo);

      axios.post(process.env.VUE_APP_URL+"/api/auth/reconocimientos", formData, config)
          .then(() => {
            this.closeModal('modal-crear-reconocimiento')
            this.loading.create = false;
            this.makeToast('success', '¡El reconocimiento se ha creado satisfactoriamente!', '¡Reconocimiento creado!')
            this.getReconocimientos();
          })
          .catch((err) => {
            this.loading.create = false;
            console.log('>> Error while trying to create a new recognition.', err);
            this.closeModal('modal-crear-reconocimiento')
            this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar crear el reconocimiento.', 'Error fatal.')
          })
    },
    closeModal(modal) {
      this.$refs[modal].hide()
      this.reset()
    },
    reset()
    {
      this.description = '';
      this.name= ''
      this.image_user = ''
      this.photo = ''
      this.showDescriptionAlert = false
    },
    getReconocimientos() {

      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/reconocimientos';

      axios.get(url, config).then(data => {
        this.reconocimientos = data.data
        this.loading.get = false
      })

    },

    deleteReconocimiento() {
      this.loading.delete = true;
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let formData = new FormData();
      formData.append('_method', 'DELETE');

      axios.post(process.env.VUE_APP_URL+"/api/auth/reconocimientos/"+this.reconocimiento_id, formData, config)
          .then(() => {
            this.closeModal3()
            this.loading.delete = false;
            this.makeToast('success', '¡El reconocimiento se ha eliminado satisfactoriamente!', '¡Reconocimiento eliminado!')
            this.getReconocimientos();
          })
          .catch((err) => {
            this.loading.delete = false;
            console.log('>> Error while trying to delete a recognition.', err);
            this.closeModal3()
            this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar eliminar el reconocimiento.', 'Error fatal.')
          })
    },
    updateReco() {

      this.loading.update = true

      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('name', this.name);
      formData.append('description', this.description);
      formData.append('picture', this.photo);


      axios.post(process.env.VUE_APP_URL+"/api/auth/reconocimientos/"+this.reconocimiento_id, formData, config)
          .then(() => {
            this.closeModal('modal-editar-reconocimiento')
            this.loading.update = false
            this.makeToast('success', '¡El reconocimiento se ha actualizado satisfactoriamente!', '¡Reconocimiento actualizado!')
            this.getReconocimientos();
          })
          .catch((err) => {
            this.loading.update = false;
            console.log('>> Error while trying to update a recognition.', err);
            this.closeModal('modal-editar-reconocimiento')
            this.makeToast('danger', 'Ha ocurrido un error inesperado al intentar actualizar el reconocimiento.', 'Error fatal.')
          })
    },
    showModalDelete(id) {
      this.$refs['modal-eliminar-reconocimiento'].show();
      this.reconocimiento_id = id;
    },
    showModalEdit(id) {
      const resource = this.reconocimientos.find((e) => { return e.id === id })
      if (resource) {
        this.reconocimiento_id = resource.id
        this.description = resource.description;
        this.name = resource.name;
        this.image_user = process.env.VUE_APP_URL+'/storage/'+resource.picture;
        this.showDescriptionAlert = false
      }
      this.$refs['modal-editar-reconocimiento'].show();
    },
    closeModal3() {
      this.$refs['modal-eliminar-reconocimiento'].hide();
      this.reconocimiento_id = '';
    },
    searchTable() {
      var key = String(this.search).toLowerCase();
      var response = [];
      this.projects = this.original_projects;

      if(key !== "") {
        response = [];
        for(let project of this.projects) {
          for(let value of Object.values(project)) {
            if(String(value).toLowerCase().search(key) >= 0) {
              response.push(project);
              break;
            }
          }
        }
        console.log('response', response);
        this.projects = response;
      } else {
        this.projects = this.original_projects;
      }
    },
    showModalCreate() {
      this.reset()
      this.position = '';
      this.$refs['modal-crear-reconocimiento'].show();
    },

    getPlainAndTruncateText(text) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();

      if(content.length>90) {
          content = content.substring(0, 90);
          content = content + ('(...)');
      }
      return content;
    },

    checkState(field) {
      let aux = true;
      switch (field) {
        case 'name':
          aux = 0 === this.name.length ? null : false
          if (this.name.length >= 3) aux  = true
          break;
        default:
          aux = 0 === this.description.length ? null : false
          if (this.description.length >= 10) aux = true
          break;
      }

      return aux;
    },

    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },
    showDetailDialog(user) {
      this.user = user
      this.dialog = true
    }
  },
}
</script>

<style scoped>

.ver {
  background-color: #3379A1 !important;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
}

.reconocimientos_row img{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.4 !important;
  /*object-fit: cover;
  object-position: center;*/
}

.card-img, .card-img-top {
   border-radius: 11px 11px 0px 0px !important;
}

.card-footer:last-child {
  border-radius: 0px 0px 12px 12px !important;
}

.img-reconocimientos-card{
  aspect-ratio: 2.5 !important;
}

</style>
