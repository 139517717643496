<template>
    <div style="height:100% !important; overflow-y: auto !important; overflow-x: hidden;">
        <b-row style="height:100% !important;" class="m-0 p-0">
            <b-col
                :cols="menuCols"
                class="p-0"
                
                v-show="showMenu"
            >
                <Sidebar
                  :mini="mini"
                  class="sticky-left"
                  @changeMenu="changeMenu"
                  style="background-color:#5F7D91; height: 100% !important; width: 100% !important; min-height: 100vh;" />

               
            </b-col>
            <b-col
                style="background-color:#fff;"
                :cols="dashboardCols"
                class="m-0 p-0"
            >
                <Navbar
                class="sticky-top"
                style="box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 14px !important; z-index:9 !important;"/>
                <router-view style="width: 100%;" />
            </b-col>
        </b-row>
    </div>
</template>
<script>
 import Sidebar from '@/components/Sidebar.vue';
 import Navbar from '@/components/Navbar.vue';

    export default {
        name: "Dashboard",
        components: {
           Sidebar,
            Navbar
        },
        data () {
            return {
                menuCols: 2,
                dashboardCols: 10,
                mini: false,
                isMobile: false,

                showMenu: true
            }
        },
        mounted() {
            this.onResize()
            window.addEventListener('resize', this.onResize, { passive: true })
        },
        methods: {
            onResize () {
                this.isMobile = window.innerWidth < 1200
            },
            changeMenu() {
                this.mini = !this.mini
                if (this.mini) {
                    this.menuCols = 1
                    this.dashboardCols = 11
                } else {
                    this.menuCols = 2
                    this.dashboardCols = 10
                }
            }
        },
        watch: {
            isMobile: {
                inmediate: true,
                handler(newV) {
                    if (newV) {
                        this.showMenu = false
                        this.dashboardCols = 12
                    } else {
                        this.dashboardCols = 10
                        this.showMenu = true
                    }
                }
            }
        }
    }

</script>

<style>
.content {
    /* border-radius: 10px; */
    margin: 0px 6px 6px 1px;
    overflow: auto !important; 
    height: 100vh;
}


</style>