<template>
    <div>
         <b-row id="users" class="m-0 p-0" style="margin-bottom: 100px;">
          <b-col xs="12" sm="12" class="m-0 p-0">
              <b-row align-v="center" class="mb-5 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="12">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">Usuarios que le dieron me gusta</h3>
                </b-col>

                <b-col 
                  cols="12" sm="12" md="12" class="px-0" style="display: none;">
                  <div style="max-width: 320px;" class=" text-center">
                    <h4 :style="`${isMobile ? 'text-align: center;' : ''} padding:0;`" class="mb-2 mt-4">Buscar por nombre:</h4>
                    <b-form-group
                      id="input-group-1"
                      label=""
                      label-for="input-1"
                      class="mb-0"
                    >
                      <b-form-input
                        id="input-1"
                        type="text"
                        v-model="searchName"
                        required
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>

              <!--- Tabla de usuarios --->

              <b-table
              head-variant="dark"
              class="mb-0"
              :per-page="perPage"
              id="my-table"
              :current-page="currentPage"
              :fields="[
                {
                  key: 'name',
                  label: 'Nombre',
                  thClass: 'table-dark'
                },
                {
                  key: 'lastname',
                  label: 'Apellido',
                  thClass: 'table-dark'
                },
                {
                  key: 'email',
                  label: 'Email',
                  thClass: 'table-dark'
                },
                {
                  key: 'plant',
                  label: 'Planta',
                  thClass: 'table-dark'
                },
              ]"
              :stacked="isMobile"
              striped
              th-class="table-dark"
              :items="loading ? [] : users"
              >

           
              <template #cell(plant)="data">
                {{ getPlant(data.item.factory) }}
              </template>

              <template #top-row v-if="users.length === 0">
                <td>No hay usuarios que hayan dado like</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </template>
 
              </b-table>

              <b-pagination
                v-model="currentPage"
                v-if="users.length > 0 && !loading"
                dark
                align="right"
                :total-rows="users.length"
                :per-page="perPage"
                class="mt-3"
                aria-controls="my-table"
              >
                <template #page="{ page, active }">
                  <b v-if="active">{{ page }}</b>
                  <i v-else>{{ page }}</i>
                </template>
              </b-pagination>

              <!-- Skeleton loader --->

              <b-skeleton-table
              v-if="loading"
              :stacked="isMobile"
              :rows="7"
              :columns="7"
              :table-props="{ bordered: true, striped: true }"
            >
            </b-skeleton-table>
          </b-col>
        </b-row>
    </div>
</template>

<script>
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';

export default {
  name: 'Users',
  components: {

  },
  props: {
    typeModule: {
      type: String,
      default: ''
    },
    idModule: {
      type: String,
      default: ''
    }
  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        users: [],
        loading: true,
        isMobile: false,
        perPage: 8,
        currentPage: 1,
        birthday: '',
        searchName: '',
        timer:null,
        actualDate: '',
      }
    },
  mounted() {
    this.getUsers();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  watch: {
    searchName() {
      this.searchTimeOut()
    }
  },
  methods: {
    clearTimeout () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
    },
    searchTimeOut () {
      this.clearTimeout()
      // wait 800 mili seconds to avoid request too much search
      this.timer = setTimeout(async () => {
        this.getUsers()
      }, 800)
    },
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    
    async getUsers() {
      this.loading = true
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/get-likers/${this.idModule}`;

      await axios.get(url, config).then(data => {
        let datausers = []
        if (data?.data?.data?.likers && Array.isArray(data?.data?.data?.likers)){
          data?.data?.data?.likers.forEach(element => {
            if (element?.module === this.typeModule && element?.user ){
              datausers.push(element?.user)
            }
          });
        }
        
        this.users = datausers
        this.loading = false
      })
    },

    getPlant (value) {
      if (value === 'las_gaviotas'){
        return 'Planta Las Gaviotas'
      }
      if (value === 'san_ignacio'){
        return 'Planta San Ignacio'
      }
      if (value === 'casa_matriz_santiago'){
        return 'Casa Matriz Santiago'
      }
      if (value === 'auricop'){
        return 'Auricop'
      }
      if (value === 'minera_cobre_verde'){
        return 'Minera Cobre Verde'
      }
      if (value === 'enami'){
        return 'Enami'
      }
      return 'No tiene planta asignada'
    },
  },
}
</script>
<style>
h3 {
  color: #263a39;
  text-align: left;
  margin-top: 20px !important;
}

.ver {
  background-color: #3379A1 !important;
  margin: 0px !important;
}

.rounded-btn {
    border-radius: 20px !important;
    padding: 3px 5px !important;
    height: 1.9em !important;
    border: 0 !important;
}

.table-dark {
  background: #263A39!important;
}

#__BVID__39 > thead:nth-child(1), #__BVID__154 > thead:nth-child(1) {
  background: #263A39!important;
}
th {
  border: 0 !important;
}

tr.odd {
  background: #F4F6F8#f8aa8949 !important;
}

.page-item.active .page-link {
  background-color: #263A39!important;
  border-color: #263A39!important;
  color: #fff !important;
}

.page-link {
  color: #263A39!important;
}
</style>
