<template>
  <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
    <b-row>
      <b-col sm="12">
        <b-row>
          <b-col xs="12" sm="12">
           <b-row align-v="center" class="mb-5 mt-4 px-0 mx-0">
            <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
              <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">
                Recursos
              </h3>
            </b-col>

            <b-col class="m-0 p-0" cols="12" md="6">
              <b-input-group class="pl-0 pl-sm-5" style="margin-top: 20px;">
                <b-input-group-prepend style="background-color: white; height: 38px;">
                  <img
                    src="../assets/search-interface-symbol.svg" 
                    style="height:20px; margin-top:0.5em;"
                     class="px-2" />
                </b-input-group-prepend>
                <b-form-input v-model="search" @keyup="searchTable" style="border: 0;" placeholder="Buscar recursos"></b-form-input>
              </b-input-group>
                          
            </b-col>

            <b-col
              class="p-0 mt-3"
              :style="`display: flex; ${isMobile ? 'justify-content: center; margin-top: 10px;' : 'justify-content: start;'}`" 
              xs="12" sm="12" md="6">
              <b-button 
                type="button" 
                class="btn ver crear"
                v-if="rol === 'admin'"
                @click="showModalCreate()"
                :style="`color: #FFFFFF; ${!isMobile ? 'width: 160px;' : 'width: 100%;'}`">
                Crear recurso
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>

    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret style="margin-left: 85%;">
      <template #button-content>
        <div class="d-flex" style="height: 20px;">
          <img style="height: 14px; margin-top: 3px; margin-right: 3px;" :src="mosaic ? require('../assets/mosaico2.png') : require('../assets/lista.png')"/>
          <p style="margin-left: 5px; color: black;">Ver Vistas</p>
        </div>
      </template>
      <b-dropdown-item href="#" @click="showList()"><img src="../assets/lista.png" style="color: black; margin-right: 5px;" >Lista</b-dropdown-item>
      <b-dropdown-item href="#" @click="showMosaic()"><img src="../assets/mosaico2.png" style="color: black; margin-right: 5px;">Mosaico</b-dropdown-item>
    </b-dropdown>

    <div id="lista" class="mt-4" v-if="!mosaic && !loading.get">
      <table class="table table-striped" id="table_projects">
        <thead class="table-dark">
        <tr>
          <th scope="col"></th>
          <th scope="col">NOMBRE DEL ARCHIVO</th>
          <th scope="col">FECHA</th>
          <th scope="col" colspan="3">ACCIONES</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="project in projects" :key="project.id">
          <td ></td>
          <td><span>{{ project.name }}</span><b-badge v-if="!project.resource_by_users" variant="danger" class="ml-2" style="top: -1px; position: relative;">Nuevo</b-badge></td>
          <td>{{ getFormattedDate(project.created_at) }}</td>
          <td class="d-flex justify-content-center">
            <b-button
                type="button"
                class="rounded-btn"
                style="color: #FFFFFF; background: #3379A1 no-repeat padding-box;"
                @click="project.type === 'type_link' ? showResourceDetail(project) : showModalView(project.id)">
              <b-icon scale="0.8" icon="eye-fill"></b-icon>
            </b-button>
            <b-button
                type="button"
                class="rounded-btn ml-3"
                v-show="project.type === 'type_link'"
                style="background: #45bba9 no-repeat;"
                @click="redirectResource(project.link)">
              <b-icon scale="0.8" icon="box-arrow-up-right"></b-icon>
            </b-button> 
            <b-button
                type="button"
                v-show="rol === 'admin'"
                class="rounded-btn ml-3"
                style="background: #f0900f no-repeat;"
                @click="showModalEdit(project.id)">
              <b-icon scale="0.8" icon="pencil-fill"></b-icon>
            </b-button>
            <b-button
                type="button"
                v-show="rol === 'admin'"
                class="rounded-btn ml-3"
                style="background: #dc3545 no-repeat;"
                @click="showModalDelete(project.id)">
              <b-icon scale="0.8" icon="trash-fill"></b-icon>
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div id="mosaico" class="mt-4"  v-if="mosaic && !loading.get" >
      <b-row style="margin: 0; padding-bottom: 10px;">
        <b-col v-for="(project, index) in projects" :key="index" cols="12" md="6" lg="4" xl="3" class="card-resources">
          <b-card
              img-alt="Image"
              class="project_row mt-2 mb-2 mt-4 mb-2  px-0"
          > 
            <b-badge v-if="!project.resource_by_users" variant="danger" style="top: -8px; position: absolute; right:-8px; font-size: 19px;">Nuevo</b-badge>
            <b-img style="border-radius: 0.25rem 0.25rem 0px 0px !important; max-height: 200px;cursor:pointer;"
                @click="project.type === 'type_link' ? showResourceDetail(project) : showModalView(project.id)"
               :src="project.type === 'type_file' ? (project.file_image !== null &&  project.file_image !== '' ? url_img + project.file_image : 'https://via.placeholder.com/728x400.png?text=Archivo') : ( project.file && project.file.length > 0 ? url_img + project.file : 'https://via.placeholder.com/728x400.png?text=Link')" 
              >
            </b-img>
            <div style="min-height: 10em; max-height: 10em; overflow-y: scroll" class="scroll mt-3 px-3" >
              <b-card-body class="px-3 text-left">
              <span style="display:block;" class="mb-2">
                <strong>{{project.name}}</strong>
              </span>
              <span>
                <strong>Descripcion:</strong>
                {{getPlainAndTruncateText(project.description,130)}}
              </span>
              </b-card-body>
              <b-card-body class="p-0 text-left mt-3" v-if="project.type === 'type_file'">
              <span>
                <strong>Peso:</strong> {{ project.size ? project.size.toString().concat(' KB') : 'No disponible.' }}
              </span>
              </b-card-body>
              <b-card-body class="p-0 text-left mt-3" >
              <span>
                <strong>Creado el:</strong> {{ getFormattedDate(project.created_at) }}
              </span>
              </b-card-body>
            </div>

            <template #footer>
              <b-card-body class="p-0 text-center">
                <b-button
                    type="button"
                    class="rounded-btn"
                    @click="project.type === 'type_link' ? showResourceDetail(project) : showModalView(project.id)"
                    style="color: #FFFFFF; background: #3379a1 no-repeat padding-box;">
                  <b-icon scale="0.8" icon="eye-fill"></b-icon>
                </b-button>
                <b-button
                    type="button"
                    class="rounded-btn ml-3"
                    v-show="project.type === 'type_link'"
                    style="background: #45bba9 no-repeat;"
                    @click="redirectResource(project.link)">
                  <b-icon scale="0.8" icon="box-arrow-up-right"></b-icon>
                </b-button> 
                <b-button
                    type="button"
                    v-show="rol === 'admin'"
                    class="rounded-btn ml-3"
                    style="background: #f0900f no-repeat;"
                    @click="showModalEdit(project.id)">
                  <b-icon scale="0.8" icon="pencil-fill"></b-icon>
                </b-button>
                <b-button
                    type="button"
                    v-show="rol === 'admin'"
                    class="rounded-btn ml-3"
                    style="background:  #dc3545 no-repeat;"
                    @click="showModalDelete(project.id)">
                  <b-icon scale="0.8" icon="trash-fill"></b-icon>
                </b-button>
              </b-card-body>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- SKELETON LOADERS -->
    <div v-if="loading.get">
      <b-row style="margin: 0; padding-bottom: 10px;">
        <b-col style="" v-for="item in 5" :key="item" cols="12" md="6" lg="4" xl="3">
          <b-card class="project_row mt-2 mb-2 mt-4 mb-2" style="min-height: 24.8em; border-radius: 12px;" no-body img-top>
          <b-skeleton-img card-img="top" aspect="2:1"></b-skeleton-img>
          <b-card-body class="mx-2 px-0">
            <b-skeleton width="80%"></b-skeleton>

            <b-skeleton class="mt-4" animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>

            <b-skeleton animation="wave" class="mt-4" width="70%"></b-skeleton>
          </b-card-body>

          <template #footer>
              <b-card-body style="border-radius: 12px;" class="p-0 text-center d-flex justify-content-center">
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
                <b-skeleton size="30px" class="mx-1" type="avatar"></b-skeleton>
              </b-card-body>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- Modal ver recurso -->
    <b-modal ref="modal-ver-recurso" size="lg" title="Ver Recurso" >
      <b-row>
        <b-col col sm="12">
          <iframe :src="image" title="description" class="project_row" style="    display: block;
          height: 20em;
          width: 100%;"></iframe>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal crear recurso -->
    <b-modal
        id="modal-crear-recurso"
        ref="modal-crear-recurso"
        size="lg"
        centered
        hide-footer >

      <template #modal-title>
        <b class="ml-1 ml-md-4" style="color:#45bba9">
          Crear recurso
        </b>
      </template>

      <b-row class="mt-4 justify-content-center">
        <b-col cols="10">
          <form v-on:submit.prevent="createProject" id="form_crear_recurso">
            <b-form-group
                id="input-group-1"
                label="Nombre"
                label-for="input-1"
            >
              <b-form-input
                  id="input-1"
                  type="text"
                  v-model="name"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-group-1"
                label="Descripción"
                label-for="input-1"
            >
              <vue-editor v-model="description"></vue-editor>
              <span class="text-danger" style="font-size:12.8px" v-if="showDescriptionAlert">
                La descripción debe contener al menos 10 caracteres.
              </span>
            </b-form-group>
            <!--
            <b-form-group
                id="input-group-1"
                label="Tipo"
                label-for="input-1"
            >
              <b-form-select v-model="type_r" :options="typeOptions" class="form-select"/>
            </b-form-group> -->
            <b-form-group
                id="link"
                label="Link"
                label-for="input-1"
                v-if="type_r === 'type_link'"
            >
              <b-form-input
                  id="input-1"
                  type_r="text"
                  v-model="link"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="archivo"
                label="Imagen"
                label-for="input-1"
            >
              <b-form-file class="form-control-file"
                           accept="image/jpeg, image/png, application/pdf"
                           v-model="photo"
                           :browse-text="'Seleccionar'"
                           placeholder=" "
                           drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
             <b-form-group
                id="Imagen"
                label="Imagen"
                label-for="input-1"
                v-if="type_r === 'type_file'"
            >
              <b-form-file class="form-control-file"
                           accept="image/jpeg, image/png, application/pdf"
                           v-model="file_img"
                           @change="obtenerImagen"
                           :browse-text="'Seleccionar'"
                           placeholder=" "
                           drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </form>
        </b-col>
      </b-row>

      <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
          <b-btn
              block
              variant="outline-danger"
              @click="closeModal('modal-crear-recurso')">
            Cancelar
          </b-btn>
        </b-col>
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1">
          <b-overlay
              :show="loading.crearRecurso"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
          >
            <b-btn
                block
                class="outline-btn"
                @click="createProject">
              Crear
            </b-btn>
          </b-overlay>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal editar recurso -->
    <b-modal
        id="modal-editar-recurso"
        ref="modal-editar-recurso"
        size="lg"
        centered
        hide-footer >

      <template #modal-title>
        <b class="ml-1 ml-md-4" style="color:#45bba9">
          Editar recurso
        </b>
      </template>

      <b-row class="mt-4 justify-content-center">
        <b-col cols="10">
          <form v-on:submit.prevent="createProject" id="form_">
            <b-form-group
                id="input-group-1"
                label="Nombre"
                label-for="input-1"
            >
              <b-form-input
                  id="input-1"
                  type="text"
                  v-model="name"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-group-1"
                label="Descripción"
                label-for="input-1"
            >
              <vue-editor v-model="description"></vue-editor>
              <span class="text-danger" style="font-size:12.8px" v-if="showDescriptionAlert">
                La descripción debe contener al menos 10 caracteres.
              </span>
            </b-form-group>
            <!--
            <b-form-group
                id="input-group-1"
                label="Tipo"
                label-for="input-1"
            >
              <b-form-select v-model="type_r" :options="typeOptions" class="form-select"/>
            </b-form-group>
            -->
            <b-form-group
                id="link"
                label="Link"
                label-for="input-1"
                v-if="type_r === 'type_link'"
            >
              <b-form-input
                  id="input-1"
                  type_r="text"
                  v-model="link"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group
                id="archivo"
                label="Imagen"
                label-for="input-1"
            >
              <b-form-file class="form-control-file"
                           accept="image/jpeg, image/png, application/pdf"
                           v-model="photo"
                           @change="obtenerImagen"
                           :browse-text="'Seleccionar'"
                           placeholder=" "
                           drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
            <b-form-group
                id="Imagen"
                label="Imagen"
                label-for="input-1"
                v-if="type_r === 'type_file'"
            >
              <b-form-file class="form-control-file"
                           accept="image/jpeg, image/png, application/pdf"
                           v-model="file_img"
                           @change="obtenerImagen"
                           :browse-text="'Seleccionar'"
                           placeholder=" "
                           drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </form>
        </b-col>
      </b-row>

      <b-row class="ma-0 mt-3 px-5 pb-3 justify-content-center justify-content-md-end">
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
          <b-btn
              block
              variant="outline-danger"
              @click="closeModal('modal-editar-recurso')">
            Cancelar
          </b-btn>
        </b-col>
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1">
          <b-overlay
              :show="loading.editarRecurso"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
          >
            <b-btn
                block
                class="outline-btn"
                @click="updateResource">
              Editar
            </b-btn>
          </b-overlay>
        </b-col>
      </b-row>
    </b-modal>

    <!-- Modal elminar recurso -->
    <b-modal
        ref="modal-eliminar-recurso"
        centered
        hide-footer>
      <template #modal-title>
        <b class="ml-1 ml-md-4" style="color:#45bba9">
          Confirmación
        </b>
      </template>

      <p class="mt-3" style="text-align: center;">
        ¿Está seguro de que desea eliminar este recurso?
      </p>

      <b-row class="ma-0 mt-4 px-5 pb-3 justify-content-center">
        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1 pb-3 pb-md-0">
          <b-btn
              block
              variant="outline-danger"
              @click="closeModal3">
            Mejor no
          </b-btn>
        </b-col>

        <b-col cols="12" md="4" class="px-0 mx-0 mx-md-1">
          <b-overlay
              :show="loading.deleteRecurso"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
          >
            <b-btn
                block
                class="outline-btn"
                @click="deleteResource">
              Sí
            </b-btn>
          </b-overlay>
        </b-col>
      </b-row>
    </b-modal>

      <!-- Modal detalle de recurso link -->
    <b-modal 
      id="modal-ver-recurso-detalles"
      ref="modal-ver-recurso-detalles"
      centered
      size="lg"
      hide-footer>

      <template #modal-title>
        <b style="color:#45bba9">
          Detalle de recurso
        </b>
      </template>

      <b-card 
        v-if="resource"
        class="ma-0 pa-0"
        :header="resource.name">
                  
        <b-card-text class="pt-2"  v-if="resource.file">
          <b-card-img
            :src="url_img + resource.file" 
            alt="resource_pic"
            style="width: 100%;"
            class="img ">
          </b-card-img>
        </b-card-text>

          <b-card-text style="max-height: 400px; overflow-y: scroll; font-size: 15px;" class="pt-1 px-2 pb-3 scroll">
            <div v-html="resource.description"></div>
          </b-card-text>

          <b-card-text class="d-flex justify-content-center">
               <b-btn
                   v-show="resource.type === 'type_link'"
                  variant="outline-success"
                   @click="redirectResource(resource.link)">
                  Ir al link
                </b-btn>
          </b-card-text>
      </b-card>
    </b-modal>

  </div>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { VueEditor } from "vue2-editor";
import { cloneDeep } from 'lodash';

export default {
  name: 'Resources2',
  components: {
    VueEditor
  },
  data() {
    return {
      url_img: process.env.VUE_APP_URL+'/storage/',
      projects: [],
      original_projects: [],
      search: '',
      rol: null,
      mosaic: true,
      name: '',
      description: '',
      showDescriptionAlert:false,
      status: '',
      photo: null,
      image: '',
      file_img: '',
      isMobile: false,
      position: '',
      project_id: '',
      text: '',
      resource: null,
      dialog: false,
      link: '',
      type_r: 'type_link',
      typeOptions: [
        { value: 'type_file', text: 'Archivo' },
        { value: 'type_link', text: 'Link' },
      ],
      loading: {
        crearRecurso: false,
        get: true,
        deleteRecurso: false,
        editarRecurso: false,
      }
    }
  },
  mounted() {
    this.rol = (localStorage.userRol);
    this.getResources();
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  watch:{
    description(){
      let plainDesc = this.description.replace(/<[^>]*>/g, '');
      if (plainDesc.length < 10){
        this.showDescriptionAlert = true;
      }
      else {
        this.showDescriptionAlert = false;
      }
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
    },
    showList() {
      this.mosaic = false;
    },
    showMosaic() {
      this.mosaic = true;
    },
    obtenerImagen(e){
      let file = e.target.files[0];
      // console.log(file);
      this.photo = file;
      this.cargarImagen(file);
    },
    cargarImagen(file){
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      }
      reader.readAsDataURL(file);
    },
    getFormattedDate(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a')
    },
    createProject() {
      this.loading.crearRecurso = true
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let formData = new FormData();
      formData.append('name', this.name);
      formData.append('description', this.description);
      formData.append('resource', this.photo);
      formData.append('link', this.link);
      formData.append('file_img', this.file_img);
      formData.append('type', this.type_r);

      axios.post(process.env.VUE_APP_URL+"/api/auth/resources", formData, config)
          .then(data => {
            console.log(data);
            this.closeModal('modal-crear-recurso')
            this.loading.crearRecurso = true
            this.getResources();
          });
    },
    closeModal(modal) {
      this.$refs[modal].hide()
      this.reset()
    },
    reset()
    {
      this.name = '';
      this.description = '';
      this.image = '';
      this.link = '';
      this.type_r = 'type_link';
      this.showDescriptionAlert = false
    },
    getResources() {

      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/resources';

      axios.get(url, config).then(data => {
        this.loading.get = false
        this.projects = data.data;
        this.original_projects = data.data;
      })

    },
    deleteResource() {
      this.loading.deleteRecurso = true;
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let formData = new FormData();
      formData.append('_method', 'DELETE');

      axios.post(process.env.VUE_APP_URL+"/api/auth/resources/"+this.project_id, formData, config)
          .then(data => {
            console.log(data);
            this.closeModal3()
            this.loading.deleteRecurso = false;
            this.getResources();
          });
    },
    async viewResource(id) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      await axios.get(process.env.VUE_APP_URL+"/api/auth/set-viewed-resource/"+id, config)
          .then(data => {
            console.log(data);
          });

      
      this.projects.forEach(element => {
        if (id === element.id) {
          element.resource_by_users = true
        }
      });
      this.original_projects.forEach(element => {
        if (id === element.id) {
          element.resource_by_users = true
        }
      });
      localStorage.setItem('updateResources', 'true')
    },

    updateResource() {

      this.loading.editarRecurso = true

      const config = {
        headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('name', this.name);
      formData.append('description', this.description);
      formData.append('resource', this.photo);
      formData.append('link', this.link);
      formData.append('file_img', this.file_img);
      formData.append('type', this.type_r);

      axios.post(process.env.VUE_APP_URL+"/api/auth/resources/"+this.project_id, formData, config)
          .then(data => {
            console.log(data);
            this.closeModal('modal-editar-recurso')
            this.loading.editarRecurso = false
            this.getResources();
          });
    },
    showModalView(id) {
      const project = this.projects.find((e) => { return e.id === id })
      if (project) {
        if (project.type === 'type_file' ) {
          this.image = process.env.VUE_APP_URL+'/storage/'+project.file;

          this.$refs['modal-ver-recurso'].show();
        }  else if (project.type === 'type_link' ) {
          console.log('hello');
        }
      }
    },
    showModalDelete(id) {
      this.$refs['modal-eliminar-recurso'].show();
      this.project_id = id;
    },
    showModalEdit(id) {
      const resource = this.projects.find((e) => { return e.id === id })
      if (resource) {
        this.project_id = resource.id
        this.name = resource.name;
        this.description = resource.description;
        this.image = process.env.VUE_APP_URL+'/storage/'+resource.image;
        this.link = resource.link;
        this.type_r = resource.type;
        this.showDescriptionAlert = false
      }
      this.$refs['modal-editar-recurso'].show();
    },
    closeModal3() {
      this.$refs['modal-eliminar-recurso'].hide();
      this.project_id = '';
    },
    searchTable() {
      let key = String(this.search).toLowerCase();
      var response = [];
      this.projects = cloneDeep(this.original_projects);

      if(key !== "") {
        response = [];
        for(let project of this.projects) {
          for(let value of Object.values(project)) {
            if(String(value).toLowerCase().search(key) >= 0) {
              response.push(project);
              break;
            }
          }
        }
        this.projects = response;
      } else {
        this.projects = cloneDeep(this.original_projects);
      }
    },
    showModalCreate() {
      this.reset()
      this.position = '';
      this.$refs['modal-crear-recurso'].show();
    },
    redirectResource(link) {
      window.open('http://'+link);
    },
    showResourceDetail(resource) {
      this.resource = resource
      if (!resource.resource_by_users) {
        this.viewResource(resource.id)
      }
      this.$refs['modal-ver-recurso-detalles'].show();
    },
    getPlainAndTruncateText(text, length = 255) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();

      if(content.length>length) {
        content = content.substring(0, length);
        content = content + ('(...)');
      }

      return content;
    },
  },
}
</script>

<style scoped>

.ver {
  background-color: #3379A1 !important;
  border-radius: 5px;
  width: 100px;
  margin: 10px;
}

.project_row img{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.4 !important;
  /*object-fit: cover;
  object-position: center;*/
}

.scroll {
  max-height: 600px;/*450px;*/
  overflow-y: auto;
}

/* Tamaño del scroll */
.scroll::-webkit-scrollbar {
  width: 8px;
}

/* Estilos barra (thumb) de scroll */
.scroll::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.scroll::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */
.scroll::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 4px;
}

.scroll::-webkit-scrollbar-track:hover,
.scroll::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

.card-resources .card-body{
  padding: 0 !important;
}

</style>
