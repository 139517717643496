<template>
  <div>
    <Header />
      <div class="container-fluid">
          <div class="wrapper fadeInDown">
          <div style="overflow-wrap: break-word !important;" id="formContent">
            <!-- Tabs Titles -->

            <!-- Icon 
            <div style="overflow-wrap: break-word; font-size:1.1rem !important; font-weight:800!important; text-align:center; padding-top: 19px; padding-bottom: 19px;" class="fadeIn first">
              Bienvenidos a la Intranet Cobre y Metales
            </div>-->
            <div class="fadeIn first">
              <h3 style="text-align: center;font-size: 18px !important;padding: 20px 20px;">Bienvenidos a la Intranet Cobre y Metales</h3>
            </div>

            <!-- Login Form -->
            <form v-on:submit.prevent="login " id="form_">

              <b-form-group
                id="input-group-1"
                label="Email"
                label-for="input-1"
                style="padding-left:8%;padding-right: 8%;"
              >
                <b-form-input
                  id="input-1"
                  type="text"
                  style="width: 100% !important;margin: 0px;padding: 15px 35px;"
                  v-model="usuario"
                  :state="checkState('email')"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-feedback">
                    Debe ingresar un email válido.
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="input-group-1"
                label="Contraseña"
                label-for="input-1"
                style="padding-left:8%;padding-right: 8%;"
              >
              <div style="width: 100%;position: relative;">
                <b-form-input
                  id="input-1"
                  :type="seePassword ? 'text' : 'password'"
                  style="width: 100% !important;margin: 0px;padding: 15px 35px;"
                  v-model="password"
                ></b-form-input>
                <b-icon 
                    @click="seePassword = !seePassword" 
                    :icon="seePassword ? 'eye-slash' : 'eye'"
                    aria-hidden="true"
                    scale="1.45"
                    style="position: absolute;right: 13px;cursor:pointer;top: 11px;">
                </b-icon>
              </div>
                <b-form-invalid-feedback id="input-1-feedback">
                    La contraseña debe contener al menos 4 letras.
                  </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group id="input-group-4">
                  <b-form-checkbox-group
                    id="checkboxes-4"
                    style="padding-left: 50px;">
                  <b-row>
                    <b-col cols="12" sm="3"> <b-form-checkbox value="me" style="font-size: 12px; padding-left: 10px;"> <span style="margin-left:10px;">Recuérdame</span></b-form-checkbox></b-col>
                    <b-col cols="12" sm="9" offset-sm="1" style="font-size: 12px; margin: 0; text-align:right; padding-right: 47px;">
                      Olvidaste tu contraseña 
                      <router-link to="/reset-password">
                      ingresa aquí
                      </router-link>
                      </b-col>
                  </b-row>
                </b-form-checkbox-group>
              </b-form-group>
              <b-row>
                <b-col md="10" offset-md="1">
                  <b-button :disabled="disableBtn" class="btn ver crear block" type="submit">
                    <span v-if="loading" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                    {{ loading ? "" : "Ingresar" }}
                  </b-button>
                 </b-col>
              </b-row>
              <b-alert v-model="showDismissibleAlert" variant="success">{{ textShowDismissibleAlert }}</b-alert>
            </form>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import axios from 'axios';
export default {
  name: 'Home',
  components: {
     Header,
  },
  data: function() {
    return {
      usuario: "",
      password: "",
      show: false,
      error_msg: "",
      loading: false,
      showDismissibleAlert: false,
      textShowDismissibleAlert: 'Se envió el correo electrónico de recuperación.',
      seePassword: false
    }
  },
  mounted() {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    localStorage.removeItem('userRol');
    const showResetPasswordLogin =  localStorage.getItem('showResetPasswordLogin')
    if (showResetPasswordLogin === 'trueReset') {
      this.showDismissibleAlert = true
      this.textShowDismissibleAlert = 'Se ha cambiado su contraseña exitosamente, ya puede ingresar con su nueva contraseña.'
      localStorage.removeItem('showResetPasswordLogin')
    }
    if (showResetPasswordLogin === 'true') {
      this.showDismissibleAlert = true
      this.textShowDismissibleAlert = 'Se envió el correo electrónico de recuperación.'
      localStorage.removeItem('showResetPasswordLogin')
    }
  },
  computed: {
    disableBtn() {
        if(
          !this.usuario || !this.password
        ) {
          return true
        }

        return false
    }
  },
  methods: {
     userData() {
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

        let url = process.env.VUE_APP_URL+'/api/auth/user';

        axios.get(url, config).then(data => {
          localStorage.userData = JSON.stringify(data.data);
          localStorage.userRol = data.data[0].role[0].name;

          this.$router.push('inicio');
        })
    },
    login() {
      let json = {
        "email": this.usuario.toLowerCase(),
        "password": this.password
      }
      this.loading = true

      axios.post(process.env.VUE_APP_URL+"/api/auth/login", json)
        .then(data => {
          console.log('data', data);
          this.loading = false
          if(data.statusText == 'OK') {
            localStorage.token = data.data.access_token;
            this.userData();
          } 
        }).catch(error => {
          this.loading = false
         console.log('error', error);
            this.show = true;
            this.$bvToast.toast('Email o contraseña incorrectos. Intente nuevamente.', {
              title: '¡Ha ocurrido un error!',
              variant: 'danger',
              solid: false
            })
        })
    },

    checkState(field) {
      let aux = true;
      switch (field) {
        case 'email': 
         aux = 0 === this.usuario.length ? null : false
          if (
            this.usuario.length > 1 &&
            this.usuario.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[a-zA-Z0-9-.]")
          ) aux  = true
          break;
        default:
          aux = 0 === this.password.length ? null : false
          if (this.password.length >= 4) aux  = true
          break;
      }

      return aux;
    },
  }
}
</script>

<style scoped>

/* BASIC */

html {
  background-color: #56baed;
  height: 100%;
}

body {
  font-family: "Rubik", sans-serif;
  height: 100%;
}

a {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #cccccc;
}



/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

@media (max-width: 576px) {
  .wrapper{
    padding: 0px;
  }

}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: left;
  margin-top: -100px;
  margin-top: 80px;
  margin-bottom: 50px;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}



/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  background-color: #39ace7;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=text], input[type=password]{
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
  background-color: #fff;
}

input[type=text]:placeholder {
  color: #cccccc;
}



/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}



/* OTHERS */

*:focus {
    outline: none;
} 

#icon {
  width:60%;
}

h4{
  font: Rubik;
  letter-spacing: 0px;
  color: #263A39;
  opacity: 1;
  padding: 22px;
  font-size: 18px;
  font-weight: 800;
}

.block {
  border-radius: 5px;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px !important;
}

.container-fluid {
    background-image: url(/img/fondo_login.e1b10e88.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 115px);
}

.row{
  align-items: center;
}

</style>
