<template>
  <div>
    <Header />
      <div class="container-fluid" :style="{'background-image': 'url(' + require('../assets/fondo_login.jpg') + ')', 'background-position': 'center' , 'background-repeat': 'no-repeat', 'background-size': 'cover', 'min-height': 'calc(100vh - 115px)'}">
        <div class="wrapper fadeInDown">
          <div id="formContent">
            <!-- Tabs Titles -->
            <div class="fadeIn first" style="display:block; margin-right:0px;">
              <a class="back-button" href="javascript:history.back()">&lt; VOLVER</a>
            </div>
            
            <!-- Icon -->
            <div class="fadeIn first">
              <h3 style="text-align: center;font-size: 15px !important;padding: 10px 20px;">
                Para recuperar tu contraseña de acceso, por favor <br>ingrese  la siguiente información
              </h3>
            </div>

            <!-- Login Form -->
            <form v-on:submit.prevent="resetPassword" id="form_">

              <b-form-group
                id="input-group-1"
                label="Correo"
                label-for="input-1"
                style="text-align: left; margin-left: 37px !important; "
              >
                <b-form-input
                  id="input-1"
                  type="text"
                  v-model="email"
                  required
                ></b-form-input>
              </b-form-group>
              
              <b-button type="submit" id="ingresar" variant="primary">Recuperar Contraseña</b-button>
              <!--<b-alert v-model="showDismissibleAlert" variant="success">Se envió el correo electrónico de recuperación.</b-alert>-->
            </form>

            <div class="alert alert-danger" role="alert" v-if="error">
              {{error_msg}}
            </div>

          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import axios from 'axios';
export default {
  name: 'Home',
  components: {
     Header,
  },
  data: function() {
    return {
      email: "",
      error: false,
      error_msg: "",
      showDismissibleAlert: false
    }
  },
  watch:{
    showDismissibleAlert(){
      if (this.showDismissibleAlert === true){
        localStorage.setItem('showResetPasswordLogin', 'true')
        this.$router.push('/')
      }
    }
  },
  methods: {
    resetPassword() {
      let json = {
        "email": this.email,
      }
      
      this.error= false
      this.error_msg= "" 

      axios.post(process.env.VUE_APP_URL+"/api/auth/send-email-reset-password", json)
        .then(data => {
          if(data.data.code == 200) {
            this.email = "";
            this.showDismissibleAlert = true;
          } 
        }).catch(err =>{
          console.log(err)
          this.error= true
          this.error_msg= "El correo ingresado no pertenece a ningun usuario en la plataforma" 
        })
    }
  }
}
</script>

<style scoped>

/* BASIC */

html {
  background-color: #56baed;
}

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

a {
  color: #92badd;
  display:inline-block;
  text-decoration: none;
  font-weight: 400;
}

h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display:inline-block;
  margin: 40px 8px 10px 8px; 
  color: #cccccc;
}



/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; 
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

@media (max-width: 576px) {
  .wrapper{
    padding: 0px;
  }

}


#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 100%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  box-shadow: 0 30px 60px 0 rgba(0,0,0,0.3);
  text-align: left;
  margin-top: 50px;
  margin-bottom: 50px;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}



/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}



/* FORM TYPOGRAPHY*/

input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type=button]:hover, input[type=submit]:hover, input[type=reset]:hover  {
  background-color: #39ace7;
}

input[type=button]:active, input[type=submit]:active, input[type=reset]:active  {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

input[type=text] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

input[type=text]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

input[type=text]:placeholder {
  color: #cccccc;
}



/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

.fadeIn {
  opacity:0;
  -webkit-animation:fadeIn ease-in 1;
  -moz-animation:fadeIn ease-in 1;
  animation:fadeIn ease-in 1;

  -webkit-animation-fill-mode:forwards;
  -moz-animation-fill-mode:forwards;
  animation-fill-mode:forwards;

  -webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  animation-duration:1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after{
  width: 100%;
}



/* OTHERS */

*:focus {
    outline: none;
} 

#icon {
  width:60%;
}

h4{
  font: Rubik;
  letter-spacing: 0px;
  color: #263A39;
  opacity: 1;
  padding: 22px;
  font-size: 22px;
  font-weight: 800;
}

#ingresar{
  background: transparent linear-gradient(180deg, #803312 0%, #C07F68 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  opacity: 1;
  width: 80%;
  min-height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.back-button{
  color:#803312;
  width:auto;
  padding: 10px;
  display: block;
  margin-right: 10px;
  margin-top:5px;
  font-size: 11px;
  font-weight: 600;
  text-align: right;
}


</style>
