<template>
      <b-card
          tag="article"
          border-variant="white"
          style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 0px;"
          id="card_noticias"
          class="mt-0 mb-2"
      >

        <div class="shadow-none mx-0 mt-0 mb-4">
          <div class="d-flex flex-row justify-content-between">
            <h4 class="pt-3" style="font-weight: 500 !important; font-size: 18px !important;">
              <img style="width: 37px; margin-top: -10px;" src="../../assets/noticia.svg" alt="Boletin"/> 
              <span class="ml-2">Noticias</span>
            </h4>
            <div @click="$router.push('/listado-noticias')" style="width:30px; height: 27px; margin-top: 14px; cursor: pointer;">
              <b-icon icon="three-dots-vertical"></b-icon>
            </div>
          </div>
          <div class="mt-4 px-0 mx-0 d-flex">
            <div
                class="m-0 pr-0 pl-0 d-flex justify-content-start"
                @click="showNotice('internal')"
            >
              <b-btn
                id="internal_btn"
                style="font-size: 13px; font-weight: 400 !important; width:83px; !important;"
                :class="{ 'outline-btn-disabled' : !showInternal, 'outline-btn' : showInternal }"
                class="py-1"
              >
                Internas
              </b-btn>
            </div>
            <div
                class="m-0 pl-2 d-flex justify-content-start"
                @click="showNotice('external')"
            >
              <b-btn
                id="external_btn"
                style="font-size: 13px; font-weight: 400 !important; width:83px; !important;"
                :class="{ 'outline-btn-disabled' : !showExternal, 'outline-btn' : showExternal }"
                class="py-1"
              >
                Externas
              </b-btn>
            </div>
          </div>
        </div>

        <b-card-body style="margin-top: 30px;" id="loading" v-if="loading && showInternal || showExternal && notices_external.length < 1">
          <div class="d-flex justify-content-center">
            <span style="font-weight: 500 !important;">Cargando...</span>
            <b-spinner class="ml-auto"></b-spinner>
          </div>
        </b-card-body>

          <b-card-body :class="`scroll p-0 ${hasNotif && dashVr ? 'notices_scroll_dash-notif' : ''} ${dashVr && !hasNotif ? 'notices_scroll_dash' : ''} ${!dashVr ? 'notices_scroll_full' : ''}`" v-else>

          <!-- INTERNAL NEWS -->

           <b-card
             v-for="notice in notices"
             v-show="showInternal"
             border-variant="white"
             :key="notice.id"
             no-body
             class="overflow-hidden mb-3 card-title m-0"
             style="max-width: 100%; cursor:pointer;">
             <b-row
              no-gutters>
              <b-col
                @click="changeNotice(`/noticia/internal/${notice.id}`)"
                style="object-fit: cover; object-position: center;"
                class="d-flex align-items-center justify-content-center"
                cols="12">
                <img v-if="!notice.image" src="../../assets/noticia.svg" class="notice_row" alt="">
                <b-card-img
                  v-else
                  :src="url_img + notice.image"
                  alt="Image"
                  class="rounded-0 notice_row"></b-card-img>
              </b-col>
              
              <b-col
                cols="12">
                <b-card-body class="pl-3 pr-2">
                  <h4 style="font-weight:500 !important; font-size: 13px !important;">
                    {{ notice.name }}
                  </h4>
                  <b-card-text>
                    {{truncateText(notice.description,110)}}
                  </b-card-text>
                </b-card-body>
                </b-col>
              <b-col cols="12">
                <div class="w-100 d-flex mt-2 mb-2">
                  <b-btn 
                    type="button" 
                    :class="notice.liked ? 'btn-heart btn-heart-liked' : 'btn-heart' "
                    :disabled="notice.liked"
                    @click="() => { likeNotice(notice); notice.liked = true }"
                    >
                    <b-icon icon="heart" style=" color:rgb(0, 0, 0);" aria-hidden="true"></b-icon> 
                  </b-btn>
                  <b-btn 
                    type="button" 
                    class="btn-comment ml-2"
                    @click="changeNotice(`/noticia/internal/${notice.id}?comentopen=true`)"
                    >
                    <b-icon icon="chat-text" style=" color:rgb(0, 0, 0);" aria-hidden="true"></b-icon> 
                  </b-btn>
                </div>
              </b-col>
              </b-row>
            </b-card>

            <!-- EXTERNAL NEWS -->

           <b-card
             v-for="notice in notices_external"
             v-show="showExternal"
             border-variant="white"
             :key="notice.id"
             no-body
             class="overflow-hidden mb-3 card-title mr-0"
             style="max-width: 100%; cursor: pointer;">
             <b-row
              no-gutters>
              <b-col
                @click="changeNotice(`/noticia/external/${notice.id}`)"
                style="object-fit: cover; object-position: center;"
                class="d-flex align-items-center justify-content-center"
                cols="12">
                <img v-if="!notice.featured_media" src="../../assets/noticia.svg" alt="" class="notice_row" >
                <b-card-img
                  v-else
                  :src="setImageNoticeExternal(notice.featured_media)"
                  alt="Image"
                  class="rounded-0 notice_row"></b-card-img>
              </b-col>
              
              <b-col
                cols="12">
                <b-card-body class="pl-3 pr-2">
                  <h4 style="font-weight:500 !important; font-size: 13px !important;">
                    {{ truncateText(notice.title.rendered, 46) }}
                  </h4>
                  <b-card-text>
                      {{ truncateText(notice.content.rendered, 110) }}
                  </b-card-text>
                </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </b-card-body>
      </b-card>
</template>
<script>
import axios from 'axios';
import { orderBy } from 'lodash';
import moment from 'moment';

export default {
  name: 'Noticias',
  props: {
    dashVr: {
      type: Boolean,
      default: true
    },
    hasNotif:  {
      type: Boolean,
      default: false
    },
  },
  data() {
      return {
        url_img: process.env.VUE_APP_URL+'/storage/',
        notices: '',
        notices_external: '',
        name: '',
        description: '',
        status: '',
        link: '',
        photo: null,
        image: '',
        notice_id: '',
        categories_external: [],
        images_external: [],
        showInternal: true,
        showExternal: false,
        loading: true,
        user_id: '',
      }
    },
  mounted() {
    let userData = JSON.parse(localStorage.userData)
    this.user_id = userData[0].id
    this.getNoticesInternal();
    this.getCategoriesExternal();
  },
  methods: {
    changeNotice(link) {
        this.$router.push(link)
        /// TODO: chequear si el link es similar al anterior y recargar de lo contrario no, pq falla
        location.reload()
      },
    showNotice(type_notice) {
      if(type_notice == 'internal') {

        document.getElementById("external_btn").classList.remove("n_active");
        document.getElementById("external_btn").classList.add("n_inactive");
        document.getElementById("internal_btn").classList.remove("n_inactive");
        document.getElementById("internal_btn").classList.add("n_active");

        this.showInternal = true;
        this.showExternal = false;

      } else if(type_notice == 'external') {

        document.getElementById("external_btn").classList.remove("n_inactive");
        document.getElementById("external_btn").classList.add("n_active");
        document.getElementById("internal_btn").classList.remove("n_active");
        document.getElementById("internal_btn").classList.add("n_inactive");

        this.showInternal = false;
        this.showExternal = true;
      }
    },
    showModalCreate() {
        this.name = '';
        this.description = '';
        this.status = '';
        this.link = '';
        this.image = '';
        this.$refs['modal-1'].show();
      },
    showModalEdit(id) {
      for (let i = 0; i < this.notices.length; i++) {
        if(this.notices[i].id == id) {
            this.notice_id = this.notices[i].id;
            this.name = this.notices[i].name;
            this.description = this.notices[i].description;
            this.link = this.notices[i].link;
            this.status = this.notices[i].status;
            this.image = process.env.VUE_APP_URL+'/storage/'+this.notices[i].image;
        }
      }

      this.$refs['modal-2'].show();
    },
    showModalDelete(id) {
        this.$refs['modal-3'].show();
        this.notice_id = id;
      },
    createNotice() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.token}` }
        };

       let formData = new FormData();
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('image', this.photo);
       formData.append('status', this.status);
       formData.append('link', this.link);

      axios.post(process.env.VUE_APP_URL+"/api/auth/notices", formData, config)
        .then(data => {
           console.log(data);
            this.name = '';
            this.description = '';
            this.status = '';
            this.image = '';
            this.link = '';

           this.getNoticesInternal();
        });
    },
    async getNoticesInternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = process.env.VUE_APP_URL+'/api/auth/notices';
      this.loading = true
      await axios.get(url, config).then(data => {
          data.data.forEach(element => {
            element.liked = false            
          });
          this.notices = orderBy(
              data.data,
              [
                e => {
                  return moment(e.created_at).unix();
                },
              ],
              ['desc']
            );
          this.loading = false;
      })

      this.getLikes(this.notices)
    },

    likeNotice(notice){
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

        const data ={
          user_id : this.user_id,
          module: 'noticias',
          module_id: notice.id
        }
      axios.post(process.env.VUE_APP_URL+"/api/auth/like", data, config)
        .then(() => {
       
        })
        .catch(() => {
          
        })
    },

    async getLikes(data){
      const config = {
            headers: { Authorization: `Bearer ${localStorage.token}`}
        };

      let noticesData = data
      let arrayLikes = []

      await axios.get(process.env.VUE_APP_URL+"/api/auth/get-all-likes",  config)
        .then((data) => {
        
          if (data?.data?.data?.likes && Array.isArray(data?.data?.data?.likes)){
            data.data.data.likes.forEach(element => {
              if (element?.module === 'noticias' && element?.like ){
                arrayLikes.push(element.module_id)
              }
            });
          }
        })
        .catch(() => {
          
        })

        noticesData.forEach(element => {
          element.liked = arrayLikes.some(like => like === element.id)
        });

        this.notices = noticesData
    },

    getCategoriesExternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = 'https://redimin.cl/wp-json/wp/v2/categories';
       this.loading = true;
      axios.get(url, config).then(data => {
        for (let i = 0; i < data.data.length; i++) {
          this.categories_external[i] =  data.data[i].id;
        }
        this.loading = false;
        this.getNoticesExternal();
      })

    },
    getImageNoticeExternal(id) {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = ' https://www.redimin.cl/wp-json/wp/v2/media/'+id;


      axios.get(url, config).then(data => {
        this.images_external.push(data.data);

      })

    },
    setImageNoticeExternal(id) {
      for (let i = 0; i < this.images_external.length; i++) {
        if (this.images_external[i].id == id) {
          return this.images_external[i].guid.rendered;
        }

      }
    },
    getNoticesExternal() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = 'https://redimin.cl/wp-json/wp/v2/posts?categories='+this.categories_external.toString();
      // let images = [];

      axios.get(url, config).then(data => {
        this.notices_external = data.data;

        for (let i = 0; i < this.notices_external.length; i++) {
           this.getImageNoticeExternal(this.notices_external[i].featured_media)
        }

      })



    },
    getTitleNoticeExternal(id) {
        let title = '';

        for (let i = 0; i < this.notices_external.length; i++) {
          if(this.notices_external[i].id == id) {
             title = this.notices_external[i].title.rendered;
          }
        }

        return title;

    },
     getDescriptionNoticeExternal() {
        // let notice = '';

        // for (let i = 0; i < this.notices_external.length; i++) {
        //   if(this.notices_external[i].id == id) {
        //      notice = this.notices_external[i].content.rendered;
        //   }
        // }

        // // console.log('notice image', notice);
        // // let position_ini = notice.search("<img");
        // // let position_fin = notice.search("/>") ;
        // // let result = notice.substring(position_ini, parseInt(position_fin) + 2);

        // return result;

    },
    updateNotice() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'PUT');
       formData.append('name', this.name);
       formData.append('description', this.description);
       formData.append('image', this.photo);
       formData.append('status', this.status);
       formData.append('link', this.link);


      axios.post(process.env.VUE_APP_URL+"/api/auth/notices/"+this.notice_id, formData, config)
        .then(data => {
           console.log(data);
            this.name = '';
            this.description = '';
            this.status = '';
            this.image = '';
            this.link = '';

           this.getNoticesInternal();
        });
    },
    deleteNotice() {

      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

       let formData = new FormData();
       formData.append('_method', 'DELETE');

      axios.post(process.env.VUE_APP_URL+"/api/auth/notices/"+this.notice_id, formData, config)
        .then(data => {
          console.log(data);
           this.getNoticesInternal();
        });
    },
    obtenerImagen(e){
        let file = e.target.files[0];
        // console.log(file);
        this.photo = file;
        this.cargarImagen(file);
    },
    cargarImagen(file){
        let reader = new FileReader();
        reader.onload = (e) => {
            this.image = e.target.result;
        }
        reader.readAsDataURL(file);
    },
    truncateText(text, length = 230) {
      if (!text) return '';

      let plainText = text.replace(/<[^>]*>/g, '').replace(/&#8220;/gi, "").replace(/&#8221;/gi, "").replace(/&nbsp;/gi, "");
      let content = plainText.trim();
      if(content.length>length) {
        content = content.substring(0, length);
        content = content + ('(...)');
      }

      return content;
    },
  },
}
</script>

<style >
h4 {
    text-align: left;
}

.n_active {
    text-align: center;
    color: #47B29E;
    border: 1px solid #47B29E;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
    margin-left:15px!important;
    font-size:14px;
}

.card-body h6{
    text-align: left;
    font-size: 14px;
    font-weight: bold;
}

.card-body .card-text{
    text-align: left;
    font-size: 10px;
}

.n_inactive {
    text-align: center;
    letter-spacing: 0px;
    color: #B0B0B2;
    opacity: 1;
    background: #F4F6F8 0% 0% no-repeat padding-box;
    border-radius: 0px;
    opacity: 1;
    cursor: pointer;
    font-size: 14px;
    height: 18px;
}

.card-img {
    /*padding-top: 15px;*/
    padding-top: 0px!important;
}

.img-notice {
    max-height: 95px;
}

.hide {
  display: none;
}

.show {
  display: flex;
}

#card_noticias.scroll {
  max-height: 550px;
  overflow-y: auto;
}

.notices_scroll_full {
  max-height: 660px !important;
  min-height: 435px !important;
  overflow-y: auto !important;
}

.notices_scroll_dash {
  max-height: 435px !important;
  min-height: 435px !important;
  overflow-y: auto !important;
}

.notices_scroll_dash-notif {
  max-height: 240px !important;
  min-height: 240px !important;
  overflow-y: auto !important;
}

.col-md-4.offset-md-1{
  margin-left:0px!important;
}

/*#internal_btn{*/
/*  margin-left:15px!important;*/
/*}*/

/*#external_btn{*/
/*  margin-left:15px!important;*/
/*}*/

.card-title{
  margin-bottom: 0px!important;
}

.card-body{
  padding:0.5rem 1.25rem 0.5rem 1.25rem!important;
}

.n_active.col-md-3.offset-md-1{
  max-width: 50%!important;
}

.n_inactive.col-md-3.offset-md-1{
  max-width: 50%!important;
}

@media screen and (max-width: 1280px){
  .n_inactive{
    font-size:15px!important;
    padding-left:5px;
    padding-right:5px;
  }
  .n_active{
    font-size:15px!important;
    padding-left:5px;
    padding-right:5px;
  }
  .card-body h6{
    font-size:14px!important;
  }
}

.project_row img{
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.4 !important;
  /*object-fit: cover;
  object-position: center;*/
}

.card_title h4 {
  white-space: pre-line !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.outline-btn-disabled {
  border: 1.4px solid #f4f6f8 !important;
  background-color: #f4f6f8 !important;
  border-radius: 0px !important;
  color: #aaaaaa !important;
}

.notice_row {
  margin-bottom: 10px;
  width: 100%;
  aspect-ratio: 1.9 !important;
  /*object-fit: cover;
  object-position: center;*/
}

.btn-heart{
  border: none !important;
  background-color: transparent !important;
  color: #e31b23 !important;
  font-size: 14px !important;
  padding: 3px 3px !important;
  max-height: 28px !important;
  transition: 0.3s ease-in;
}
.btn-heart:hover  {
  color: #FFFFFF !important;
  background-color: #e31b23 !important;
}

.btn-heart svg{
  width:24px;
  height:24px;
  fill: #e31b23
}

.btn-heart:hover svg {
  fill: #FFFFFF  !important;
}

.btn-heart-liked {
  color: #FFFFFF !important;
  background-color: #e31b23 !important;
}

.btn-heart-liked svg {
  fill: #FFFFFF  !important;
}

.btn-comment{
  border: none !important;
  background-color: transparent !important;
  color: #f0900f !important;
  font-size: 14px !important;
  padding: 3px 3px !important;
  max-height: 28px;
  transition: 0.3s ease-in;
}
.btn-comment:hover  {
  color: #FFFFFF !important;
  background-color: #f0900f !important;
}

.btn-comment svg{
  width:24px;
  height:24px;
  fill: #f0900f;
  margin-top: -2px;
}

.btn-comment:hover svg {
  fill: #FFFFFF  !important;
}

.btn-comment-comented {
  color: #FFFFFF !important;
  background-color: #e31b23 !important;
}

.btn-comment-comented svg {
  fill: #FFFFFF  !important;
}
</style>
