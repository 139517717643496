<template>
    <div :class="{
      'mx-0 px-5' : !isMobile,
      'mx-0 px-2' : isMobile
    }">
        <b-row id="users" style="margin-bottom: 50px;" class="justify-content-center">
          <b-col xs="12" sm="12">
              <b-row align-v="center" class="mb-0 mt-4 px-0 mx-0">
                <b-col style="margin: 0; padding:0;" align-self="center" cols="12" sm="12" md="6">
                  <h3 :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0;`">Mensajes recibidos</h3>
                </b-col>
              </b-row>
          </b-col>
          <b-col xs="12" sm="12" md="12" class="pt-4 fix-things-calendar">
              <h3 class="mb-3" :style="`${isMobile ? 'text-align: center;' : ''} margin: 0 !important; padding:0; font-size: 22px;`">Mensajes nuevos</h3>
              <h4 v-if="!loadingNewMessages && newMessages.length < 1" class="mb-5" style="text-align: center;">No tiene mensajes nuevos para mostrar</h4>
              <div v-for="(message, index) in newMessages" :key="message.id" class="p-md-3 mb-4" style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 8px; background-color:#EBF0F4">
                <div class="d-flex">
                  <b-avatar variant="warning" v-if="message.user_from.image === '' || !message.user_from.image" :text="$showInitials(message.user_from.name, message.user_from.lastname)" size="3.5rem"></b-avatar>
                  <b-avatar variant="warning" v-else :src="setImage(message.user_from.image)" size="3.5rem"></b-avatar>
                  <div class="ml-2 d-flex flex-column justify-content-center" >
                      <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                          Enviado el: {{ formatDate(message.created_at) }}
                      </div>
                      <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                          {{ `${message.user_from.name} ${message.user_from.lastname ? message.user_from.lastname : ''}` }}
                      </div>
                      <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                          {{ message.user_from.position }}
                      </div>
                  </div>
              </div>
              <p class="mt-2 p-message-birthday">
                {{message.message}}
              </p>
              <div class="mt-0 p-message-birthday" v-if="isResponse(message.response_parent)">
                <div class="text-left" style="font-weight: bold;">Respuesta del mensaje:</div>
                <div class="text-left">{{message.response_parent[0].message}}</div>
              </div>
              <div class="mt-0 p-message-birthday d-flex justify-content-start" v-if="!isResponse(message.response_parent) && !message.isAnswering">
                <b-btn 
                  type="button" 
                  class="btn-ver"
                  size="lg"
                  v-if="!message.response_message.length"
                  style="min-height: 32px; width: 100px; font-size: 15px !important;"
                  @click="setAnswer(index, true, false)"
                  :disabled="loadingAnswerPost">
                  Responder
                </b-btn>
                <b-badge variant="success" v-if="message.response_message.length">Mensaje ya respondido</b-badge>
              </div>
              <div class="mt-0 p-message-birthday " v-if="!isResponse(message.response_parent) && message.isAnswering">
                <b-form-group
                    id="input-group-1"
                    label=""
                    label-for="input-1"
                    style="width:100%"
                >
                    <b-form-textarea
                        id="textarea"
                        v-model="message.answer"
                        rows="2"
                        max-rows="5"
                        maxlength="200"
                    ></b-form-textarea>
                </b-form-group>
                <div class="d-flex justify-content-start">
                  <b-btn 
                    type="button" 
                    class="btn-ver mr-2"
                    size="lg"
                    style="min-height: 32px; width: 100px; font-size: 15px !important;"
                    @click="setAnswer(index, false, false)"
                    :disabled="loadingAnswerPost">
                    Cancelar
                  </b-btn>
                  <b-btn 
                    type="button" 
                    class="btn-ver"
                    size="lg"
                    style="min-height: 32px; width: 160px; font-size: 15px !important;"
                    @click="answer(index, false)"
                    :disabled="message.answer === ''">
                    <span v-if="loadingAnswerPost" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                    {{ loadingAnswerPost ? "" : "Enviar respuesta" }}
                  </b-btn>
                </div>
              </div>
            </div>
            <template v-if="loadingNewMessages">
              <div class="d-flex justify-content-center align-content-center pt-4 pb-4" style="width:100%">
                <b-spinner ></b-spinner>
              </div>
            </template>
          </b-col>
          <b-col xs="12" sm="12" md="12" class="pt-4 fix-things-calendar">
              <h3 class="mb-3" :style="`${isMobile ? 'text-align: center;' : ''}padding:0; font-size: 22px;`">Mensajes antiguos</h3>
              <h4 v-if="!loadingOldMessages && oldMessages.length < 1" class=" mb-5" style="text-align: center;">No tiene mensajes antiguos para mostrar</h4>
              <div v-for="(message, index) in oldMessages" :key="message.id" class="p-md-3 mb-4" style=" box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 14px; border-radius: 8px; background-color:#EBF0F4">
                <div class="d-flex">
                  <b-avatar variant="warning" v-if="message.user_from.image === '' || !message.user_from.image" :text="$showInitials(message.user_from.name, message.user_from.lastname)" size="3.5rem"></b-avatar>
                  <b-avatar variant="warning" v-else :src="setImage(message.user_from.image)" size="3.5rem"></b-avatar>
                  <div class="ml-2 d-flex flex-column justify-content-center" >
                      <div style="font-size:11px;text-align:left;font-weight:400;" class="grayblue-text">
                          Enviado el: {{ formatDate(message.created_at) }}
                      </div>
                      <div style="font-size:12px;text-align:left;font-weight:500;" class="">
                          {{ `${message.user_from.name} ${message.user_from.lastname ? message.user_from.lastname : ''}` }}
                      </div>
                      <div style="font-size:12px;text-align:left;font-weight:400;" class="">
                          {{ message.user_from.position }}
                      </div>
                  </div>
              </div>
              <p class="mt-2 p-message-birthday">
                {{message.message}}
              </p>
              <div class="mt-0 p-message-birthday" v-if="isResponse(message.response_parent)">
                <div class="text-left" style="font-weight: bold;">Respuesta del mensaje:</div>
                <div class="text-left">{{message.response_parent[0].message}}</div>
              </div>
              <div class="mt-0 p-message-birthday d-flex justify-content-start" v-if="!isResponse(message.response_parent) && !message.isAnswering">
                <b-btn 
                  type="button" 
                  class="btn-ver"
                  size="lg"
                  v-if="!message.response_message.length"
                  style="min-height: 32px; width: 100px; font-size: 15px !important;"
                  @click="setAnswer(index, true, true)"
                  :disabled="loadingAnswerPost">
                  Responder
                </b-btn>
                <b-badge variant="success" v-if="message.response_message.length">Mensaje ya respondido</b-badge>
              </div>
              <div class="mt-0 p-message-birthday " v-if="!isResponse(message.response_parent) && message.isAnswering">
                <b-form-group
                    id="input-group-1"
                    label=""
                    label-for="input-1"
                    style="width:100%"
                >
                    <b-form-textarea
                        id="textarea"
                        v-model="message.answer"
                        rows="2"
                        max-rows="5"
                        maxlength="200"
                    ></b-form-textarea>
                </b-form-group>
                <div class="d-flex justify-content-start">
                  <b-btn 
                    type="button" 
                    class="btn-ver mr-2"
                    size="lg"
                    style="min-height: 32px; width: 100px; font-size: 15px !important;"
                    @click="setAnswer(index, false, true)"
                    :disabled="loadingAnswerPost">
                    Cancelar
                  </b-btn>
                  <b-btn 
                    type="button" 
                    class="btn-ver"
                    size="lg"
                    style="min-height: 32px; width: 160px; font-size: 15px !important;"
                    @click="answer(index, true)"
                    :disabled="message.answer === ''">
                    <span v-if="loadingAnswerPost" class="spinner-border spinner-border-sm mt-1 mx-2" role="status" aria-hidden="true"></span>
                    {{ loadingAnswerPost ? "" : "Enviar respuesta" }}
                  </b-btn>
                </div>
              </div>
            </div>
            <template v-if="loadingOldMessages">
              <div class="d-flex justify-content-center align-content-center pt-4 pb-4" style="width:100%">
                <b-spinner ></b-spinner>
              </div>
            </template>
          </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  components:{

  },
  name: 'Messages',
  data() {
      return {
        isMobile: false,
        isMd: false,
        dataUser: [ { id: 0 } ],
        newMessages: [],
        oldMessages: [],
        urlImgBack: process.env.VUE_APP_URL+'/storage/',
        loadingNewMessages:true,
        loadingOldMessages:true,
        loadingAnswerPost:false
      }
    },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    this.dataUser = JSON.parse(localStorage.userData)
    Promise.all([ 
        this.getMessagesUser(),
        this.getReadMessagesUser()
    ]).then(values=>{
        console.log(values)
    })
  },
  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 1200
      this.isMd = window.innerWidth > 770 && window.innerWidth < 1000
    },
    formatDateToWord(date) {
      return date ? moment(date).locale('es').format('DD [de] MMMM') : ''
    },
    formatDate(date){
      if (date === '' || !date) {
        return 'No asignada'
      }
      else {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    setImage(url) {
      return url ? this.urlImgBack + url : ''
    },
    getMessagesUser() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/get-messages/${this.dataUser[0].id}/false`;

      axios.get(url, config).then(async (data) => {
        this.newMessages = []
        if (data.data.data.length > 0) {
          data.data.data.forEach(element => {
            element.isAnswering = false
            element.answer = ''
          })
          this.newMessages = data.data.data.reverse()
        }
        this.loadingNewMessages = false
        this.newMessages.forEach(element => {
          this.readMessage(element)
        });
        //console.log(data)
      }).catch(error => {
        this.loadingNewMessages = false
        console.log(error)
      })
    },

    getReadMessagesUser() {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/get-messages/${this.dataUser[0].id}/true`;

      axios.get(url, config).then(async (data) => {
        this.oldMessages = []
        if (data.data.data.length > 0) {
          data.data.data.forEach(element => {
            element.isAnswering = false
            element.answer = ''
          })
          this.oldMessages = data.data.data.reverse()
          
        }
        this.loadingOldMessages = false
        //console.log(data)
      }).catch(error => {
        this.loadingOldMessages = false
        console.log(error)
      })
    },

    readMessage(message) {
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };

      let url = `${process.env.VUE_APP_URL}/api/auth/read-message/${message.id}`;

      axios.get(url, config).then(async (data) => {
        console.log(data)
      }).catch(error => {
        console.log(error)
      })
    },

    isResponse(value){
      if (value) {
        if (value.length) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    answer(value, old) {
      this.loadingAnswerPost = true
      
      const config = {
          headers: { Authorization: `Bearer ${localStorage.token}` }
      };
      let messageData = old ? this.oldMessages[value] : this.newMessages[value] 
      //user_from.id
      let url = process.env.VUE_APP_URL+'/api/auth/store-messages';

      let formData = new FormData();
      formData.append('from', messageData.to);
      formData.append('to', messageData.from);
      formData.append('parent_id', messageData.id);
      formData.append('response_id', messageData.id);
      let messagePayload = messageData.answer

      formData.append('message', messagePayload);

      axios.post(url, formData, config).then(async (data) => {
        console.log(data)
        this.loadingAnswerPost = false
        this.makeToast('success', '¡El mensaje ha sido enviado satisfactoriamente!', '¡Mensaje enviado!')
        old ? this.oldMessages[value].isAnswering = false : this.newMessages[value].isAnswering = false
        Promise.all([ 
          this.getMessagesUser(),
          this.getReadMessagesUser()
        ]).then(values=>{
            console.log(values)
        })
      }).catch(error => {
        console.log(error)
        this.loadingAnswerPost = false
        this.makeToast('danger', 'Ya has respondido anteriormente a este mensaje.', 'Error fatal.')
      })
    },

    setAnswer(index, value, old) {
      if (value) {
        this.oldMessages.forEach(element => {
          element.isAnswering = false
        });
        this.newMessages.forEach(element => {
          element.isAnswering = false
        });
      }
      old ? this.oldMessages[index].isAnswering = value : this.newMessages[index].isAnswering = value
    },

    makeToast(variant, body, title) {
      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true
      })
    },
  }
}
</script>

<style>

</style>